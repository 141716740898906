import React, { FC } from "react";
import { useSnackbar } from "notistack";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import { Alert, Box, Button, DialogActions } from "@mui/material";
import { declOfNum } from "../../../../../../helpers/declination";
import { useDeleteSportEquipmentMutation, useGetSportEquipmentsQuery } from "../../../../../../graphql/graphQlApiHooks";


interface Props {
  close: () => void;
  onSubmitted: () => void;
  equipments: string[];
}


const GroupDeleteSportEquipments: FC<Props> = (props: Props) => {
  const { close, equipments, onSubmitted } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { getInput } = useGetSportEquipmentInputVars();
  const [deleteSportEquipment] = useDeleteSportEquipmentMutation();

  const {refetch} = useGetSportEquipmentsQuery({variables: {input: getInput()}});

  const groupDeleteHandler = () => {

    const promises = equipments.map((id) => {
      return deleteSportEquipment({
        variables: { id: id as string }
      });
    });

    Promise.all(promises).then(() => {
      refetch().then(() => {
        onSubmitted();
        enqueueSnackbar("Оборудование успешно удалено");
      });
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          borderTop: "1px solid",
          borderColor: "divider"
        }}
      >
        <Alert severity='info'>
          <>
            {declOfNum(equipments.length, ["Выбрана", "Выбрано", "Выбрано"])}
            <b>{equipments.length}</b> {declOfNum(equipments.length, ["единица", "единицы", "единиц"])} оборудования. <br />
            <br />
            <b>Внимание!</b> При удалении оборудования, будут удалены все связанные с ним данные. <br />
            <br />
            <b>Вы уверены, что хотите продолжить удаление?</b>
          </>
        </Alert>
      </Box>

      <DialogActions>
        <Button
          onClick={close}
          color='secondary'
          size='small'
        >
          Отмена
        </Button>
        <Button
          color='secondary'
          size='small'
          variant='contained'
          onClick={() => {
            groupDeleteHandler();
          }}

        >
          Удалить оборудование
        </Button>

        {/*<LoadingButton*/}
        {/*  loading={isSubmitting}*/}
        {/*  size="small"*/}
        {/*  onClick={() => handleSubmit()}*/}
        {/*  variant="contained"*/}
        {/*>*/}
        {/*  Удалить оборудование*/}
        {/*</LoadingButton>*/}
      </DialogActions>
    </>
  );
};

export default GroupDeleteSportEquipments;