import { makeVar, ReactiveVar } from "@apollo/client";
import { SportObjectEntity, SportSubZoneEntity, SportZoneEntity } from "graphql/graphQlApiHooks";
import { SportEquipmentListFilters, sportEquipmentListFiltersVars } from "../spor-equipmnts/sportEquipmentListFilters";

export type SportObjectsVars = {
  sportObjectList: SportObjectEntity[];
  userSportObjectList: SportObjectEntity[];
  selectedSportObjectIds: string[];

  filteredSportZoneList: SportZoneEntity[];
  filteredSportSubZoneList: SportSubZoneEntity[];
}

const SPORT_OBJECTS_INITIAL = {
  sportObjectList: [],
  userSportObjectList: [],
  selectedSportObjectIds: [],

  filteredSportZoneList: [],
  filteredSportSubZoneList: [],
};

export const sportObjectsVars = makeVar<SportObjectsVars>(SPORT_OBJECTS_INITIAL);

export const useSportObjectsVars = () => createSportObjectsVars(sportObjectsVars, sportEquipmentListFiltersVars);

const createSportObjectsVars = (sportObjectsVars: ReactiveVar<SportObjectsVars>, sportEquipmentListFiltersVars: ReactiveVar<SportEquipmentListFilters>) => {

  const setSportObjectList = (sportObjectList: SportObjectEntity[]) => {
    const sportObjectsVar: SportObjectsVars = sportObjectsVars();

    const updatedSportObjectsVar: SportObjectsVars = {
      ...sportObjectsVar,
      sportObjectList
    };
    sportObjectsVars(updatedSportObjectsVar);


    let userSportObjectIds = JSON.parse(localStorage.getItem("user_sportObjects")!);
    if (!userSportObjectIds || userSportObjectIds.length === 0) {
      userSportObjectIds = sportObjectList.map(item => item.id);

      // localStorage.setItem('user_sportObjects', JSON.stringify(userSportObjectIds))
    }

    const userSportObjectList = sportObjectList.filter(item => userSportObjectIds.includes(item.id));

    setUserSportObjectList(userSportObjectList);


  };

  const setUserSportObjectList = (userSportObjectList: SportObjectEntity[]) => {
    const sportObjectsVar: SportObjectsVars = sportObjectsVars();

    const updatedSportObjectsVar: SportObjectsVars = {
      ...sportObjectsVar,
      userSportObjectList
    };
    sportObjectsVars(updatedSportObjectsVar);

    let selectedSportObjectIds = JSON.parse(localStorage.getItem("sportObjectIds")!);
    if (!selectedSportObjectIds) {
      selectedSportObjectIds = userSportObjectList.map(item => item.id);

      // localStorage.setItem("selectedSportObjectIds", selectedSportObjectIds);
    }

    setSelectedSportObjectIds(selectedSportObjectIds);

  };

  const setSelectedSportObjectIds = (selectedSportObjectIds: string[]) => {
    const sportObjectsVar: SportObjectsVars = sportObjectsVars();
    const selectedSportObjects = sportObjectsVar.sportObjectList.filter(item => selectedSportObjectIds.includes(item.id));
    const filteredSportZoneList = selectedSportObjects.map(item => item.sportZones).flat() as SportZoneEntity[];
    const filteredSportSubZoneList = selectedSportObjects.map(item => item.sportSubZones).flat() as SportSubZoneEntity[];
    const selectedSportZoneIds = filteredSportZoneList.map(item => item.id);
    const selectedSportSubZoneIds = filteredSportSubZoneList.map(item => item.id);

    const updatedSportObjectsVar: SportObjectsVars = {
      ...sportObjectsVar,
      selectedSportObjectIds,
      filteredSportZoneList,
      filteredSportSubZoneList
    };
    sportObjectsVars(updatedSportObjectsVar);

    const filters = sportEquipmentListFiltersVars()

    sportEquipmentListFiltersVars({...filters, sportZoneIds: selectedSportZoneIds, sportSubZoneIds: selectedSportSubZoneIds})

  };


  return {
    setSportObjectList,
    setUserSportObjectList,
    setSelectedSportObjectIds,
  };
};