import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    Collapse,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    InputAdornment,
    OutlinedInputProps,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {
    DictionaryEntity,
    DictionaryTypesEnum,
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    EquipmentTypeDictionaryEntity,
    FileStoreEntity,
    GetAllSportObjectsQuery,
    SportKindDictionaryEntity,
    SportObjectEntity,
    SportSubZoneEntity,
    SportZoneEntity,
    UpdateEquipmentRequestInput,
    useGetAllDictionariesQuery,
    useGetAllSportObjectsQuery,
    useGetFullDictionaryQuery,
    useUpdateEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {isValidUrl} from "helpers/validateUrl";
import {useReactiveVar} from "@apollo/client";
import {userVars} from "store/reactiveVarsStores/auth/user";
import {Helmet} from "react-helmet";
import {
    OtherDocumentsGrid
} from "../../../Equipments/components/Dialogs/views/SportEquipmentDocuments/OtherDocumentsGrid";
import {useSnackbar} from "notistack";

interface IEquipmentRequestEditDialogProps {
    close: (close: boolean) => void;
    open: boolean;
    row: EquipmentRequestEntity;
}

/**
 * Начальное состояние для валидации формы.
 */
const getInitialErrors = () => ({
    sportObject: undefined as string | undefined,
    sportZone: undefined as string | undefined,
    sportSubZone: undefined as string | undefined,
    name: undefined as string | undefined,
    sportKind: undefined as string | undefined,
    equipmentType: undefined as string | undefined,
    equipmentLink: undefined as string | undefined,
    analogEquipmentLink: undefined as string | undefined,
    urgentRequirementComment: undefined as string | undefined,
    serviceNoteScanId: undefined as string | undefined
});

/**
 * Начальное состояние для мутации редактирования заявки на оборудование.
 */
const getInitialInput = (entity: EquipmentRequestEntity) => (): UpdateEquipmentRequestInput => ({
    id: entity.id,
    sportObjectId: entity.sportObject.id,
    sportZoneId: entity.sportZone.id,
    sportSubZoneId: entity?.sportSubZone?.id,
    equipmentTypeId: entity.equipmentTypeObject?.id,
    sportKindId: entity.sportKindObject?.id,
    serviceNoteScanId: entity.serviceNoteScan?.id,
    unitOfMeasurementId: entity.unitOfMeasurement?.id,
    name: entity.name || "",
    count: Number(entity.count) || 1,
    description: entity.description || "",
    urgentRequirementComment: entity.urgentRequirementComment || "",
    equipmentLink: entity.equipmentLink || "",
    mandatorySpecifications: entity.mandatorySpecifications || "",
    price: entity.price || 0,
    analogEquipmentLink: entity.analogEquipmentLink || "",
    requestStatus: entity.requestStatus
});

/**
 * Диалог редактирования заявки на оборудование.
 */
export const EquipmentRequestEditDialog: FC<IEquipmentRequestEditDialogProps> = ({
                                                                                     row: equipmentRequest,
                                                                                     close,
                                                                                     open
                                                                                 }) => {
    const {enqueueSnackbar} = useSnackbar();

    const user = useReactiveVar(userVars);
    const [updateEquipmentRequest] = useUpdateEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests'],
    });
    const {data: {getAllSportObjects} = {} as GetAllSportObjectsQuery} = useGetAllSportObjectsQuery();
    const {data: allDictionaries} = useGetAllDictionariesQuery();
    const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds;
    const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes;

    const isFormFrozen = equipmentRequest.requestStatus !== EquipmentRequestStatusEnum.Submitted;

    const {data: {dictionaries: unitOfMeasurements = []} = {}} = useGetFullDictionaryQuery({
        variables: {dictionaryType: DictionaryTypesEnum.MeasurementUnit}
    });

    // Спортобъекты
    const [sportObjects_a1, setSportObjects_a1] = useState<SportObjectEntity[]>([]);
    const [sportObject_a1, setSportObject_a1] = useState<SportObjectEntity | null>(null);
    // Объекты спорта
    const [sportZones_a2, setSportZones_a2] = useState<SportZoneEntity[]>([]);
    const [sportZone_a2, setSportZone_a2] = useState<SportZoneEntity | null>(null);
    // Спортзоны
    const [sportSubZones_a3, setSportSubZones_a3] = useState<SportSubZoneEntity[]>([]);
    const [sportSubZone_a3, setSportSubZone_a3] = useState<SportSubZoneEntity | null>(null);
    // Виды спорта
    const [sportKinds_b1, setSportKinds_b1] = useState<SportKindDictionaryEntity[]>([]);
    const [sportKind_b1, setSportKind_b1] = useState<SportKindDictionaryEntity | null>(null);
    // Тип оборудования
    const [equipmentTypes_b2, setEquipmentTypes_b2] = useState<EquipmentTypeDictionaryEntity[]>([]);
    const [equipmentType_b2, setEquipmentType_b2] = useState<EquipmentTypeDictionaryEntity | null>(null);
    // Единица измерения
    const [unitOfMeasurement, setUnitOfMeasurement] = useState<DictionaryEntity | null>(null);
    // Флаг срочной потребности
    const [isCriticallyNeed, setIsCriticallyNeed] = useState(!!equipmentRequest.urgentRequirementComment);
    // Скан служебной записки с потребностью
    const [serviceNoteScan, setServiceNoteScan] = useState<FileStoreEntity | null>(null);
    const serviceNoteScanInputRef = React.useRef<HTMLInputElement>(null);
    // Input для мутации редактирования
    const [input, setInput] = useState<UpdateEquipmentRequestInput>(getInitialInput(equipmentRequest));
    // Ошибки валидации
    const [errors, setErrors] = useState(getInitialErrors);

    // ссылка на поле описания срочной потребности
    const criticallyNeedRef = useRef<HTMLInputElement>(null);

    // Фокус на поле описания срочной потребности при чеке срочной потребности
    useEffect(() => {
        if (isCriticallyNeed && criticallyNeedRef?.current) {
            criticallyNeedRef?.current.focus();
        }
    }, [criticallyNeedRef, isCriticallyNeed]);

    // При открытии диалога обновляем Input
    useEffect(() => {
        if (getAllSportObjects) {
            setSportObjects_a1(getAllSportObjects as SportObjectEntity[]);
            if (equipmentRequest) {
                setInput(getInitialInput(equipmentRequest));
                setUnitOfMeasurement(equipmentRequest.unitOfMeasurement || null);
                setServiceNoteScan(equipmentRequest.serviceNoteScan || null);
                setIsCriticallyNeed(!!equipmentRequest.urgentRequirementComment);
                setSportObject_a1(equipmentRequest.sportObject);
                const sportObjectResult = [...getAllSportObjects].find((o) => o.id === equipmentRequest.sportObject.id);
                setSportZones_a2(sportObjectResult?.sportZones as SportZoneEntity[] || []);
                setSportZone_a2(equipmentRequest.sportZone || null);
                setSportSubZones_a3(sportObjectResult?.sportSubZones as SportSubZoneEntity[] || []);
                setSportSubZone_a3(equipmentRequest?.sportSubZone || null);

                setSportKind_b1(equipmentRequest?.sportKindObject || null);
                setSportKinds_b1(sportKinds as SportKindDictionaryEntity[]);
                setEquipmentType_b2(equipmentRequest?.equipmentTypeObject || null);
                setEquipmentTypes_b2(equipmentRequest?.sportKindObject?.equipmentTypes || []);
            }
        }
    }, [getAllSportObjects, equipmentRequest, !!open]);

    useMemo(() => {
        sportObject_a1 && setInput({...input, sportObjectId: sportObject_a1.id});
    }, [sportObject_a1]);

    useMemo(() => {
        sportZone_a2 && setInput({...input, sportZoneId: sportZone_a2.id});
    }, [sportZone_a2]);

    useMemo(() => {
        sportSubZone_a3 && setInput({...input, sportSubZoneId: sportSubZone_a3.id});
    }, [sportSubZone_a3]);

    /* ====================================== */
    /* ========== Действия диалога ========== */
    /* ====================================== */

    const onSubmit = async () => {

        const nextErrors: typeof errors = {
            name: !input.name?.trim()
                ? "Вы не указали название оборудования" : undefined,

            sportObject: !input.sportObjectId
                ? "Вы не выбрали спортобъект" : undefined,
            sportZone: !input.sportZoneId
                ? "Вы не выбрали объект спорта" : undefined,
            sportSubZone: !input.sportSubZoneId
                ? "Вы не выбрали спортзону" : undefined,

            sportKind: !input.sportKindId
                ? "Вы не выбрали вид спорта" : undefined,
            equipmentType: !input.equipmentTypeId
                ? "Вы не выбрали тип оборудования" : undefined,

            serviceNoteScanId: !input.serviceNoteScanId
                ? "Вы не загрузили скан служебной записки" : undefined,

            urgentRequirementComment: isCriticallyNeed && !input.urgentRequirementComment?.trim()
                ? "Вы не указали причину срочной потребности" : undefined,
            equipmentLink: input.equipmentLink?.trim() && !isValidUrl(input.equipmentLink?.trim())
                ? "Ссылка на оборудование должна быть валидным URL" : undefined,
            analogEquipmentLink: input.analogEquipmentLink?.trim() && !isValidUrl(input.analogEquipmentLink?.trim())
                ? "Ссылка на аналоги оборудования должна быть валидным URL" : undefined
        };
        setErrors(nextErrors);
        if (Object.values(nextErrors).every((error) => !error)) {
            try {
                await updateEquipmentRequest({variables: {input}});
                handleCloseModal();
                enqueueSnackbar("Заявка обновлена", {variant: "success"});
            } catch (error) {
                enqueueSnackbar("Ошибка при обновлении заявки", {variant: "error"});
                console.error("Ошибка при обновлении заявки:", error);
            }
        }
    };

    const handleCloseModal = () => {
        setErrors(getInitialErrors);
        close(false);
    };

    /* ======================================== */
    /* ========== Хэндлеры изменений ========== */
    /* ======================================== */

    // Изменение Спортобъекта
    const onChangeSportObjectHandler = (value: SportObjectEntity) => {
        setSportObject_a1(value);
        value && setInput({...input, sportObjectId: value.id});
        setSportZones_a2(value?.sportZones || []);
        onChangeSportZoneHandler(null);
    };
    // Изменение Объекта спорта
    const onChangeSportZoneHandler = (sportZone: SportZoneEntity | null) => {
        sportZone?.id && setInput({...input, sportZoneId: sportZone.id as string});
        setSportZone_a2(sportZone);
        setSportSubZones_a3(sportZone?.sportSubZones ?? []);
        setSportSubZone_a3(null);
    };
    // Изменение Спортзоны
    const onChangeSportSubZoneHandler = (sportSubZone: SportSubZoneEntity | null) => {
        sportSubZone?.id && setInput({...input, sportSubZoneId: sportSubZone.id as string});
        setSportSubZone_a3(sportSubZone);
    };
    // Изменение Вида спорта
    const onChangeSportKindHandler = (sportKind: SportKindDictionaryEntity | null) => {
        setSportKind_b1(sportKind);
        if (sportKind && sportKind?.equipmentTypes) {
            setInput({...input, sportKindId: sportKind.id as string});
            setEquipmentTypes_b2(sportKind.equipmentTypes as EquipmentTypeDictionaryEntity[]);
        } else {
            setInput({...input, sportKindId: ""});
            setEquipmentTypes_b2([]);
        }
        setEquipmentType_b2(null);
    };
    // Изменение Типа оборудования
    const onChangeEquipmentTypeHandler = (equipmentType: EquipmentTypeDictionaryEntity | null) => {
        setEquipmentType_b2(equipmentType);
        if (equipmentType?.sportKind && equipmentType?.sportKind?.id !== sportKind_b1?.id) {
            setSportKind_b1(equipmentType?.sportKind);
            const sportKind = sportKinds_b1.find((kind: SportKindDictionaryEntity) => kind.id === equipmentType?.sportKind?.id);
            if (sportKind && sportKind?.equipmentTypes) {
                setInput({...input, sportKindId: sportKind.id as string});
                setEquipmentTypes_b2(sportKind?.equipmentTypes as EquipmentTypeDictionaryEntity[]);
            }
        }
        if (equipmentType && equipmentType?.equipmentSpecifications) {
            setInput({...input, equipmentTypeId: equipmentType.id as string});
        }
    };
    // Изменение единицы измерения
    const onChangeUnitOfMeasurement = (unit?: DictionaryEntity | null) => {
        setUnitOfMeasurement(unit);
        setInput({...input, unitOfMeasurementId: unit?.id || null});
    };
    // Изменение количества
    const onClickCountUp = () => setInput({...input, count: input.count + 1});
    const onClickCountDown = () => setInput({...input, count: Math.max(1, input.count - 1)});
    const onChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => setInput({
        ...input,
        count: Math.max(1, Number(event.target.value))
    });
    // Изменение цены
    const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => setInput({
        ...input,
        price: Math.max(0, Number(event.target.value))
    });

    /* ========================= */
    /* ========== JSX ========== */
    /* ========================= */

    return (
        <>

            <Helmet>
                <title>
                    {
                        input?.name ? `Редактирование заявки на спортивное оборудование | ${input?.name}` : "Редактирование заявки на спортивное оборудование"
                    }
                </title>
            </Helmet>

            <DialogContent
                dividers
                sx={{
                    bgcolor: "background.default",
                    py: 2,
                    px: 3,
                }}
            >
                <Stack spacing={3}>
                    {/*/* ========================================================= */}
                    {/*/* =============== Расположение оборудования =============== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack
                            direction='column'
                            spacing={2}
                        >
                            <Typography variant={"h5"}>
                                Расположение оборудования
                            </Typography>

                            <Stack direction={'row'} spacing={2}>
                                <Autocomplete
                                    /* ======================================================== */
                                    /* =================== Спортобъект ======================== */
                                    /* ======================================================== */
                                    value={sportObject_a1}
                                    id='sportObject'
                                    fullWidth
                                    options={sportObjects_a1}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: SportObjectEntity) => onChangeSportObjectHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        {...isFormFrozen && {InputProps: {readOnly: true}}}
                                        required
                                        error={!!errors.sportObject}
                                        helperText={errors.sportObject}
                                        label='Спортобъект'
                                        placeholder='Выберите спортобъект'
                                        size={"small"}
                                        variant='filled'
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{disableUnderline: true,}}
                                    />}
                                />
                                <Autocomplete
                                    /* =================================== */
                                    /* ========== Объект спорта ========== */
                                    /* =================================== */
                                    value={sportZone_a2}
                                    id='sportZone'
                                    fullWidth
                                    disabled={sportZones_a2.length === 0}
                                    options={sportZones_a2}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: any) => onChangeSportZoneHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        {...isFormFrozen && {InputProps: {readOnly: true}}}
                                        label='Объект спорта'
                                        size='small'
                                        placeholder='Выберите объект спорта'
                                        error={!!errors.sportZone}
                                        helperText={errors.sportZone}
                                        variant='filled'
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{disableUnderline: true,}}
                                    />}
                                />
                                <Autocomplete
                                    /* =============================== */
                                    /* ========== Спортзона ========== */
                                    /* =============================== */
                                    value={sportSubZone_a3}
                                    id='sportZone'
                                    fullWidth
                                    disabled={sportSubZones_a3.length === 0}
                                    options={sportSubZones_a3}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: any) => onChangeSportSubZoneHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}

                                        {...isFormFrozen && {InputProps: {readOnly: true}}}
                                        label='Спортзона'
                                        placeholder='Выберите спортзону'
                                        size='small'
                                        error={!!errors.sportSubZone}
                                        helperText={errors.sportSubZone}
                                        variant='filled'
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{disableUnderline: true,}}
                                    />}
                                />
                            </Stack>
                        </Stack>
                    </Card>

                    {/*/* ========================================================= */}
                    {/*/* ==================== Общая информация =================== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Общая информация
                            </Typography>
                            <Stack
                                direction='row'
                                gap={2}
                            >
                                <TextField
                                    /* ======================================================== */
                                    /* ========== Название необходимого оборудования ========== */
                                    /* ======================================================== */
                                    required
                                    value={input.name}
                                    onChange={(event) => setInput({...input, name: event.target.value})}
                                    id='equipmentName'
                                    label='Название необходимого оборудования'
                                    sx={{flexGrow: 1}}
                                    placeholder='Укажите название'
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    variant={"filled"}
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: {
                                            fontWeight: 600,
                                        }
                                    }}
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    /* ================================ */
                                    /* ========== Количество ========== */
                                    /* ================================ */
                                    required
                                    sx={{width: "18%", ".MuiInputBase-input": {textAlign: "center"}}}
                                    id='equipmentCount'
                                    value={input.count}
                                    onChange={onChangeCount}
                                    type={"number"}
                                    variant='filled'
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                onClick={onClickCountDown}
                                                edge='start'
                                                size='small'
                                            > <RemoveIcon fontSize='small'/></IconButton>
                                        </InputAdornment>,
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                onClick={onClickCountUp}
                                                edge='start'
                                                size='small'
                                            > <AddIcon fontSize='small'/> </IconButton>
                                        </InputAdornment>,
                                        sx: {
                                            gap: 2,
                                            '& .MuiFilledInput-input': {
                                                py: 3,
                                            }
                                        }
                                    } as Partial<OutlinedInputProps>}
                                    InputLabelProps={{
                                        shrink: false,
                                        style: {display: "none"}
                                    }}
                                />

                                <Autocomplete
                                    /* ================================ */
                                    /* ====== Единицы измерения ====== */
                                    /* ================================ */
                                    sx={{width: "17%"}}
                                    value={unitOfMeasurement || null}
                                    id='unitOfMeasurement'
                                    options={unitOfMeasurements}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: any) => {
                                        onChangeUnitOfMeasurement(value)
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label='Единицы измерения'
                                        variant={"filled"}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />}
                                />
                            </Stack>

                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <Autocomplete
                                    /* ================================ */
                                    /* ========== Вид спорта ========== */
                                    /* ================================ */
                                    value={sportKind_b1}
                                    disabled={sportKinds.length === 0}
                                    id='sportKind'
                                    fullWidth
                                    options={sportKinds_b1}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: any) => onChangeSportKindHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label='Вид спорта'
                                        variant={"filled"}
                                        required={true}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder='Выберите вид спорта'
                                        size={"small"}
                                        error={!!errors.sportKind}
                                        helperText={errors.sportKind}
                                    />}
                                />
                                <Autocomplete
                                    /* ====================================== */
                                    /* ========== Тип оборудования ========== */
                                    /* ====================================== */
                                    value={equipmentType_b2}
                                    disabled={sportKind_b1?.equipmentTypes?.length === 0}
                                    id='equipmentType'
                                    fullWidth
                                    options={equipmentTypes_b2.length > 0 ? equipmentTypes_b2 : equipmentTypes}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}
                                    onChange={(e, value: EquipmentTypeDictionaryEntity | null) => onChangeEquipmentTypeHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label='Тип оборудования'
                                        variant={"filled"}
                                        required={true}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder='Выберите тип оборудования'
                                        size={"small"}
                                        error={!!errors.equipmentType}
                                        helperText={errors.equipmentType}
                                    />}
                                />
                            </Stack>

                        </Stack>
                    </Card>

                    {/*/* ========================================================= */}
                    {/*/* =====================  Детали заявки ==================== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Детали заявки
                            </Typography>

                            <Stack spacing={2}>
                                <FormControl
                                    /* ================================================= */
                                    /* ========== Срочная потребность ========== */
                                    /* ================================================= */
                                    size={"small"}
                                    variant={"filled"}
                                    component="fieldset"
                                    sx={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FormLabel component="legend" sx={{
                                        fontSize: "1rem",
                                        transform: " scale(0.75)",
                                        transformOrigin: "top left"
                                    }}>
                                        Срочная потребность
                                    </FormLabel>
                                    <FormControlLabel
                                        id={"isCriticallyNeed"}
                                        label={isCriticallyNeed ? "Да" : "Нет"}
                                        sx={{
                                            ml: 0
                                        }}

                                        control={
                                            <Switch
                                                name="isCriticallyNeed"
                                                color="primary"
                                                size={"small"}
                                                onChange={({target: {checked}}) => {
                                                    setIsCriticallyNeed(checked)
                                                    !checked && setInput({
                                                        ...input,
                                                        urgentRequirementComment: ""
                                                    });
                                                }}
                                                checked={isCriticallyNeed}
                                            />
                                        }
                                    />
                                </FormControl>

                                <Collapse in={isCriticallyNeed} sx={{width: "100%"}}>
                                    <TextField
                                        autoComplete="off"
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        inputRef={criticallyNeedRef}
                                        fullWidth
                                        required
                                        label={"Причина срочности"}
                                        multiline={true}
                                        minRows={3}
                                        maxRows={3}
                                        InputLabelProps={{
                                            shrink: true
                                        }}

                                        placeholder={"Почему оборудование необходимо срочно?"}
                                        size={"small"}

                                        name={"urgentRequirementComment"}
                                        value={input?.urgentRequirementComment}
                                        onChange={(e) => setInput({...input, urgentRequirementComment: e.target.value})}
                                        error={!!errors.urgentRequirementComment}
                                        helperText={errors.urgentRequirementComment}
                                    />
                                </Collapse>
                            </Stack>

                            <Stack
                                direction='column'
                                gap={2}
                            >
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        /* ================================================= */
                                        /* ========== Обязательные характеристики ========== */
                                        /* ================================================= */
                                        value={input.mandatorySpecifications}
                                        onChange={e => setInput({...input, mandatorySpecifications: e.target.value})}
                                        fullWidth
                                        label='Обязательные характеристики'
                                        placeholder='Обязательные характеристики'
                                        size='small'
                                        variant={"filled"}
                                        InputProps={{disableUnderline: true}}
                                        InputLabelProps={{shrink: true}}
                                    />
                                    <TextField
                                        /* ========================== */
                                        /* ========== Цена ========== */
                                        /* ========================== */
                                        sx={{width: "25%"}}
                                        label='Цена за единицу'
                                        value={input.price}
                                        onChange={onChangePrice}
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: <InputAdornment position="end">₽</InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        type='number'
                                        placeholder='Цена за единицу'
                                        size='small'
                                    />
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        /* ============================================ */
                                        /* ========== Ссылка на оборудование ========== */
                                        /* ============================================ */
                                        value={input.equipmentLink}
                                        onChange={e => setInput({...input, equipmentLink: e.target.value})}
                                        fullWidth
                                        size={"small"}
                                        label='Ссылка на оборудование'
                                        placeholder='Ссылка на оборудование'
                                        error={!!errors.equipmentLink}
                                        helperText={errors.equipmentLink}
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        /* =================================================== */
                                        /* ========== Ссылка на аналог оборудования ========== */
                                        /* =================================================== */
                                        value={input.analogEquipmentLink}
                                        onChange={e => setInput({...input, analogEquipmentLink: e.target.value})}
                                        fullWidth
                                        label='Ссылка на аналог оборудования'
                                        placeholder='Ссылка на аналог оборудования'
                                        size='small'
                                        error={!!errors.analogEquipmentLink}
                                        helperText={errors.analogEquipmentLink}
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Stack>
                                <FormControl size={"small"}
                                             fullWidth
                                             variant={"filled"}
                                             component="fieldset"
                                             sx={{px: '6px'}}
                                             error={!!errors.serviceNoteScanId}
                                             required={true}
                                >
                                    <FormLabel component="legend" sx={{
                                        fontSize: "1rem",
                                        transform: "scale(0.75)",
                                        transformOrigin: "top left",
                                        mb: -2
                                    }}>
                                        Прикрепите скан служебной записки с потребностью
                                    </FormLabel>
                                    <OtherDocumentsGrid
                                        documents={serviceNoteScan ? [serviceNoteScan] : null}
                                        setDocuments={(value) => {
                                            const document = value[0] || null
                                            setInput({...input, serviceNoteScanId: document?.id || null})
                                            setServiceNoteScan(document)
                                        }}
                                        multiple={false}
                                        id={'serviceNoteScanId'}
                                        isEditable={true}
                                    />
                                    {errors.serviceNoteScanId && (
                                        <FormHelperText error={true}>
                                            {errors.serviceNoteScanId}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>

                        </Stack>
                    </Card>


                    {/*/* ========================================================= */}
                    {/*/* ===============  Дополнительная информация ============== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Дополнительная информация
                            </Typography>


                            <TextField
                                /* ========================================================= */
                                /* ========== Дополнительная информация по заявке ========== */
                                /* ========================================================= */
                                value={input.description}
                                onChange={(e) => setInput({...input, description: e.target.value})}
                                id='equipmentDescription'
                                label='Дополнительная информация по заявке'
                                placeholder='Укажите дополнительную информацию к заявке'
                                multiline
                                rows={4}
                                fullWidth
                                size={"small"}
                                variant={"filled"}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Stack>
                    </Card>

                </Stack>
            </DialogContent>

            <DialogActions>
                {!isFormFrozen ? <>
                    <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        form='editEquipmentRequest'
                        onClick={onSubmit}
                    >
                        Сохранить изменения
                    </Button>
                </> : <>
                    <Button
                        onClick={handleCloseModal}
                        variant='contained'
                        color='inherit'
                    >
                        Закрыть
                    </Button>
                </>}
            </DialogActions>
        </>
    );
};
