import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Box} from "@mui/system";
import {
    EquipmentRepairRequestStatusEnum,
    useEquipmentRepairRequestAddStatusMutation
} from "../../../../../../graphql/graphQlApiHooks";
import {LoadingButton} from "@mui/lab";

interface Props {
    open: boolean,
    onClose: () => void,
    equipmentRepairRequestId: string
}
const IncludedInContractDialog: FC<Props> = ({open, onClose, equipmentRepairRequestId}) => {
    const [comment, setComment] = React.useState('');
    const [addStatus, {loading}] = useEquipmentRepairRequestAddStatusMutation()

    const submitHandler = () => {
        addStatus({
            variables: {
                equipmentRepairRequestId,
                state: {
                    status: EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract,
                    comment
                }
            }
        }).then(() => {
            onClose();
            setComment('')
        })
    };

    return (

        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setComment('')
            }}
        >
            <DialogTitle>
                Включение в контракт на ремонт
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Укажите номер контракта в комментарии к заявке
                </DialogContentText>
                <Box
                    mt={2}
                >
                    <TextField
                        label="Номер контракта"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        placeholder={'Номер контракта'}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        size={'small'}
                        required={true}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                        setComment('');
                    }}
                    size={'small'}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={!comment}
                    variant={'contained'}
                    size={'small'}
                    onClick={submitHandler}
                >
                    Включено в контракт на ремонт
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
};

export default IncludedInContractDialog;