import { makeVar, useReactiveVar } from "@apollo/client";
import { cloneDeep } from "lodash";
import { FileStoreEntity } from "../../../../../../graphql/graphQlApiHooks";

const initialState = {
  isOpen: false,
  sportEquipmentCardOnClose: undefined as undefined | (() => void),
  sportEquipmentId: undefined as undefined | string,
  wroteOffCertificate: undefined as undefined | FileStoreEntity
};

const SportEquipmentWriteOffDialogState = makeVar(cloneDeep(initialState));

export const useSportEquipmentWriteOffDialogState = () => useReactiveVar(SportEquipmentWriteOffDialogState);

/**
 * Открыть диалог списания спортивного оборудования
 */
export const SportEquipmentWriteOffDialogOpen = (sportEquipmentId: string, sportEquipmentCardOnClose?: () => void) => SportEquipmentWriteOffDialogState({
  ...cloneDeep(initialState),
  isOpen: true,
  sportEquipmentCardOnClose,
  sportEquipmentId
});

/**
 * Закрыть диалог списания спортивного оборудования
 */
export const SportEquipmentWriteOffDialogClose = () => SportEquipmentWriteOffDialogState(cloneDeep(initialState));

/**
 * Установить идентификатор акта списания
 */
export const SportEquipmentWriteOffDialogSetWroteOffCertificate = (wroteOffCertificate: FileStoreEntity) => SportEquipmentWriteOffDialogState({
  ...SportEquipmentWriteOffDialogState(),
  wroteOffCertificate
});
