import {
    EquipmentRepairRequestEntity,
    EquipmentTypeDictionaryEntity,
    SportKindDictionaryEntity,
    SportObjectEntity,
    TechnicalCondition
} from "../../../../graphql/graphQlApiHooks";
import {Link} from "react-router-dom";
import EquipmentRowName from "../../../../components/EquipmentRowName";
import Tooltiper from "../../../../components/Tooltiper";
import {TagStatusRepairRequest} from "./TagStatusRepairRequest";
import HistoryRepairRequest from "./HistoryRepairRequest";
import ActionsRepairRequest from "./ActionsRepairRequest";
import React from "react";
import {GridColumns} from "@mui/x-data-grid";
import {useTheme} from "@mui/styles";
import {Maybe} from "graphql/jsutils/Maybe";

const SportObjectCell = ({inUse, sportObject}: { inUse: boolean, sportObject: SportObjectEntity }) => {
    const theme = useTheme();
    return <Tooltiper
        text={sportObject?.name}
        props={{
            variant: !sportObject?.name ? "body2" : "subtitle1",
            style: {
                ...(!inUse && {color: theme.palette.grey["500"]})
            }
        }}
    />;
};

const EquipmentTypeCell = ({inUse, equipmentTypeObject}: {
    inUse: boolean,
    equipmentTypeObject: Maybe<EquipmentTypeDictionaryEntity>
}) => {
    const theme = useTheme();
    return <Tooltiper
        text={equipmentTypeObject?.name}
        props={{
            variant: !equipmentTypeObject?.name ? "body2" : "subtitle1",
            style: {
                ...(!inUse && {color: theme.palette.grey["500"]})
            }
        }}
    />;
};

const SportKindCell = ({inUse, sportKindObject}: {
    sportKindObject: Maybe<SportKindDictionaryEntity>,
    inUse: boolean
}) => {
    const theme = useTheme();
    return <Tooltiper
        text={sportKindObject?.name}
        props={{
            variant: !sportKindObject?.name ? "body2" : "subtitle1",
            style: {
                ...(!inUse && {color: theme.palette.grey["500"]})
            }
        }}
    />;
};

export const columns: GridColumns<EquipmentRepairRequestEntity> = [
    {
        field: 'equipment.name',
        headerName: "Оборудование",
        renderCell: (
            {
                row: {
                    sportEquipment: {
                        name,
                        inUse,
                        inventoryNumber,
                        technicalCondition,
                        failureReason,
                        photos,
                        mainPhoto,
                        id,
                        isEditable
                    }
                }
            }: { row: EquipmentRepairRequestEntity }
        ) => {
            return (
                <Link to={`/repairs-request/equipments/${id}`}>
                    <EquipmentRowName
                        name={name}
                        inUse={inUse}
                        inventoryNumber={inventoryNumber}
                        technicalCondition={technicalCondition === TechnicalCondition.Working ? true : false}
                        failureReason={failureReason}
                        avatarUrl={mainPhoto ? mainPhoto.avatarUrl : (photos && photos?.length > 0) && photos?.[0].avatarUrl}
                        avatarUrlOrigin={mainPhoto ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].url}
                        isEditable={isEditable}
                    />
                </Link>
            );
        },
        minWidth: 300,
        flex: 2.5,
        disableColumnMenu: true,
        editable: false
    },
    {
        field: 'equipment.sportObject.name',
        headerName: "Спорт объект",
        editable: false,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row: {sportEquipment: {sportObject, inUse}}}: { row: EquipmentRepairRequestEntity }) => {
            return (
                <SportObjectCell sportObject={sportObject} inUse={inUse}/>
            );
        },
    },
    {
        field: 'equipment.equipmentTypeObject.name',
        headerName: "Тип оборудования",
        editable: false,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({row: {sportEquipment: {equipmentTypeObject, inUse}}}: { row: EquipmentRepairRequestEntity }) => {
            return (
                <EquipmentTypeCell equipmentTypeObject={equipmentTypeObject} inUse={inUse}/>
            );
        },
    },
    {
        field: 'equipment.sportKind.sportKindObject.name',
        headerName: "Вид спорта",
        editable: false,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        flex: 1,

        renderCell: ({row: {sportEquipment: {sportKindObject, inUse}}}: { row: EquipmentRepairRequestEntity }) => {
            return (
                <SportKindCell sportKindObject={sportKindObject} inUse={inUse}/>

            );
        },
    },
    {
        field: 'stateHistory.status',
        headerName: "Статус заявки",
        renderCell: (
            {
                row: {
                    stateHistory
                }
            }: { row: EquipmentRepairRequestEntity }
        ) => {
            return (
                <TagStatusRepairRequest stateHistory={stateHistory}/>
            );
        },
        disableColumnMenu: true,
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 1,
    },
    {
        field: 'stateHistory',
        headerName: "История заявки",
        renderCell: (
            {
                row: {
                    stateHistory,
                    sportEquipment
                }
            }: { row: EquipmentRepairRequestEntity }
        ) => {
            return (
                <HistoryRepairRequest
                    stateHistory={stateHistory}
                    equipment={sportEquipment}
                />
            );
        },
        disableColumnMenu: true,
        sortable: false,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 1,
    },
    {
        field: 'actions',
        headerName: "Действия",
        renderCell: (
            {
                row: equipmentRepairRequest
            }: { row: EquipmentRepairRequestEntity }
        ) => {
            return (
                <ActionsRepairRequest
                    equipmentRepairRequest={equipmentRepairRequest}
                />
            );
        },
        disableColumnMenu: true,
        sortable: false,
        editable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 2.5,
    },
]