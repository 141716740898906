import React from 'react';
import {useDialog} from "providers/DialogProvider";
import {MultiplySuccessRequestsDialog} from "../../components/Dialogs/MultiplySuccessRequestsDialog";

export const useMultiplySuccessRequests = () => {
    const [dialog, closeDialog] = useDialog();

    const multiplySuccessRequests = ({ids, onSuccess}: { ids: string[], onSuccess: () => void }) => {
        dialog({
            onClose: closeDialog,
            title: "Приём оборудования в работу",
            children: <>
                <MultiplySuccessRequestsDialog
                    equipmentIds={ids}
                    close={() => {
                        closeDialog();
                        onSuccess();
                    }}
                />
            </>
        });
    };

    return {multiplySuccessRequests};
};

