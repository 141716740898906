import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button, Popover, TextField} from '@mui/material';
import {darken, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import {useStores} from "../../../../../store";


const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	sportZoneCreatePanel: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 220,
		'& .MuiFilledInput-root': {
			background: darken(theme.palette.background.default, 0.05),
			'&:before': {
				borderColor: theme.palette.divider
			},
			'&:hover, &.Mui-focused': {
				background: darken(theme.palette.background.default, 0.01)
			}
		}
	},
	sportZoneCreatePanelInput: {},
	sportZoneCreatePanelBottomPanel: {
		display: 'none',
		'&.showPanel': {
			display: 'flex',
			justifyContent: 'space-between'
		}
	}
}));

function EditSportKind({...rest}) {
	const classes = useStyles();
	const inputName = useRef<HTMLInputElement | null>(null)
	const [showPanel, setShowPanel] = useState<boolean>(false)
	const {dictionariesStore} = useStores();

	const [inputNameValue, setInputNameValue] = useState('')


	const editSportKind = () => {
		if (inputNameValue !== '' && rest.sportKind.id) {

			const input = {
				id: rest.sportKind.id,
				name: inputNameValue,
			}
			dictionariesStore.updateSportKindDictionary(input).finally(rest.onClose)
		}
	};


	useMemo(() => {
		if (rest.sportKind) {
			setShowPanel((
					inputNameValue !== rest.sportKind.name
			))
		}
	}, [rest.sportKind, inputNameValue])

	useEffect(() => {
		inputName.current?.focus()
	}, [rest.anchorEl, inputName])

	useEffect(() => {
		if (rest.sportKind) {
			setInputNameValue(rest.sportKind.name)
		}
	}, [rest.sportKind])

	function onClose() {
		if (rest.sportKind) {
			setInputNameValue(rest.sportKind.name)
			rest.onClose()
		}
	}

	return (
			<>
				{!rest?.sportKind ? null :
						<Popover
								id="sportZone-create-panel"
								anchorEl={rest.anchorEl}
								keepMounted
								open={Boolean(rest.anchorEl)}
								onClose={onClose}
								className={classes.sportZoneCreatePanel}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
						>
							<form
									autoComplete="off"
									className={classes.sportZoneCreatePanel}
							>
								<TextField
										onChange={(e) => setInputNameValue(e.target.value)}
										value={inputNameValue}
										inputRef={inputName}
										id="name"
										label="Название"
										variant="filled"
										size={'small'}
										autoComplete='off'
										tabIndex={0}
										autoFocus
										multiline
										fullWidth
								/>
							</form>
							<Box
									className={clsx(classes.sportZoneCreatePanelBottomPanel, showPanel ? 'showPanel' : null)}
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										p: 1
									}}
							>
								<Button onClick={onClose}
								        color={'secondary'}
								        sx={{
									        py: .5,
									        px: 2
								        }}
								>Отмена</Button>
								<Button
										onClick={editSportKind}
										color="primary"
										variant={'contained'}
										sx={{
											py: .5,
											px: 2
										}}
								>Сохранить</Button>
							</Box>

						</Popover>
				}
			</>
	)
}

EditSportKind.protoTypes = {}

export default EditSportKind;
