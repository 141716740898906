import {ActionButtonsProps, DialogsStates} from "../ActionButtons";
import {MenuItem} from "@mui/material";
import {DepartmentEnum} from "graphql/graphQlApiHooks";
import React from "react";
import {EquipmentRelocationRequestStatusEnum} from "store/stores/equipment-relocation-request-status.enum";
import RequestForAdmission from "../../Dialogs/RequestForAdmission";
import RelocateConfirmation from "../../Dialogs/RelocateConfirmation";
import AcceptTransportRequest from "../../Dialogs/AcceptTransportRequest";
import RejectTransportRequest from "../../Dialogs/RejectTransportRequest";
import RelocateCompletion from "../../Dialogs/RelocateCompletion";
import EquipmentRelocationHistory from "../../Dialogs/EquipmentRelocationHistory";
import {useNavigate} from "react-router-dom";

const MenuItems = (props: ActionButtonsProps) => {
    const {
        relocateEquipment,
    } = props;
    const status = relocateEquipment.status as EquipmentRelocationRequestStatusEnum;


    // Состояния диалогов
    const [dialogsStates, setDialogsStates] = React.useState<
        DialogsStates & {
        history: boolean
    }
    >({
        equipmentDetails: false,  // Информация по оборудованию
        equipmentRelocationHistory: false,  // История перемещений
        requestForAdmission: false, // Подача заявки на запрос получания оборудования
        acceptRelocation: false, // Одобрение заявки на перемещение
        rejectRelocation: false, // Отклонение заявки на перемещение
        relocateConfirmation: false, // Подтверждение отправления оборудования
        relocateCompletion: false, // Подтверждение получения оборудования
        acceptTransportRequest: false, // Подтверждение заявки в транспортную службу из Softra
        rejectTransportRequest: false, // Отклонение заявки в транспортную службу из Softra
        history: false
    });


    const onOpenRequestForAdmission = () => {
        setDialogsStates({...dialogsStates, requestForAdmission: true});
    };
    const handleCloseRequestForAdmission = () => {
        setDialogsStates({...dialogsStates, requestForAdmission: false});
    };

    const onOpenAcceptTransportRequest = () => {
        setDialogsStates({...dialogsStates, acceptTransportRequest: true});
    };
    const handleCloseAcceptTransportRequest = () => {
        setDialogsStates({...dialogsStates, acceptTransportRequest: false});
    };

    const onOpenRejectTransportRequest = () => {
        setDialogsStates({...dialogsStates, rejectTransportRequest: true});
    };
    const handleCloseRejectTransportRequest = () => {
        setDialogsStates({...dialogsStates, rejectTransportRequest: false});
    };

    const onOpenRelocateConfirmation = () => {
        setDialogsStates({...dialogsStates, relocateConfirmation: true});
    };
    const handleCloseRelocateConfirmation = () => {
        setDialogsStates({...dialogsStates, relocateConfirmation: false});
    };

    const onOpenRelocateCompletion = () => {
        setDialogsStates({...dialogsStates, relocateCompletion: true});
    };
    const handleCloseRelocateCompletion = () => {
        setDialogsStates({...dialogsStates, relocateCompletion: false});
    };

    const onOpenHistory = () => {
        setDialogsStates({...dialogsStates, history: true});
    };
    const handleCloseHistory = () => {
        setDialogsStates({...dialogsStates, history: false});
    };


    const Actions = () => {
        const userDepartments: DepartmentEnum[] = JSON.parse(localStorage.getItem('user_departments') || '[]');
        const userSportObjectIds: string[] = JSON.parse(localStorage.getItem('sportObjectIds') || '[]');


        // Пользователь с правами отдела платных услуг
        const isDepartmentPlanningAndOrganizationPaidServices = userDepartments.includes(DepartmentEnum.PlanningAndOrganizationPaidServices);

        // Пользователь с правами отдела приёмки
        const isDepartmentEquipmentAcceptanceAndDiagnostics = userDepartments.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics);

        // Администраторы
        const isDepartmentAdmin = userDepartments.includes(DepartmentEnum.Admins);

        // Люблй пользователь из спорт. объекта нахождения с правами и без
        const isUserFromSourceSportObjectBase = userSportObjectIds?.includes(relocateEquipment?.sourceSportObject?.id);

        // Обычный пользователь из спорт. объекта нахождения без прав
        const isUserFromSourceSportObject = isUserFromSourceSportObjectBase && !isDepartmentPlanningAndOrganizationPaidServices && !isDepartmentAdmin;

        // Люблй пользователь  из спорт. объекта назначения с правами и без
        const isUserFromTargetSportObject = userSportObjectIds?.includes(relocateEquipment?.targetSportObject?.id);

        /**
         * Итем "Отклонить" для сотрудников отдела приёмки.
         * Должна быть доступна всегда, кроме конечных статусов заявки.
         */
        const KillMenuItem = () => isDepartmentEquipmentAcceptanceAndDiagnostics
            ? <MenuItem
                onClick={onOpenRejectTransportRequest}
                sx={{
                    color: 'grey.500',
                }}
            >Отклонить</MenuItem>
            : null;

        switch (status) {
            /** Запрос подан */
            case EquipmentRelocationRequestStatusEnum.RequestSubmitted:
                return <>
                    {!isUserFromSourceSportObject || isDepartmentEquipmentAcceptanceAndDiagnostics &&
                        <MenuItem
                            onClick={onOpenRequestForAdmission}
                            sx={{
                                color: 'primary.main'
                            }}
                        >Запросить оборудование</MenuItem>}
                    <KillMenuItem/>
                </>;

            /** Запрос принят спорт. объектом */
            case EquipmentRelocationRequestStatusEnum.AcceptedBySportObject:
            case EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment:  // Запрос принял спорт. объект
                return <>
                    {isDepartmentEquipmentAcceptanceAndDiagnostics ?
                        <MenuItem
                            onClick={onOpenAcceptTransportRequest}
                            sx={{
                                color: 'primary.main'
                            }}
                        >Одобрить</MenuItem>
                        : null}
                    <KillMenuItem/>
                </>;

            /** Запрос одобрен, но оборудование не отправлено */
            case EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment:
                return <>
                    {isUserFromTargetSportObject &&
                        <MenuItem
                            onClick={onOpenRelocateConfirmation}
                            sx={{
                                color: 'primary.main'
                            }}
                        >Подтвердить отправление</MenuItem>
                    }
                    <KillMenuItem/>
                </>;


            /** Запрос одобрен, но оборудование не перемещено */
            case EquipmentRelocationRequestStatusEnum.RelocationStarted:
                return <>
                    {isUserFromTargetSportObject &&
                        <MenuItem
                            onClick={onOpenRelocateCompletion}
                            sx={{
                                color: 'primary.main'
                            }}
                        >Подтвердить приём</MenuItem>}
                    <KillMenuItem/>
                </>;


            case EquipmentRelocationRequestStatusEnum.Relocated:
            case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
            case EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment:
                return null;
            default: {
                console.error(`Unknown status: ${status satisfies never}`);
                return null;
            }
        }
    };

    const navigate = useNavigate();

    return (
        <>
            <MenuItem
                onClick={() => {
                    navigate(`/relocateEquipments/equipments/${relocateEquipment.equipment.id}`);
                }}
            >
                Просмотр оборудования
            </MenuItem>

            <Actions/>
            <MenuItem
                onClick={onOpenHistory}
            >
                История перемещений
            </MenuItem>



            <EquipmentRelocationHistory
                open={dialogsStates.history}
                onClose={handleCloseHistory}
                data={relocateEquipment}
            />

            <RequestForAdmission
                open={dialogsStates.requestForAdmission}
                onClose={handleCloseRequestForAdmission}
                data={relocateEquipment}
            />

            <RelocateConfirmation
                open={dialogsStates.relocateConfirmation}
                onClose={handleCloseRelocateConfirmation}
                data={relocateEquipment}
            />

            <AcceptTransportRequest
                open={dialogsStates.acceptTransportRequest}
                onClose={handleCloseAcceptTransportRequest}
                data={relocateEquipment}
            />

            <RejectTransportRequest
                open={dialogsStates.rejectTransportRequest}
                onClose={handleCloseRejectTransportRequest}
                data={relocateEquipment}
            />

            <RelocateCompletion
                open={dialogsStates.relocateCompletion}
                onClose={handleCloseRelocateCompletion}
                data={relocateEquipment}
            />

        </>
    );
};

export default MenuItems