import {DepartmentEnum, EquipmentRelocationRequestEntity} from 'graphql/graphQlApiHooks';
import {Box, Button} from '@mui/material';
import React from 'react';
import {EquipmentRelocationRequestStatusEnum} from 'store/stores/equipment-relocation-request-status.enum';
import RequestForAdmission from '../../Dialogs/RequestForAdmission';
import RejectRelocation from '../../Dialogs/RejectRelocation';
import RelocateConfirmation from '../../Dialogs/RelocateConfirmation';
import AcceptTransportRequest from '../../Dialogs/AcceptTransportRequest';
import RelocateCompletion from '../../Dialogs/RelocateCompletion';
import RejectTransportRequest from '../../Dialogs/RejectTransportRequest';

export interface ActionButtonsProps {
    relocateEquipment: EquipmentRelocationRequestEntity,
}

export interface DialogsStates {
    equipmentDetails: boolean;
    equipmentRelocationHistory: boolean;
    requestForAdmission: boolean;
    acceptRelocation: boolean;
    rejectRelocation: boolean;
    relocateConfirmation: boolean;
    relocateCompletion: boolean;
    acceptTransportRequest: boolean;
    rejectTransportRequest: boolean;
}

const ActionButtons = (props: ActionButtonsProps) => {
    const {
        relocateEquipment,
    } = props;
    const status = relocateEquipment.status as EquipmentRelocationRequestStatusEnum;


    // Состояния диалогов
    const [dialogsStates, setDialogsStates] = React.useState<DialogsStates>({
        equipmentDetails: false,  // Информация по оборудованию
        equipmentRelocationHistory: false,  // История перемещений
        requestForAdmission: false, // Подача заявки на запрос получания оборудования
        acceptRelocation: false, // Одобрение заявки на перемещение
        rejectRelocation: false, // Отклонение заявки на перемещение
        relocateConfirmation: false, // Подтверждение отправления оборудования
        relocateCompletion: false, // Подтверждение получения оборудования
        acceptTransportRequest: false, // Подтверждение заявки в транспортную службу из Softra
        rejectTransportRequest: false // Отклонение заявки в транспортную службу из Softra
    });


    const onOpenRequestForAdmission = () => {
        setDialogsStates({...dialogsStates, requestForAdmission: true});
    };
    const handleCloseRequestForAdmission = () => {
        setDialogsStates({...dialogsStates, requestForAdmission: false});
    };


    const handleCloseRejectRelocation = () => {
        setDialogsStates({...dialogsStates, rejectRelocation: false});
    };


    const onOpenRelocateConfirmation = () => {
        setDialogsStates({...dialogsStates, relocateConfirmation: true});
    };
    const handleCloseRelocateConfirmation = () => {
        setDialogsStates({...dialogsStates, relocateConfirmation: false});
    };


    const onOpenRelocateCompletion = () => {
        setDialogsStates({...dialogsStates, relocateCompletion: true});
    };
    const handleCloseRelocateCompletion = () => {
        setDialogsStates({...dialogsStates, relocateCompletion: false});
    };


    const onOpenAcceptTransportRequest = () => {
        setDialogsStates({...dialogsStates, acceptTransportRequest: true});
    };
    const handleCloseAcceptTransportRequest = () => {
        setDialogsStates({...dialogsStates, acceptTransportRequest: false});
    };


    const onOpenRejectTransportRequest = () => {
        setDialogsStates({...dialogsStates, rejectTransportRequest: true});
    };
    const handleCloseRejectTransportRequest = () => {
        setDialogsStates({...dialogsStates, rejectTransportRequest: false});
    };


    const Actions = () => {
        const userDepartments: DepartmentEnum[] = JSON.parse(localStorage.getItem('user_departments') || '[]');
        const userSportObjectIds: string[] = JSON.parse(localStorage.getItem('sportObjectIds') || '[]');

        // Пользователь с правами отдела платных услуг
        const isDepartmentPlanningAndOrganizationPaidServices = userDepartments.includes(DepartmentEnum.PlanningAndOrganizationPaidServices);

        // Пользователь с правами отдела приёмки
        const isDepartmentEquipmentAcceptanceAndDiagnostics = userDepartments.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics);

        // Администраторы
        const isDepartmentAdmin = userDepartments.includes(DepartmentEnum.Admins);

        // Люблй пользователь из спорт. объекта нахождения с правами и без
        const isUserFromSourceSportObjectBase = userSportObjectIds?.includes(relocateEquipment?.sourceSportObject?.id);

        // Обычный пользователь из спорт. объекта нахождения без прав
        const isUserFromSourceSportObject = isUserFromSourceSportObjectBase && !isDepartmentPlanningAndOrganizationPaidServices && !isDepartmentAdmin;

        // Люблй пользователь  из спорт. объекта назначения с правами и без
        const isUserFromTargetSportObject = userSportObjectIds?.includes(relocateEquipment?.targetSportObject?.id);

        /**
         * Кнопка "Отклонить" для сотрудников отдела приёмки.
         * Должна быть доступна всегда, кроме конечных статусов заявки.
         */
        const KillButton = () => isDepartmentEquipmentAcceptanceAndDiagnostics
            ? <Button size="small" color="secondary" onClick={onOpenRejectTransportRequest}>Отклонить</Button>
            : null;

        switch (status) {
            /** Запрос подан */
            case EquipmentRelocationRequestStatusEnum.RequestSubmitted:
                return <>
                    <KillButton/>
                    {!isUserFromSourceSportObject || isDepartmentEquipmentAcceptanceAndDiagnostics &&
                        <Button size="small" onClick={onOpenRequestForAdmission} variant="contained" color="info">Запросить
                            оборудование</Button>}
                </>;
            /** Запрос принял спорт. объект */
            case EquipmentRelocationRequestStatusEnum.AcceptedBySportObject:
            case EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment:
                return isDepartmentEquipmentAcceptanceAndDiagnostics ? <>
                    <Button size="small" color="secondary" onClick={onOpenRejectTransportRequest}>Отклонить</Button>
                    <Button size="small" variant="contained" onClick={onOpenAcceptTransportRequest}>Одобрить</Button>
                </> : null;
            /** Запрос одобрен, но оборудование не отправлено */
            case EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment:
                return <>
                    <KillButton/>
                    {isUserFromSourceSportObjectBase &&
                        <Button size="small" variant="contained" onClick={onOpenRelocateConfirmation}>Подтвердить
                            отправление</Button>}
                </>;
            /** Запрос одобрен, но оборудование не перемещено */
            case EquipmentRelocationRequestStatusEnum.RelocationStarted:
                return <>
                    <KillButton/>
                    {isUserFromTargetSportObject &&
                        <Button size="small" variant="contained" onClick={onOpenRelocateCompletion}>Подтвердить
                            приём</Button>}
                </>;
            case EquipmentRelocationRequestStatusEnum.Relocated:
            case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
            case EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment:
                return null;
            default: {
                console.error(`Unknown status: ${status satisfies never}`);
                return null;
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'end',
                width: '100%',
                "& .MuiButton-root": {
                    px: 1,
                    py: .5,
                    mr: 1,
                    fontSize: '12px',
                    "&:last-child": {mr: 0}
                }
            }}
        >
            <Actions/>


            <RequestForAdmission
                open={dialogsStates.requestForAdmission}
                onClose={handleCloseRequestForAdmission}
                data={relocateEquipment}
            />


            <RejectRelocation
                open={dialogsStates.rejectRelocation}
                onClose={handleCloseRejectRelocation}
                data={relocateEquipment}
            />

            <RelocateConfirmation
                open={dialogsStates.relocateConfirmation}
                onClose={handleCloseRelocateConfirmation}
                data={relocateEquipment}
            />

            <AcceptTransportRequest
                open={dialogsStates.acceptTransportRequest}
                onClose={handleCloseAcceptTransportRequest}
                data={relocateEquipment}
            />

            <RejectTransportRequest
                open={dialogsStates.rejectTransportRequest}
                onClose={handleCloseRejectTransportRequest}
                data={relocateEquipment}
            />

            <RelocateCompletion
                open={dialogsStates.relocateCompletion}
                onClose={handleCloseRelocateCompletion}
                data={relocateEquipment}
            />

        </Box>
    );
};

export default ActionButtons
