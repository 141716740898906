import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStyles} from "components/Dialogs/Dialog";

import {useTheme} from "@mui/styles";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Hidden,
    IconButton,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Theme} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import {AntTab, AntTabs} from "components/AntTabs";
import {TabPanel} from "components/TabPanel";
import Gallery from "components/Gallery";

import Form from "./Form";

import {useStores} from "store";
import Settings from "store/settings";
import {Image} from "store/stores/gallery.store";

import {FileStoreEntity, SportEquipmentEntity} from "graphql/graphQlApiHooks";
import BasicDocuments from "components/Dialogs/BasicDocuments";
import OtherDocuments, {UploadButton} from "components/Dialogs/OtherDocuments";
import {BootstrapTooltip} from "components/BootstrapTooltip";

export const useStylesUpload = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: "100%",
        backdropFilter: "blur(10px)",
        "& .MuiDialogContent-root": {
            padding: 0
        },
        [theme.breakpoints.down("md")]: {
            margin: 0,
            borderRadius: 0,
            alignSelf: "end"
        }
    },
    uploadArea: {
        marginTop: "1.25rem",
        border: "none",
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        backgroundColor: "transparent",
        padding: "1rem 1rem",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        transition: "all .3s",
        justifyContent: "space-between",
        fontSize: 13.3333,
        fontWeight: 400,
        "&:hover, &:focus": {
            cursor: "pointer",
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232e44ff' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            "& .uploadIcon": {
                fill: theme.palette.primary.main
            }
        },
        "& .upload-area-icon": {
            display: "block",
            width: "2.25rem",
            height: "2.25rem",
            marginRight: ".5rem",
            "& svg": {
                maxHeight: "100%",
                maxWidth: "100%"
            }
        },
        "& .upload-area-file": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        "& .uploadIcon": {
            transition: "all .3s",
            fill: theme.palette.text.secondary
        },
        "& .upload-area-titles": {
            marginLeft: "1rem"
            // opacity: .8
        },
        "& .upload-area-title": {
            display: "block",
            fontWeight: "700",
            color: theme.palette.text.primary,
            textAlign: "left"
        },
        "& .upload-area-description": {
            display: "block",
            color: theme.palette.text.secondary,
            "& strong": {
                fontWeight: "700",
                color: theme.palette.primary.main
            }
        }

    },
    dialogContent: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            overflow: "initial",
            height: "auto"
        }
    },
    sidebar: {
        width: 280,
        flexGrow: 0,
        flexBasis: 280,
        flexShrink: 0,
        position: "relative",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)"
    },
    sidebarContainer: {
        padding: "1rem 1rem 0",
        position: "absolute",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        overflowY: "auto"
    },
    main: {
        width: "100%",
        height: "100%",
        position: "relative",
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
            overflow: "initial"
        }
    },
    documents: {
        position: "absolute",
        height: "calc(100% - 49px)",
        width: "100%",
        overflowY: "auto",
        padding: "1rem 2rem",
        "& .document:not(:first-child)": {
            marginTop: "1rem"
        }
    },
    avatar: {
        marginRight: ".5em",
        border: `2px solid ${theme.palette.primary.main}`,
        padding: "2px",
        "& img": {
            borderRadius: "100%"
        }
    },
    titleInput: {
        fontSize: "18px",
        fontWeight: 500
    }

}));

export interface IFile {
    description: string;
    id: string;
    document?: any | null;
}

export const basicButtonUploads: IFile[] = [
    {
        description: "Акт ввода в эксплуатацию",
        id: "commissioningCertificates"
    },
    {
        description: "Паспорт",
        id: "passport"
    },
    {
        description: "Сертификат",
        id: "certificate"
    },
    {
        description: "Гарантийный талон",
        id: "guarantee"
    },
    {
        description: "Руководство по эксплуатации",
        id: "manual"
    }
];


const EquipmentDetails = observer((props: {
    equipment: SportEquipmentEntity | null,
    onClose: () => void,
    open: boolean
}) => {
    const classes = useStyles();
    const classesUpload = useStylesUpload();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const {equipment: sportEquipment, onClose, open} = props;
    const {galleryStore} = useStores();

    const [value, setValue] = React.useState(0);
    const [equipmentPhotos, setEquipmentPhotos] = React.useState<any[]>([]);
    const [basicDocuments, setBasicDocuments] = React.useState<IFile[]>([]);
    const [otherDocuments, setOtherDocuments] = React.useState<IFile[]>([]);
    const descriptionElementRef = React.useRef<HTMLDivElement>(null);
    const handleChangeAntTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(0);
    }, [open]);


    useEffect(() => {
        if (sportEquipment) {
            const photos: Image[] = sportEquipment?.photos as Image[] || [];
            if (photos.length > 0) {
                galleryStore.setImages(photos.map(photo => {
                    return {
                        ...photo,
                        url: Settings.server + photo.url,
                        rotate: 0,
                        subHtml: sportEquipment.name,
                        mainPhoto: Boolean(sportEquipment?.mainPhoto?.id === photo?.id)
                    };
                }));
            } else {
                galleryStore.clear();
            }

            const setBasicDocument = (buttonUpload: IFile) => {
                delete buttonUpload.document;

                const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key],
                    key: any = buttonUpload.id,
                    document = getKeyValue<keyof SportEquipmentEntity, SportEquipmentEntity>(key)(sportEquipment as SportEquipmentEntity) as Array<FileStoreEntity>;

                if (document && document?.length && document[0]) buttonUpload.document = document[0];

                return buttonUpload;
            };

            setBasicDocuments(
                basicButtonUploads.map(buttonUpload => setBasicDocument(buttonUpload)).filter(item => item.document)
            );

            const otherDocumentsData = sportEquipment?.otherDocuments;
            if (otherDocumentsData && otherDocumentsData.length > 0) {
                setOtherDocuments(otherDocumentsData.map(document => {
                    return {
                        description: document.description,
                        id: document.id,
                        document: document
                    };
                }));
            }

        }
    }, [sportEquipment]);


    const handleCloseModal = () => {
        setOtherDocuments([]);
        basicButtonUploads.map(document => {
            delete document.document;
            return document;
        });
        galleryStore.clear();
        onClose();
    };


    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                maxWidth={"md"}
                classes={{paper: classesUpload.root}}
                hideBackdrop={matches}
            >
                <Box
                    className={classes.root}
                >
                    <DialogTitle
                        classes={{root: classes.dialogTitle}}
                    >
                        <Typography
                            variant={"h5"}
                            align={"center"}
                            component={"div"}
                        > Просмотр оборудования </Typography>
                        <IconButton
                            className={classes.closeButton}
                            onClick={onClose}
                            size="large"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>

                    {sportEquipment ? <>
                        <div className={classes.titles}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                width={"100%"}
                                sx={{
                                    padding: theme.spacing(3, 2),
                                    boxShadow: `0 -1px 0 0 ${theme.palette.divider} inset`
                                }}
                            >
                                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>

                                    <Stack direction={"column"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Avatar
                                                sx={{
                                                    width: 30,
                                                    height: 30,
                                                    bgcolor: theme.palette.background.default
                                                }}
                                                src={`${Settings.server}${sportEquipment?.photos?.[0]?.avatarUrl}`}
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/mossport_logo.svg`}
                                                    alt="mossport_logo"
                                                />
                                            </Avatar>
                                            <Box>
                                                <Typography variant={"h4"} component={"div"}>
                                                    {sportEquipment?.name}
                                                </Typography>
                                                <BootstrapTooltip title={"Бухгалтерское название"}>
                                                    <Typography variant={"body2"} component={"div"}>
                                                        {sportEquipment?.accountingName}
                                                    </Typography>
                                                </BootstrapTooltip>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"column"}>
                                        <Typography variant={"caption"} component={"div"} textAlign={"right"}>
                                            Инвентарный номер
                                        </Typography>
                                        <Typography variant={"h5"} component={"div"} textAlign={"right"}>
                                            {sportEquipment?.inventoryNumber}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </div>
                        <DialogContent className={classesUpload.dialogContent}>
                            <Hidden mdDown>
                                <Box className={classesUpload.sidebar}>
                                    <Box className={classesUpload.sidebarContainer}>
                                        <Form equipment={sportEquipment}/>
                                    </Box>
                                </Box>
                            </Hidden>

                            <Box className={classesUpload.main}>
                                <Box
                                    ref={descriptionElementRef}
                                    tabIndex={-1}
                                >
                                    <AntTabs
                                        sx={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 2,
                                            background: theme.palette.background.paper
                                        }}
                                        value={value}
                                        onChange={handleChangeAntTab}
                                        aria-label="ant"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >

                                        {matches && < AntTab label={`Информация по оборудованию`}/>}
                                        <AntTab
                                            label="Фото"
                                        />
                                        <AntTab
                                            label="Описание и документы"
                                            disabled={basicDocuments.length === 0 && otherDocuments.length === 0 && !sportEquipment?.specifications?.[0]?.valueString}
                                        />
                                    </AntTabs>

                                    <Hidden mdUp>

                                        <TabPanel
                                            value={value}
                                            index={(matches) ? 0 : 3}
                                        >
                                            <Box
                                                sx={{
                                                    p: 2
                                                }}
                                            >
                                                <Form equipment={sportEquipment}/>
                                            </Box>
                                        </TabPanel>
                                    </Hidden>

                                    <TabPanel
                                        value={value}
                                        index={(matches) ? 1 : 0}
                                    >
                                        <Box
                                            sx={{
                                                p: theme.spacing(2),
                                                position: "absolute",
                                                height: "calc(100% - 49px)",
                                                width: "100%",
                                                "& label": {
                                                    cursor: "default"
                                                }
                                            }}
                                        >
                                            <Gallery readOnly/>

                                        </Box>

                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index={(matches) ? 2 : 1}
                                    >
                                        <Typography sx={{padding: "1rem 2rem 0"}}>
                                            Описание оборудования
                                        </Typography>
                                        {(sportEquipment?.specifications?.[0]?.valueString) ?
                                            <Typography sx={{padding: "1rem 2rem 0"}}>
                                                {sportEquipment?.specifications?.[0]?.valueString}
                                            </Typography>
                                            :

                                            <Typography sx={{padding: "1rem 2rem 0", opacity: .5}}
                                                        variant={"body2"}>
                                                Нет описания
                                            </Typography>
                                        }
                                        <Typography sx={{padding: "1rem 2rem 0"}}>
                                            Основные документы
                                        </Typography>
                                        {basicDocuments.length > 0 ?
                                            <BasicDocuments
                                                readOnly={true}
                                                documents={basicDocuments}
                                                onDocumentsChange={setBasicDocuments}
                                            />
                                            :
                                            <Typography sx={{padding: "1rem 2rem 0", opacity: .5}}
                                                        variant={"body2"}>
                                                Нет документов
                                            </Typography>
                                        }
                                        <Box
                                        >
                                            {otherDocuments.length > 0 ? <OtherDocuments
                                                    readOnly={true}
                                                    documents={otherDocuments}
                                                    onDocumentsChange={(otherDocuments: UploadButton[]) => {
                                                        setOtherDocuments(otherDocuments);
                                                    }}
                                                /> :
                                                <>
                                                    <Typography sx={{padding: "1rem 2rem 0"}}>
                                                        Прочие документы
                                                    </Typography>
                                                    <Typography sx={{padding: "1rem 2rem 0", opacity: .5}}
                                                                variant={"body2"}>
                                                        Нет документов
                                                    </Typography>
                                                </>
                                            }
                                        </Box>
                                    </TabPanel>


                                </Box>
                            </Box>

                        </DialogContent>
                    </> : <Box sx={{
                        backgroundColor: "#45454529",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <CircularProgress/>
                    </Box>}


                    <DialogActions>
                        <Button
                            color={"primary"}
                            className={classes.submitButton}
                            variant="contained"
                            autoFocus
                            form="editEquipmentForm"
                            onClick={handleCloseModal}
                            disabled={!sportEquipment}
                        >
                            Ок
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
});

export default EquipmentDetails;
