import React, {FC, useCallback} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip
} from '@mui/material';
import {
    useEquipmentRepairRequestsQuery,
    useExportEquipmentRepairRequestsInExcelLazyQuery
} from '../../../../graphql/graphQlApiHooks';
import {useSnackbar} from 'notistack';
import {downloadFile} from '../../../../helpers/downloadFile';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {LoadingButton} from "@mui/lab";
import {SiMicrosoftexcel} from "react-icons/si";
import {useReactiveVar} from "@apollo/client";
import {
    repairRequestsListPaginationVars
} from "../../../../store/reactiveVarsStores/repair-requests/repairRequestsListPagination";
import {equipmentRepairRequestsFiltersVar} from "../../../../store/reactiveVarsStores/repair-requests/filters";
import {sportObjectsVars} from "../../../../store/reactiveVarsStores/sport-objects/sportObjects";
import Settings from "../../../../store/settings";


/**
 * Кнопка экспорта заявок на ремонт оборудования.
 */
export const ExportEquipmentRepairRequestsInExcelButton: FC = () => {

    const pagination = useReactiveVar(repairRequestsListPaginationVars);
    const filters = useReactiveVar(equipmentRepairRequestsFiltersVar);

    const {
        selectedSportObjectIds,
        filteredSportZoneList,
        filteredSportSubZoneList,
    } = useReactiveVar(sportObjectsVars);


    // Заявки на ремонт оборудование

    const {data: equipmentRepairRequests} = useEquipmentRepairRequestsQuery({
        variables: {
            filter: {
                // текущие значения фильтров
                ...filters,
                sportObjectIds: selectedSportObjectIds,
                sportZoneIds: !filters.sportZoneIds ? filteredSportZoneList.map(o => o.id) : filters.sportZoneIds,
                sportSubZoneIds: !filters.sportSubZoneIds ? filteredSportSubZoneList.map(o => o.id) : filters.sportSubZoneIds,
                // текущие значения пагинации
                skip: pagination?.page * pagination?.pageSize,
                take: pagination?.pageSize
            },
        },
        pollInterval: Settings.pollIntervals.sportEquipments
    });


    // Прочее
    const [exportRepairRequests, {loading}] = useExportEquipmentRepairRequestsInExcelLazyQuery({fetchPolicy: 'no-cache'});

    const {enqueueSnackbar} = useSnackbar();

    // Получение текста для подсказки
    const getTooltipText = (count: number) => {
        const lastDigits = count.toString().slice(-2);
        return lastDigits.endsWith('1') && !lastDigits.endsWith('11')
            ? `из ${count} заявки`
            : `из ${count} заявок`;
    };
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [open, setOpen] = React.useState(false);
    const options = ['Экспорт выбранных заявок', 'Экспорт всех заявок'];
    const [selectedIndex, setSelectedIndex] = React.useState(1);


    const handleClick = useCallback(() => {
        exportRepairRequests({
            variables: {
                filter: {
                    ...(selectedIndex === 1 ? {
                        sportObjectIds: selectedSportObjectIds
                    } : {
                        // текущие значения фильтров
                        ...filters,
                        sportObjectIds: selectedSportObjectIds,
                        sportZoneIds: !filters.sportZoneIds ? filteredSportZoneList.map(o => o.id) : filters.sportZoneIds,
                        sportSubZoneIds: !filters.sportSubZoneIds ? filteredSportSubZoneList.map(o => o.id) : filters.sportSubZoneIds,
                        // текущие значения пагинации
                        skip: pagination?.page * pagination?.pageSize,
                        take: pagination?.pageSize
                    }),
                }
            }
        })
            .then((res) => downloadFile(res.data.exportEquipmentReapairRequestsInExcel))
            .catch((err) => enqueueSnackbar(`Ошибка экспорта файла: ${err?.message}`, {variant: 'error'}));
    }, [selectedIndex]);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (

        <Box>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Tooltip
                    title={selectedIndex === 0 ?
                        `Сгенерировать Excel файл ${getTooltipText(equipmentRepairRequests?.equipmentRepairRequests?.equipmentRepairRequests?.length || 0)}` :
                        'Сгенерировать Excel файл из всех заявок'}
                >
                    <LoadingButton
                        onClick={handleClick}
                        startIcon={<SiMicrosoftexcel/>}
                        disabled={loading || (!equipmentRepairRequests?.equipmentRepairRequests?.equipmentRepairRequests?.length && selectedIndex === 0)}
                        loading={loading}
                        variant='contained'
                        size='small'
                    >{options[selectedIndex]}</LoadingButton>
                </Tooltip>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    variant='contained'
                    sx={{
                        padding: '0',
                    }}
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            disabled={index === 2}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};
