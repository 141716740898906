import { makeVar, ReactiveVar } from "@apollo/client";
import { SortSportEquipmentsColumnsEnum } from "./sort-sport-equipments-columns.enum";
import { SortDirectionEnum } from "./sort-direction.enum";

export type SportEquipmentListSortOrder = {
  [key in SortSportEquipmentsColumnsEnum as string]: SortDirectionEnum | null | undefined;
}

export const SportEquipmentListSortOrderInitial: SportEquipmentListSortOrder = {
  [SortSportEquipmentsColumnsEnum.INVENTORY_NUMBER]: SortDirectionEnum.DESC
};

export const sportEquipmentListSortOrderVar = makeVar<SportEquipmentListSortOrder>(
  SportEquipmentListSortOrderInitial
);

export const useSportEquipmentListSortOrderVar = () => createSportEquipmentListSortOrderVar(sportEquipmentListSortOrderVar);

const createSportEquipmentListSortOrderVar = (sportEquipmentListSortOrderVar: ReactiveVar<SportEquipmentListSortOrder>) => {

  const setOrder = (orderModel: SportEquipmentListSortOrder) => {
    sportEquipmentListSortOrderVar(orderModel);
  };

  return {
    setOrder
  };
};
