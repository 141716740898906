import {GridCellParams} from "@mui/x-data-grid";
import {Badge, IconButton} from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import React from "react";
import {BootstrapTooltip} from "components/BootstrapTooltip";
import EquipmentRelocationHistory from "../../Dialogs/EquipmentRelocationHistory";


const HistoryButton = (props: { params: GridCellParams }) => {
    const {params: {row}} = props;
    const [dialogState, setDialogState] = React.useState(false);

    return (
        <>
            <BootstrapTooltip title={'История перемещений'}>
                <IconButton
                    color="inherit"
                    component="span"
                    onClick={() => setDialogState(true)}
                >
                    <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                            !row?.acceptBySportObjectComments &&
                            !row?.acceptComments &&
                            !row?.rejectReason
                        }
                    >
                        <MessageOutlinedIcon
                            sx={{
                                opacity: .5,
                                transition: 'all .6s',
                                fontSize: '18px !important'
                            }}
                        />
                    </Badge>
                </IconButton>
            </BootstrapTooltip>

            <EquipmentRelocationHistory
                open={dialogState}
                onClose={() => setDialogState(false)}
                data={row}
            />


        </>
    );
};

export default HistoryButton