import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client";
import { faker } from "@faker-js/faker";
import { SportEquipmentEntity } from "./graphql/graphQlApiHooks";


export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        users: {
          merge(existing, incoming) {
            return existing;
          }
        }
      }
    },
    SportEquipmentHistoryEntity: {
      keyFields: ["id"], // Указываем ключевое поле для идентификации объектов
      merge(existing, incoming) {
        if (existing && incoming) {
          // Проверяем, есть ли уже объект с таким id
          if (existing.sportEquipmentHistoryJson.id !== incoming.sportEquipmentHistoryJson.id) {
            // Объекты имеют разные id, выполняем замену объекта
            return incoming;
          } else {
            // Объекты имеют одинаковый id, делаем уникальный id для нового объекта
            incoming.sportEquipmentHistoryJson.id = faker.datatype.uuid();
            return incoming;

          }
        }
        return existing;
      },
    },
  },
});

