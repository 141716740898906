import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from "@apollo/client";

import {client} from "./store";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
		<ApolloProvider client={client}>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</ApolloProvider>
);
