import React from 'react';
import {ReactComponent as WarehouseIcon} from '../../../../../../assets/navbar-icons/warehouse.svg';
import {ReactComponent as ReportsIcon} from '../../../../../../assets/navbar-icons/reports.svg';
import {ReactComponent as EquipmentRequestIcon} from '../../../../../../assets/navbar-icons/equipment-request.svg';
import {ReactComponent as RepairsRequestIcon} from '../../../../../../assets/navbar-icons/repairs-request.svg';
import {ReactComponent as EquipmentOutgoingRequestIcon} from '../../../../../../assets/navbar-icons/equipment-outgoing-request.svg';
import {ReactComponent as EquipmentExternalRequestIcon} from '../../../../../../assets/navbar-icons/equipment-external-request.svg';
import {SvgIcon} from "@mui/material";

export const Warehouse = () => {
    return (
        <SvgIcon component={WarehouseIcon} viewBox="0 0 32 32" />
    );
};
export const Reports = () => {
    return (
        <SvgIcon component={ReportsIcon} viewBox="0 0 32 32" />
    );
};

export const EquipmentRequest = () => {
    return (
        <SvgIcon component={EquipmentRequestIcon} viewBox="0 0 32 32" />
    );
};

export const RepairsRequest = () => {
    return (
        <SvgIcon component={RepairsRequestIcon} viewBox="0 0 32 32" />
    );
};

export const EquipmentOutgoingRequest = () => {
    return (
        <SvgIcon component={EquipmentOutgoingRequestIcon} viewBox="0 0 32 32" />
    );
};

export const EquipmentExternalRequest = () => {
    return (
        <SvgIcon component={EquipmentExternalRequestIcon} viewBox="0 0 32 32" />
    );
};