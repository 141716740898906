export const formatPrice = (price:number) => {
    if (price === 0) {
        return 'Не указана';
    }
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(price);
};