import React from 'react';
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";

const Content = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  }
})) as typeof Box

const Main = styled(Box)(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
})) as typeof Box

const MainContent = styled(Box)(({theme}) => ({
  width: 'auto',
  height: '100%',
  position: 'relative',
  overflowX: 'auto',
  // padding: theme.spacing(0, 3),
})) as typeof Box

interface PageContentProps {
  aside?: boolean
  children: any
}

const PageContent = ({children, ...props}: PageContentProps) =>
  <Content className={'page__content'}>
    <Main component={'main'} className={'page__main main'}>
      <MainContent className={'main__content'}>
        {children}
      </MainContent>
    </Main>
  </Content>

export default PageContent;
