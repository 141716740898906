import { FileStoreEntity } from "graphql/graphQlApiHooks";
import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
import UploaderService from "services/UplodaService";


interface UploadDocumentContext {
  onClearInput: () => void;
  loading: boolean;
  progress: number;
  id: string;
}

const defaultState = {
  onClearInput: () => {
  },
  loading: false,
  progress: 0,
  id: ""
};

const Context = createContext<UploadDocumentContext>(defaultState);

interface ChildrenProps {
  id: string;
  onClearInput: () => void;
  loading: boolean;
  progress: number;
}

interface UploadDocumentProps {
  children: (props: PropsWithChildren<ChildrenProps>) => JSX.Element;
  onChange: (value: FileStoreEntity) => void;
  id: string;
}

export function UploadDocument(props: UploadDocumentProps) {
  const {
    children,
    onChange,
    id
  } = props;

  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const uploadInput = React.createRef<HTMLInputElement>();
  const onUploadChange: React.FormEventHandler<HTMLInputElement> = (event) => {
    setLoading(true);
    setProgress(0);
    UploaderService.uploadFile(event, {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setProgress(percent);
        }
      }
    })
      .then((res) => {
          if (res) {
            onChange(res);
          }
        }
      )
      .finally(() => {
        setProgress(100);
        setLoading(false);
      });
  };


  const onClearInput = useCallback(() => {
    if (uploadInput?.current) uploadInput.current.value = "";
  }, []);

  const contextValue = useMemo(() => ({
    onClearInput,
    loading,
    progress,
    id
  }), [loading, progress, id]);

  return (
    <Context.Provider value={contextValue}>
      <>
        <Context.Consumer>
          {children}
        </Context.Consumer>
        <input
          id={`button-upload-${id}`}
          name={`button-upload-${id}`}
          style={{ display: "none" }}
          type="file"
          accept="documents/*"
          onInput={onUploadChange}
          ref={uploadInput}
        />
      </>
    </Context.Provider>
  );
}
