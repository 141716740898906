import {
    CreateSportEquipmentInput,
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    EquipmentTypeDictionaryEntity,
    FileStoreEntity,
    GetSportEquipmentsDocument,
    ImageStoreEntity,
    SportCategoryDictionaryEntity,
    SportEquipmentEntity,
    SportKindDictionaryEntity,
    SportObjectEntity,
    SportSubZoneEntity,
    SportZoneEntity,
    TechnicalCondition,
    UpdateEquipmentRequestInput,
    useCreateSportEquipmentMutation,
    useUpdateEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import {useGetSportEquipmentInputVars} from "store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import {FormikHelpers, FormikProvider, useFormik} from "formik";
import dayjs, {Dayjs} from "dayjs";
import {Card, DialogContent, Grid, Stack} from "@mui/material";
import StickyBox from "react-sticky-box";
import CommissioningDate from "views/Pages/Equipments/components/Dialogs/views/СommissioningDate";
import {SportEquipmentLocation} from "views/Pages/Equipments/components/Dialogs/views/SportEquipmentLocation";
import {SportEquipmentCategory} from "views/Pages/Equipments/components/Dialogs/views/SportEquipmentCategory";
import {SportEquipmentRequestSupplied} from "./components/SportEquipmentRequestSupplied";
import {SportEquipmentAboutInfo} from "./components/SportEquipmentAboutInfo";
import {SportEquipmentPhotos} from "views/Pages/Equipments/components/Dialogs/views/SportEquipmentPhotos";
import {SportEquipmentDocuments} from "./components/SportEquipmentDocuments";
import {FormikErrors} from "formik/dist/types";
import {PageDialogActions} from "components/Dialogs/PageDialogActions";
import ErrorsStepper from "views/Pages/Equipments/components/Dialogs/views/ErrorsStepper";
import {Box} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import * as Yup from "yup";


interface Props {
    equipment: EquipmentRequestEntity;
    close: () => void;
}

export const SuppliedRequestDialog: FC<Props> = (props) => {
    const {equipment, close} = props;
    const {enqueueSnackbar} = useSnackbar();
    const {getInput} = useGetSportEquipmentInputVars();

    const [createSportEquipment] = useCreateSportEquipmentMutation();
    const [updateEquipmentRequest] = useUpdateEquipmentRequestMutation();


    const [sportEquipment, setSportEquipment] = useState<SportEquipmentEntity>(initialSportEquipment);

    const CreateSportEquipmentSchema = useMemo(() => Yup.object().shape({
        name: Yup.string().required("Укажите название оборудования"),
        accountingName: Yup.string().required("Укажите бухгалтерское название оборудования"),
        inventoryNumber: Yup.string().required("Укажите инвентарный номер"),
        commissioningDate: Yup.string()
            .required("Укажите дату ввода в эксплуатацию")
            .nullable(),

        ...((sportEquipment?.technicalCondition === TechnicalCondition.NotWorking && sportEquipment?.failureReason === "") && {failureReason: Yup.string().required("Укажите причину неисправности")}),

        sportObjectId: Yup.string().required("Выберите спортобъект").nullable(),
        count: Yup.number().min(1, "Количество оборудования не может быть меньше 1"),
    }), [sportEquipment?.technicalCondition, sportEquipment?.failureReason]);

    const formik = useFormik({
        initialValues: {
            name: "",
            accountingName: "",
            inventoryNumber: "",
            description: undefined,
            inUse: true,
            technicalCondition: TechnicalCondition.Working,
            failureReason: undefined,
            commissioningDate: null,

            sportCategoryId: undefined,
            sportKindId: undefined,
            equipmentTypeId: undefined,

            sportObjectId: undefined,
            sportZoneId: undefined,
            sportSubZoneId: undefined,

            specificationValues: [],

            certificateIds: [],
            commissioningCertificateIds: [],
            guaranteeIds: [],
            manualIds: [],
            passportIds: [],
            otherDocumentsIds: [],

            mainPhotoId: undefined,
            photosIds: [],
            visualInspectionReportId: undefined,
            waybillId: undefined,
            count: 1,

        } as CreateSportEquipmentInput & {
            visualInspectionReportId: string | undefined,
            waybillId: string | undefined,
            count: number,
        },
        validationSchema: CreateSportEquipmentSchema,
        onSubmit: (
            values: CreateSportEquipmentInput & {
                visualInspectionReportId: string | undefined,
                waybillId: string | undefined,
                count: number,
            },
            {setSubmitting}: FormikHelpers<CreateSportEquipmentInput & {
                visualInspectionReportId: string | undefined,
                waybillId: string | undefined,
                count: number,
            }>
        ) => {
            const input = {
                id: equipment.id,
                name: equipment.name,
                count: Number(equipment.count),
                equipmentTypeId: sportEquipment.equipmentTypeObject.id,
                sportObjectId: sportEquipment.sportObject.id,
                requestStatus: EquipmentRequestStatusEnum.EquipmentAccepted,

                visualInspectionReportId: requestInput.visualInspectionReport?.id,
                waybillId: requestInput.waybill?.id,
            } as UpdateEquipmentRequestInput;
            console.log(input)

            const handleAddSportEquipment = async () => {
                const promises = [];
                const input = {
                    ...values,
                    otherDocumentsIds: [...values.otherDocumentsIds, requestInput.visualInspectionReport?.id,
                        ...(requestInput.waybill?.id ? [requestInput.waybill.id] : [])
                    ].filter((value) => value !== undefined),
                    visualInspectionReportId: undefined,
                    waybillId: undefined,
                    count: undefined,
                }

                for (let i = 0; i < values.count; i++) {
                    promises.push(
                        createSportEquipment({
                            variables: {
                                input: input
                            },
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GetSportEquipmentsDocument,
                                    fetchPolicy: "network-only",
                                    variables: {
                                        input: getInput()
                                    }
                                }
                            ]
                        })
                    );
                }

                try {
                    await Promise.all(promises);
                    enqueueSnackbar("Всё оборудование успешно добавлены", {
                        variant: "success"
                    });
                    close();
                } catch (error) {
                    enqueueSnackbar(
                        error.message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
                        {
                            variant: "error"
                        }
                    );
                } finally {
                    setSubmitting(false);
                }
            };
            updateEquipmentRequest({
                variables: {
                    input
                }
            }).then(() => {
                handleAddSportEquipment();
                enqueueSnackbar("Заявка успешно обновлена", {
                    variant: "success"
                });
            }).catch((e) => {
                setSubmitting(false);
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
            });


        }
    });

    const {errors, touched, isSubmitting, handleSubmit, setValues, handleBlur, values} = formik;

    useEffect(() => {
        if (sportEquipment) {
            setValues(prevState => ({
                ...prevState,
                ...(sportEquipment?.id !== undefined && {id: sportEquipment.id}),
                ...(sportEquipment?.name !== undefined && {name: sportEquipment.name}),
                ...(sportEquipment?.accountingName !== undefined && {accountingName: sportEquipment.accountingName}),
                ...(sportEquipment?.inventoryNumber !== undefined && {inventoryNumber: sportEquipment.inventoryNumber}),
                ...(sportEquipment?.description !== undefined && {description: sportEquipment.description}),
                ...(sportEquipment?.inUse !== undefined && {inUse: sportEquipment.inUse}),
                ...(sportEquipment?.technicalCondition !== undefined && {technicalCondition: sportEquipment.technicalCondition}),
                ...(sportEquipment?.failureReason !== undefined && {failureReason: (sportEquipment?.technicalCondition === TechnicalCondition.NotWorking) ? sportEquipment.failureReason : ""}),
                ...(sportEquipment?.commissioningDate !== undefined && {
                    commissioningDate: dayjs(sportEquipment.commissioningDate).format("DD.MM.YYYY") === "Invalid Date" ? "" : dayjs(sportEquipment.commissioningDate).format("DD.MM.YYYY")
                }),
                ...(sportEquipment?.sportCategoryObject?.id && {sportCategoryId: sportEquipment.sportCategoryObject.id}),
                ...(sportEquipment?.sportKindObject?.id && {sportKindId: sportEquipment.sportKindObject.id}),
                ...(sportEquipment?.equipmentTypeObject?.id && {equipmentTypeId: sportEquipment.equipmentTypeObject.id}),
                ...(sportEquipment?.sportObject?.id && {sportObjectId: sportEquipment.sportObject.id}),
                ...(sportEquipment?.sportZone?.id && {sportZoneId: sportEquipment.sportZone.id}),
                ...(sportEquipment?.sportSubZone?.id && {sportSubZoneId: sportEquipment.sportSubZone.id}),
                ...(sportEquipment?.certificate?.length > 0 && {certificateIds: sportEquipment.certificate?.map(item => item.id)}),
                ...(sportEquipment?.commissioningCertificates?.length > 0 && {commissioningCertificateIds: sportEquipment.commissioningCertificates?.map(item => item.id)}),
                ...(sportEquipment?.guarantee?.length > 0 && {guaranteeIds: sportEquipment.guarantee?.map(item => item.id)}),
                ...(sportEquipment?.manual?.length > 0 && {manualIds: sportEquipment.manual?.map(item => item.id)}),
                ...(sportEquipment?.passport?.length > 0 && {passportIds: sportEquipment.passport?.map(item => item.id)}),
                ...(sportEquipment?.otherDocuments?.length > 0 && {otherDocumentsIds: sportEquipment.otherDocuments?.map(item => item.id)}),
                ...((sportEquipment?.mainPhoto?.id) ? {mainPhotoId: sportEquipment.mainPhoto.id} : sportEquipment?.photos?.length > 0 && {mainPhotoId: sportEquipment?.photos?.[0]?.id}),
                ...(sportEquipment?.photos && {photosIds: sportEquipment.photos?.map(item => item.id)})
            } as CreateSportEquipmentInput & {
                visualInspectionReportId: string | undefined,
                waybillId: string | undefined,
                count: number,
            }));


            if (sportEquipment?.photos?.length > 0) {
                const mainPhoto = sportEquipment?.photos?.find(item => item.id === sportEquipment?.mainPhoto?.id);
                if (!mainPhoto)
                    setSportEquipment(prevState => ({...prevState, mainPhoto: sportEquipment?.photos?.[0]}));
            }


        }
    }, [sportEquipment]);

    useEffect(() => {
        if (sportEquipment === initialSportEquipment) {
            setSportEquipment(prevState => ({
                ...prevState,
                ...(equipment?.name !== undefined && {name: equipment.name}),
                ...(equipment?.name !== undefined && {accountingName: equipment.name}),

                ...(equipment?.sportSubZone?.id && {sportSubZone: {id: equipment.sportSubZone.id} as SportSubZoneEntity}),
                ...(equipment?.sportZone?.id && {sportZone: {id: equipment.sportZone.id} as SportZoneEntity}),
                ...(equipment?.sportObject?.id && {sportObject: {id: equipment.sportObject.id} as SportObjectEntity}),

                ...(equipment?.sportKindObject?.sportCategoryId && {sportCategoryObject: {id: equipment.sportKindObject.sportCategoryId} as SportCategoryDictionaryEntity}),
                ...(equipment?.sportKindObject?.id && {sportKindObject: {id: equipment.sportKindObject.id} as SportKindDictionaryEntity}),
                ...(equipment?.equipmentTypeObject?.id && {equipmentTypeObject: {id: equipment.equipmentTypeObject.id} as EquipmentTypeDictionaryEntity}),
            }));
        }
    }, [equipment]);


    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {

        const {current: descriptionElement} = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }

    }, [descriptionElementRef]);

    const [requestInput, setRequestInput] = useState<UpdateEquipmentRequestInput & {
        visualInspectionReport?: FileStoreEntity,
        waybill?: FileStoreEntity,
    }>({
        count: Number(equipment.count),
        equipmentTypeId: equipment.equipmentTypeObject.id,
        id: equipment.id,
        name: equipment.name,
        requestStatus: equipment.requestStatus,
        sportObjectId: equipment.sportObject.id,
    });

    useEffect(() => {
        formik.setFieldValue("count", requestInput.count);
        formik.setFieldValue("visualInspectionReportId", requestInput.visualInspectionReport?.id);
        formik.setFieldValue("waybillId", requestInput.waybill?.id);
    }, [requestInput]);


    return (
        <>
            <DialogContent
                sx={{
                    p: 0,
                    position: "relative",
                    zIndex: 0,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    bgcolor: "background.default"
                }}
                dividers
            >
                <Stack
                    flex={1}
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    height={"100%"}
                >
                    <FormikProvider value={formik}>

                        <Grid
                            container
                            spacing={4}
                            px={3}
                            pb={3}
                        >
                            <Grid
                                item
                                lg={3}
                                md={3.5}
                            >
                                <StickyBox
                                    offsetTop={11}
                                    offsetBottom={11}
                                >
                                    <Stack
                                        spacing={2}
                                        mt={2}
                                    >

                                        <Card
                                            sx={{p: 2}}
                                            elevation={2}
                                        >
                                            <CommissioningDate
                                                touched={touched}
                                                errors={errors}

                                                handleBlur={handleBlur}
                                                isEditable

                                                date={dayjs(sportEquipment?.commissioningDate)}
                                                setDate={(date: Dayjs | null) => {
                                                    setSportEquipment(prevState => ({
                                                            ...prevState,
                                                            commissioningDate: dayjs(date).format("YYYY-MM-DD")
                                                        })
                                                    );
                                                }}
                                            />
                                        </Card>

                                        <Card
                                            sx={{p: 2}}
                                            elevation={2}
                                        >

                                            <SportEquipmentLocation
                                                vertical
                                                touched={touched}

                                                handleBlur={handleBlur}
                                                errors={errors}
                                                isEditable={sportEquipment?.isEditable}
                                                location={{
                                                    sportObject: sportEquipment?.sportObject,
                                                    sportZone: sportEquipment?.sportZone,
                                                    sportSubZone: sportEquipment?.sportSubZone
                                                }}
                                                onChangeLocation={(sportObject: SportObjectEntity, sportZone: SportZoneEntity, sportSubZone: SportSubZoneEntity) => {
                                                    setSportEquipment(prevState => ({
                                                            ...prevState,
                                                            sportObject: sportObject,
                                                            sportZone: sportZone,
                                                            sportSubZone: sportSubZone
                                                        })
                                                    );
                                                }}
                                            />
                                        </Card>

                                        <Card
                                            sx={{p: 2}}
                                            elevation={2}
                                        >
                                            <SportEquipmentCategory
                                                vertical
                                                touched={touched}
                                                errors={errors}
                                                isEditable={sportEquipment?.isEditable}
                                                sportEquipment={sportEquipment}
                                                setSportEquipment={setSportEquipment}
                                                handleBlur={handleBlur}
                                            />
                                        </Card>

                                    </Stack>
                                </StickyBox>
                            </Grid>
                            <Grid
                                item
                                lg={9}
                                md={8.5}
                            >
                                <Stack
                                    spacing={4}
                                    mt={2}
                                >
                                    <SportEquipmentRequestSupplied
                                        errors={errors}
                                        sportEquipment={sportEquipment}
                                        setSportEquipment={setSportEquipment}
                                        isEditable
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        equipmentRequest={equipment}
                                        requestInput={requestInput}
                                        setRequestInput={setRequestInput}
                                    />

                                    <SportEquipmentAboutInfo
                                        errors={errors}
                                        sportEquipment={sportEquipment}
                                        setSportEquipment={setSportEquipment}
                                        isEditable
                                        handleBlur={handleBlur}
                                        touched={touched}
                                    />

                                    <Card
                                        sx={{p: 3}}
                                        elevation={2}
                                    >
                                        <SportEquipmentPhotos
                                            isEditable
                                            sportEquipmentPhotos={sportEquipment?.photos || []}
                                            setSportEquipmentPhotos={(photos: ImageStoreEntity[]) => setSportEquipment(prevState => {
                                                return {
                                                    ...prevState,
                                                    photos: photos as ImageStoreEntity[]
                                                };
                                            })}
                                            mainPhoto={sportEquipment?.mainPhoto}
                                            setMainPhoto={(mainPhoto) => setSportEquipment(prevState => ({
                                                ...prevState,
                                                mainPhoto: mainPhoto as ImageStoreEntity
                                            }))}
                                        />
                                    </Card>

                                    <SportEquipmentDocuments
                                        isEditable
                                        documents={{
                                            ...(sportEquipment?.certificate?.length > 0 && {certificate: sportEquipment?.certificate}),
                                            ...(sportEquipment?.commissioningCertificates?.length > 0 && {commissioningCertificate: sportEquipment?.commissioningCertificates}),
                                            ...(sportEquipment?.guarantee?.length > 0 && {guarantee: sportEquipment?.guarantee}),
                                            ...(sportEquipment?.manual?.length > 0 && {manual: sportEquipment?.manual}),
                                            ...(sportEquipment?.passport?.length > 0 && {passport: sportEquipment?.passport}),
                                            ...(sportEquipment?.otherDocuments?.length > 0 && {otherDocuments: sportEquipment?.otherDocuments}),
                                            ...(requestInput?.visualInspectionReport && {visualInspectionReport: requestInput?.visualInspectionReport}),
                                            ...(requestInput?.waybill && {waybill: requestInput?.waybill})
                                        } as ISportEquipmentDocuments}
                                        setDocuments={(documents: ISportEquipmentDocuments) => {
                                            setSportEquipment(prevState => ({
                                                ...prevState,
                                                certificate: documents?.certificate,
                                                commissioningCertificates: documents?.commissioningCertificate,
                                                guarantee: documents?.guarantee,
                                                passport: documents?.passport,
                                                manual: documents?.manual,
                                                otherDocuments: documents?.otherDocuments,
                                            }))
                                            setRequestInput(prevState => ({
                                                ...prevState,
                                                visualInspectionReport: documents?.visualInspectionReport,
                                                waybill: documents?.waybill,
                                                visualInspectionReportId: documents?.visualInspectionReport?.id || '',
                                                waybillId: documents?.waybill?.id || '',
                                            }))
                                        }
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>


                    </FormikProvider>
                </Stack>
            </DialogContent>

            <Actions {...{
                isSubmitting: isSubmitting,
                handleSubmit: () => handleSubmit(),
                errors: errors
            }} />


        </>
    );
};


interface ActionsProps {
    isSubmitting: boolean;
    handleSubmit: () => void;
    errors: FormikErrors<CreateSportEquipmentInput>;
}

const Actions = ({isSubmitting, handleSubmit, errors}: ActionsProps) => {

    return (
        <>
            <PageDialogActions>

                <ErrorsStepper errors={errors}/>

                <Box
                    display={"flex"}
                    flex={1}
                />

                <LoadingButton
                    loading={isSubmitting}
                    size='small'
                    onClick={() => handleSubmit()}
                    variant='contained'
                >
                    Добавить
                </LoadingButton>
            </PageDialogActions>
        </>
    );
};

export interface ISportEquipmentDocuments {
    certificate: FileStoreEntity[] | null,
    commissioningCertificate: FileStoreEntity[] | null,
    guarantee: FileStoreEntity[] | null,
    manual: FileStoreEntity[] | null,
    passport: FileStoreEntity[] | null,
    otherDocuments: FileStoreEntity[],
    visualInspectionReport: FileStoreEntity | null,
    waybill: FileStoreEntity | null,
}

const initialSportEquipment = {
    name: "",
    accountingName: "",
    inventoryNumber: "Ожидает постановки на баланс",
    technicalCondition: TechnicalCondition.Working,
    inUse: true,
    isEditable: true,
    commissioningDate: dayjs().format("YYYY-MM-DD")
} as SportEquipmentEntity;