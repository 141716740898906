export enum SortEquipmentRequestColumnsEnum {
    RequestName = 'request.name',
    SportObjectName = 'sportObject.name',
    RequestCount = 'request.count',
    RequestCreatedAt = 'request.createdAt',
    RequestSportKind = 'request.sportKind',
    RequestRequestStatus = 'request.requestStatus',
    RequestRejectReason = 'request.rejectReason',
    RequestDescription = 'request.description',
}

