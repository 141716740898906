import React, { useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Page from "components/Page";
import RentTable from "./Table";
import { useTheme } from "@mui/styles";
import { lighten } from "@material-ui/core";
import { ExternalOrderEntity, useGetOrdersQuery } from "../../../graphql/graphQlApiHooks";
import Tabs from "./Tabs";
import PageTitle from "../../../components/PageTitle";
import PageContent from "../../../components/PageContext";
import { useReactiveVar } from "@apollo/client";
import {
  rentSportEquipmentTabActiveVars,
  RentSportEquipmentTabs
} from "../../../store/rent-sport-equpments/rentSportEquipmentTabActive";
import OrderList from "./Lists/OrderList";
import EquipmentList from "./Lists/EquipmentList";
import { Outlet } from "react-router-dom";
import RentalReportDialog from "./Dialogs/RentalReportDialog/RentalReportDialog";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useDialog } from "../../../providers/DialogProvider";
import { SportEquipmentsRelocate } from "../Equipments/components/Main/Footer/SportEquipmentsRelocate";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  page: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative"
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  pageTitle: {
    height: 90,
    padding: theme.spacing(3, 4),
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "21px",
      whiteSpace: "nowrap",
      color: theme.palette.text.secondary,
      transition: "all .3s",
      transform: "scale(.8)",
      "&:hover": {
        color: lighten(theme.palette.text.primary, 0.2)
      },
      "&.active": {
        color: theme.palette.text.primary,
        transform: "scale(1)"
      }
    },
    "& .toolbar": {
      marginLeft: "2rem",
      display: "flex",
      listStyle: "none",
      width: "100%",
      "&__item:not(:last-child)": {
        marginRight: "1em"
      }
    }
  },

  mainSection: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  mainSectionContainer: {
    width: "auto",
    height: "100%",
    position: "relative",
    overflowX: "auto",
    padding: theme.spacing(0, 3)
  }
}));


function DateRangeFilter() {
  return null;
}

const RentEquipments = () => {
  const classes = useStyles();
  const theme = useTheme();
  const tabActive = useReactiveVar(rentSportEquipmentTabActiveVars);
  const [isRentalReportOpen, setIsRentalReportOpen] = useState(false);

  const [dialog, closeDialog] = useDialog();

  const { data, loading } = useGetOrdersQuery({
    variables: {
      input: {
        statuses: []
      }
    },
    pollInterval: 120000

  });
  const title = "Аренда оборудования";

  const renderDataList = () => {
    if (tabActive === RentSportEquipmentTabs.ORDERS) {
      return <OrderList />;
    } else if (tabActive === RentSportEquipmentTabs.EQUIPMENTS) {
      return (
        <EquipmentList />
      );
    }
  };

  const handleShowFilter = () => {
    return dialog({
      onClose: closeDialog,
      title: "Фильтр",
      children: <DateRangeFilter />
    });
  };

  return (

    <Page title={title}>

      <Box
        display={"flex"}
        px={5}
        pt={4}
        pb={3}
        component={"header"}
        className='page__header'
      >
        <PageTitle title={title} />
        <Box flexGrow={1} />

        <Tooltip arrow title='Формирование отчёта о количестве арендованного оборудования в указанный период'>
          <Button
            variant='contained'
            size='small'
            startIcon={<SummarizeIcon />}
            onClick={() => setIsRentalReportOpen(true)}
          >Сформировать отчёт</Button>
        </Tooltip>
        <RentalReportDialog
          isOpen={isRentalReportOpen}
          onClose={() => setIsRentalReportOpen(false)}
        />
        <Button
          onClick={handleShowFilter}
          color={"primary"}
          size={"small"}
        > Фильтр </Button>
      </Box>
      <Tabs />

      <PageContent>
        {renderDataList()}
        <Outlet />
      </PageContent>
    </Page>
  );
};

export default RentEquipments;
