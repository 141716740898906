import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {Button,} from "@mui/material";
import {useStores} from "store";
import AcceptRelocation from "../AcceptRelocation";
import RejectRelocation from "../RejectRelocation";

const EquipmentRelocatable = observer(() => {
    let navigate = useNavigate();
    let {id} = useParams<"id">();
    const {equipmentRelocationRequestStore} = useStores();

    const [openRejectDialog, setOpenRejectDialog] = React.useState(false);
    const [openAcceptDialog, setOpenAcceptDialog] = React.useState(true);

    function handleClose() {
        navigate('/relocateEquipments');
    }

    useEffect(() => {
        equipmentRelocationRequestStore.setCurrentRelocateEquipmentId(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    return (
        <>
            <AcceptRelocation
                data={equipmentRelocationRequestStore?.currentRelocateEquipment}
                onClose={handleClose}
                open={openAcceptDialog}
                rejectButton={
                    <Button
                        onClick={() => {
                            setOpenAcceptDialog(false)
                            setOpenRejectDialog(true)
                        }}
                        color={'secondary'}
                    >Отклонить заявку</Button>
                }
            />
            <RejectRelocation
                data={equipmentRelocationRequestStore?.currentRelocateEquipment}
                onClose={handleClose}
                open={openRejectDialog}
                acceptButton={
                    <Button
                        onClick={() => {
                            setOpenRejectDialog(false)
                            setOpenAcceptDialog(true)
                        }}
                        color={'primary'}
                    >Одобрить перемещение</Button>
                }
            />
        </>

    );
})

export default EquipmentRelocatable;
