import {RequestEntity} from "../../../../types/RequestEntity";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import {
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    useUpdateCombinedEquipmentRequestMutation,
    useUpdateEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import StatusSelect from "../../../StatusSelect";
import {useSuppliedRequestAction} from "../../../../hooks/actions/useSuppliedRequestAction";
import {getLabelStatusRequest} from "../../../../lib/getLabelStatusRequest";
import {useRejectRequestAction} from "../../../../hooks/actions/useRejectRequestAction";

export const RequestStatusCellRenderer = ({equipmentRequestEntity, disableEdit}: {
    equipmentRequestEntity: RequestEntity,
    disableEdit?: boolean
}) => {
    const [status, setStatus] = useState(equipmentRequestEntity?.requestStatus);
    const {onSuppliedDialogHandler} = useSuppliedRequestAction();
    const [openWarning, setOpenWarning] = useState(false);
    const [nextStatus, setNextStatus] = useState<EquipmentRequestStatusEnum | null>(null);
    const {onRejectReasonDialogHandler} = useRejectRequestAction()

    const [statusOptions, setStatusOptions] = useState<EquipmentRequestStatusEnum[]>([
        EquipmentRequestStatusEnum.Submitted,
        EquipmentRequestStatusEnum.Pending,
        EquipmentRequestStatusEnum.DocumentPackageFormation,
        EquipmentRequestStatusEnum.ContractingServiceAndPlanningAndEconomicDepartmentApproval,
        EquipmentRequestStatusEnum.LotFormation,
        EquipmentRequestStatusEnum.Auction,
        EquipmentRequestStatusEnum.Acceptance,
        EquipmentRequestStatusEnum.EquipmentAccepted
    ])


    useEffect(() => {
        setStatus(equipmentRequestEntity?.requestStatus);
        if (isSingleRequest(equipmentRequestEntity)) {
            setStatusOptions(prev => Array.from(new Set([...prev, EquipmentRequestStatusEnum.Rejected])));
        }
        if (isCombinedRequest(equipmentRequestEntity)) {
            if (
                equipmentRequestEntity?.requestStatus === EquipmentRequestStatusEnum.Acceptance
                || equipmentRequestEntity?.requestStatus === EquipmentRequestStatusEnum.EquipmentAccepted
            ) {
                setStatusOptions([
                    EquipmentRequestStatusEnum.Acceptance,
                    EquipmentRequestStatusEnum.EquipmentAccepted
                ]);
            }
        }
    }, [equipmentRequestEntity]);

    const [updateCombinedEquipmentRequest, {loading: combineLoading}] = useUpdateCombinedEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests']
    });

    const [updateEquipmentRequest, {loading: singleLoading}] = useUpdateEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests']
    });

    const loading = combineLoading || singleLoading;

    const confirmStatusChange = (newStatus: EquipmentRequestStatusEnum) => {
        if (isCombinedRequest(equipmentRequestEntity)) {
            updateCombinedEquipmentRequest({
                variables: {
                    input: {
                        id: equipmentRequestEntity.id,
                        requestStatus: newStatus
                    }
                }
            }).then(() => {
                setStatus(newStatus);
            });
        } else if (isSingleRequest(equipmentRequestEntity)) {
            updateEquipmentRequest({
                variables: {
                    input: {
                        id: equipmentRequestEntity.id,
                        requestStatus: newStatus,
                        count: parseInt(equipmentRequestEntity.count),
                        equipmentTypeId: equipmentRequestEntity.equipmentTypeObject.id,
                        name: equipmentRequestEntity.name,
                        sportObjectId: equipmentRequestEntity.sportObject.id,
                    }
                }
            }).then(() => {
                setStatus(newStatus);
            });
        }
    };

    const onChangeStatus = (newStatus: EquipmentRequestStatusEnum) => {
        if ([EquipmentRequestStatusEnum.Acceptance, EquipmentRequestStatusEnum.EquipmentAccepted].includes(newStatus)) {
            setNextStatus(newStatus);
            setOpenWarning(true);
        } else {
            confirmStatusChange(newStatus);
        }
    };

    const handleCloseWarning = (confirm: boolean) => {
        setOpenWarning(false);
        if (confirm && nextStatus) {
            confirmStatusChange(nextStatus);
        }
        setNextStatus(null);
    };


    const statuses = statusOptions.map((value) => ({
        label: getLabelStatusRequest(value),
        value: value
    }));

    const warningMessage = nextStatus === EquipmentRequestStatusEnum.Acceptance
        ? "Это необратимое действие. Вернуться на предыдущие шаги будет невозможно."
        : "Это необратимое действие. Дальнейшие изменения заявки будут недоступны.";

    return (
        <>
            <Dialog open={openWarning} onClose={() => handleCloseWarning(false)}>
                <DialogTitle>Внимание!</DialogTitle>
                <DialogContent>
                    <Alert variant={'standard'} severity="error">
                        {warningMessage}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseWarning(false)} color="primary">Отмена</Button>
                    <Button onClick={() => handleCloseWarning(true)} color="secondary">Подтвердить</Button>
                </DialogActions>
            </Dialog>

            {(isCombinedRequest(equipmentRequestEntity) || (isSingleRequest(equipmentRequestEntity) && !equipmentRequestEntity.isInCombinedRequest)) &&
                <Box
                    sx={{
                        width: '100%',
                        '& .MuiFilledInput-root': {
                            transform: 'scale(.8)',
                            transformOrigin: 'left center',
                        }
                    }}
                >
                    <StatusSelect
                        value={status}
                        onChange={({target: {value}}) => {
                            if (value === EquipmentRequestStatusEnum.Rejected) {
                                onRejectReasonDialogHandler(equipmentRequestEntity as EquipmentRequestEntity);
                            } else {
                                onChangeStatus(value as EquipmentRequestStatusEnum)
                            }
                        }}
                        disabled={
                            status === EquipmentRequestStatusEnum.Supplied
                            || status === EquipmentRequestStatusEnum.EquipmentAccepted
                            || status === EquipmentRequestStatusEnum.Rejected
                            || loading
                            || disableEdit
                        }
                        loading={loading}
                        statuses={statuses}
                    />
                </Box>
            }
            {(
                    isSingleRequest(equipmentRequestEntity)
                    && equipmentRequestEntity?.isInCombinedRequest
                    && (
                        equipmentRequestEntity.parentCombinedRequest?.requestStatus === EquipmentRequestStatusEnum.Acceptance
                        || equipmentRequestEntity.parentCombinedRequest?.requestStatus === EquipmentRequestStatusEnum.EquipmentAccepted
                    )
                    && equipmentRequestEntity?.requestStatus !== EquipmentRequestStatusEnum.EquipmentAccepted
                ) &&
                <Box
                    sx={{
                        width: '100%',
                        '& .MuiFilledInput-root': {
                            transform: 'scale(.8)',
                            transformOrigin: 'left center',
                        }
                    }}
                >
                    <Button size="small" onClick={() => onSuppliedDialogHandler(equipmentRequestEntity)}>
                        Принять оборудование
                    </Button>
                </Box>
            }
            {(
                    isSingleRequest(equipmentRequestEntity)
                    && equipmentRequestEntity?.isInCombinedRequest
                    && equipmentRequestEntity?.requestStatus === EquipmentRequestStatusEnum.EquipmentAccepted
                ) &&
                <Box
                    sx={{
                        width: '100%',
                        '& .MuiFilledInput-root': {
                            transform: 'scale(.8)',
                            transformOrigin: 'left center',
                        }
                    }}
                >
                    <StatusSelect
                        value={status}
                        onChange={({target: {value}}) => {
                            if (value === EquipmentRequestStatusEnum.Rejected) {
                                onRejectReasonDialogHandler(equipmentRequestEntity as EquipmentRequestEntity);
                            } else {
                                onChangeStatus(value as EquipmentRequestStatusEnum)
                            }
                        }}
                        disabled={true}
                    />
                </Box>
            }
        </>
    );
};
