import React, { FC, useEffect } from "react";
import { DataGrid, GridColumns, GridRowData } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { useStyles } from "../styles";
import {
  ExternalOrderRecordEntity,
  TechnicalCondition,
  useGetNotReturnedEquipmentQuery
} from "../../../../../graphql/graphQlApiHooks";
import Tooltiper from "../../../../../components/Tooltiper";
import DateViewer from "../../../../../components/DateViewer";
import { ContactsViewer, RenderStatus } from "../OrderList";
import { useTheme } from "@mui/styles";
import EquipmentRowName from "../../../../../components/EquipmentRowName";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import UserAvatar from "../../../../../components/UserAvatar";

interface Props {

}

const EquipmentList: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [rows, setRows] = React.useState<readonly GridRowData[]>([]);

  const { data, loading } = useGetNotReturnedEquipmentQuery({
    pollInterval: 120000
  });

  useEffect(() => {
    if (data?.getNotReturnedEquipment) {
      setRows(data.getNotReturnedEquipment);
    }
  }, [data]);


  const columns: GridColumns = [{
    field: "id", headerName: "№",
    flex: .5,
    renderCell: (params) => {
      return (
        <Tooltiper
          text={
            String(params.row.externalOrder.id)
          }
          props={{
            sx: {
              fontSize: "0.75rem !important",
              fontWeight: 600,
              color: theme.palette.text.secondary
            }
          }}
        />
      );
    },
    minWidth: 110,
    disableColumnMenu: true
  },
    {
      field: "equipment",
      headerName: "Оборудование",
      renderCell: ({ row: { equipment } }: { row: ExternalOrderRecordEntity }) => {
        const {
          id,
          name,
          inventoryNumber,
          mainPhoto,
          photos,
          inUse,
          technicalCondition,
          failureReason,
          isEditable
        } = equipment;
        return (
          <EquipmentRowName
            name={name}
            inventoryNumber={inventoryNumber || "б/н"}
            inUse={inUse}
            technicalCondition={(technicalCondition === TechnicalCondition.Working)}
            avatarUrl={(mainPhoto) ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].avatarUrl}
            avatarUrlOrigin={(mainPhoto) ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].url}
            failureReason={failureReason}
            isEditable={isEditable}
          />
        );
      },
      flex: 2,
      minWidth: 220,
      disableColumnMenu: true,
    },
    {
      field: "externalOrderOwner", headerName: "Заявитель",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            spacing={1}
          >
            <UserAvatar
              user={params.row?.externalOrder?.externalOrderOwner}
              showTooltip={true}
            />
            <Tooltiper
              text={
                params.row?.externalOrder?.externalOrderOwner?.fio ||
                params.row?.externalOrder?.externalOrderOwner?.login
              }
            />
          </Stack>
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "receivingName",
      headerName: "Контакты для связи",
      renderCell: (params) => {
        return (
          <ContactsViewer
            giverName={params.row.externalOrder.receivingName}
            giverPhone={params.row.externalOrder.receivingPhone}
          />
        );
      },
      flex: 1,
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: "targetPointAddress",
      headerName: "Адрес",
      renderCell: (params) => {
        return (
          <Tooltiper text={params.row.externalOrder.targetPointAddress} />
        );
    },
      flex: 1,
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: "startEventDate", headerName: "Дата начала мероприятия",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.externalOrder.startEventDate ?
            <DateViewer
              date={params.row.externalOrder.startEventDate}
              showTime
            />
            :
            null
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "endEventDate", headerName: "Дата окончания мероприятия",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.externalOrder.endEventDate ?
            <DateViewer
              date={params.row.externalOrder.endEventDate}
              showTime
            />
            :
            null
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "returnDate", headerName: "Дата возврата",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          params.row.externalOrder.returnDate ?
            <DateViewer date={params.row.externalOrder.returnDate} />
            :
            null

        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "status",
      headerName: "Статус",

      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
            width={"100%"}
          >
            {
              <RenderStatus status={params.row.externalOrder.status} />
            }
          </Box>
        );
      },
      flex: 1,
      minWidth: 120,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box
      sx={{ height: "100%", width: "100%" }}
      className={classes.root}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        disableSelectionOnClick={true}

        rowsPerPageOptions={[5, 10, 50, 100]}
        onCellDoubleClick={({ row }) =>
          navigate(`order/${row.externalOrder.id}`)
        }
        initialState={{
          sorting: {
            sortModel: [{ field: "status", sort: "asc" }]
          }
        }}
      />

    </Box>
  );
};

export default EquipmentList;