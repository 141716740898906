import React from "react";
import { Box, Button } from "@mui/material";
import { GridSelectedRowCount } from "@mui/x-data-grid/components/GridSelectedRowCount";
import { GridPagination } from "@mui/x-data-grid";

interface Props {
  selected: any;
  onSportEquipmentsRelocateHandler: () => void;
  onCategoryChangeHandler: () => void;
  onGroupDeleteHandler: () => void;
}


export const Footer = (props: Props) => {

  return (
    <>
      <Box sx={{ px: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box display={"flex"}>

          {props.selected.length > 0 ? <>
            <GridSelectedRowCount selectedRowCount={props.selected.length} />
            <Button color={"primary"} variant={"outlined"} size={"small"}
                    sx={{ py: .5, px: 2, mr: 2 }} onClick={props.onSportEquipmentsRelocateHandler}>Переместить
              оборудование</Button>
            <Button color={"primary"} variant={"outlined"} size={"small"}
                    sx={{ py: .5, px: 2, mr: 2 }}  onClick={props.onCategoryChangeHandler}>Изменить категорию</Button>
            <Button color={"primary"} variant={"outlined"} size={"small"}
                    sx={{ py: .5, px: 2 }} onClick={props.onGroupDeleteHandler}>
              Удалить выбранное оборудование
            </Button>
          </> : null}
        </Box>
        {
          // @ts-ignore
          <GridPagination />
        }
      </Box>
    </>
  );
};
