import { Box, Skeleton, Grid, useMediaQuery } from "@mui/material";
import React, { FC } from "react";
import { Theme } from "@mui/material/styles";

interface Props {

}

export const SportEquipmentPreloader: FC<Props> = (props: Props) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={4}
      sx={{
        height: sm ? '100%' : 'calc(100% + 20px)'
      }}
    >
      <Grid
        item
        xs={12}
        sm={5}
        height='100%'
      >
        <Skeleton
          width={"100%"}
          height='100%'
          variant={"rectangular"}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
      >
        <Box p={3}>
          <Skeleton
            variant='text'
            sx={{ fontSize: "1.2rem" }}
          />
          <Box mb={2}>
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              width={70}
              style={{ display: "inline-block" }}
            />
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              style={{ display: "inline-block", marginLeft: ".5rem" }}
              width={200}
            />
          </Box>

          <Box>
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              width={90}
              style={{ display: "inline-block" }}
            />
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              style={{ display: "inline-block", marginLeft: ".5rem" }}
              width={180}
            />
          </Box>
          <Box>
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              width={80}
              style={{ display: "inline-block" }}
            />
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              style={{ display: "inline-block", marginLeft: ".5rem" }}
              width={190}
            />
          </Box>

          <Box>
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              width={60}
              style={{ display: "inline-block" }}
            />
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              style={{ display: "inline-block", marginLeft: ".5rem" }}
              width={170}
            />
          </Box>


          <Box>
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              width={85}
              style={{ display: "inline-block" }}
            />
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
              style={{ display: "inline-block", marginLeft: ".5rem" }}
              width={250}
            />
            <Skeleton
              variant='text'
              sx={{ fontSize: "1rem" }}
            />
          </Box>
        </Box>
      </Grid>

    </Grid>
  );
};

export default SportEquipmentPreloader;