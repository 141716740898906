import {
    EquipmentExternalRequest,
    EquipmentOutgoingRequest,
    EquipmentRequest,
    RepairsRequest,
    Reports,
    Warehouse
} from "./navbar-icons/icons";
import {ReactNode} from "react";

interface IItem {
    href: string;
    icon: string | ReactNode;
    title: string;
}

interface IItems extends Array<IItem> {
}

export const items: IItems = [
    {
        href: '/equipments',
        icon:  <Warehouse />,
        title: 'Оборудование'
    },
    {
        href: '/reports',
        icon: <Reports/>,
        title: 'План обслуживания'
    },
    {
        href: '/equipmentRequest',
        icon: <EquipmentRequest/>,
        title: 'Заявки на оборудование'
    },
    {
        href: '/repairs-request',
        icon: <RepairsRequest/>,
        title: 'Заявки на ремонт оборудования '
    },
    {
        href: '/relocateEquipments',
        icon: <EquipmentOutgoingRequest/>,
        title: 'МСО.Объявления'
    },
    {
        href: '/rent',
        icon: <EquipmentExternalRequest/>,
        title: 'Аренда оборудования'
    },
];
