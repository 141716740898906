import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetAllSportObjectsDocument, GetAllSportObjectsQueryResult, SportObjectEntity } from "graphql/graphQlApiHooks";
import { styled } from "@mui/styles";
import { stringAvatar } from "../../../../../../helpers/stringAvatar";
import { client, useStores } from "../../../../../../store";
import { observer } from "mobx-react-lite";
import Select from "./Select";
import {
  sportObjectsVars,
  useSportObjectsVars
} from "../../../../../../store/reactiveVarsStores/sport-objects/sportObjects";
import { useReactiveVar } from "@apollo/client";
import { useLocation } from "react-router-dom";


const RootStyled = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  wrap: "nowrap",
  [theme.breakpoints.up("md")]: {
    width: 320
  }
})) as typeof Box;


interface Props {
  [key: string]: any;
}

const SportObjectsSelect = observer((props: Props) => {
  const location = useLocation();
  const { sportObjectsStore } = useStores();
  const [isVisible, setIsVisible] = useState(true);

  const { setSelectedSportObjectIds, setSportObjectList } = useSportObjectsVars();
  const { userSportObjectList, sportObjectList } = useReactiveVar(sportObjectsVars);

  useEffect(() => {
  client
    .query({ query: GetAllSportObjectsDocument })
    .then(({ data: { getAllSportObjects } }: GetAllSportObjectsQueryResult) =>
      sportObjectList.length === 0 && setSportObjectList(getAllSportObjects as SportObjectEntity[])
    );
  }, [])

  useEffect(() => {
    if (
      window.location.href.includes('relocateEquipments')
      || window.location.href.includes('settings')
      || window.location.href.includes('rent')
    ) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }

  }, [location])

  const setSelectedSportObjectIdsHandler = (selectedSportObjectIds: string[]) => {
    sportObjectsStore.setCurrentIds(selectedSportObjectIds);
    setSelectedSportObjectIds(selectedSportObjectIds);
  };

  if (!isVisible) return null;

  if (userSportObjectList?.length === 0) return (
    <RootStyled>
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={"30px"}
      />
    </RootStyled>
  );

  if (userSportObjectList?.length === 1) return (
    <RootStyled>
      <Stack direction={"row"} spacing={2} alignItems={'center'}>
        <Avatar {...stringAvatar(userSportObjectList[0]?.name)} sx={{ width: 28, height: 28 }} />
        <Typography
          variant={"h5"}
          color={"inherit"}
          sx={{ lineHeight: 1, mb: 2 }}
        >
          {userSportObjectList[0]?.name}
        </Typography>
      </Stack>
    </RootStyled>
  );

  return (
    <RootStyled>
      <Select
        sportObjects={userSportObjectList as SportObjectEntity[]}
        selectedSportObjectIds={sportObjectsStore.currentIds}

        actions={{
          setSelectedSportObjectIds: setSelectedSportObjectIdsHandler
        }}
      />
    </RootStyled>
  );
});

export default SportObjectsSelect;
