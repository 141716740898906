import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        }
    },
    pageTitle: {
        height: 90,
        padding: theme.spacing(3, 0),
        display: 'flex',
        alignItems: 'center'
    },
    pageHeader: {
        padding: theme.spacing(3, 3, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.paper
    },

    leftSection: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            flexBasis: 320,
            flexGrow: 0,
            flexShrink: 0,
        }

    },
    aside: {
        bottom: 0,
        top: 0,
        left: 0,
        [theme.breakpoints.up('md')]: {
            width: 320,
            position: 'absolute',
        }
    },
    mainSection: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    mainSectionContainer: {
        width: 'auto',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    }
}));

export default useStyles;
