import React from 'react';
import {styled, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {observer} from "mobx-react-lite";
import {Autocomplete, Box, Button, InputBase} from "@mui/material";
import {autocompleteClasses, AutocompleteCloseReason} from "@mui/material/Autocomplete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from '@mui/icons-material/Close';
import createStyles from "@mui/styles/createStyles";


const useStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		fontSize: 13,
		display: 'flex',
	},
	button: {
		fontSize: 13,
		width: '100%',
		textAlign: 'left',
		padding: '.5rem .75rem',
		color: '#586069',
		fontWeight: 600,
		// border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
		cursor: 'pointer',
		'& .MuiSvgIcon-root': {
			marginRight: 4
		},
		'&:hover,&:focus': {
			color: theme.palette.primary.main,
		},
		'& $label': {
			width: '100%',
			marginLeft: '.5rem',
			borderRadius: theme.shape.borderRadius,
			background: theme.palette.background.default,
			color: '#586069',
			fontSize: '.75rem',
			lineHeight: '1rem',
			padding: '.25rem .5rem',
		},
		'& svg': {
			width: 16,
			height: 16,
		},
	},
	tag: {
		marginTop: 3,
		height: 20,
		padding: '.15em 4px',
		fontWeight: 600,
		lineHeight: '15px',
		borderRadius: 2,
	},
	popper: {
		border: '1px solid rgba(27,31,35,.15)',
		boxShadow: '0 3px 12px rgba(27,31,35,.15)',
		borderRadius: 3,
		width: 300,
		zIndex: 1,
		fontSize: 13,
		color: '#586069',
		backgroundColor: '#f6f8fa',
	},
	header: {
		borderBottom: '1px solid #e1e4e8',
		padding: '8px 10px',
		fontWeight: 600,
		display: 'flex',
		justifyContent: 'space-between'
	},
	inputBase: {
		padding: 10,
		width: '100%',
		borderBottom: '1px solid #dfe2e5',
		'& input': {
			height: '30px',
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			border: '1px solid #ced4da',
			fontSize: 14,
			'&:focus': {
				boxShadow: `rgba(${theme.palette.primary.main}, .25) 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.main,
			},
		},
	},
	paper: {
		boxShadow: 'none',
		margin: 0,
		color: '#586069',
		fontSize: 13,
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: 8,
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent',
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	popperDisablePortal: {
		position: 'relative',
	},
	iconSelected: {
		width: 17,
		height: 17,
		marginRight: 5,
		marginLeft: -2,
	},
	avatar: {
		width: 24,
		height: 24,
		flexShrink: 0,
		borderRadius: '50%',
		marginRight: 8,
		marginTop: 2,
	},
	text: {
		flexGrow: 1,
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18,
	},
	label: {}
}),);

interface PopperComponentProps {
	anchorEl?: any;
	disablePortal?: boolean;
	open: boolean;
}

const StyledAutocompletePopper = styled('div')(({theme}) => ({
	[`& .${autocompleteClasses.paper}`]: {
		boxShadow: 'none',
		margin: 0,
		color: 'inherit',
		fontSize: 13,
	},
	[`& .${autocompleteClasses.listbox}`]: {
		backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
		padding: 0,
		[`& .${autocompleteClasses.option}`]: {
			minHeight: 'auto',
			alignItems: 'flex-start',
			padding: 8,
			borderBottom: `1px solid  ${
					theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
			}`,
			'&[aria-selected="true"]': {
				backgroundColor: 'transparent',
			},
			'&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	},
	[`&.${autocompleteClasses.popperDisablePortal}`]: {
		position: 'relative',
	},
}));

function PopperComponent(props: PopperComponentProps) {
	const {disablePortal, anchorEl, open, ...other} = props;
	return <StyledAutocompletePopper {...other} />;
}

const FilterAutocomplete = observer((rest: {
	filterResult: (result: any) => any,
	values: any[], selected?: any[],
	multipleDisabled?: boolean,
	onClose?: () => void
}) => {
	const classes = useStyles();
	const {values, filterResult} = rest;

	const selected = rest.selected ? rest.selected : [];

	const [pendingValue, setPendingValue] = React.useState<any[]>([...values].filter(val => selected.includes(val.id)));

	const selectAll = () => {
		setPendingValue(values)
		filterResult(values)
	};
	const unSelectAll = () => {
		setPendingValue([])
		filterResult([])
	};
	return (
			<>
				<Autocomplete
						open
						multiple={(!rest?.multipleDisabled) ? true : undefined}
						onClose={(
								event: React.ChangeEvent<{}>,
								reason: AutocompleteCloseReason
						) => {
							if (reason === 'escape') {
								// handleClose();
							}
						}}
						value={(!rest?.multipleDisabled) ? pendingValue : undefined}
						onChange={(event, newValue) => {
							filterResult(newValue);
							setPendingValue(newValue);
						}}
						disableCloseOnSelect
						PopperComponent={PopperComponent}
						disablePortal
						renderTags={() => null}
						noOptionsText="Нет результатов"
						isOptionEqualToValue={(option, value) => option?.id === value?.id}
						groupBy={option => (option?.sportKind) ? option?.sportKind.name : undefined}
						renderOption={(props, option, {selected}) => {
							selected = (!rest?.multipleDisabled) ? selected : rest?.selected?.[0] === option.name
							return (
									<li {...props} key={props.id}>
										<Box
												component={DoneIcon}
												sx={{width: 17, height: 17, mr: '5px', ml: '-2px'}}
												style={{
													visibility: selected ? 'visible' : 'hidden',
												}}
										/>
										<div className={classes.text}>
											{option.name}
											<br/>
											{option.description}
										</div>
										<CloseIcon
												className={classes.close}
												style={{visibility: selected ? 'visible' : 'hidden'}}
										/>
									</li>
							)
						}}
						// options={[]}
						options={[...values]}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
								<>
									<InputBase
											ref={params.InputProps.ref}
											inputProps={params.inputProps}
											autoFocus
											className={classes.inputBase}
											type={'search'}
									/>

									{(!rest?.multipleDisabled) &&
                                        <div className={classes.header}>
                                            <Button
                                                size={'small'}
                                                onClick={selectAll}
                                            >Выбрать все</Button>
                                            <Button
                                                size={'small'}
                                                onClick={unSelectAll}
                                            >Сбросить</Button>
                                        </div>
									}
								</>
						)}
				/>
			</>
	)
})

export default FilterAutocomplete;
