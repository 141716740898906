import {ReactNode, useState} from "react";
import {Box, Popover} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

const MouseOverPopover = (props: { children: ReactNode, urlAvatar?: string }) => {
    const {children, urlAvatar} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {children}
            </Box>
            {urlAvatar && <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none"
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <CardMedia
                    component="img"
                    height="300"
                    image={urlAvatar}
                    alt="green iguana"
                />
            </Popover>}
        </div>
    );
};

export default MouseOverPopover;
