import React, {FC} from 'react';
import {Alert, Button, DialogActions, DialogContent, Typography} from "@mui/material";
import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface Props {
    equipment: EquipmentRequestEntity;
    message?: string;
    onClose: () => void;
    onDelete: (id: string) => void
}

export const DeleteRequestDialog: FC<Props> = ({message, onClose, onDelete, equipment}) => {
    return (
        <>
            <DialogContent>
                <Alert variant={'standard'} severity="error" icon={<DeleteForeverIcon fontSize="inherit"/>}>
                    <Typography color={'inherit'} gutterBottom>{message ? message : <>Это действие необратимо. Чтобы
                        подтвердить удаление,
                        нажмите кнопку ниже.</>}</Typography>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    autoFocus
                    onClick={() => onDelete(equipment.id)}
                    color="error"
                >
                    Удалить
                </Button>
            </DialogActions>
        </>
    )
};

