import React from "react";
import {EquipmentRequestEntity, useDeleteEquipmentRequestMutation} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {DeleteRequestDialog} from "../../components/Dialogs/DeleteRequestDialog";

export const useDeleteRequestAction = () => {
    const [dialog, closeDialog] = useDialog();
    const [deleteEquipmentRequest] = useDeleteEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests'],
    })

    const handleDelete = (equipment: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Удаление заявки на оборудование",
            maxWidth: "xs",
            fullWidth: true,
            children: <>
                <DeleteRequestDialog
                    equipment={equipment}
                    onClose={() => closeDialog()}
                    onDelete={(id: string) => {
                        closeDialog()
                        deleteEquipmentRequest({variables: {id}})
                    }}
                />
            </>
        });
    };

    return {
        handleDelete
    }
};