import React from 'react';
import { GetSportEquipmentInput, SportEquipmentEntity } from "../../../../../graphql/graphQlApiHooks";
import {Box, Stack, Typography} from "@mui/material";
import {styled, Theme} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import SportObjectFilter from "./sportObjectFilter";
import SportCategoryFilter from "./sportCategoryFilter";
import { SportEquipmentCategory } from "../../../Equipments/components/Dialogs/views/SportEquipmentCategory";

interface Props {
	filters: GetSportEquipmentInput
	onChangeFilters: (filters: GetSportEquipmentInput) => void
	sportEquipment?: SportEquipmentEntity
}

const List = styled(Box)(({theme}) => ({}))
const ListContent = styled(Box)(({theme}) => ({}))

const ListHeader = styled(Typography)(({theme}) => ({})) as typeof Typography
const useStylesUploadForm = makeStyles((theme: Theme) => ({
	placementWrapper: {
		padding: "1em",
		backgroundColor: 'rgb(243,246,250)',
		borderRadius: "6px",
		marginBottom: "1em",
	}
}))

function EditSidebarTsx(props: Props) {



	return (
			<>



				<Stack direction={'column'} spacing={1}>
					{/*<SportObjectFilter {...props}/>*/}

					{/*<SportCategoryFilter {...props}/>*/}
					{/*<SportEquipmentCategory*/}
					{/*	vertical={false}*/}
					{/*	setSportEquipment={()=>{}}*/}
					{/*	sportEquipment={props?.sportEquipment}*/}
					{/*	isEditable*/}
					{/*/>*/}

				</Stack>


			</>
	);
}

export default EditSidebarTsx;