import React from 'react';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {Box} from "@mui/material";
import SportObjectsList from "./SportObjectsList";
import SportAreasList from "./SportZonesList";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '1.25rem 0 1.25rem 1.25rem'
    },
}),
);

export default function StructureMenu({...rest}) {
    const classes = useStyles();

    return (

        <Box className={classes.root}>

            <>
                <SportObjectsList />
                <SportAreasList />
            </>


        </Box>

    );
}
