import React from "react";
import {Card, Stack} from "@mui/material";
import {Header} from "./Header";
import {DocumentsGrid} from "./DocumentsGrid";
import {OtherDocumentsGrid} from "./OtherDocumentsGrid";
import {ISportEquipmentDocuments} from "../../index";


interface Props {
    documents: ISportEquipmentDocuments;
    setDocuments: (value: ISportEquipmentDocuments) => void;
    isEditable?: boolean;
}

export const SportEquipmentDocuments = (props: Props) => {
    const {
        documents,
        setDocuments,
        isEditable
    } = props;

    return (
        <Stack spacing={3}>


            <Card sx={{p: 3}} elevation={2}>
                <Header
                    title={"Основные документы"}
                    description={"Прикрепите основные документы"}
                />

                <DocumentsGrid
                    documents={documents}
                    setDocuments={setDocuments}
                    isEditable={isEditable}
                />
            </Card>

            <Card sx={{p: 3}} elevation={2}>
                <Header
                    title={"Прочие документы"}
                    description={"Прикрепите дополнительные документы, если они есть"}
                />

                <OtherDocumentsGrid
                    id={"SportEquipmentOtherDocuments"}
                    documents={documents}
                    setDocuments={setDocuments}
                    isEditable={isEditable}
                />
            </Card>

        </Stack>
    );
};
