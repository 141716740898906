import { ReactiveVar } from "@apollo/client";
import { GetSportEquipmentInput } from "graphql/graphQlApiHooks";
import { SportEquipmentListFilters, sportEquipmentListFiltersVars } from "./sportEquipmentListFilters";
import { SportEquipmentTab, sportEquipmentTabActiveVars, SportEquipmentTabIdsEnum } from "./sportEquipmentTabActive";
import { SportEquipmentListPagination, sportEquipmentListPaginationVars } from "./sportEquipmentListPagination";
import { SportEquipmentListSortOrder, sportEquipmentListSortOrderVar } from "./sportEquipmentListSortOrder";
import { SportObjectsVars, sportObjectsVars } from "../sport-objects/sportObjects";


export const useGetSportEquipmentInputVars = () => (
  createGetSportEquipmentInputVars(sportEquipmentTabActiveVars, sportEquipmentListFiltersVars, sportEquipmentListPaginationVars, sportEquipmentListSortOrderVar, sportObjectsVars)
);

const createGetSportEquipmentInputVars = (
  sportEquipmentTabActiveVars: ReactiveVar<SportEquipmentTab>,
  sportEquipmentListFiltersVars: ReactiveVar<SportEquipmentListFilters>,
  sportEquipmentListPaginationVars: ReactiveVar<SportEquipmentListPagination>,
  sportEquipmentListSortOrderVar: ReactiveVar<SportEquipmentListSortOrder>,
  sportObjectsVars: ReactiveVar<SportObjectsVars>
) => {

  const tabActive = sportEquipmentTabActiveVars();
  const sportEquipmentsFilters = sportEquipmentListFiltersVars();
  const pagination = sportEquipmentListPaginationVars();
  const sortOrderModel = sportEquipmentListSortOrderVar();
  const sportObjects = sportObjectsVars();

  const filters = () => {
    const {commissioningDate, ...filters} = sportEquipmentsFilters
    return {
      ...filters,
      ...(commissioningDate && {
        commissioningDateFrom: commissioningDate?.startDate?.toISOString(),
        commissioningDateTo: commissioningDate?.endDate?.toISOString(),
      } )
    }
  }

  const getInput = (): GetSportEquipmentInput => ({

    sportObjectIds: [...sportObjects.selectedSportObjectIds],

    ...(filters && { ...filters() }),

    ...(tabActive?.id === SportEquipmentTabIdsEnum.IS_ARCHIVED && { archived: true }),
    ...(tabActive?.id === SportEquipmentTabIdsEnum.IS_WROTE_OFF && { isWroteOff: true }),

    ...(sortOrderModel && { order: sortOrderModel }),

    ...(pagination && {
      skip: pagination?.page * pagination?.pageSize,
      take: pagination?.pageSize
    }),

  });


  return {
    getInput
  };
};

