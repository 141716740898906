import PageTitle from "components/PageTitle";
import React from "react";
import { Box, Button, Stack } from "@mui/material";
import SearchByName from "./SearchByName/SearchByName";
import ExcelTools from "./ExcelTools";

interface Props {
  title: string;
  searchInputPlaceholder: string;
  addButtonName: string;

  actions: {
    searchSportEquipmentByName: (value: string) => void;
    openAddSportEquipmentDialog: any
  };
}

export const Header = (props: Props) => {
  const {
    title,
    searchInputPlaceholder,
    addButtonName,

    actions: {
      searchSportEquipmentByName,
      openAddSportEquipmentDialog
    }
  } = props;

  return (
    <Box display={"flex"} px={5} pt={4} pb={3} component={"header"} className="page__header">

      <PageTitle title={title}  />

      <Box flexGrow={1} />
      <Stack direction={"row"} spacing={3} alignItems={"center"}>

        <SearchByName
          placeholder={searchInputPlaceholder}
          searchSportEquipmentByName={(text: string) => {
            searchSportEquipmentByName(text.length > 0 ? text : null);
          }}
        />

        <ExcelTools />

        <Button
          size={"small"}
          variant={"contained"}
          onClick={openAddSportEquipmentDialog}
        >
          {addButtonName}
        </Button>

      </Stack>


    </Box>
  );
};


export default Header;
