import React, {FC} from 'react';
import {Box} from "@mui/material";
import Page from "../../../components/Page";
import PageTitle from "../../../components/PageTitle";
import PageContent from "../../../components/PageContext";
import {useEquipmentRepairRequestsQuery} from "../../../graphql/graphQlApiHooks";
import EquipmentRepairRequestList from "./List";
import {Outlet} from "react-router-dom";
import Filters from "./filters";
import {useReactiveVar} from "@apollo/client";
import {equipmentRepairRequestsTotalCountVar} from "../../../store/reactiveVarsStores/repair-requests/totalCount";
import {ExportEquipmentRepairRequestsInExcelButton} from "./ExportEquipmentRepairRequestsInExcelButton";


const RepairsRequest: FC = () => {
    const pageTitle = "Заявки на ремонт оборудования";
    const total = useReactiveVar(equipmentRepairRequestsTotalCountVar);


    const {data: {equipmentRepairRequests} = {}, loading} = useEquipmentRepairRequestsQuery({
        variables: {
            filter: {}
        },
    });

    return (
        <Page title={pageTitle}>


            <Box display={"flex"} px={5} pt={4} pb={3} component={"header"} className="page__header">
                <PageTitle
                    title={pageTitle}
                    count={total}
                    totalCount={equipmentRepairRequests?.total}
                    loading={loading}
                />

                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"end"}
                    width={'100%'}
                >

                    <ExportEquipmentRepairRequestsInExcelButton/>
                </Box>
            </Box>

            <Filters/>

            <PageContent>
                <EquipmentRepairRequestList/>
                {<Outlet/>}
            </PageContent>

        </Page>
    );
};

export default RepairsRequest
