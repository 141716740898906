import {RequestEntity} from "../../../../types/RequestEntity";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import Tooltiper from "components/Tooltiper";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import React from "react";

// Компонент рендеринга ячейки для вида спорта
export const SportKindNameCellRenderer = ({equipmentRequestEntity}: { equipmentRequestEntity: RequestEntity }) => {
    return (
        <>
            {isSingleRequest(equipmentRequestEntity) &&
                <Tooltiper text={equipmentRequestEntity?.sportKindObject?.name}/>
            }
            {isCombinedRequest(equipmentRequestEntity) &&
                <Tooltiper
                    text={
                        Array.from(new Set(equipmentRequestEntity?.childRequests
                            ?.map((request) => request.sportKindObject?.name)
                            .filter(name => name)
                        )).join(', ')
                    }
                />}
        </>
    );
};