import React from "react";

import { Dayjs } from "dayjs";
import "dayjs/locale/ru";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { FormControl, TextField } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import { TextFieldLabel } from "../TextFieldLabel";

interface Props {
  date: Dayjs | null;
  setDate: (values: Dayjs | null) => void;
  touched?: FormikTouched<any>;
  errors?: FormikErrors<any>;
  isEditable?: boolean;
  handleBlur?: (event: React.FocusEvent<any>) => void;
}

const CommissioningDate = (props: Props) => {
  const {
    date,
    setDate,
    touched,
    errors,
    isEditable,
    handleBlur
  } = props;


  return (
    <FormControl
      size={"small"}
      fullWidth
      variant={"filled"}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
        <DatePicker
          views={["day", "month", "year"]}

          label={
            <TextFieldLabel
              labelText={"Дата ввода в эксплуатацию"}
            />
          }

          value={date}
          onChange={setDate}

          readOnly={!isEditable}

          renderInput={params => <TextField
            autoComplete="off"
            {...params}
            variant={"filled"}
            inputProps={{
              ...params.inputProps,
              placeholder: "дд.мм.гггг"
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
            InputLabelProps={{
              shrink: true
            }}
            required
            size={"small"}

            onBlur={handleBlur}

            {
              ...{
                ...(touched?.commissioningDate && errors?.commissioningDate && { error: true }),
                ...(touched?.commissioningDate && errors?.commissioningDate && { helperText: errors?.commissioningDate as string })
              }
            }
          />}
        />
      </LocalizationProvider>
    </FormControl>

  );
};

export default CommissioningDate;