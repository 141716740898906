import React from 'react';
import {observer} from "mobx-react-lite";
import {
    Box,
    ListItem,
    ListItemText, Stack,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {SportObjectEntity, SportSubZoneEntity, SportZoneEntity} from "../../../graphql/graphQlApiHooks";
import {HighlightedBox} from "../../HighlightedBox";
import Tooltiper from "../../Tooltiper";
import {UsersOfSportObject} from 'components/UsersOfSportObject';


interface Props {
    sourceSportObject?: SportObjectEntity | null
    targetSportObject?: SportObjectEntity | null
    targetSportZone?: SportZoneEntity | null
    targetSportSubZone?: SportSubZoneEntity | null
}

const StepperRelocation = observer((props: Props) => {
    const {sourceSportObject, targetSportObject, targetSportZone, targetSportSubZone} = props

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <ListItem>
                <ListItemText
                    secondary={'Движение обрудования'}
                />
            </ListItem>

            <HighlightedBox
                sx={{
                    mt: -1,
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <Stepper sx={{
                    alignItems: 'stretch',
                    '& .MuiStepConnector-root': {
                        px: '2%',
                        alignSelf: 'center',
                    },
                    '& .MuiStep-root': {
                        minHeight: '100%',
                    },
                    '& .MuiStepLabel-root': {
                        minHeight: '100%',
                    },
                    '& .MuiStepLabel-labelContainer': {
                        alignSelf: 'start',
                    },
                    '& .MuiStepLabel-iconContainer': {
                        display: 'none',
                    },
                }}>
                    <Step active={Boolean(sourceSportObject?.name)} sx={{
                        width: '45%',
                    }}>
                        <StepLabel>
                            <Typography variant={'body2'} sx={{fontSize: '11px'}}>
                                Спортобъект нахождения
                            </Typography>

                            <Stack direction={'row'} spacing={1}>
                                <Tooltiper text={sourceSportObject?.name} props={{
                                    variant: 'h5',
                                }}/>

                                <UsersOfSportObject
                                    sportObjectId={sourceSportObject?.id as string}
                                />
                            </Stack>
                        </StepLabel>
                    </Step>

                    <Step active={Boolean(targetSportObject?.name)} sx={{
                        width: '45%',
                        position: 'relative'
                    }}>
                        <StepLabel>
                            <Typography variant={'body2'} sx={{fontSize: '11px'}}>
                                Спортобъект назначения
                            </Typography>
                            <Stack direction={'row'} spacing={1}>
                                <Tooltiper text={targetSportObject?.name} props={{
                                    variant: 'h5',
                                }}/>
                                {targetSportObject &&
                                    <UsersOfSportObject
                                        sportObjectId={targetSportObject?.id as string}
                                    />
                                }
                            </Stack>

                            <Tooltiper text={targetSportZone?.name} props={{
                                variant: 'caption',
                                sx: {display: 'block'}
                            }}/>
                            <Tooltiper text={targetSportSubZone?.name} props={{
                                variant: 'caption',
                                sx: {display: 'block'}
                            }}/>
                        </StepLabel>
                    </Step>
                </Stepper>
            </HighlightedBox>
        </Box>
    );
})

export default StepperRelocation;
