import {makeVar} from "@apollo/client";
import {DateRange} from "components/mui-daterange-picker";
import {EquipmentRequestStatusEnum} from "graphql/graphQlApiHooks";

export interface EquipmentRequestsFilters {
    name?: string;
    description?: string;
    applicationDate?: DateRange;
    requestStatus?: (keyof typeof EquipmentRequestStatusEnum)[]

    equipmentTypeIds?: string[];
    sportKindIds?: string[];
    sportCategoryIds?: string[];

    sportSubZoneIds?: string[];
    sportZoneIds?: string[];
}

const initialFilters: EquipmentRequestsFilters = {
    name: null,
    description: null,
    applicationDate: null,
    requestStatus: null,

    equipmentTypeIds: null,
    sportKindIds: null,

    sportZoneIds: null,
    sportSubZoneIds: null,
}

export const equipmentRequestsFiltersVar = makeVar<EquipmentRequestsFilters>(initialFilters);

const resetFilters = () => {
    equipmentRequestsFiltersVar(initialFilters);
}

const setFilters = (filters: EquipmentRequestsFilters) => {
    equipmentRequestsFiltersVar(filters);
}

const setFilter = (filter: keyof EquipmentRequestsFilters, value: any) => {
    equipmentRequestsFiltersVar({
        ...equipmentRequestsFiltersVar(),
        [filter]: value
    });
}

const getFilters = () => {
    return equipmentRequestsFiltersVar();
}

const getFilter = (filter: keyof EquipmentRequestsFilters) => {
    return equipmentRequestsFiltersVar()[filter];
}

export const useEquipmentRequestsFilters = () => {
    return {
        setFilters,
        setFilter,
        getFilters,
        getFilter,
        resetFilters
    }
}