import React, {useEffect, useState} from 'react';
import {Avatar, Stack, Tooltip, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {UserEntity} from "../graphql/graphQlApiHooks";
import {stringAvatar} from "../helpers/stringAvatar";

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
		<Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 220,
		minWidth: 180,
		fontSize: theme.typography.pxToRem(12),
	},
}));


interface Props {
	user: UserEntity,
	showTooltip?: boolean
	props?: {
		[key: string]: any
	}
}

function UserAvatar(rest: Props) {
	const {user, props, showTooltip} = rest;
	const [fio, setFio] = useState('');

	useEffect(() => {
		if (user) {
			const fio = user?.fio ? user?.fio : user.login;
			setFio(fio)
		} else {
			setFio('')
		}
	}, [user]);


	return (
			<>
				<HtmlTooltip
						arrow
						title={
							showTooltip === false ? '' : <>
								<Stack direction={'row'} spacing={1} alignItems={'center'} pb={1} px={1} pt={.5}>
									<Avatar alt={fio}
									        {...stringAvatar(fio as string)}
									        sx={{
										        width: 22,
										        height: 22,
									        }}
									        {...props}
									/>
									<Stack direction={'column'} spacing={-.5}>
										<Typography color="inherit" variant={'subtitle1'} sx={{
											textTransform: 'capitalize',
											fontSize: '12px'
										}}>{fio}</Typography>

									</Stack>
								</Stack>
							</>
						}
				>
					<Avatar alt={fio}
					        {...stringAvatar(fio as string)}
					        sx={{
						        width: 22,
						        height: 22,
					        }}
					        {...props}
					/>
				</HtmlTooltip>

			</>
	);
}

export default UserAvatar;