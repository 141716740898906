import React, {useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import {
	Box,
	Button, Checkbox, Chip, FormControlLabel, TextField, Typography,
} from "@mui/material";
import {
	EquipmentServiceJobEntity, InputMaybe,
	Scalars,
	UpdateEquipmentServiceJobInput,
} from "../../../../../graphql/graphQlApiHooks";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import Gallery from "components/Gallery";
import DoneIcon from '@mui/icons-material/Done';
import Tooltiper from "components/Tooltiper";
import {useStores} from "../../../../../store";
import {Image} from "../../../../../store/stores/gallery.store";
import Settings from "../../../../../store/settings";
import dayjs, {Dayjs} from "dayjs";

interface Props {
	data: EquipmentServiceJobEntity | null
	open: boolean
	onClose: () => void
}

const Report = observer((props: Props) => {
	const { data, open, onClose, } = props;
	const {galleryStore, serviceJobsStore, userStore} = useStores()

	const [description, setDescription] = useState('');
	const [doneCheckbox, setDoneCheckbox] = useState(false);
	const [deadLineDate, setDeadLineDate] = useState<any>(null);

	useEffect(()=>{
		if (data?.deadline){
			setDeadLineDate(data.deadline)
		}
	},[data])

	const handleClose = () => {
		setDescription('')
		setDoneCheckbox(false)
		galleryStore.clear()
		onClose()
	};

	useMemo(()=>{
		if (data?.photos && data?.photos?.length > 0){
			galleryStore.setImages(
					[...data.photos as Image[]].map((photo:Image)=>{
						return {
							...photo,
							url: Settings.server + photo.url,
							rotate: 0,
							subHtml: data?.equipment?.name,
						}
					})
			)
		}
	}, [data])

	const handleConfirm = () => {
		if ( data ) {
			const input = {
				id: data.id,
				done: doneCheckbox,
				doneDate: dayjs().format('YYYY-MM-DD'),
				deadline: dayjs(deadLineDate).format('YYYY-MM-DD'),
				description: description,
				photosIds: galleryStore.images.map((photo: Image) => photo.id).filter((photo: Image) => photo) || [],
			}
			serviceJobsStore.update(input as UpdateEquipmentServiceJobInput).then(() => {
				handleClose()
			})

		}
	};

	function handleChangeDoneCheckbox() {
		setDoneCheckbox(!doneCheckbox)
	}

	function changeDeedLineDate(date: Dayjs | null ) {
		setDeadLineDate(date)
	}
	function updateDeedLineDate(date: Dayjs | null ) {
		serviceJobsStore.update({
			id: data?.id as string,
			done: data?.done!,
			deadline: dayjs(date).format('YYYY-MM-DD')
		})

	}

	return (

			<Dialog
					open={open}
					onClose={handleClose}
					title={'Отчёт по обслуживанию оборудования'}
					titles={[
						<EquipmentInfo
								equipment={data?.equipment}
								endAdornment={{
									title: 'Крайний срок выполнения',
									date: deadLineDate as string,
									editableDate: (!data?.done)
								}}
								onDateChange={changeDeedLineDate}
								onDateAccept={updateDeedLineDate}
						/>,
						<Box alignItems={'center'} width={'100%'} display={'flex'} justifyContent={'space-between'} sx={{p: 2}}>
							<Typography component={'div'} variant={'caption'} sx={{width:'100%'}} >Вид работ:</Typography>
							<Tooltiper
									text={(!data?.name || data?.name === '') ? 'Без названия' : data.name}
									props={{
										variant: 'h5',
										align: 'right',
										sx: {width:'100%'}
									}}
							/>
						</Box>,
						<Box alignItems={'center'} width={'100%'} display={'flex'} justifyContent={'space-between'} sx={{p: 2}}>
							<Typography component={'div'} variant={'caption'} sx={{width:'50%'}}>Состояние выполнения работы:</Typography>
							{(!data?.done) ?
									<FormControlLabel
											control={
												<Checkbox
														color={'success'}
														checked={doneCheckbox}
														onChange={handleChangeDoneCheckbox}
														inputProps={{'aria-label': 'controlled'}}/>
											}
											label={(doneCheckbox) ? 'Выполнено' : 'Не выполнено'}
									/> :
									<Chip size={'small'} variant="outlined" color="success" icon={<DoneIcon/>}
									      label={(data.jobStatus === 'DONE') ? 'Выполнено' : 'Выполнено с опозданием'}/>
							}
						</Box>,
					]}
					actions={<Box >
						{(!data?.done) ?
								<Button
										variant={'contained'}
										onClick={handleConfirm}
								>Отправить отчёт</Button>
								:
								<Button
										variant={'contained'}
										onClick={handleClose}
								>Закрыть отчёт</Button>
						}
					</Box>}
			>
				<Box sx={{position: 'relative'}}>
					{(!data?.done) ?
					<TextField
							value={description}
							onChange={(event) => setDescription(event.target.value)}
							id="equipmentDescription"
							label="Отчёт о проделанной работе"
							placeholder={'Опишите состояние оборудования'}
							multiline
							maxRows={4}
							minRows={2}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
					/> :
							<>
								<Typography component={'div'} variant={'caption'} sx={{width:'100%'}}>Отчёт о проделанной работе</Typography>
								<Typography component={'div'} variant={'body1'} sx={{width:'100%'}}>{data?.description}</Typography>
							</>

					}
					<Box sx={{mt: 3, maxHeight: 250, minHeight: 250, overflowY: 'auto', position: 'relative'}}>
						<Box sx={{position: 'absolute', width:'100%', height: '100%'}}>
							<Gallery readOnly={(data?.done)}/>
						</Box>
					</Box>
				</Box>

			</Dialog>
	);
})

export default Report;
