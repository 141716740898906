import {EquipmentRepairRequestEntity, EquipmentRepairRequestStatusEnum} from "../../../../graphql/graphQlApiHooks";
import React from "react";
import {
    Button,
    ButtonGroup,
    Popper,
    Paper,
    MenuItem,
    MenuList,
    Grow,
    ClickAwayListener,
} from "@mui/material";
import {Box} from "@mui/system";
import FixedByOwnEffortsDialog from "./Actions/Dialogs/fixedByOwnEfforts.dialog";
import IncludedInContractDialog from "./Actions/Dialogs/includedInContract.dialog";
import RecognisedImpracticalDialog from "./Actions/Dialogs/recognisedImpractical.dialog";
import CompletedRepairDialog from "./Actions/Dialogs/completedRepair.dialog";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ActionsRepairRequest = (props: { equipmentRepairRequest: EquipmentRepairRequestEntity }) => {
    const {
        equipmentRepairRequest: {
            stateHistory,
            id
        }
    } = props;

    const lastState = stateHistory[stateHistory.length - 1];
    const [dialogsState, setDialogsState] = React.useState({
        fixedByOwnEfforts: false,
        includedInContract: false,
        recognisedImpractical: false,
        completedRepair: false,
    });

    const fixedByOwnEffortsHandler = () => {
        setDialogsState(prevState => ({
            ...prevState,
            fixedByOwnEfforts: true
        }));
    };

    const includedInContractHandler = () => {
        setDialogsState(prevState => ({
            ...prevState,
            includedInContract: true
        }));
    };

    const recognisedImpracticalHandler = () => {
        setDialogsState(prevState => ({
            ...prevState,
            recognisedImpractical: true
        }));
    };
    const completedRepairHandler = () => {
        setDialogsState(prevState => ({
            ...prevState,
            completedRepair: true
        }));
    };


    const options = {
        [EquipmentRepairRequestStatusEnum.Pending]: [
            'Устранено своими силами',
            'Включено в контракт на ремонт',
            'Не подлежит ремонту'
        ],
        [EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract]: [
            'Отремонтировано',
            'Не подлежит ремонту'
        ],
    };

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        switch (lastState.status) {
            case EquipmentRepairRequestStatusEnum.Pending:
                switch (selectedIndex) {
                    case 0: //'Устранено своими силами'
                        fixedByOwnEffortsHandler();
                        setSelectedIndex(0);
                        break;
                    case 1: //'Включено в контракт на ремонт'
                        includedInContractHandler();
                        setSelectedIndex(0);
                        break;
                    case 2: //'Не подлежит ремонту'
                        recognisedImpracticalHandler();
                        setSelectedIndex(0);
                        break;
                }
                break;
            case EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract:
                switch (selectedIndex) {
                    case 0: //'Отремонтировано'
                        completedRepairHandler();
                        setSelectedIndex(0);
                        break;
                    case 1: //'Не подлежит ремонту'
                        recognisedImpracticalHandler();
                        setSelectedIndex(0);
                        break;
                }
                break;
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {
                options[lastState.status]?.length > 0 && <>
                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button"color={options[lastState.status][selectedIndex] === 'Не подлежит ремонту' ? 'error' : 'primary'}>
                        <Button
                            size="small"
                            onClick={handleClick}
                            variant={'contained'}
                            color={options[lastState.status][selectedIndex] === 'Не подлежит ремонту' ? 'error' : 'primary'}
                            sx={{
                                fontSize: '12px'
                            }}
                        >
                            {options[lastState.status][selectedIndex]}
                        </Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            color={options[lastState.status][selectedIndex] === 'Не подлежит ремонту' ? 'error' : 'primary'}
                            variant={'contained'}
                            sx={{
                                fontSize: '12px',
                                minWidth:'initial',
                                width:'initial',
                                px: 1
                            }}
                        >
                            <ArrowDropDownIcon/>
                        </Button>
                    </ButtonGroup>

                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem
                                        >
                                            {options[lastState.status].map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                    dense
                                                    divider={options[lastState.status][index+1] === 'Не подлежит ремонту'}
                                                    sx={
                                                        options[lastState.status][index] === 'Не подлежит ремонту' && {
                                                            color: 'error.main'
                                                        }
                                                    }
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            }


            {/*диалог для устранено своими силами*/}
            <FixedByOwnEffortsDialog
                open={dialogsState.fixedByOwnEfforts}
                onClose={() => {
                    setDialogsState(prevState => ({
                        ...prevState,
                        fixedByOwnEfforts: false
                    }));
                }}
                equipmentRepairRequestId={id}
            />

            {/*диалог для включено в контракт на ремонт*/}
            <IncludedInContractDialog
                open={dialogsState.includedInContract}
                onClose={() => {
                    setDialogsState(prevState => ({
                        ...prevState,
                        includedInContract: false
                    }));
                }}
                equipmentRepairRequestId={id}
            />

            {/*диалог для не подлежит ремонту*/}
            <RecognisedImpracticalDialog
                open={dialogsState.recognisedImpractical}
                onClose={() => {
                    setDialogsState(prevState => ({
                        ...prevState,
                        recognisedImpractical: false
                    }));
                }}
                equipmentRepairRequestId={id}
            />

            {/*диалог для отремонтировано*/}
            <CompletedRepairDialog
                open={dialogsState.completedRepair}
                onClose={() => {
                    setDialogsState(prevState => ({
                        ...prevState,
                        completedRepair: false
                    }));
                }}
                equipmentRepairRequestId={id}
            />

        </>
    );
}

export default ActionsRepairRequest