import {BoxProps} from "@mui/material/Box/Box";
import React, {FC, ReactNode} from "react";
import {Box, Typography} from "@mui/material";

type FieldDescriptionProps = BoxProps & {
    label: string;
    value: string | number | ReactNode;
}

export const FieldDescription: FC<FieldDescriptionProps> = ({label, value, ...RootProps}) => {

    return (
        <Box
            {...RootProps}
            sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                ...(RootProps?.sx && {...RootProps?.sx})
            }}
        >
            <Typography
                variant={"caption"}
                color={"text.secondary"}
                sx={{
                    whiteSpace: 'nowrap',
                    alignSelf: 'start',
                }}
            >
                {label}:&nbsp;
            </Typography>

            <Box
                flexGrow={1}
                borderBottom={"1px dashed"}
                borderColor={"divider"}

                height={".8rem"}
                mx={1}
            />

            <Typography
                component={'span'}
                sx={{
                    fontWeight: 600,
                    lineHeight: "1.3",
                    textAlign: "right",
                }}
                variant={"body2"}
            >
                {value}
            </Typography>
        </Box>
    )
}