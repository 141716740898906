import React, {useEffect, useMemo} from 'react';
import {Avatar, Box, CardHeader, IconButton, Tooltip} from '@mui/material';
import {lighten, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
    DataGrid,
    GridColumns,
    GridRowData,
    GridSortItem,
    GridSortModel, GridToolbarContainer,
} from "@mui/x-data-grid";
import MuiDataGridStyles from "components/MuiDataGridStyles";
import dayjs from "dayjs";
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import clsx from 'clsx';
import {useStores} from '../../../../store';
import {observer} from 'mobx-react-lite';
import FiltersPanel, {FiltersType} from "components/FiltersPanel";
import FilterAutocomplete from "components/FiltersPanel/FilterComponents/FilterAutocomplete";
import {EquipmentServiceJobEntity, SportSubZoneEntity, SportZoneEntity} from "../../../../graphql/graphQlApiHooks";
import FilterSearch from "components/FiltersPanel/FilterComponents/FilterSearch";
import FilterDateRange from "components/FiltersPanel/FilterComponents/FilterDateRange";
import Settings from "../../../../store/settings";
import Tooltiper from "components/Tooltiper";
import {useTheme} from "@mui/styles";
import Report from "../Dialogs/Report/Report";
import {BootstrapTooltip} from "../../../../components/BootstrapTooltip";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiDataGrid-main': {
            '& .MuiDataGrid-row': {
                '& $cell': {
                    width: '100%',
                    maxHeight: '70px !important',
                    overflow: 'hidden',
                },
            }
        }
    },
    status: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '&.planned': {
            color: theme.palette.primary.main,
            background: lighten(theme.palette.primary.main, 0.85),
            borderRadius: 4,
            padding: '4px 8px'
        },
        '&.overdue': {
            color: '#e22d48',
            background: lighten('#e22d48', 0.85),
            borderRadius: 4,
            padding: '4px 8px'
        },
        '&.almost_overdue': {
            color: '#f47c36',
            background: lighten('#f47c36', 0.85),
            borderRadius: 4,
            padding: '4px 8px'
        },
        '&.done': {
            color: '#51cc88',
            background: lighten('#51cc88', 0.85),
            borderRadius: 4,
            padding: '4px 8px'
        },
    },
    statusIcon: {
        marginRight: '.5rem'
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `rgba(${theme.palette.primary.main}, .25) 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    cell: {}
}));

interface IStatus {
    name: string
    value: string
    className: string
}

const statuses: IStatus[] = [
    {
        name: 'Выполнено',
        value: 'DONE',
        className: 'done'
    },
    {
        name: 'Выполнено с опозданием',
        value: 'DONE_LATE',
        className: 'done_late'
    },
    {
        name: 'В плане',
        value: 'PLANNED',
        className: 'planned'
    },
    {
        name: 'Почти просрочено',
        value: 'ALMOST_EXPIRED',
        className: 'almost_overdue'
    },
    {
        name: 'Просрочено',
        value: 'EXPIRED',
        className: 'overdue'
    },
];

const getStatusName = (value: any) => {
    return statuses.find((status: IStatus) => status?.value === value)?.name || ''
};
const getStatusValue = (name: any) => {
    return statuses.find((status: IStatus) => status.value === name)?.className || ''
};
const RenderStatus = (status: any) => {
    const classes = useStyles();
    status = status.status
    let icon, statusClass;


    switch (statuses.find((s: IStatus) => s?.value === status)?.value) {
        case 'PLANNED':
            icon = <AccessTimeIcon
                fontSize={'small'}
                classes={{root: classes.statusIcon}}
            />;
            statusClass = 'planned';
            break;
        case 'ALMOST_EXPIRED':
            icon = <TimerOffIcon
                fontSize={'small'}
                classes={{root: classes.statusIcon}}
            />;
            statusClass = 'almost_overdue';
            break;
        case 'DONE_LATE':
            icon = <TimerOffIcon
                fontSize={'small'}
                classes={{root: classes.statusIcon}}
            />;
            statusClass = 'overdue';
            break;
        case 'EXPIRED':
            icon = <TimerOffIcon
                fontSize={'small'}
                classes={{root: classes.statusIcon}}
            />;
            statusClass = 'overdue';
            break;
        default:
            icon = <AlarmOnIcon
                fontSize={'small'}
                classes={{root: classes.statusIcon}}
            />;
            statusClass = 'done';
    }

    return (
        <React.Fragment>
            <div className={clsx(classes.status, statusClass)}>
                {icon}
                {getStatusName(status)}
            </div>
        </React.Fragment>
    )

};


const CustomToolbar = observer(() => {
    const {serviceJobsStore, dictionariesStore} = useStores();

    const filters: FiltersType[] = [
        {
            name: 'sportZoneIds',
            title: "Объекты спорта",
            removable: false,
            filterComponent: (props: any) => <FilterAutocomplete
                values={serviceJobsStore.sportZones}
                selected={serviceJobsStore.filters.sportZoneIds}
                filterResult={(result: SportZoneEntity[]) => serviceJobsStore.setFilters({sportZoneIds: result.map(val => val.id)})}
                {...props}
            />,
            chipDescription: () => {
                return (serviceJobsStore.filters.sportZoneIds !== null) ? `: ${serviceJobsStore.filters.sportZoneIds.length}/${serviceJobsStore.sportZoneIds.length}` : ''
            }
        },
        {
            name: 'sportSubZoneIds',
            title: "Спортзона",
            removable: false,
            filterComponent: (props: any) => <FilterAutocomplete
                values={serviceJobsStore.sportSubZones}
                selected={serviceJobsStore.filters.sportSubZoneIds}
                filterResult={(result: SportSubZoneEntity[]) => serviceJobsStore.setFilters({sportSubZoneIds: result.map(val => val.id)})}
                {...props}
            />,
            chipDescription: () => {
                return (serviceJobsStore.filters.sportSubZoneIds !== null) ? `: ${serviceJobsStore.filters.sportSubZoneIds.length}/${serviceJobsStore.sportSubZoneIds.length}` : `: 0/${serviceJobsStore.sportSubZoneIds.length}`
            }
        },
        {
            name: 'equipmentName',
            title: 'Название оборудования',
            filterComponent: (props: any) => <FilterSearch
                value={serviceJobsStore.filters.equipmentName}
                filterResult={(result: string) => serviceJobsStore.setFilters({equipmentName: result})}
                {...props}
            />,
            chipDescription: () => {
                return (serviceJobsStore.filters.equipmentName !== null && serviceJobsStore.filters.equipmentName !== '') ? ` содержит ${serviceJobsStore.filters.equipmentName}` : ''
            },
            clearFilter: () => {
                serviceJobsStore.setFilters({equipmentName: null})
            }
        },
        {
            name: 'equipmentInventoryNumber',
            title: 'Инвентарный номер',
            filterComponent: (props: any) => <FilterSearch
                value={serviceJobsStore.filters.equipmentInventoryNumber}
                filterResult={(result: string) => serviceJobsStore.setFilters({equipmentInventoryNumber: result})}
                {...props}
            />,
            chipDescription: () => {
                return (serviceJobsStore.filters.equipmentInventoryNumber !== null && serviceJobsStore.filters.equipmentInventoryNumber !== '') ? ` содержит ${serviceJobsStore.filters.equipmentInventoryNumber}` : ''
            },
            clearFilter: () => {
                serviceJobsStore.setFilters({equipmentInventoryNumber: null})
            }
        },
        {
            name: 'deadline',
            title: 'Крайний срок выполнения',
            filterComponent: (props: any) => <FilterDateRange
                value={serviceJobsStore.filters.deadline}
                filterResult={(result) => serviceJobsStore.setFilters({deadline: result})}
                {...props}
            />,
            chipDescription: () => {
                const deadline = serviceJobsStore.filters.deadline;
                if (deadline?.[0] !== null) {
                    if (!dayjs(deadline?.[0]).isSame(deadline?.[1]) && deadline?.[1]) {
                        return ` С ${dayjs(deadline?.[0]).format('ll')} по ${dayjs(deadline?.[1]).format('ll')}`
                    } else {
                        return ` ${dayjs(deadline?.[0]).format('ll')}`
                    }
                } else {
                    return ''
                }
            },
            clearFilter: () => {
                serviceJobsStore.setFilters({deadline: [null, null]})
            }
        },
        {
            name: 'done',
            title: 'Дата выполнения',
            filterComponent: (props: any) => <FilterDateRange
                value={serviceJobsStore.filters.done}
                filterResult={(result) => serviceJobsStore.setFilters({done: result})}
                {...props}
            />,
            chipDescription: () => {
                const done = serviceJobsStore.filters.done;
                if (done?.[0] !== null) {
                    if (!dayjs(done?.[0]).isSame(done?.[1]) && done?.[1]) {
                        return ` С ${dayjs(done?.[0]).format('ll')} по ${dayjs(done?.[1]).format('ll')}`
                    } else {
                        return ` ${dayjs(done?.[0]).format('ll')}`
                    }
                } else {
                    return ''
                }
            },
            clearFilter: () => {
                serviceJobsStore.setFilters({done: [null, null]})
            }
        }
    ];

    function getFiltersSelected(filters: any) {
        let result = [];
        for (let [key, value] of Object.entries(serviceJobsStore.filters)) {
            const filter = filters.find((item: any) => item.name === key);
            const isNullsArray = Boolean(Array.isArray(value) && value.filter((item: any) => item === null).length === 2)
            const isEmpty = Boolean(value == null || isNullsArray)
            if (!isEmpty || filter?.removable === false)
                result.push(filter)
        }
        return result;
    }

    return (
        <GridToolbarContainer>
            <FiltersPanel
                store={serviceJobsStore}
                filters={filters}
                selected={getFiltersSelected(filters)}
            />
        </GridToolbarContainer>
    );
});

const ServiceJobsTable = observer((rest: { isArchived: boolean; searchByEquipmentNameValue: string }) => {
    const classes = useStyles();
    const theme = useTheme();
    const tableClasses = MuiDataGridStyles();
    const {serviceJobsStore} = useStores();
    const [openEditServiceJobDialog, setOpenEditServiceJobDialog] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState<EquipmentServiceJobEntity | null>(null);
    const [rows, setRows] = React.useState<readonly GridRowData[]>([]);


    useEffect(() => {
        serviceJobsStore.setFilters({equipmentName: rest.searchByEquipmentNameValue})
    }, [rest.searchByEquipmentNameValue])


    const columns: GridColumns = [
        {
            field: 'equipment.name', headerName: 'Оборудование',
            renderCell: (params) => {
                const avatarUrl = (params.row.equipment.photos?.length > 0) && params.row.equipment.photos?.[0].avatarUrl
                return (
                    <CardHeader
                        sx={{
                            p: 0,
                            maxWidth: '100%',
                            '& .MuiCardHeader-content': {
                                overflow: 'hidden',
                            },
                        }}
                        avatar={
                            <Avatar
                                sx={{width: 30, height: 30, bgcolor: theme.palette.background.default}}
                                src={(avatarUrl) ? `${Settings.server}${avatarUrl}` : ''}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/static/mossport_logo.svg`}
                                    alt="mossport_logo"
                                />
                            </Avatar>
                        }
                        title={
                            <Tooltiper
                                text={params.row.equipment.name}
                                props={{
                                    variant: 'h5'
                                }}
                            />
                        }
                        subheader={`Инв. № ${params.row.equipment.inventoryNumber}`}
                    />
                );
            },
            flex: 2,
            minWidth: 180,
            disableColumnMenu: true,
        },
        {
            field: 'equipmentServiceJob.name',
            headerName: 'Название и вид работ',
            renderCell: (params) => {
                return (
                    <CardHeader
                        title={
                            <Tooltiper
                                text={(params?.row?.name) ? params.row.name : 'Название не указано'}
                                props={
                                    (params?.row?.name) ? {
                                        variant: 'h4'
                                    } : {
                                        variant: 'h4',
                                        sx: {
                                            color: 'text.secondary',
                                            fontWeight: 400
                                        }
                                    }
                                }
                            />
                        }
                    />
                );
            },
            flex: 2,
            minWidth: 180,
            disableColumnMenu: true,
        },
        {
            field: 'sportZone.name', headerName: 'Объект спорта',
            renderCell: (params) => (
                <Tooltiper
                    text={params.row?.sportZone?.name}
                />
            ),
            flex: 1,
            minWidth: 120,
            disableColumnMenu: true,
        },
        {
            field: 'sportSubZone.name', headerName: 'Спортзона',
            renderCell: (params) => (
                <Tooltiper
                    text={params.row?.sportSubZone?.name}
                />
            ),
            flex: 1,
            minWidth: 120,
            disableColumnMenu: true,
        },
        {
            field: 'equipmentServiceJob.deadline', headerName: 'Крайний срок работ',
            flex: 1,
            type: 'date',
            renderCell: (params) => {
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        lineHeight={'initial'}
                    >
                        {dayjs(Number(params.row.deadline)).format('DD MMM YYYY')}
                    </Box>
                );
            },
            minWidth: 120,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'equipmentServiceJob.doneDate', headerName: 'Дата выполнения',
            flex: 1,
            type: 'date',
            renderCell: (params) => {
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        lineHeight={'initial'}
                    >
                        {
                            params.row.doneDate ?
                                dayjs(Number(params.row.doneDate)).format('DD MMM YYYY')
                                :
                                null
                        }
                    </Box>
                );
            },
            minWidth: 120,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center'
        },
        {
            minWidth: 230,
            field: 'equipmentServiceJob.jobStatus', headerName: 'Статус',
            flex: 2,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (

                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            lineHeight={'initial'}
                        >
                            <RenderStatus status={params.row.jobStatus}/>
                        </Box>

                        <Box display={'flex'}>
                            <BootstrapTooltip title={'Отчёт по обслуживанию оборудования'}>
                                <IconButton
                                    size={'medium'}
                                    onClick={() => openEditServiceJobDialogHandler(params.row)}
                                >
                                    <EventNoteIcon fontSize="small" sx={{fontSize: '18px !important'}}/>

                                </IconButton>
                            </BootstrapTooltip>
                        </Box>
                    </Box>
                );
            },
            align: 'center',
            headerAlign: 'center'

        },
    ];

    useMemo(() => {
        if (serviceJobsStore?.serviceJobs) {
            if (rest.isArchived) {
                setRows([...serviceJobsStore?.archivedServiceJobs] as never[])
            } else {
                setRows([...serviceJobsStore?.serviceJobs] as never[])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceJobsStore?.serviceJobs, rest.isArchived])

    const openEditServiceJobDialogHandler = (row: EquipmentServiceJobEntity) => {
        setCurrentRow(row)
        setOpenEditServiceJobDialog(true)
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        const sortModel = newModel[0] as GridSortItem
        const sortOrder = sortModel?.field ? {[sortModel?.field]: sortModel?.sort?.toUpperCase()} : null

        serviceJobsStore.setLoading(true);
        serviceJobsStore.setSortOrder(sortOrder)
    };

    return (
        <>
            <Box
                sx={{height: '100%', width: '100%'}}
                className={classes.root}
            >
                <DataGrid
                    className={tableClasses.root}
                    rows={rows}
                    columns={columns.map(column => ({
                        ...column,
                        cellClassName: ({row}) => clsx(classes.cell, getStatusValue(row.jobStatus)),
                    }))}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onCellDoubleClick={({row}) => openEditServiceJobDialogHandler(row)}
                    components={{
                        Toolbar: CustomToolbar,
                    }}

                    rowHeight={60}
                    checkboxSelection={false}
                    columnBuffer={10}
                    columnThreshold={10}
                    density={'standard'}
                    rowThreshold={3}
                    rowBuffer={5}
                    loading={serviceJobsStore.loading}
                    pagination
                    rowCount={serviceJobsStore.count}
                    paginationMode="server"
                    page={serviceJobsStore.page}
                    pageSize={serviceJobsStore.pageSize}
                    onPageChange={(page) => serviceJobsStore.setPage(page)}
                    onPageSizeChange={(pageSize) =>
                        serviceJobsStore.setPageSize(pageSize)
                    }
                    rowsPerPageOptions={[5, 10, 50, 100]}
                />
            </Box>
            <Report
                data={currentRow}
                open={openEditServiceJobDialog}
                onClose={() => setOpenEditServiceJobDialog(false)}
            />
            {/*<EditServiceJobDialog*/}
            {/*		open={openEditServiceJobDialog}*/}
            {/*		close={setOpenEditServiceJobDialog}*/}
            {/*		row={currentRow}*/}
            {/*/>*/}
        </>
    )
})


export default ServiceJobsTable;

