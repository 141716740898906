import React, {FC, useEffect, useRef, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    Collapse,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    InputAdornment,
    OutlinedInputProps,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {
    CreateEquipmentRequestInput,
    DictionaryEntity,
    DictionaryTypesEnum,
    EquipmentTypeDictionaryEntity,
    FileStoreEntity,
    SportKindDictionaryEntity,
    useCreateEquipmentRequestMutation,
    useGetAllDictionariesQuery,
    useGetFullDictionaryQuery
} from "graphql/graphQlApiHooks";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {omitBy} from "lodash";
import {EquipmentRequestStatusEnum} from "store/stores/equipment-request-status.enum";
import {isValidUrl} from "helpers/validateUrl";
import {useReactiveVar} from "@apollo/client";
import {userVars} from "store/reactiveVarsStores/auth/user";
import {SportEquipmentLocation} from "../../../Equipments/components/Dialogs/views/SportEquipmentLocation";
import {TextFieldLabel} from "../../../Equipments/components/Dialogs/views/TextFieldLabel";
import {
    OtherDocumentsGrid
} from "../../../Equipments/components/Dialogs/views/SportEquipmentDocuments/OtherDocumentsGrid";
import {sportObjectsVars} from "store/reactiveVarsStores/sport-objects/sportObjects";
import {Helmet} from "react-helmet";
import {useSnackbar} from "notistack";

interface IEquipmentRequestCreateDialogProps {
    close: () => void;
}

/**
 Начальное состояние для валидации формы.
 */
const getInitialErrors = () => ({
    sportObjectId: undefined as string | undefined,
    sportZoneId: undefined as string | undefined,
    sportSubZoneId: undefined as string | undefined,
    name: undefined as string | undefined,
    sportKind: undefined as string | undefined,
    equipmentType: undefined as string | undefined,
    equipmentLink: undefined as string | undefined,
    analogEquipmentLink: undefined as string | undefined,
    urgentRequirementComment: undefined as string | undefined,
    serviceNoteScanId: undefined as string | undefined
});

/**
 * Начальное состояние для мутации создания заявки на оборудование.
 */
const getInitialInput = (): CreateEquipmentRequestInput => ({
    sportObjectId: "",
    sportZoneId: "",
    sportSubZoneId: "",
    equipmentTypeId: "",
    sportKindId: "",
    serviceNoteScanId: "",
    unitOfMeasurementId: "",
    name: "",
    count: 1,
    description: "",
    urgentRequirementComment: "",
    equipmentLink: "",
    mandatorySpecifications: "",
    price: 0,
    analogEquipmentLink: "",
    requestStatus: EquipmentRequestStatusEnum.Submitted,
});

/**
 * Диалог создания заявки на оборудование.
 */
export const CreateRequestDialog: FC<IEquipmentRequestCreateDialogProps> = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const user = useReactiveVar(userVars);
    const {sportObjectList} = useReactiveVar(sportObjectsVars);
    const [createEquipmentRequest] = useCreateEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests']
    });

    const {data: allDictionaries} = useGetAllDictionariesQuery();
    const allSportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds;
    const allEquipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes

    // Единицы измерения
    const {data: {dictionaries: unitOfMeasurements = []} = {}} = useGetFullDictionaryQuery({
        variables: {dictionaryType: DictionaryTypesEnum.MeasurementUnit}
    });


    // Виды спорта
    const [sportKind, setSportKind] = useState<SportKindDictionaryEntity | null>(null);
    // Тип оборудования
    const [equipmentTypes, setEquipmentTypes] = useState<EquipmentTypeDictionaryEntity[]>([]);
    const [equipmentType, setEquipmentType] = useState<EquipmentTypeDictionaryEntity | null>(null);
    // Единица измерения
    const [unitOfMeasurement, setUnitOfMeasurement] = useState<DictionaryEntity | null>(null);
    // Флаг срочной потребности
    const [isCriticallyNeed, setIsCriticallyNeed] = useState(false);
    // Скан служебной записки с потребностью
    const [serviceNoteScan, setServiceNoteScan] = useState<FileStoreEntity | null>(null);
    // Input для мутации создания
    const [input, setInput] = useState<CreateEquipmentRequestInput>(getInitialInput);
    // Ошибки валидации
    const [errors, setErrors] = useState(getInitialErrors);

    // ссылка на поле описания срочной потребности
    const criticallyNeedRef = useRef<HTMLInputElement>(null);

    // Фокус на поле описания срочной потребности при чеке срочной потребности
    useEffect(() => {
        if (isCriticallyNeed && criticallyNeedRef?.current) {
            criticallyNeedRef?.current.focus();
        }
    }, [criticallyNeedRef, isCriticallyNeed]);

    /* ====================================== */
    /* ========== Действия диалога ========== */
    /* ====================================== */

    const onSubmit = async () => {
        const cleanedInput = omitBy(input, el => el === "") as CreateEquipmentRequestInput;
        const nextErrors: typeof errors = {
            name: !cleanedInput.name?.trim()
                ? "Вы не указали название оборудования" : undefined,

            sportObjectId: !cleanedInput.sportObjectId
                ? "Вы не выбрали спортобъект" : undefined,
            sportZoneId: !cleanedInput.sportZoneId
                ? "Вы не выбрали объект спорта" : undefined,
            sportSubZoneId: !cleanedInput.sportSubZoneId
                ? "Вы не выбрали спортзону" : undefined,

            sportKind: !cleanedInput.sportKindId
                ? "Вы не выбрали вид спорта" : undefined,
            equipmentType: !cleanedInput.equipmentTypeId
                ? "Вы не выбрали тип оборудования" : undefined,

            serviceNoteScanId: !cleanedInput.serviceNoteScanId
                ? "Вы не загрузили скан служебной записки" : undefined,

            urgentRequirementComment: isCriticallyNeed && !cleanedInput.urgentRequirementComment?.trim()
                ? "Вы не указали причину срочной потребности" : undefined,
            equipmentLink: cleanedInput.equipmentLink?.trim() && !isValidUrl(cleanedInput.equipmentLink?.trim())
                ? "Ссылка на оборудование должна быть валидным URL" : undefined,
            analogEquipmentLink: cleanedInput.analogEquipmentLink?.trim() && !isValidUrl(cleanedInput.analogEquipmentLink?.trim())
                ? "Ссылка на аналоги оборудования должна быть валидным URL" : undefined
        };
        setErrors(nextErrors);
        if (Object.values(nextErrors).every((error) => !error)) {
            if (Object.values(nextErrors).every((error) => !error)) {
                try {
                    await createEquipmentRequest({variables: {input: cleanedInput}});
                    handleCloseModal();
                    enqueueSnackbar("Заявка успешно создана", {variant: "success"});
                } catch (error) {
                    enqueueSnackbar(`Ошибка создания заявки на оборудование: ${error?.message}`, {variant: "error"});
                    console.error("Ошибка создания заявки на оборудование", error);
                }
            }
        }
    };

    const handleCloseModal = () => {
        setSportKind(null);
        setEquipmentTypes([]);
        setEquipmentType(null);
        setServiceNoteScan(null);
        setIsCriticallyNeed(false);
        setUnitOfMeasurement(null);
        setInput(getInitialInput);
        setErrors(getInitialErrors);
        props.close();
    };


    /* ======================================== */
    /* ========== Хэндлеры изменений ========== */
    /* ======================================== */

    // Изменение Вида спорта
    const onChangeSportKindHandler = (sportKind: SportKindDictionaryEntity | null) => {
        setSportKind(sportKind);
        if (sportKind && allEquipmentTypes) {
            const equipmentTypesBySportKind = allEquipmentTypes.filter((type: EquipmentTypeDictionaryEntity) => type.sportKindId === sportKind.id);

            setInput({...input, sportKindId: sportKind.id as string});
            setEquipmentTypes(equipmentTypesBySportKind as EquipmentTypeDictionaryEntity[]);
        } else {
            setInput({...input, sportKindId: ""});
            setEquipmentTypes([]);
        }
        setEquipmentType(null);
    };
    // Изменение Типа оборудования
    const onChangeEquipmentTypeHandler = (equipmentType: EquipmentTypeDictionaryEntity | null) => {
        setEquipmentType(equipmentType);
        if (equipmentType?.sportKindId && equipmentType?.sportKindId !== sportKind?.id) {
            const sportKind = allSportKinds?.find((kind: SportKindDictionaryEntity) => kind.id === equipmentType?.sportKindId);
            const equipmentTypesBySportKind = allEquipmentTypes.filter((type: EquipmentTypeDictionaryEntity) => type.sportKindId === sportKind.id);

            if (sportKind && equipmentTypesBySportKind) {
                setInput({...input, sportKindId: sportKind.id as string, equipmentTypeId: equipmentType.id as string});
                setSportKind(sportKind as SportKindDictionaryEntity);
                setEquipmentTypes(equipmentTypesBySportKind as EquipmentTypeDictionaryEntity[]);
            }
        }
        if (!equipmentType) {
            setSportKind(null);
            setEquipmentTypes(allEquipmentTypes as EquipmentTypeDictionaryEntity[]);
            setInput({...input, equipmentTypeId: null, sportKindId: null});
        }
    };
    // Изменение единицы измерения
    const onChangeUnitOfMeasurement = (unit?: DictionaryEntity | null) => {
        setUnitOfMeasurement(unit);
        setInput({...input, unitOfMeasurementId: unit?.id || null});
    };
    // Изменение количества
    const onClickCountUp = () => setInput({...input, count: input.count + 1});
    const onClickCountDown = () => setInput({...input, count: Math.max(1, input.count - 1)});
    const onChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => setInput({
        ...input,
        count: Math.max(1, Number(event.target.value))
    });
    // Изменение цены
    const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => setInput({
        ...input,
        price: Math.max(0, Number(event.target.value))
    });


    /* ========================= */
    /* ========== JSX ========== */
    /* ========================= */

    return (
        <>
            <Helmet>
                <title>
                    {
                        input?.name ? `Заявка на новое спортивное оборудование | ${input?.name}` : "Заявка на новое спортивное оборудование"
                    }
                </title>
            </Helmet>
            <DialogContent
                dividers
                sx={{
                    bgcolor: "background.default",
                    py: 2,
                    px: 3,
                }}
            >

                <Stack spacing={3}>

                    {/*/* ========================================================= */}
                    {/*/* =============== Расположение оборудования =============== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Расположение оборудования
                            </Typography>
                            <SportEquipmentLocation
                                vertical={false}

                                errors={errors}
                                onChangeLocation={(sportObject, sportZone, sportSubZone) => {
                                    setInput({
                                        ...input,
                                        sportObjectId: sportObject?.id as string,
                                        sportZoneId: sportZone?.id as string,
                                        sportSubZoneId: sportSubZone?.id as string
                                    });
                                }}
                                disableTitle={true}
                                sportObjects={user.sportObjects.length > 0 ? user.sportObjects : sportObjectList}
                                touched={{sportObjectId: true, sportZoneId: true, sportSubZoneId: true}}
                                required={true}
                            />
                        </Stack>
                    </Card>

                    {/*/* ========================================================= */}
                    {/*/* ==================== Общая информация =================== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Общая информация
                            </Typography>

                            <Stack direction='row' gap={2}>
                                <TextField
                                    /* ======================================================== */
                                    /* ========== Название необходимого оборудования ========== */
                                    /* ======================================================== */
                                    required
                                    value={input.name}
                                    onChange={(event) => setInput({...input, name: event.target.value})}
                                    id='equipmentName'
                                    label='Название необходимого оборудования'
                                    sx={{flexGrow: 1}}
                                    placeholder='Укажите название'
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    variant={"filled"}
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: {
                                            fontWeight: 600,
                                        }
                                    }}
                                    InputLabelProps={{shrink: true}}
                                />

                                <TextField
                                    /* ================================ */
                                    /* ========== Количество ========== */
                                    /* ================================ */
                                    required
                                    sx={{width: "18%", ".MuiInputBase-input": {textAlign: "center"}}}
                                    id='equipmentCount'
                                    value={input.count}
                                    onChange={onChangeCount}
                                    type={"number"}
                                    variant='filled'
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                onClick={onClickCountDown}
                                                edge='start'
                                                size='small'
                                            > <RemoveIcon fontSize='small'/></IconButton>
                                        </InputAdornment>,
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                onClick={onClickCountUp}
                                                edge='start'
                                                size='small'
                                            > <AddIcon fontSize='small'/> </IconButton>
                                        </InputAdornment>,
                                        sx: {
                                            gap: 2,
                                            '& .MuiFilledInput-input': {
                                                py: 3,
                                            }
                                        }
                                    } as Partial<OutlinedInputProps>}
                                    InputLabelProps={{
                                        shrink: false,
                                        style: {display: "none"}
                                    }}
                                />

                                <Autocomplete
                                    /* ================================ */
                                    /* ====== Единицы измерения ====== */
                                    /* ================================ */
                                    sx={{width: "17%"}}
                                    value={unitOfMeasurement}
                                    id='unitOfMeasurement'
                                    options={unitOfMeasurements}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: any) => {
                                        onChangeUnitOfMeasurement(value)
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label='Единицы измерения'
                                        variant={"filled"}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />}
                                />

                            </Stack>

                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <Autocomplete
                                    /* ================================ */
                                    /* ========== Вид спорта ========== */
                                    /* ================================ */
                                    value={sportKind}
                                    disabled={allSportKinds.length === 0}
                                    id='sportKind'
                                    fullWidth
                                    options={allSportKinds}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: any) => onChangeSportKindHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label='Вид спорта'
                                        variant={"filled"}
                                        required={true}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder='Выберите вид спорта'
                                        size={"small"}
                                        error={!!errors.sportKind}
                                        helperText={errors.sportKind}
                                    />}
                                />
                                <Autocomplete
                                    /* ====================================== */
                                    /* ========== Тип оборудования ========== */
                                    /* ====================================== */
                                    value={equipmentType}
                                    disabled={sportKind?.equipmentTypes?.length === 0}
                                    id='equipmentType'
                                    fullWidth
                                    options={equipmentTypes.length > 0 ? equipmentTypes : allEquipmentTypes}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}
                                    onChange={(e, value: EquipmentTypeDictionaryEntity | null) => {
                                        onChangeEquipmentTypeHandler(value);
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label='Тип оборудования'
                                        variant={"filled"}
                                        required={true}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder='Выберите тип оборудования'
                                        size={"small"}
                                        error={!!errors.equipmentType}
                                        helperText={errors.equipmentType}
                                    />}
                                />
                            </Stack>
                        </Stack>
                    </Card>

                    {/*/* ========================================================= */}
                    {/*/* =====================  Детали заявки ==================== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Детали заявки
                            </Typography>

                            <Stack spacing={2}>
                                <FormControl
                                    /* ================================================= */
                                    /* ========== Срочная потребность ========== */
                                    /* ================================================= */
                                    size={"small"}
                                    variant={"filled"}
                                    component="fieldset"
                                    sx={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FormLabel component="legend" sx={{
                                        fontSize: "1rem",
                                        transform: " scale(0.75)",
                                        transformOrigin: "top left"
                                    }}>
                                        Срочная потребность
                                    </FormLabel>
                                    <FormControlLabel
                                        id={"isCriticallyNeed"}
                                        label={isCriticallyNeed ? "Да" : "Нет"}
                                        sx={{
                                            ml: 0
                                        }}

                                        control={
                                            <Switch
                                                name="isCriticallyNeed"
                                                color="primary"
                                                size={"small"}
                                                onChange={({target: {checked}}) => {
                                                    setIsCriticallyNeed(checked)
                                                    !checked && setInput({
                                                        ...input,
                                                        urgentRequirementComment: ""
                                                    });
                                                }}
                                                checked={isCriticallyNeed}
                                            />
                                        }
                                    />
                                </FormControl>

                                <Collapse in={isCriticallyNeed} sx={{width: "100%"}}>
                                    <TextField
                                        autoComplete="off"
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        inputRef={criticallyNeedRef}
                                        fullWidth
                                        required
                                        label={
                                            <TextFieldLabel
                                                labelText={"Причина срочности"}
                                                required
                                            />
                                        }
                                        multiline={true}
                                        minRows={3}
                                        maxRows={3}
                                        InputLabelProps={{
                                            shrink: true
                                        }}

                                        placeholder={"Почему оборудование необходимо срочно?"}
                                        size={"small"}

                                        name={"urgentRequirementComment"}
                                        value={input?.urgentRequirementComment}
                                        onChange={(e) => setInput({...input, urgentRequirementComment: e.target.value})}
                                        error={!!errors.urgentRequirementComment}
                                        helperText={errors.urgentRequirementComment}
                                    />
                                </Collapse>
                            </Stack>

                            <Stack
                                direction='column'
                                gap={2}
                            >
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        /* ================================================= */
                                        /* ========== Обязательные характеристики ========== */
                                        /* ================================================= */
                                        value={input.mandatorySpecifications}
                                        onChange={e => setInput({...input, mandatorySpecifications: e.target.value})}
                                        fullWidth
                                        label='Обязательные характеристики'
                                        placeholder='Обязательные характеристики'
                                        size='small'
                                        variant={"filled"}
                                        InputProps={{disableUnderline: true}}
                                        InputLabelProps={{shrink: true}}
                                    />
                                    <TextField
                                        /* ========================== */
                                        /* ========== Цена ========== */
                                        /* ========================== */
                                        sx={{width: "25%"}}
                                        label='Цена за единицу'
                                        value={input.price}
                                        onChange={onChangePrice}
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: <InputAdornment position="end">₽</InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        type='number'
                                        placeholder='Цена за единицу'
                                        size='small'
                                    />
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        /* ============================================ */
                                        /* ========== Ссылка на оборудование ========== */
                                        /* ============================================ */
                                        value={input.equipmentLink}
                                        onChange={e => setInput({...input, equipmentLink: e.target.value})}
                                        fullWidth
                                        size={"small"}
                                        label='Ссылка на оборудование'
                                        placeholder='Ссылка на оборудование'
                                        error={!!errors.equipmentLink}
                                        helperText={errors.equipmentLink}
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        /* =================================================== */
                                        /* ========== Ссылка на аналог оборудования ========== */
                                        /* =================================================== */
                                        value={input.analogEquipmentLink}
                                        onChange={e => setInput({...input, analogEquipmentLink: e.target.value})}
                                        fullWidth
                                        label='Ссылка на аналог оборудования'
                                        placeholder='Ссылка на аналог оборудования'
                                        size='small'
                                        error={!!errors.analogEquipmentLink}
                                        helperText={errors.analogEquipmentLink}
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Stack>
                                <FormControl size={"small"}
                                             fullWidth
                                             variant={"filled"}
                                             component="fieldset"
                                             sx={{px: '6px'}}
                                             error={!!errors.serviceNoteScanId}
                                             required={true}
                                >
                                    <FormLabel component="legend" sx={{
                                        fontSize: "1rem",
                                        transform: "scale(0.75)",
                                        transformOrigin: "top left",
                                        mb: -2
                                    }}>
                                        Прикрепите скан служебной записки с потребностью
                                    </FormLabel>
                                    <OtherDocumentsGrid
                                        documents={serviceNoteScan ? [serviceNoteScan] : null}
                                        setDocuments={(value) => {
                                            const document = value[0] || null
                                            setInput({...input, serviceNoteScanId: document?.id || null})
                                            setServiceNoteScan(document)
                                        }}
                                        multiple={false}
                                        id={'serviceNoteScanId'}
                                        isEditable={true}
                                    />
                                    {errors.serviceNoteScanId && (
                                        <FormHelperText error={true}>
                                            {errors.serviceNoteScanId}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>

                        </Stack>
                    </Card>

                    {/*/* ========================================================= */}
                    {/*/* ===============  Дополнительная информация ============== */}
                    {/*/* ========================================================= */}
                    <Card sx={{py: 2, px: 3}} elevation={2}>
                        <Stack spacing={2}>
                            <Typography variant={"h5"}>
                                Дополнительная информация
                            </Typography>


                            <TextField
                                /* ========================================================= */
                                /* ========== Дополнительная информация по заявке ========== */
                                /* ========================================================= */
                                value={input.description}
                                onChange={(e) => setInput({...input, description: e.target.value})}
                                id='equipmentDescription'
                                label='Дополнительная информация по заявке'
                                placeholder='Укажите дополнительную информацию к заявке'
                                multiline
                                rows={4}
                                fullWidth
                                size={"small"}
                                variant={"filled"}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Stack>
                    </Card>

                </Stack>
            </DialogContent>

            <DialogActions>
                <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    form='addEquipmentRequest'
                    onClick={onSubmit}
                >
                    Подать заявку
                </Button>


            </DialogActions>
        </>
    );
};


