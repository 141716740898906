import React, {useEffect, useState} from 'react';
import {Box, Button, TextField} from "@mui/material";


interface Props {
  filterResult: (result: string) => any,
  value: string,
  onClose?: (value:string) => void
  onSubmit?: string
}

function Search(props: Props) {
  const {filterResult, onClose} = props;

  const [value, setValue] = useState<string>('');

  useEffect(()=>{
    if (value !== props.value && props.value) {
      setValue(props.value)
    }

    return () => {
      setValue('')
    }
  }, [props.value])

  useEffect(()=>{
    if (props?.onSubmit) {
      onSubmit()
    }
  }, [props?.onSubmit])

  const onSubmit = () => {
    value !== props.value && value !== '' && value && filterResult(value)
    onClose && onClose(value);
  };



  return (
    <Box p={1} >

      <TextField
        label="содержит"
        type={'search'}
        variant={'standard'}
        autoFocus={true}
        value={value}
        fullWidth={true}
        placeholder={'Значение'}
        onChange={({currentTarget:{value}}) => setValue(value)}
        autoComplete={'off'}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSubmit()
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onClose && onClose(value);
          }
        }}
      />

      <Button disabled={value==='' || !value} sx={{mt:1, ml:'auto', display: 'block'}} onClick={onSubmit} variant={'text'}>
        Применить
      </Button>

    </Box>
  )
}

export default Search;
