import React from "react";
import {useDialog} from "providers/DialogProvider";
import {EquipmentRequestEntity} from "../../../../../graphql/graphQlApiHooks";
import {AddToCombinedEquipmentRequestDialog} from "../../components/Dialogs/AddToCombinedEquipmentRequestDialog";

export const useAddCombinedEquipmentRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const onAddToCombinedEquipmentRequestAction = ({equipments, onSuccess, open}: {
        equipments: EquipmentRequestEntity[],
        onSuccess: () => void,
        open: boolean
    }) => {
        console.log('AddToCombinedEquipmentRequestDialog open', open)
        dialog({
            onClose: () => {
                closeDialog();
                onSuccess();
            },
            title: "Добавление заявок в объединённую",
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            maxWidth: "md",
            fullWidth: true,
            children: <>
                {
                    open &&  <AddToCombinedEquipmentRequestDialog
                        equipments={equipments}
                        onClose={() => {
                            closeDialog();
                            onSuccess();
                        }}
                    />
                }
            </>
        });
    };

    return {
        onAddToCombinedEquipmentRequestAction
    }
};