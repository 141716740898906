import {EquipmentRequestStatusEnum} from "graphql/graphQlApiHooks";

export const getLabelStatusRequest = (status: EquipmentRequestStatusEnum) => {
    switch (status) {
        case EquipmentRequestStatusEnum.Acceptance:
            return 'Приемка';
        case EquipmentRequestStatusEnum.Auction:
            return 'Торги';
        case EquipmentRequestStatusEnum.ContractingServiceAndPlanningAndEconomicDepartmentApproval:
            return 'На согласование в Контрактной службе и в Планово-экономическом отделе';
        case EquipmentRequestStatusEnum.DocumentPackageFormation:
            return 'Формирование пакета документов';
        case EquipmentRequestStatusEnum.LotFormation:
            return 'Формирование лота';
        case EquipmentRequestStatusEnum.Submitted:
            return 'На рассмотрении';
        case EquipmentRequestStatusEnum.Pending:
            return 'На Согласовании';
        case EquipmentRequestStatusEnum.Success:
            return 'Ожидает отправления';
        case EquipmentRequestStatusEnum.Rejected:
            return 'Заявка отклонена';
        case EquipmentRequestStatusEnum.PassedToAno:
            return 'Передана в АНО';
        case EquipmentRequestStatusEnum.PassedToContractualService:
            return 'Передана в контрактную службу';
        case EquipmentRequestStatusEnum.Supplied:
        case EquipmentRequestStatusEnum.EquipmentAccepted:
            return 'Оборудование принято';
        default:
            return 'Неизвестный статус';
    }
};

