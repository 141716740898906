import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Box, Button, createFilterOptions, Stack, TextField,} from "@mui/material";
import {useStores} from "store";
import {
    AcceptRequestBySportObjectInput,
    EquipmentRelocationRequestEntity,
    SportObjectEntity,
    SportSubZoneEntity,
    SportZoneEntity,
} from "graphql/graphQlApiHooks";
import {Autocomplete} from "@mui/lab";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";

interface RequestForAdmissionProps {
    data?: EquipmentRelocationRequestEntity | null
    open: boolean
    onClose: () => void
}


const RequestForAdmission = observer((props: RequestForAdmissionProps) => {
    const {data, onClose} = props;
    const open = !!data && props.open;
    const {sportObjectsStore, equipmentRelocationRequestStore} = useStores();
    const [sportObject, setSportObject] = useState<SportObjectEntity | null>(null);
    const [sportZone, setSportZone] = useState<SportZoneEntity | null>(null);
    const [sportSubZone, setSportSubZone] = useState<SportSubZoneEntity | null>(null);
    const [comment, setComment] = useState<string>('');
    const [errorsHandler, setErrorsHandler] = useState<boolean>(false);

    const onChangeSportObject = (value: SportObjectEntity) => {
        setSportObject(value)
        setErrorsHandler(false)

        if (!value) {
            setSportZone(null)
            setSportSubZone(null)
        }

        if (value?.sportZones.length === 1) {
            const sportZone = value?.sportZones?.[0] as SportZoneEntity
            setSportZone(sportZone)
            if (sportZone?.sportSubZones.length === 1) {
                setSportSubZone(sportZone?.sportSubZones?.[0] as SportSubZoneEntity)
            } else {
                setSportSubZone(null)
            }
        } else {
            setSportZone(null)
        }

    };

    const onChangeComment = (value: string) => {
        setComment(value)
    };

    const handleClose = () => {
        onClose()
    };

    useEffect(() => {
        return () => {
            setSportObject(null)
            setSportZone(null)
            setSportSubZone(null)
            setComment('')
        }
    }, [open]);

    const onConfirm = () => {
        if (!sportObject) {
            setErrorsHandler(true)
            return;
        }

        const input: AcceptRequestBySportObjectInput = {
            equipmentRelocationRequestId: data?.id as string,
            targetSportObjectId: sportObject?.id,
            comments: (comment !== '') ? comment : undefined,
            targetSportZoneId: sportZone?.id,
            targetSportSubZoneId: sportSubZone?.id

        }

        equipmentRelocationRequestStore.acceptRequestBySportObject(input).then(() => {
            handleClose()
        }).catch((error: any) => {
            console.error(error.message)
        })
    }

    const filterOptionsTargetSportObjectSelect = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: SportObjectEntity) => option.name,
        ignoreCase: true
    });

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            title={'Выберите спортобъект назначения'}
            titles={[
                <EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt}/>,
                <StepperRelocation targetSportObject={sportObject}
                                   sourceSportObject={data?.sourceSportObject}/>
            ]}
            actions={<>
                <Button
                    variant={'contained'}
                    onClick={onConfirm}
                    color={'primary'}
                >Отправить запрос на приём оборудования</Button>
            </>}

        >
            <Box sx={{mt: 1}}>
                <Autocomplete
                    id="targetSportObject"
                    onChange={(event, value) => {
                        onChangeSportObject(value as SportObjectEntity)
                    }}
                    openOnFocus
                    value={sportObject}
                    selectOnFocus
                    size={"small"}
                    options={[...sportObjectsStore.sportObjects].filter((s: SportObjectEntity) => s.id !== data?.sourceSportObject.id)}
                    getOptionLabel={(option) => option.name}
                    filterOptions={filterOptionsTargetSportObjectSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Спортобъект*"
                            error={errorsHandler}
                            autoFocus
                            fullWidth
                            helperText={(errorsHandler) ? "Выберите спортобъект назанчения." : undefined}
                        />
                    )}

                />
            </Box>

            {/*
                выбор спортзоны
            */}
            <Stack direction={'row'} spacing={2} sx={{mt: 3, width: '100%'}}>
                <Box sx={{width: '100%'}}>
                    <Autocomplete
                        readOnly={sportObject?.sportZones.length === 1}
                        options={sportObject?.sportZones || []}
                        onChange={(event, value) => {
                            setSportZone(value as SportZoneEntity)
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Спортзона"
                                size={"small"}
                                fullWidth
                                disabled={!sportObject}
                            />
                        )}
                        value={sportZone}
                    />
                </Box>
                {/*
                    выбор Спортпощадки
                */}
                <Box sx={{width: '100%'}}>
                    <Autocomplete
                        options={sportZone?.sportSubZones || []}
                        readOnly={sportZone?.sportSubZones.length === 1}
                        onChange={(event, value) => {
                            setSportSubZone(value as SportSubZoneEntity)
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Спортпощадка"
                                size={"small"}
                                fullWidth
                                disabled={!sportSubZone}
                            />
                        )}
                        value={sportSubZone}
                    />
                </Box>


            </Stack>


            <Box sx={{mt: 2}}>
                <TextField
                    margin="dense"
                    value={comment}
                    onChange={({target: {value}}) => onChangeComment(value)}
                    type="text"
                    fullWidth
                    multiline={true}
                    rows={5}
                    placeholder={'Укажите комментарий'}
                    size={'small'}
                />
            </Box>

        </Dialog>
    );
})

export default RequestForAdmission;
