import {alpha, styled} from "@mui/material/styles";
import {Chip as MuiChip, chipClasses} from "@mui/material";
import {EquipmentRequestStatusEnum} from "graphql/graphQlApiHooks";
import {getStatusColor} from "../../lib/getStatusColor";

export const StatusChip = styled(MuiChip, {
    shouldForwardProp: (prop) => (prop !== "status" && prop !== "customColor"),
})<{
    status?: EquipmentRequestStatusEnum;
    customColor?: string;
}>(({theme, status, customColor,}) => {
    const statusColor = getStatusColor(status, theme)
    if (customColor) {
        return {
            [`&.${chipClasses.root}`]: {
                backgroundColor: alpha(customColor, 0.1),
                height: "auto",
                padding: theme.spacing(.7, 1.5),
                borderRadius: theme.shape.borderRadius,
                display: "inline-flex",
                alignItems: "center",
            },
            [`& .${chipClasses.label}`]: {
                color: customColor,
                fontSize: "12px",
                lineHeight: "12px",
                padding: 0,

                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                overflow: 'hidden',
            },
            [`& .${chipClasses.icon}`]: {
                color: customColor,
                marginRight: theme.spacing(1),
            }
        }
    }
    return {
        [`&.${chipClasses.root}`]: {
            backgroundColor: statusColor.backgroundColor,
            height: "auto",
            padding: theme.spacing(.7, 1.5),
            borderRadius: theme.shape.borderRadius,
            display: "inline-flex",
            alignItems: "center",
        },
        [`& .${chipClasses.label}`]: {
            color: statusColor.color,
            fontSize: "12px",
            lineHeight: "12px",
            padding: 0,

            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
        },
        [`& .${chipClasses.icon}`]: {
            color: statusColor.color,
            marginRight: theme.spacing(1),
        }
    };
});
