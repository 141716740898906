import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "components/Dialogs/Dialog";
import { Theme, useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import {
  ExternalOrderEntity,
  ExternalOrderRecordEntity,
  ReturnEquipmentRecordInput,
  SportEquipmentEntity,
  useAcceptExternalOrderMutation, useExportExternalOrderHistoryLazyQuery,
  useExportExternalOrderLazyQuery,
  useGetOrderByIdQuery,
  useReturnEquipmentsMutation
} from "graphql/graphQlApiHooks";
import Settings from "store/settings";
import { useStores } from "store";
import { DepartmentEnum } from "store/department.enum";
import { TechnicalConditionEnum } from "views/Pages/ReturnedEquipments/technical-condition.enum";
import { ExternalOrderStatusEnum } from "views/Pages/ReturnedEquipments/external-order-status.enum";
import Iconify from "../../../../../components/Iconify";
import CartItem from "../RentDialog/CartItem";

const useStylesUpload = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "60%",
    backdropFilter: "blur(10px)",
    "& .MuiDialogContent-root": {
      padding: 0
    }
  },
  uploadArea: {
    marginTop: "1.25rem",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    backgroundColor: "transparent",
    padding: "1rem 1rem",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "all .3s",
    justifyContent: "space-between",
    fontSize: 13.3333,
    fontWeight: 400,
    "&:hover, &:focus": {
      cursor: "pointer",
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232e44ff' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      "& .uploadIcon": {
        fill: theme.palette.primary.main
      }
    },
    "& .upload-area-icon": {
      display: "block",
      width: "2.25rem",
      height: "2.25rem",
      marginRight: ".5rem",
      "& svg": {
        maxHeight: "100%",
        maxWidth: "100%"
      }
    },
    "& .upload-area-file": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    "& .uploadIcon": {
      transition: "all .3s",
      fill: theme.palette.text.secondary
    },
    "& .upload-area-titles": {
      marginLeft: "1rem"
      // opacity: .8
    },
    "& .upload-area-title": {
      display: "block",
      fontWeight: "700",
      color: theme.palette.text.primary,
      textAlign: "left"
    },
    "& .upload-area-description": {
      display: "block",
      color: theme.palette.text.secondary,
      "& strong": {
        fontWeight: "700",
        color: theme.palette.primary.main
      }
    }

  },
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(4)
  },
  sidebar: {
    width: 280,
    flexGrow: 0,
    flexBasis: 280,
    flexShrink: 0,
    position: "relative",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
  },
  sidebarContainer: {
    padding: "1rem 1rem",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0
  },
  main: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  documents: {
    position: "absolute",
    height: "calc(100% - 49px)",
    width: "100%",
    overflowY: "auto",
    padding: "1rem 2rem",
    "& .document:not(:first-child)": {
      marginTop: "0 !important"
    }
  }

}));


interface IDialog {
  close: (close: boolean) => void;
  open: boolean;
  row: ExternalOrderEntity | null;
}
const RentDialog = ({ open, close, ...props }: IDialog) => {
  const classes = useStyles();
  const classesUpload = useStylesUpload();
  const theme = useTheme();
  const { userStore } = useStores();

  const isExternalUser = userStore.user?.departments.some((item: DepartmentEnum) => item === DepartmentEnum.PlanningAndOrganizationPaidServices || item === DepartmentEnum.Admins || item === DepartmentEnum.SportObject);

  const [orderRecords, setOrderRecords] = useState<ReturnEquipmentRecordInput[]>([]);

  const { data } = useGetOrderByIdQuery({
    variables: {
      orderId: Number(props.row?.id)
    }
  });

  useEffect(() => {
    if (data?.getOrderById?.records && orderRecords.length === 0) {
      setOrderRecords([...data?.getOrderById.records].map((item: ExternalOrderRecordEntity) => ({
        orderRecordId: item.id,
        technicalCondition: ExternalOrderStatusEnum.InTransportService ? TechnicalConditionEnum.Working : item?.technicalCondition || TechnicalConditionEnum.NotReturned,
        comment: ""
      })));
    }
  }, [data?.getOrderById]);


  const handleCloseModal = () => {
    setOrderRecords([]);
    close(false);
  };


  const items = useMemo(() => {
    if (!data?.getOrderById?.records) return;
    const cartItems = data?.getOrderById?.records.map(item => ({
      ...item.equipment, orderRecordId: item.id
    }));

    const groupBy = (arr: any[], criteria: any) => {
      return arr.reduce((obj, item) => {
        const key = typeof criteria === "function" ? criteria(item) : item[criteria];

        if (!obj.hasOwnProperty(key)) {
          obj[key] = [];
        }

        obj[key].push(item);
        return obj;

      }, {});
    };

    const groupedBySportObject: SportEquipmentEntity[][] = Object.values(
      groupBy(cartItems, (item: SportEquipmentEntity) =>
        item.sportObject?.id
      )
    );

    return groupedBySportObject.map((groupedBySportObject: SportEquipmentEntity[]) => (
      Object.values(
        groupBy(groupedBySportObject, (item: SportEquipmentEntity) =>
          item.sportSubZone?.id
        )
      )
    ));

  }, [data?.getOrderById]);

  const [acceptExternalOrderMutation] = useAcceptExternalOrderMutation();
  const [returnEquipmentsMutation] = useReturnEquipmentsMutation();


  const [exportExternalOrderHistory] = useExportExternalOrderHistoryLazyQuery();

  const handleExportSportEquipmentsInExcel = () => {
    exportExternalOrderHistory({
      variables: {
        externalOrderId: Number(props.row?.id)
      },

      fetchPolicy: "network-only"
    }).then((res) => {
      const exportExternalOrderHistory = res?.data?.exportExternalOrderHistory;
      const url = exportExternalOrderHistory?.url;
      url && window.location.replace(`${Settings.server}${url}`);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      keepMounted
      maxWidth={"md"}
      classes={{ paper: classesUpload.root }}
    >

      <div className={classes.root}>
        <form
          noValidate
          autoComplete={"off"}
          onSubmit={(event: FormEvent<HTMLElement>) => event.preventDefault()}
          id='addDataForm'
          className={classes.root}
        >

          <Stack
            direction={"row"}
            id='scroll-dialog-title'
            classes={{ root: classes.dialogTitle }}
            justifyContent={"space-between"}
            alignItems={"center"}
            py={1}
            px={2}
          >
            <Typography
              variant={"h5"}
              component={"div"}
            > Заявка на аренду оборудования</Typography>


            <Stack
              spacing={2}
              direction={"row"}
            >

              <Box>
                <Button
                  startIcon={<Iconify icon={"mdi:microsoft-excel"} />}
                  onClick={() => {
                    handleExportSportEquipmentsInExcel();
                  }}
                  size={"small"}
                >
                  Скачать Excel файл
                </Button>
              </Box>

              <Box>
                <IconButton
                  onClick={handleCloseModal}
                  size='small'
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          </Stack>


          <DialogContent
            className={classesUpload.dialogContent}
            dividers={false}
          >

            <Box width={"100%"}>

              <div className={classesUpload.main}>
                <Box
                  id='scroll-dialog-description'
                  tabIndex={-1}
                  p={2}
                >
                  <Stack
                    direction={"column"}
                    spacing={2}
                  >
                    {
                      items && items?.map((items, index) =>
                        <CartItem
                          key={index}
                          items={items as SportEquipmentEntity[][]}
                          externalOrderId={data?.getOrderById?.id}
                          rowRecordId={data?.getOrderById?.records[index]?.id}
                          isExternalUser={isExternalUser}
                          orderRecords={orderRecords}
                          onChangeOrderRecords={(orderRecords) => setOrderRecords(orderRecords)}
                          rowStatus={data?.getOrderById.status}
                        />)
                    }


                  </Stack>

                </Box>
              </div>
            </Box>

          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            {!isExternalUser ? (
                data?.getOrderById?.status === ExternalOrderStatusEnum.Created && <Button
                  color={"primary"}
                  className={classes.submitButton}
                  variant='contained'
                  autoFocus
                  onClick={() => {
                    acceptExternalOrderMutation({
                      variables: {
                        input: {
                          externalOrderId: Number(data?.getOrderById.id),
                          sendToTransportService: isExternalUser
                        }
                      }
                    }).then(() => {
                      close(false);
                    });
                  }}
                  size={"small"}
                >
                  Отправить заявку в транспортную службу
                </Button>
              ) :
              data?.getOrderById?.status === ExternalOrderStatusEnum.ReturnedIncomplete || data?.getOrderById?.status === ExternalOrderStatusEnum.InTransportService &&
              <Button
                color={"primary"}
                className={classes.submitButton}
                variant='contained'
                onClick={() => {
                  returnEquipmentsMutation({
                    variables: {
                      input: {
                        orderId: Number(data?.getOrderById.id),
                        orderRecords: orderRecords
                      }
                    }
                  }).then(() => {
                    close(false);
                  });
                }}
                size={"small"}
              >
                Подтвердить приём
              </Button>
            }
          </DialogActions>
        </form>
      </div>
    </Dialog>

  );
};


export default RentDialog;
