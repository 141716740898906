import React from "react";
import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {EquipmentRequestEditDialog} from "../../components/Dialogs/EditRequestDialog";

export const useEditRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const handleEdit = (equipment: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Редактирование заявки на спортивное оборудование",
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            maxWidth: "md",
            fullWidth: true,
            children: <>
                <EquipmentRequestEditDialog
                    row={equipment}
                    close={() => {
                        closeDialog();
                    }}

                    open={true}
                />
            </>
        });
    };


    return {
        handleEdit
    }
};