import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent, Grid,
  IconButton, LinearProgress,
  Stack,
  Typography
} from "@mui/material";
import {
  SportEquipmentEntity,
  SportEquipmentHistoryEntity, useGetSportEquipmentHistoryByHistoryIdLazyQuery, useGetSportEquipmentHistoryLazyQuery,
  useGetSportEquipmentHistoryQuery
} from "graphql/graphQlApiHooks";
import PageDialog from "../../../../../../components/Dialogs/PageDialog";
import { BootstrapTooltip } from "../../../../../../components/BootstrapTooltip";
import Iconify from "../../../../../../components/Iconify";
import { getFio } from "../../../../../../helpers/getFio";
import UserAvatar from "../../../../../../components/UserAvatar";
import dayjs from "dayjs";
import SportEquipmentCard, {
  SportEquipmentCardContent, SportEquipmentCardFooter,
  SportEquipmentCardHeader, SportEquipmentCardMain
} from "../SportEquipment/SportEquipmentCard/imdex";
import PhotoPreview from "../SportEquipment/PhotoPreview";
import ErrorsStepper from "../views/ErrorsStepper";
import { LoadingButton } from "@mui/lab";
import { PageDialogActions } from "../../../../../../components/Dialogs/PageDialogActions";
import StickyBox from "react-sticky-box";
import { Helmet } from "react-helmet";
import SportEquipmentPreloader from "../SportEquipment/Preloader";

interface HistoryDialogProps {
}

export const HistoryDialog: React.FC<HistoryDialogProps> = () => {
  const { sportEquipmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [history, setHistory] = useState<SportEquipmentHistoryEntity[]>([]);
  const [selectedHistory, setSelectedHistory] = useState<SportEquipmentHistoryEntity | null>(null);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number | null>(null);


  const [getSportEquipmentHistoryByHistoryId, {
    data: historyData,
    loading: historyLoading
  }] = useGetSportEquipmentHistoryByHistoryIdLazyQuery();

  const { data, loading } = useGetSportEquipmentHistoryQuery({
    variables: { id: sportEquipmentId },
    skip: !sportEquipmentId,

    onCompleted: (data) => {

      if (data.getSportEquipmentHistoryByEquipmentId.length > 0) {
        setHistory(
          [...data.getSportEquipmentHistoryByEquipmentId].sort((
            a,
            b
          ) => {
            return dayjs(Number(b.createdAt)).unix() - dayjs(Number(a.createdAt)).unix();
          }) as SportEquipmentHistoryEntity[]);

        if (selectedHistoryIndex === null) {
          setSelectedHistoryIndex(0);
        }
      }
    }
  });

  useEffect(() => {
    if (selectedHistoryIndex !== null) {
      getSportEquipmentHistoryByHistoryId({
        variables: {
          id: history[selectedHistoryIndex].id
        },
        onCompleted: (data) => {
          if (data?.getSportEquipmentHistoryByHistoryId)
            setSelectedHistory(data?.getSportEquipmentHistoryByHistoryId as SportEquipmentHistoryEntity);
        }
      });
    }
  }, [selectedHistoryIndex]);


  const handleClose = () => {
    // если есть история переходов то назад, иначе на список
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate("/equipments");
    }
  };
  const ref = useRef(null);
  const [isLocationCollapse, setIsLocationCollapse] = useState(false);

  useEffect(() => {

    const handleScroll = () => {
      setIsLocationCollapse(ref.current.scrollTop > 0);
    };

    ref?.current?.addEventListener("scroll", handleScroll);

    return () => {
      ref?.current?.removeEventListener("scroll", handleScroll);
    };

  }, [ref]);


  return (
    <PageDialog
      title={"История редактирований"}
      onClose={handleClose}
      maxWidth={"lg"}
      additionalButtonComponents={[
        <Box key={2}>
          {!loading &&
            <BootstrapTooltip title={"Редактировать"}>
              <IconButton
                size={"small"}
                onClick={() => {
                  navigate("/equipments/edit/" + sportEquipmentId);
                }}
              >
                <Iconify
                  icon={"material-symbols:edit-outline"}
                  fontSize={"inherit"}
                />
              </IconButton>
            </BootstrapTooltip>
          }
        </Box>
      ]}

    >
      <Helmet>
        <title>
          {
            selectedHistory?.sportEquipmentHistoryJson?.name ? `Просмотр истории редактирования спортивного оборудования | ${selectedHistory?.sportEquipmentHistoryJson?.name}` : "Просмотр истории редактирования спортивного оборудования"
          }
        </title>
      </Helmet>

      <DialogContent
        sx={{
          padding: 0,
          overflow: "hidden",
          height: "calc(100vh - 170px)"
        }}
      >
        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <LinearProgress />
          </Box>
        ) : (
          <>
            {history.length > 0 ? (
              <Stack
                direction='row'
                width={"100%"}
                height={"100%"}
                position={"relative"}
              >
                {
                  historyLoading && (
                    <Box
                      position={"absolute"}
                      top={0}
                      left={0}
                      width={"100%"}
                      zIndex={1}
                    >
                      <LinearProgress />
                    </Box>
                  ) }
                {
                  !selectedHistory ? (
                    <Box
                     sx={{
                       height: "100%",
                       width: "calc(100% - 200px + 0.7rem)",
                       bgcolor: "white"
                     }}
                    />
                  ) : (
                    <Grid
                      container
                      spacing={4}
                      sx={{
                        height: "100%",
                        width: "calc(100% - 200px + 0.7rem)",
                        bgcolor: "white"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        style={{ position: "relative" }}
                      >
                        <PhotoPreview
                          name={selectedHistory?.sportEquipmentHistoryJson?.name ? selectedHistory?.sportEquipmentHistoryJson.name : "Фото оборудования"}
                          photos={selectedHistory?.sportEquipmentHistoryJson?.photos}
                          mainPhoto={selectedHistory?.sportEquipmentHistoryJson?.mainPhoto}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        sx={{ height: "calc(100% + 20px)" }}
                      >
                        <SportEquipmentCardContent ref={ref}>
                          <SportEquipmentCardHeader
                            sportEquipment={selectedHistory?.sportEquipmentHistoryJson}
                            isCollapse={isLocationCollapse}
                          />
                          <SportEquipmentCardMain sportEquipment={selectedHistory?.sportEquipmentHistoryJson} />


                        </SportEquipmentCardContent>

                      </Grid>
                    </Grid>
                  )
                }




                <Stack
                  direction='column'
                  spacing={2}
                  overflow={"auto"}
                  height={"100%"}
                  mx={2}
                  position={"sticky"}
                  top={"0"}
                  pt={"16px"}
                  width={'200px'}
                >
                  {history.map((item, i) => (
                    <Button
                      key={item.id}
                      variant={selectedHistoryIndex === i ? "contained" : "text"}
                      onClick={() => {
                        selectedHistoryIndex !== i && setSelectedHistoryIndex(i);
                      }}
                    >
                      <Stack
                        direction='column'
                        spacing={2}
                      >
                        <Stack
                          direction='row'
                          alignItems='center'
                          spacing={1}
                        >
                          <UserAvatar
                            user={item.user}
                          />
                          <Typography
                            color={selectedHistoryIndex === i ? "white" : "textPrimary"}
                          >
                            {getFio(item.user)}
                          </Typography>
                        </Stack>
                        <Stack
                          direction='row'
                          spacing={1}
                          alignItems='center'
                        >
                          <Iconify
                            icon={"mdi:clock-time-four-outline"}
                          />
                          <Typography
                            variant={"caption"}
                            color={selectedHistoryIndex === i ? "white" : "textPrimary"}
                          >
                            {dayjs(Number(item.createdAt)).format("DD.MM.YYYY HH:mm")}
                          </Typography>
                        </Stack>
                      </Stack>

                    </Button>
                  ))}
                </Stack>
              </Stack>

            ) : (
              <Typography>История редактирований пуста.</Typography>
            )}

          </>
        )}
      </DialogContent>
      <PageDialogActions>

        <Box
          display={"flex"}
          flex={1}
        />

        <Button
          size='small'
          variant='contained'
          onClick={handleClose}
        >
          Закрыть
        </Button>
      </PageDialogActions>
    </PageDialog>
  );
};
