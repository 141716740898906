import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";

import LightGallery from 'lightgallery/react' ;
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import "lightgallery/css/lg-rotate.css";
import "lightgallery/css/lg-fullscreen.css";

import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import FaceIcon from '@mui/icons-material/Face';
// import lgFullscreen from 'lightgallery/plugins/fullscreen';

import ImageListItem from "@mui/material/ImageListItem";
import {Box, Button, Card, CardActionArea, CardMedia, ClickAwayListener, Fab, Popover, Tooltip, Typography,} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {alpha, darken, Theme} from "@mui/material/styles";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import {ReactSVG} from "react-svg";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import {Skeleton} from "@mui/lab";
import {Scalars} from "../graphql/graphQlApiHooks";
import {Image} from "../store/stores/gallery.store";
import { BootstrapTooltip } from "./BootstrapTooltip";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '100%',
		'& .MuiCardActionArea-root': {
			'& canvas': {
				cursor: 'pointer !important',
				width: '100% !important',
				height: '100% !important',
				objectFit: 'cover',
			}
		},
		'& .lg-react-element ': {
			display: 'grid',
			overflowY: 'auto',
			listStyle: 'none',
			padding: 0,
			WebkitOverflowScrolling: 'touch',
			gridTemplateColumns: 'repeat(3, 1fr)',
			gap: 4
		},
		'& .lg-backdrop': {
			position: 'fixed',
		},
		'& .lg-outer': {
			width: '100%',
			height: '100%',
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 1050,
			opacity: 0.001,
			outline: 'none',
			willChange: 'auto',
			overflow: 'hidden',
			transition: 'opacity 0.15s ease 0s',
			'&.lg-visible': {
				opacity: 1
			},
			'& .lg-toolbar': {
				'& .lg-icon': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					'&:hover svg': {
						fill: '#fff',
					}
				},
				'& svg': {
					willChange: 'fill',
					transition: 'fill 0.2s linear',
					fill: '#999',
					width: 22,
					height: 22
				},
			},

		}
	},
	addPhotoButton: {
		position: 'fixed',
		fontSize: '1.5em'
	},
	emptyGallery: {
		justifyContent: 'center',
		display: 'flex',
		'& svg': {
			fill: darken('#e8ecf1', .05),
			height: '150px',
			maxWidth: '100%',
		}
	},
	emptyGalleryWrapper: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(4),
		margin: '0 auto',
		background: alpha('#e8ecf1', 0.4),
		borderRadius: theme.shape.borderRadius,
		display: 'flex',
		alignSelf: 'center',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		justifyItems: 'center',
		flexDirection: 'column',
		cursor: 'pointer',
		'& p': {
			color: '#dce0e4'
		}
	},
}));


export const MouseOverPopover = (props: {readonly?: boolean, children: ReactNode, image: Image, setMainPhotoHandler: (imageId: string) => void, }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const buttonRef = useRef<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null)
	};

	const handlePopoverMouseLeave = () => {
		const mouseEvent: MouseEvent = window.event as MouseEvent;
		if (anchorEl) {
			const mouseLeave = mouseEvent.pageX - anchorEl.getBoundingClientRect().left < 0
					|| mouseEvent.pageX - anchorEl.getBoundingClientRect().right > 0
					|| mouseEvent.pageY - anchorEl.getBoundingClientRect().top < 0
					|| mouseEvent.pageY - anchorEl.getBoundingClientRect().bottom > 0;

			mouseLeave && handlePopoverClose()
		}

	};

	const open = Boolean(anchorEl);


	const handleClickAway = () => {
		handlePopoverClose()
	};
	return (
			<div>
				<Box
						onMouseEnter={(event)=> !props?.readonly && handlePopoverOpen(event)}
						onMouseLeave={()=> !props?.readonly && handlePopoverMouseLeave()}
				>
					{props.children}
				</Box>
				{(!props.image?.mainPhoto) && <Popover
                    id="mouse-over-popover"
                    sx={{
						pointerEvents: 'none'
					}}
                    PaperProps={{
						sx: {
							// pointerEvents: 'none',
							background: 'transparent'
						},
					}}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
                    transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
                    disableRestoreFocus
                    elevation={0}
                    transitionDuration={100}
                >
                    <ClickAwayListener onClickAway={handleClickAway}>
	                    <Box className={'mediaActions'} width={anchorEl?.offsetWidth}
	                         height={'60px'}
	                         sx={{
							     opacity: (anchorEl) ? 1 : 0,
							     display: 'flex',
							     justifyContent: 'end',
							     alignItems: 'center',
							     px: 2,
						     }}
	                    >
	                        <Button
	                            variant={'contained'}
	                            sx={{
									px: 1, py: 1,
									pointerEvents: 'all',
								}}
	                            onClick={() => {
									props.setMainPhotoHandler(props.image.id as string)
								}}
	                        >
	                            Сделать основным
	                        </Button>
	                    </Box>
                    </ClickAwayListener>
                </Popover>}
			</div>
	);
};

const Gallery = observer((props: {
	readOnly?: boolean
	sportEquipmentId?: string
}) => {
	const classes = useStyles();
	const lightGallery = useRef<any>(null);
	const [container, setContainer] = useState<HTMLElement | null>(null)
	const editors = React.useRef<any>([]);
	const {galleryStore} = useStores();
	const {images, uploadFile, onChangeImage, onSaveChangedImages, deleteImage, setMainPhoto} = galleryStore;
	const uploadFileInput = React.createRef<HTMLInputElement>();

	function setMainPhotoHandler(photoId: string) {
		const input = {
			mainPhotoId: photoId,
			sportEquipmentId: props?.sportEquipmentId as string
		}

		setMainPhoto(input)

	}

	const getLgComponent = () => {
		if (container !== null) {
			return (
					<LightGallery
							plugins={[lgZoom, lgRotate, lgThumbnail]}
							elementClassNames="custom-wrapper-class"
							download={false}
							onRotateLeft={({rotate}: any) => {
								const lgItem = lightGallery?.current.galleryItems[lightGallery?.current.index]
								onChangeImage({...lgItem, rotate})
							}}
							onRotateRight={({rotate}: any) => {
								const lgItem = lightGallery?.current.galleryItems[lightGallery?.current.index]
								onChangeImage({...lgItem, rotate})
							}}
							onBeforeClose={() => {
								onSaveChangedImages()
							}}
							onAfterClose={() => {
								lightGallery?.current?.destroyModules();
							}}
							flipHorizontal={false}
							flipVertical={false}
							onInit={onInit}
							container={container}
							autoplayVideoOnSlide={true}

					>{getItems()}</LightGallery>
			);
		}
		return null;
	};

	const onInit = useCallback((detail) => {
		if (detail) {
			lightGallery.current = detail.instance;
			let lg = lightGallery.current;
			const addBtn =
					'<label htmlFor="images-upload" aria-label="Add slide" class="lg-icon" id="lg-add">' +
					'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 12c0-3.037-1.232-5.789-3.222-7.778s-4.741-3.222-7.778-3.222-5.789 1.232-7.778 3.222-3.222 4.741-3.222 7.778 1.232 5.789 3.222 7.778 4.741 3.222 7.778 3.222 5.789-1.232 7.778-3.222 3.222-4.741 3.222-7.778zM21 12c0 2.486-1.006 4.734-2.636 6.364s-3.878 2.636-6.364 2.636-4.734-1.006-6.364-2.636-2.636-3.878-2.636-6.364 1.006-4.734 2.636-6.364 3.878-2.636 6.364-2.636 4.734 1.006 6.364 2.636 2.636 3.878 2.636 6.364zM8 13h3v3c0 0.552 0.448 1 1 1s1-0.448 1-1v-3h3c0.552 0 1-0.448 1-1s-0.448-1-1-1h-3v-3c0-0.552-0.448-1-1-1s-1 0.448-1 1v3h-3c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path></svg>' +
					'<input id="images-upload" name="images-upload" style="display: none;" type="file" accept="image/*, .heic" />' +
					'</label>';

			const deleteBtn =
					'<button class="lg-icon" type="button" aria-label="Remove slide" class="lg-icon" id="lg-delete"> <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 12c0-3.037-1.232-5.789-3.222-7.778s-4.741-3.222-7.778-3.222-5.789 1.232-7.778 3.222-3.222 4.741-3.222 7.778 1.232 5.789 3.222 7.778 4.741 3.222 7.778 3.222 5.789-1.232 7.778-3.222 3.222-4.741 3.222-7.778zM21 12c0 2.486-1.006 4.734-2.636 6.364s-3.878 2.636-6.364 2.636-4.734-1.006-6.364-2.636-2.636-3.878-2.636-6.364 1.006-4.734 2.636-6.364 3.878-2.636 6.364-2.636 4.734 1.006 6.364 2.636 2.636 3.878 2.636 6.364zM8 13h8c0.552 0 1-0.448 1-1s-0.448-1-1-1h-8c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path></svg></button>';

			if (props?.readOnly !== true) {
				lg.outer.find('.lg-toolbar').append(addBtn);
				lg.outer.find('.lg-toolbar').append(deleteBtn);

				lg.outer.find('#lg-delete').on('click', () => {
					let galleryItems = JSON.parse(JSON.stringify(lg.galleryItems));
					const currentLg = lightGallery?.current.galleryItems[lightGallery?.current.index]
					galleryItems = galleryItems.filter((item: any, i: number) => i !== lg.index)
					lg.updateSlides(galleryItems, lg.index);
					deleteImage(currentLg)
				});

				lg.outer.find('#images-upload').on('change', handleChangeImage);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.readOnly]);

	const setContainerRef = useCallback((node) => {
		if (node !== null) {
			setContainer(node);
		}
	}, []);

	const getItems = useCallback(() => {
		return (
				images.map((item: any, index: number) => (
						<ImageListItem
								key={item.id}
								className="gallery-item"
								cols={1}
								data-src={item.url}
								data-sub-html={item.subHtml}
								sx={{
									'&:hover .mediaActions': {
										opacity: 1,
									}
								}}
						>
							<MouseOverPopover image={item} setMainPhotoHandler={setMainPhotoHandler} readonly={props?.readOnly}>
								<Card elevation={1} sx={{height: '100%',}}>
									<CardActionArea sx={{
										height: '100%',
									}}>
										{(item?.loading) ?
												<Skeleton variant="rectangular" width={200} height={200}/>
												: <>

													<CardMedia
															component="img"
															height={'100%'}
															image={item.url}
															alt={item.name}
															sx={{
																pointerEvents: 'none'
															}}
													/>
													{(item?.mainPhoto) &&
                                                        <Box className={'mediaActions'} position={'absolute'}
                                                             width={'100%'}
                                                             height={'60px'} top={0} left={0}
                                                             sx={{
															     transition: 'all .3s',
															     display: 'flex',
															     justifyContent: 'end',
															     alignItems: 'center',
															     px: 2,
															     color: 'white'
														     }}
                                                        >
                                                            <BootstrapTooltip title={'Основное фото'}>
                                                                <FaceIcon color={'inherit'}/>
                                                            </BootstrapTooltip>

                                                        </Box>}

												</>}
									</CardActionArea>

								</Card>
							</MouseOverPopover>
						</ImageListItem>
				))
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images]);


	useEffect(() => {
		lightGallery?.current?.refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images]);

	useEffect(() => {
		editors.current = Array.from(new Set(editors.current))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editors.current]);

	const clearInput = () => {
		if (!uploadFileInput || !uploadFileInput.current)
			return;

		uploadFileInput.current.value = '';
	}

	const handleChangeImage = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let imgList = event.target.files;

			Array.from(imgList).forEach((image: any) => {
				uploadFile(image as File).then((res: any) => {
					// console.log(res)
				})
			});
		}
	};

	return (
			<div className={classes.root}>
				<Box
						style={{zIndex: 2}}
						ref={setContainerRef}
				/>
				<div className={classes.root}>
					{(images && images.length > 0) ? getLgComponent() : <>
						<label
								htmlFor="btn-upload-pictures"
								className={classes.emptyGalleryWrapper}
								onClick={clearInput}
						>

							{(!props?.readOnly) ? <ReactSVG
									src="/static/images/emptyGallery.svg"
									className={classes.emptyGallery}
							/> : null}
							<Typography
									variant={'body1'}>{(!props?.readOnly) ? 'Добавьте фото обородувания' : 'Нет фотографий'}</Typography>
						</label>
					</>}

					<Box
							position={'absolute'}
							right={100}
							bottom={100}
					>
						{(images && images.length > 0 && !props?.readOnly) ?
								<Fab
										color={'primary'}
										component={'label'}
										htmlFor="btn-upload-pictures"
										className={classes.addPhotoButton}
										onClick={clearInput}
								>
									<AddAPhotoOutlinedIcon fontSize={'inherit'}/>
								</Fab> : null
						}
					</Box>
				</div>
				<input
						id="btn-upload-pictures"
						name="btn-upload-pictures"
						style={{display: 'none'}}
						ref={uploadFileInput}
						type="file"
						accept="image/*, .heic"
						onChange={handleChangeImage}
						multiple
						disabled={(props?.readOnly === true)}
				/>


			</div>
	);
});

export default Gallery