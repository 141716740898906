import {
	AddSportCategoryDictionaryDocument,
	CreateSportCategoryDictionaryInput,
	CreateSportKindDictionaryInput,
	DeleteSportCategoryDictionaryDocument,
	UpdateSportCategoryDictionaryDocument,
	UpdateSportCategoryDictionaryInput,
} from './../../graphql/graphQlApiHooks';
import { autorun, makeAutoObservable, toJS } from 'mobx';
import RootStore, { client } from '../.';
import { isEqual, queryToMobxObservable } from '../../helpers';
import {
	AddEquipmentSpecificationDictionaryDocument,
	AddEquipmentTypeDictionaryDocument,
	AddSportKindDictionaryDocument,
	CreateEquipmentSpecificationDictionaryInput,
	CreateEquipmentTypeDictionaryInput,
	DeleteEquipmentSpecificationDictionaryDocument,
	DeleteEquipmentTypeDictionaryDocument,
	DeleteSportKindDictionaryDocument,
	EquipmentSpecificationDictionaryEntity,
	EquipmentTypeDictionaryEntity,
	GetAllEquipmentSpecificationDictionaryDocument,
	GetAllEquipmentTypeDictionaryDocument,
	GetAllSportCategoryDictionaryDocument,
	GetAllSportKindDictionaryDocument,
	GetEquipmentSpecificationDictionaryByEquipmentTypeIdDocument,
	SportCategoryDictionaryEntity,
	SportKindDictionaryEntity,
	UpdateEquipmentSpecificationDictionaryDocument,
	UpdateEquipmentSpecificationDictionaryInput,
	UpdateEquipmentTypeDictionaryDocument,
	UpdateEquipmentTypeDictionaryInput,
	UpdateSportKindDictionaryDocument,
	UpdateSportKindDictionaryInput,
} from '../../graphql/graphQlApiHooks';
import Settings from '../settings';

const DictionariesStore = class {
	public rootStore?: RootStore;

	public dictionaries: any[] = [
		{ id: 'sportKind', name: 'Виды спорта', values: [], count: 0 },
		{ id: 'equipmentType', name: 'Типы оборудования', values: [], count: 0 },
	];

	public dictionary?: any = [];
	public sportKindDictionary: SportKindDictionaryEntity[] = [];
	public sportCategoryDictionary: SportCategoryDictionaryEntity[] = [];
	public activeSportKind: SportKindDictionaryEntity | null = null;
	public equipmentTypeDictionary: EquipmentTypeDictionaryEntity[] = [];
	public equipmentSpecificationDictionary: EquipmentSpecificationDictionaryEntity[] =
		[];
	public sportKindDictionaryCount: number = 0;
	public addedSportKindId: string | null = null;
	public expanded: string | false = 'panel1';

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);

		autorun(() => {
			if (this.sportKindDictionaryQuery?.current()) {
				let result: any = this.sportKindDictionaryQuery.current();
				result =
					result.getAllSportKindDictionary as SportKindDictionaryEntity[];

				const equipmentTypes = result.equipmentTypes;
				const equipmentSpecifications = result.equipmentSpecifications;
				const sportCategories = result.sportCategories;

				const sportKinds = result.sportKinds.map(
					(sportKind: SportKindDictionaryEntity) => {
						const sportCategory = sportCategories.find((category: any) => {
							return category.id === sportKind.sportCategoryId;
						});
						const equipmentTypesFiltered = equipmentTypes
							.filter(
								(equipmentType: EquipmentTypeDictionaryEntity) =>
									equipmentType?.sportKindId === sportKind.id
							)
							.filter(
								(equipmentType: EquipmentTypeDictionaryEntity) =>
									equipmentType?.sportKindId === sportKind.id
							)
							.map((equipmentType: EquipmentTypeDictionaryEntity) => {
								const equipmentSpecificationsFiltered =
									equipmentSpecifications.filter(
										(
											equipmentSpecification: EquipmentSpecificationDictionaryEntity
										) =>
											equipmentSpecification?.equipmentTypeId ===
											equipmentType.id
									);
								return {
									...equipmentType,
									equipmentSpecifications: equipmentSpecificationsFiltered,
								};
							});
						return {
							...sportKind,
							equipmentTypes: equipmentTypesFiltered,
							sportCategory: sportCategory || null,
						};
					}
				);

				sportKinds
					.slice()
					.sort(function (
						a: SportKindDictionaryEntity,
						b: SportKindDictionaryEntity
					) {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});

				if (!isEqual(sportKinds, this.sportKindDictionary)) {
					if (this.addedSportKindId) {
						const activeSportKind = sportKinds.find(
							(item: SportKindDictionaryEntity) =>
								item.id === this.addedSportKindId
						);
						if (activeSportKind) {
							this.activeSportKind = activeSportKind;
							this.addedSportKindId = null;
						}
					}

					this.sportKindDictionary = sportKinds;

					if (this.activeSportKind?.id) {
						const activeSportKind = sportKinds.find(
							(item: SportKindDictionaryEntity) =>
								item.id === this.activeSportKind?.id
						);
						if (activeSportKind) {
							this.activeSportKind = sportKinds.find(
								(item: SportKindDictionaryEntity) =>
									item.id === this.activeSportKind?.id
							);
						} else if (sportKinds.length > 0) {
							this.activeSportKind = sportKinds[0];
						}
					} else if (sportKinds.length > 0) {
						this.activeSportKind = sportKinds[0];
					}
				}
			}

			if (this.equipmentTypeDictionaryQuery?.current()) {
				let result: any = this.equipmentTypeDictionaryQuery.current();
				result = result.getAllEquipmentTypeDictionary;

				if (!isEqual(result, this.equipmentTypeDictionary)) {
					this.equipmentTypeDictionary = result;
				}
			}

			if (this.sportCategoryDictionaryQuery?.current()) {
				let result: any = this.sportCategoryDictionaryQuery.current();
				result = result.getAllSportCategoryDictionary;

				if (!isEqual(result, this.sportCategoryDictionary)) {
					this.sportCategoryDictionary = result;
				}
			}

			if (this.equipmentSpecificationDictionaryQuery?.current()) {
				let result: any = this.equipmentSpecificationDictionaryQuery.current();
				result = result.getAllEquipmentSpecificationDictionary;

				if (!isEqual(result, this.equipmentSpecificationDictionary)) {
					this.equipmentSpecificationDictionary = result;
				}
			}
		});
	}

	get sportKindDictionaryQuery() {
		if (this.rootStore?.userStore?.currentUser) {
			return queryToMobxObservable(
				client.watchQuery({
					query: GetAllSportKindDictionaryDocument,
					pollInterval: Settings.pollIntervals.dictionaries,
				})
			);
		}
		return undefined;
	}

	get sportCategoryDictionaryQuery() {
		if (this.rootStore?.userStore?.currentUser) {
			return queryToMobxObservable(
				client.watchQuery({
					query: GetAllSportCategoryDictionaryDocument,
					pollInterval: Settings.pollIntervals.dictionaries,
				})
			);
		}
		return undefined;
	}

	get equipmentTypeDictionaryQuery() {
		if (this.rootStore?.userStore?.currentUser) {
			return queryToMobxObservable(
				client.watchQuery({
					query: GetAllEquipmentTypeDictionaryDocument,
					pollInterval: Settings.pollIntervals.dictionaries,
				})
			);
		}
		return undefined;
	}

	get equipmentSpecificationDictionaryQuery() {
		if (this.rootStore?.userStore?.currentUser) {
			return queryToMobxObservable(
				client.watchQuery({
					query: GetAllEquipmentSpecificationDictionaryDocument,
					pollInterval: Settings.pollIntervals.dictionaries,
				})
			);
		}
		return undefined;
	}

	getDictionary(dictionaryName: 'sportKind' | 'equipmentType') {
		return [...this.dictionaries].find(item => item.id === dictionaryName)
			.values;
	}

	setDictionary(id: string) {
		this.dictionary = [...this.dictionaries].find(item => item.id === id);
	}

	setActiveSportKind(id: string) {
		if (this.activeSportKind?.id !== id) this.setExpanded('panel1');
		this.activeSportKind =
			this.sportKindDictionary.find(item => item.id === id) || null;
	}

	setExpanded(panel: string | false) {
		this.expanded = panel;
	}

	getEquipmentSpecificationByEquipmentTypeId(equipmentTypeIdId: string) {
		return new Promise(function (resolve, reject) {
			client
				.query({
					query: GetEquipmentSpecificationDictionaryByEquipmentTypeIdDocument,
					variables: { equipmentTypeId: equipmentTypeIdId },
				})
				.then((result: any) => {
					resolve(
						toJS(
							result.data.getEquipmentSpecificationDictionaryByEquipmentTypeId
						)
					);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	createEquipmentSpecificationDictionary = (
		input: CreateEquipmentSpecificationDictionaryInput
	) =>
		client
			.mutate({
				mutation: AddEquipmentSpecificationDictionaryDocument,
				variables: { input: input },
				refetchQueries: [{ query: GetAllSportKindDictionaryDocument }],
			})
			.then(res => {
				this.rootStore?.setSnakeMessage(
					'Новая характеристика оборудования успешно создана.'
				);
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	createSportCategoryDictionary = (input: CreateSportCategoryDictionaryInput) =>
		client
			.mutate({
				mutation: AddSportCategoryDictionaryDocument,
				variables: { input: input },
				refetchQueries: [
					{ query: GetAllSportKindDictionaryDocument },
					{ query: GetAllSportCategoryDictionaryDocument },
				],
			})
			.then(res => {
				this.rootStore?.setSnakeMessage(
					'Новая категория спорта успешно создана.'
				);
				return res
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	updateSportCategoryDictionary = (input: UpdateSportCategoryDictionaryInput) =>
		client
			.mutate({
				mutation: UpdateSportCategoryDictionaryDocument,
				variables: { input: input },
				refetchQueries: [
					{ query: GetAllSportKindDictionaryDocument },
					{ query: GetAllSportCategoryDictionaryDocument },
				],
			})
			// .then(res => {
			// 	this.rootStore?.setSnakeMessage('Категория спорта успешно обновлена.');
			// })
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	deleteSportCategoryDictionary = (id: string) =>
		client
			.mutate({
				mutation: DeleteSportCategoryDictionaryDocument,
				variables: { id: id },
				refetchQueries: [
					{ query: GetAllSportKindDictionaryDocument },
					{ query: GetAllSportCategoryDictionaryDocument },
				],
			})
			.then(res => {
				this.rootStore?.setSnakeMessage('Категория спорта успешно удалена.');
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	updateEquipmentSpecificationDictionary = (
		input: UpdateEquipmentSpecificationDictionaryInput
	) =>
		client
			.mutate({
				mutation: UpdateEquipmentSpecificationDictionaryDocument,
				variables: { input: input },
				refetchQueries: [{ query: GetAllSportKindDictionaryDocument }],
			})
			.then(res => {
				this.rootStore?.setSnakeMessage(
					'Характеристика оборудования успешно обновлена.'
				);
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	createSportKindDictionary = (input: CreateSportKindDictionaryInput) =>
		client
			.mutate({
				mutation: AddSportKindDictionaryDocument,
				variables: { input },
				refetchQueries: [
					{ query: GetAllSportKindDictionaryDocument },
					{ query: GetAllSportCategoryDictionaryDocument },
				],
			})
			.then(res => {
				// this.rootStore?.setSnakeMessage('Новый вид спорта успешно создан.');
				this.addedSportKindId = res.data.addSportKindDictionary.id;
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	createEquipmentTypeDictionary = (input: CreateEquipmentTypeDictionaryInput) =>
		client
			.mutate({
				mutation: AddEquipmentTypeDictionaryDocument,
				variables: { input },
				refetchQueries: [{ query: GetAllSportKindDictionaryDocument }],
			})
			.then(() => {
				this.rootStore?.setSnakeMessage(
					'Новый тип оборудования успешно создан.'
				);
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	updateSportKindDictionary = (input: UpdateSportKindDictionaryInput) =>
		client
			.mutate({
				mutation: UpdateSportKindDictionaryDocument,
				variables: { input },
				refetchQueries: [
					{ query: GetAllSportKindDictionaryDocument },
					{ query: GetAllSportCategoryDictionaryDocument },],
			})
			.then(() => {
				this.rootStore?.setSnakeMessage('Вид спорта успешно обновлен.');
			})
			.catch((error: any) => {
				this.rootStore?.setSnakeMessage(error.message, 'error');
			});

	updateEquipmentTypeDictionary = (input: UpdateEquipmentTypeDictionaryInput) =>
		client
			.mutate({
				mutation: UpdateEquipmentTypeDictionaryDocument,
				variables: { input },
				refetchQueries: [{ query: GetAllSportKindDictionaryDocument }],
			})
			.then(() => {
				this.rootStore?.setSnakeMessage('Тип оборудования успешно обновлен.');
			})
			.catch((error: any) => {
				this.rootStore?.setSnakeMessage(error.message, 'error');
			});

	deleteSportKindDictionary = (id: string) =>
		client
			.mutate({
				mutation: DeleteSportKindDictionaryDocument,
				variables: { id },
				refetchQueries: [
					{ query: GetAllSportKindDictionaryDocument },
					{ query: GetAllSportCategoryDictionaryDocument },],
			})
			.then(() => {
				this.rootStore?.setSnakeMessage('Вид спорта успешно удален.');
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	deleteEquipmentTypeDictionary = (id: string) =>
		client
			.mutate({
				mutation: DeleteEquipmentTypeDictionaryDocument,
				variables: { id },
				refetchQueries: [{ query: GetAllSportKindDictionaryDocument }],
			})
			.then(() => {
				this.rootStore?.setSnakeMessage('Тип оборудования успешно удален.');
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);

	deleteEquipmentSpecificationDictionary = (id: string) =>
		client
			.mutate({
				mutation: DeleteEquipmentSpecificationDictionaryDocument,
				variables: { id },
				refetchQueries: [{ query: GetAllSportKindDictionaryDocument }],
			})
			.then(() => {
				this.rootStore?.setSnakeMessage(
					'Характеристика оборудования успешно удалена.'
				);
			})
			.catch((error: any) =>
				this.rootStore?.setSnakeMessage(error.message, 'error')
			);
};

export default DictionariesStore;
