import React, { useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ImageStoreEntity } from "graphql/graphQlApiHooks";

interface Props {
  title: string;
  description?: string;
  photos: ImageStoreEntity[];
  setIsSelectable: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectable: boolean;
  isEditable?: boolean;
}

export const SportEquipmentPhotosHeader = (props: Props) => {
  const {
    title,
    description,
    setIsSelectable,
    isSelectable,
    photos,
    isEditable
  } = props;


  useEffect(() => {
    if (!isEditable) {
      setIsSelectable(false);
    }
  }, [isEditable]);

  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Stack>
        <Typography variant={"h5"}>
          {title}
        </Typography>
        {description && <Typography variant={"caption"}>
          {description}
        </Typography>}
      </Stack>

      {photos?.length > 0 && <Box>
        <Button
          size={"small"}
          color={"inherit"}
          disabled={!isEditable}
          onClick={() => {
            setIsSelectable(prevState => !prevState);
          }}
        >
          {!isSelectable ? "Выделить" : "Отмена"}
        </Button>
      </Box>}
    </Stack>
  );
};
