import React from "react";
import { Root } from "../SportEquipmentPhotos/PhotoGrid";
import { UploadPhotoButton as UploadDocumentsButton } from "../SportEquipmentPhotos/PhotoGrid/UploadPhotoButton";
import { FileStoreEntity } from "graphql/graphQlApiHooks";
import { UploadDocument } from "./UploadDocument";
import { ISportEquipmentDocuments } from "../../dialogs";

interface Props {
  documents: ISportEquipmentDocuments;
  setDocuments: (value: ISportEquipmentDocuments) => void;
  isEditable?: boolean;
}

export const DocumentsGrid = (props: Props) => {
  const {
    documents,
    setDocuments,
    isEditable
  } = props;

  return (
    <Root
      container
      spacing={2}
      className={"documents-grid"}
      marginTop={"0 !important"}
      marginLeft={"-16px !important"}
      columns={15}
    >
      <UploadDocument
        id={"commissioningCertificate"}
        onChange={(value: FileStoreEntity) => setDocuments({
          ...documents,
          commissioningCertificate: [value]
        })}
      >
        {({ id, onClearInput, loading, progress }) => (
          <UploadDocumentsButton
            buttonId={id}
            value={documents?.[id]}
            onClearInput={onClearInput}
            loading={loading}
            progress={progress}
            icon={"icon-park-outline:file-addition-one"}
            primaryText={"Акт ввода в эксплуатацию"}
            disabled={!documents?.[id] && !isEditable}
            isEditable={isEditable}
            onDelete={() => {
              setDocuments({
                ...documents,
                commissioningCertificate: null
              });
            }}
          />
        )}
      </UploadDocument>
      <UploadDocument
        id={"passport"}
        onChange={(value: FileStoreEntity) => setDocuments({
          ...documents,
          passport: [value]
        })}
      >
        {({ id, onClearInput, loading, progress }) => (
          <UploadDocumentsButton
            buttonId={id}
            value={documents?.[id]}
            onClearInput={onClearInput}
            loading={loading}
            progress={progress}
            icon={"icon-park-outline:file-addition-one"}
            primaryText={"Паспорт"}
            disabled={!documents?.[id] && !isEditable}
            onDelete={() => {
              setDocuments({
                ...documents,
                passport: null
              });
            }}
          />
        )}
      </UploadDocument>
      <UploadDocument
        id={"certificate"}
        onChange={(value: FileStoreEntity) => setDocuments({
          ...documents,
          certificate: [value]
        })}
      >
        {({ id, onClearInput, loading, progress }) => (
          <UploadDocumentsButton
            buttonId={id}
            value={documents?.[id]}
            onClearInput={onClearInput}
            disabled={!documents?.[id] && !isEditable}
            loading={loading}
            progress={progress}
            icon={"icon-park-outline:file-addition-one"}
            primaryText={"Сертификат"}
            onDelete={() => {
              setDocuments({
                ...documents,
                certificate: null
              });
            }}
          />
        )}
      </UploadDocument>
      <UploadDocument
        id={"guarantee"}
        onChange={(value: FileStoreEntity) => setDocuments({
          ...documents,
          guarantee: [value]
        })}
      >
        {({ id, onClearInput, loading, progress }) => (
          <UploadDocumentsButton
            buttonId={id}
            value={documents?.[id]}
            onClearInput={onClearInput}
            loading={loading}
            disabled={!documents?.[id] && !isEditable}
            progress={progress}
            icon={"icon-park-outline:file-addition-one"}
            primaryText={"Гарантийный талон"}
            onDelete={() => {
              setDocuments({
                ...documents,
                guarantee: null
              });
            }}
          />
        )}
      </UploadDocument>
      <UploadDocument
        id={"manual"}
        onChange={(value: FileStoreEntity) => setDocuments({
          ...documents,
          manual: [value]
        })}
      >
        {({ id, onClearInput, loading, progress }) => (
          <UploadDocumentsButton
            buttonId={id}
            value={documents?.[id]}
            onClearInput={onClearInput}
            loading={loading}
            disabled={!documents?.[id] && !isEditable}
            progress={progress}
            icon={"icon-park-outline:file-addition-one"}
            primaryText={"Руководство по эксплуатации"}
            onDelete={() => {
              setDocuments({
                ...documents,
                manual: null
              });
            }}
          />
        )}
      </UploadDocument>


    </Root>
  );
};
