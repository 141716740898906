import React, { FC, MouseEvent, ReactNode, useMemo, useState } from "react";
import {
  ExternalOrderEntity,
  ExternalOrderNotExistingRecordEntity,
  ExternalOrderRecordEntity, TechnicalCondition,
  useGetOrdersQuery
} from "../../../../../graphql/graphQlApiHooks";
import {
  AvatarGroup,
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid, GridColumns, GridRowData } from "@mui/x-data-grid";
import clsx from "clsx";
import { useTheme } from "@mui/styles";
import MuiDataGridStyles from "../../../../../components/MuiDataGridStyles";
import { ExternalOrderStatusEnum } from "../../../ReturnedEquipments/external-order-status.enum";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ReportGmailerrorred from "@mui/icons-material/ReportGmailerrorred";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Tooltiper from "../../../../../components/Tooltiper";
import { useStyles } from "../styles";
import { useNavigate } from "react-router-dom";
import EquipmentRowName from "../../../../../components/EquipmentRowName";
import DateViewer from "../../../../../components/DateViewer";
import UserAvatar from "../../../../../components/UserAvatar";
import Iconify from "../../../../../components/Iconify";
import { useSnackbar } from "notistack";
import { BootstrapTooltip } from "../../../../../components/BootstrapTooltip";


interface IStatus {
  name: string;
  value: string;
  className: string;
}

const statuses: IStatus[] = [
  {
    name: "Заявка создана",
    value: "CREATED",
    className: "created"
  },
  {
    name: "Отправлено",
    value: ExternalOrderStatusEnum.InTransportService,
    className: "inTransportService"
  },
  {
    name: "Возвращено не полностью",
    value: ExternalOrderStatusEnum.ReturnedIncomplete,
    className: "returnedIncomplete"
  },
  {
    name: "Возвращено полностью",
    value: ExternalOrderStatusEnum.ReturnedComplete,
    className: "returnedComplete"
  }
];

const getStatusName = (value: any) => {
  return statuses.find((status: IStatus) => status?.value === value)?.name || "";
};
const getStatusValue = (name: any) => {
  return statuses.find((status: IStatus) => status.value === name)?.className || "";
};
export const RenderStatus = (status: any) => {
  const classes = useStyles();
  status = status.status;
  let icon, statusClass;


  switch (statuses.find((s: IStatus) => s?.value === status)?.value) {
    case ExternalOrderStatusEnum.Created:
      icon = <FiberManualRecordIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = statuses.find((s: IStatus) => s?.value === status).className;
      break;
    case ExternalOrderStatusEnum.ReturnedComplete:
      icon = <DoneAllIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = statuses.find((s: IStatus) => s?.value === status).className;
      break;
    case ExternalOrderStatusEnum.ReturnedIncomplete:
      icon = <ReportGmailerrorred
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = statuses.find((s: IStatus) => s?.value === status).className;
      break;
    case ExternalOrderStatusEnum.InTransportService:
      icon = <RestartAltIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = statuses.find((s: IStatus) => s?.value === status).className;
      break;
    default:
      icon = <DoneAllIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = "ended";
  }

  return (
    <React.Fragment>
      <div className={clsx(classes.status, statusClass)}>
        {icon}
        <Tooltiper text={getStatusName(status)} />
      </div>
    </React.Fragment>
  );

};


interface Props {
}

function CopyToClipboard(props: { text: string, onCopy: () => void, children: ReactNode }) {
  const { onCopy, children } = props;

  const handleClick = () => {
    navigator.clipboard.writeText(props.text).then(() => {
      onCopy();
    });
  };


  return (
    <IconButton
      aria-label='copy'
      aria-controls='copy-menu'
      aria-haspopup='true'
      onClick={handleClick}
      title={"Скопировать в буфер обмена"}
    >
      {children}
    </IconButton>
  );

}

export const ContactsViewer = (props: { giverName?: string, giverPhone?: string }) => {
  const theme = useTheme();
  const { giverName = "", giverPhone = "" } = props;
  const { enqueueSnackbar } = useSnackbar();
  return (<BootstrapTooltip
    arrow
    title={
      <>
        <Typography
          color='inherit'
          variant={"caption"}
        >
          Контакты для связи
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            color='inherit'
            variant={"body1"}
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Iconify
              icon={"mdi:account"}
              color='inherit'
              sx={{
                mr: 1
              }}
            />
            <span>
                    {giverName}
                    </span>
          </Typography>
          <CopyToClipboard
            text={giverName}
            onCopy={() => enqueueSnackbar(`Имя ${giverName} скопировано в буфер обмена`)}
          >
            <IconButton
              size={"small"}
              sx={{
                padding: 0,
                color: "white"
              }}
            >
              <Iconify
                icon={"mdi:content-copy"}
                fontSize={"small"}
                color='inherit'
              />
            </IconButton>
          </CopyToClipboard>

        </Stack>

        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            component={Link}
            href={`tel:${giverPhone}`}
            underline={"none"}
            variant={"body1"}
            sx={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary.lighter
            }}
            title={"Позвонить"}
          >
            <Iconify
              icon={"mdi:phone"}
              color='inherit'
              sx={{
                mr: 1
              }}
            />
            <span>
                    {giverPhone}
                    </span>
          </Typography>
          <CopyToClipboard
            text={giverPhone}
            onCopy={() => enqueueSnackbar(`Номер ${giverPhone} скопирован в буфер обмена`)}
          >
            <IconButton
              size={"small"}
              sx={{
                padding: 0,
                color: "white"
              }}
            >
              <Iconify
                icon={"mdi:content-copy"}
                fontSize={"small"}
                color='inherit'
              />
            </IconButton>
          </CopyToClipboard>

        </Stack>

      </>
    }
  >
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        overflow: "hidden",
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        textOverflow: "ellipsis"
      }}
    >
      {
        giverName && <Stack
          direction={"row"}
          spacing={1}

          sx={{
            width: "100%",
            alignItems: "center",
            textOverflow: "ellipsis"
          }}
        >
          <Iconify icon={"mdi:account"} />
          <Box
            component={"span"}
            sx={{
              overflow: "hidden",
              width: "100%",
              textOverflow: "ellipsis"
            }}
          >
            {giverName}
          </Box>
        </Stack>}
      {giverPhone && <Stack
        direction={"row"}
        spacing={1}

        sx={{
          overflow: "hidden",
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          textOverflow: "ellipsis"
        }}
      >
        <Iconify icon={"mdi:phone"} />
        <Box
          component={"span"}
          sx={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis"
          }}
        >
          {giverPhone}
        </Box>
      </Stack>}
    </Stack>
  </BootstrapTooltip>);
};

const OrderList: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tableClasses = MuiDataGridStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState<ExternalOrderEntity | null>(null);
  const [rows, setRows] = React.useState<readonly GridRowData[]>([]);

  const { data, loading } = useGetOrdersQuery({
    variables: {
      input: {
        statuses: []
      }
    },
    pollInterval: 120000
  });


  const columns: GridColumns = [
    {
      field: "id", headerName: "№",
      flex: .5,
      renderCell: (params) => {
        return (
          <Tooltiper
            text={
              String(params.row.id)
            }
            props={{
              sx: {
                fontSize: "0.75rem !important",
                fontWeight: 600,
                color: theme.palette.text.secondary
              }
            }}
          />
        );
      },
      minWidth: 110,
      disableColumnMenu: true
    },
    {
      field: "records", headerName: "Оборудование",
      flex: 1,
      renderCell: (
        { row: { records, notExistingEquipmentRecords } }: { row: ExternalOrderEntity }
      ) => {
        return (
          <AvatarGroup
            max={4}
            sx={{
              "& .MuiAvatar-root": {
                width: 24,
                height: 24
              }
            }}
          >
            {
              records?.map(({ equipment }: ExternalOrderRecordEntity) => {
                const {
                  id,
                  name,
                  inventoryNumber,
                  mainPhoto,
                  photos,
                  inUse,
                  technicalCondition,
                  failureReason,
                  isEditable
                } = equipment;
                return (
                  <EquipmentRowName
                    key={id}
                    avatarOnly={true}
                    name={name}
                    inventoryNumber={inventoryNumber || "б/н"}
                    inUse={inUse}
                    technicalCondition={(technicalCondition === TechnicalCondition.Working)}
                    avatarUrl={(mainPhoto) ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].avatarUrl}
                    avatarUrlOrigin={(mainPhoto) ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].url}
                    failureReason={failureReason}
                    isEditable={isEditable}
                  />
                );
              })
            }
            {
              notExistingEquipmentRecords?.map(( notExistingEquipmentRecord: ExternalOrderNotExistingRecordEntity  ) => {
                const {
                  id,
                  name,
                  images,
                } = notExistingEquipmentRecord;
                return (
                  <EquipmentRowName
                    key={id}
                    avatarOnly={true}
                    name={name}
                    avatarUrl={(images && images?.length > 0) && images?.[0].avatarUrl}
                    avatarUrlOrigin={(images && images?.length > 0) && images?.[0].url}
                    inventoryNumber={''}
                    inUse={true}
                    technicalCondition={true}
                  />
                );
              })
            }
          </AvatarGroup>
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "externalOrderOwner", headerName: "Заявитель",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            spacing={1}
          >
            <UserAvatar
              user={params.row.externalOrderOwner}
              showTooltip={true}
            />
            <Tooltiper
              text={
                params.row.externalOrderOwner?.fio ||
                params.row.externalOrderOwner?.login
              }
            />
          </Stack>
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "receivingName", headerName: "Контакты для связи",
      flex: 1,
      renderCell: (params) => {
        return (
          <ContactsViewer
            giverName={params.row.receivingName}
            giverPhone={params.row.receivingPhone }
          />
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "targetPointAddress", headerName: "Адрес",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          <Tooltiper text={params.row.targetPointAddress} />
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "startEventDate", headerName: "Дата начала мероприятия",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.startEventDate ?
            <DateViewer
              date={params.row.startEventDate}
              showTime
            />
            :
            null
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "endEventDate", headerName: "Дата окончания мероприятия",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.endEventDate ?
            <DateViewer
              date={params.row.endEventDate}
              showTime
            />
            :
            null
        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "returnDate", headerName: "Дата возврата",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          params.row.returnDate ?
            <DateViewer date={params.row.returnDate} />
            :
            null

        );
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "status", headerName: "Статус",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
            width={"100%"}
          >
            {
              <RenderStatus status={params.row.status} />
            }
          </Box>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    }
  ];

  useMemo(() => {
    data?.getOrders && setRows(data?.getOrders as unknown as never[]);
  }, [data?.getOrders]);

  const openDialogHandler = (row: ExternalOrderEntity) => {
    setCurrentRow(row);
    setOpenDialog(true);
  };


  const [contextRow, setContextRow] = useState<ExternalOrderEntity | null>(null);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const contextMenuHandler = (event: MouseEvent) => {
    event.preventDefault();
    const row = event.currentTarget;
    const sportEquipmentId = row.getAttribute("data-id");
    setContextRow(
      (rows as ExternalOrderEntity[]).find((item) =>
        item.id === sportEquipmentId
      )
    );
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6
        }
        :
        null
    );
  };

  const contextMenuCloseHandler = () => {
    setContextMenu(null);
  };

  return (
    <>
      <Box
        sx={{ height: "100%", width: "100%" }}
        className={classes.root}
      >
        <DataGrid

          rows={rows}
          columns={columns.map(column => ({
            ...column,
            cellClassName: ({ row }) => clsx(classes.cell, getStatusValue(row.status))
          }))}
          getRowClassName={({ id }) => (id === contextRow?.id && contextMenu !== null) ? "isOpenContextMenu" : ""}
          rowHeight={50}
          loading={loading}

          componentsProps={{
            row: {
              onContextMenu: contextMenuHandler,
              style: { cursor: "context-menu" }
            }
          }}
          // onRowDoubleClick={(params: GridRowData) => doubleClickHandler(params.row.id)}

          // sortingMode='server'
          // onSortModelChange={sortModelChangeHandler}

          // checkboxSelection
          disableSelectionOnClick={true}
          // selectionModel={selected}
          // onSelectionModelChange={(newSelection: any) => {
          //   setSelected(newSelection);
          // }}


          columnBuffer={columns.length}
          columnThreshold={columns.length}
          // rowThreshold={pagination?.pageSize / 2}
          // rowBuffer={pagination?.pageSize / 2}


          pagination
          // paginationMode='server'
          // rowCount={Math.round(allCount)}
          // page={pagination?.page}
          // pageSize={pagination?.pageSize}
          // onPageChange={(page) => setPagination({ ...pagination, page: page })}
          // onPageSizeChange={(pageSize) =>
          //   setPagination({ ...pagination, pageSize: pageSize })
          //}
          rowsPerPageOptions={[5, 10, 50, 100]}


          onCellDoubleClick={({ row }) =>
            navigate(`order/${row.id}`)
          }
          initialState={{
            sorting: {
              sortModel: [{ field: "status", sort: "asc" }]
            }
          }}
        />
      </Box>


      <Menu
        open={contextMenu !== null}
        onClose={contextMenuCloseHandler}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        elevation={20}
        sx={{
          maxWidth: 300,
          "& .MuiListItemText-root .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100%",
            display: "flow-root"
          }
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`order/${contextRow?.id}`);
            contextMenuCloseHandler();
          }}
        >
          <ListItemIcon>
            <Iconify icon={"mdi:eye"} />
          </ListItemIcon>
          <ListItemText primary='Просмотр' />
        </MenuItem>
      </Menu>

    </>
  );
};

export default OrderList;