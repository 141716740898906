import React, {FC, useState} from 'react';
import {useGetUsersOfSportObjectQuery, UserOfSportObjectResponse} from "../../graphql/graphQlApiHooks";
import {
    Autocomplete,
    Avatar,
    Chip,
    ClickAwayListener,
    IconButton,
    OutlinedInput,
    InputBase,
    Popper,
    Stack,
    Tooltip,
    Typography, ButtonBase
} from "@mui/material";
import {styled, useTheme} from '@mui/material/styles';


import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {Contacts, Email} from "@mui/icons-material";
import {autocompleteClasses, AutocompleteCloseReason} from "@mui/material/Autocomplete";
import {useSnackbar} from "notistack";
//@ts-ignore
const tr = new CyrillicToTranslit()

const StyledAutocompletePopper = styled('div')(({theme}) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${
                theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
            }`,
            cursor: 'default',
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                {
                    backgroundColor: 'transparent',
                },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

interface PopperComponentProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}

function PopperComponent(props: PopperComponentProps) {
    const {disablePortal, anchorEl, open, ...other} = props;
    return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({theme}) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${
        theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
    width: 260,
    borderRadius: 6,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

type UsersOfSportObjectProps = {
    sportObjectId: string

}

const getUserFioByLogin = (login: string) => {
    if (login.includes('test')) return login;

    let surname = tr.reverse(login);
    surname = surname.charAt(0).toUpperCase() + surname.slice(1);

    const firstName = surname.slice(-2).charAt(0).toUpperCase();
    const lastName = surname.slice(-1).toUpperCase();

    return `${surname.slice(0, -2)} ${firstName}.${lastName}.`;
};

export const UsersOfSportObject: FC<UsersOfSportObjectProps> = ({sportObjectId}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {data, loading, error} = useGetUsersOfSportObjectQuery({
        variables: {
            sportObjectId
        },
        skip: !sportObjectId
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;
    const snackbar = useSnackbar()

    const handleCopyEmail = (option: UserOfSportObjectResponse) => {
        if (option.email) {
            navigator.clipboard.writeText(option.email);
            snackbar.enqueueSnackbar(`E-mail ${option.email} скопирован в буфер обмена`)
        }
    };

    return (
        <>
            <Tooltip title={'Сотрудники на объекте'} placement={'top'}>
                <IconButton
                    onClick={handleClick}
                    disabled={loading}
                    size={"small"}
                    aria-describedby={id}
                >
                    <Contacts
                        fontSize={'small'}
                    />
                </IconButton>
            </Tooltip>
            <StyledPopper
                id={id}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                placement={'bottom-end'}
                sx={{
                    p: 2,
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Autocomplete
                            style={{width: '100%'}}
                            open
                            multiple
                            onClose={(
                                event: React.ChangeEvent<{}>,
                                reason: AutocompleteCloseReason,
                            ) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            onChange={(event, newValue, reason,) => {
                                if (
                                    event.type === 'keydown' &&
                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="Нет пользователей"
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Stack direction={'column'} spacing={1}>
                                        <Stack direction={'row'} spacing={2}
                                               alignItems={'center'}
                                        >
                                            <Avatar
                                                sx={{
                                                    bgcolor: 'primary.main',
                                                    color: 'white !important',
                                                    fontSize: '8px !important',
                                                    width: '20px',
                                                    height: '20px',
                                                }}


                                            > {getUserFioByLogin(option?.login).charAt(0)}
                                            </Avatar>
                                            <Typography
                                                variant={'body1'}>{getUserFioByLogin(option?.login)}</Typography>
                                        </Stack>
                                        <ButtonBase
                                            onClick={() => option?.email ? handleCopyEmail(option) : undefined}
                                            disableRipple={!option?.email}
                                            disabled={!option?.email}
                                        >
                                            <Tooltip title={option?.email ? 'Скопировать e-mail' : ''}>
                                                <Stack direction={'row'} spacing={2}
                                                       alignItems={'center'}
                                                >
                                                    <Email
                                                        fontSize={'small'}
                                                        color={option?.email ? 'info' : 'disabled'}
                                                    />
                                                    {option?.email
                                                        ?
                                                        <Typography
                                                            variant={'caption'}
                                                            color={'info'}

                                                        >{option.email}</Typography>
                                                        :
                                                        <Chip size={'small'} label={'E-mail не указан'}/>
                                                    }
                                                </Stack>
                                            </Tooltip>
                                        </ButtonBase>
                                    </Stack>
                                </li>
                            )}
                            options={[...data?.usersOfSportObject || []]
                                    ?.filter((option) => process.env.NODE_ENV === 'production' ? !option.login.toLowerCase().includes('test') : true)
                                    ?.sort((a, b) => getUserFioByLogin(a.login).localeCompare(getUserFioByLogin(b.login)))
                                || []
                            }
                            getOptionLabel={(option) => getUserFioByLogin(option.login)}
                            renderInput={(params) => (
                                <OutlinedInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Поиск сотрудника"
                                    type="search"
                                    size={'small'}
                                    sx={{
                                        mb: 1,
                                        width: '100%',
                                    }}
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>

            </StyledPopper>
        </>
    )
        ;
};