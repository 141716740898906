import { DepartmentEnum } from "graphql/graphQlApiHooks";
import {NoDepartmentEnum, DepartmentEnumWithNoDepartment} from "../types";

export const getCurrentDepartmentName = (name: DepartmentEnumWithNoDepartment): string => {
    switch (name) {
        case DepartmentEnum.Admins:
            return 'Администратор';
        case DepartmentEnum.PlanningAndOrganizationPaidServices:
            return 'Планирование и организация платных услуг';
        case DepartmentEnum.EquipmentAcceptanceAndDiagnostics:
            return 'Приемка и диагностика оборудования';
        case DepartmentEnum.SportObject:
            return 'Сотрудники со спортобъектов';
        case DepartmentEnum.AccountingSportEquipment:
            return 'Учет спортивного оборудования';
        case DepartmentEnum.External:
            return 'Внешние сотрудники';
        case NoDepartmentEnum.NoDepartment:
            return 'Сотрудники без департамента';
        default:
            return '';
    }
}
