import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {SportZoneEntity} from "../../../../../graphql/graphQlApiHooks";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import {useStores} from "../../../../../store";


const useStyles = makeStyles((theme: Theme) => ({
    root: {},

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

type ISportZoneDelete = {
    open: boolean,
    onClose: (value: boolean) => void;
    currentSportZone?: SportZoneEntity | null;
}

function DeleteSportZone(rest: ISportZoneDelete) {
    const classes = useStyles();
    const {open, onClose, currentSportZone} = rest
    const {settingsStore} = useStores();

    function handleClose() {
        onClose(false)
    }

    function handleDelete() {
        if (currentSportZone) {
            settingsStore.sportZoneDelete(currentSportZone.id).then(handleClose)
        }
    }

    return <>
        <Dialog
            onClose={handleClose}
            aria-labelledby="delete-sportZone-dialog-title"
            open={open}
        >
            <DialogTitle id="delete-sportZone-dialog-title">
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                >
                    <DeleteOutlineIcon
                        color={'secondary'}
                        style={{marginRight: '.3em'}}
                    />
                    <span>Удаление объекта спорта.</span>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку
                                         ниже.</Typography>

            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={handleClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    autoFocus
                    onClick={handleDelete}
                    color="secondary"
                >
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

DeleteSportZone.protoTypes = {}

export default DeleteSportZone;
