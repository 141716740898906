import {RequestEntity} from "../../../../types/RequestEntity";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import {
    CombinedEquipmentRequestEntity,
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum
} from "graphql/graphQlApiHooks";
import React from "react";
import {useEditRequestAction} from "../../../../hooks/actions/useEditRequestAction";
import {useEditCombineRequest} from "../../../../hooks/actions/useEditCombineRequest";
import {useDeleteRequestAction} from "../../../../hooks/actions/useDeleteRequestAction";
import {useDeleteCombineRequest} from "../../../../hooks/actions/useDeleteCombineRequest";
import {useViewRequestAction} from "../../../../hooks/actions/useViewRequestAction";
import {useViewCombineRequest} from "../../../../hooks/actions/useViewCombineRequest";
import Iconify from "components/Iconify";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import {
    useDeleteSingleRequestFromCombineRequestAction
} from "../../../../hooks/actions/useDeleteSingleRequestFromCombineRequestAction";

// Компонент рендеринга ячейки для действий
export const ActionsCellRenderer = ({equipmentRequestEntity}: { equipmentRequestEntity: RequestEntity }) => {
    const {handleEdit} = useEditRequestAction();
    const {handleEditCombineRequest} = useEditCombineRequest();
    const {handleDelete} = useDeleteRequestAction();
    const {handleDeleteCombineRequest} = useDeleteCombineRequest();
    const {handleDeleteSingleRequestFromCombineRequest} = useDeleteSingleRequestFromCombineRequestAction();
    const {handleView} = useViewRequestAction();
    const {handleViewCombineRequest} = useViewCombineRequest();

    return (
        <div className={'row-actions'}>
            <Stack direction={'row'} spacing={2}>
                <Tooltip title="Просмотреть">
                    <IconButton
                        onClick={() => isSingleRequest(equipmentRequestEntity) ?
                            handleView(equipmentRequestEntity as EquipmentRequestEntity) :
                            handleViewCombineRequest(equipmentRequestEntity as CombinedEquipmentRequestEntity)}
                        size="small"
                    >
                        <Iconify sx={{fontSize: '1.2rem'}} icon={"mdi:show-outline"}/>
                    </IconButton>
                </Tooltip>

                {(
                    equipmentRequestEntity?.requestStatus !== EquipmentRequestStatusEnum.Supplied
                    && equipmentRequestEntity?.requestStatus !== EquipmentRequestStatusEnum.EquipmentAccepted
                    && equipmentRequestEntity?.requestStatus !== EquipmentRequestStatusEnum.Rejected
                ) && (
                    <Tooltip title="Редактировать">
                        <IconButton
                            onClick={() => isSingleRequest(equipmentRequestEntity) ?
                                handleEdit(equipmentRequestEntity as EquipmentRequestEntity) :
                                handleEditCombineRequest(equipmentRequestEntity as CombinedEquipmentRequestEntity)}
                            size="small"
                        >
                            <Iconify sx={{fontSize: '1.2rem'}} icon={"material-symbols:edit-outline"}/>
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip
                    title={(isSingleRequest(equipmentRequestEntity) && equipmentRequestEntity?.isInCombinedRequest) ? "Убрать из заявки" : "Удалить"}>
                    <IconButton
                        onClick={() => {
                            isCombinedRequest(equipmentRequestEntity) && handleDeleteCombineRequest(equipmentRequestEntity as CombinedEquipmentRequestEntity);
                            (isSingleRequest(equipmentRequestEntity) && !equipmentRequestEntity?.isInCombinedRequest) && handleDelete(equipmentRequestEntity as EquipmentRequestEntity);
                            (isSingleRequest(equipmentRequestEntity) && equipmentRequestEntity?.isInCombinedRequest) && handleDeleteSingleRequestFromCombineRequest(equipmentRequestEntity as EquipmentRequestEntity);

                        }}
                        size="small"
                    >
                        {(isSingleRequest(equipmentRequestEntity) && equipmentRequestEntity?.isInCombinedRequest) ?
                            <Iconify sx={{fontSize: '1.2rem'}} icon={"mage:box-minus"}/> :
                            <Iconify sx={{fontSize: '1.2rem'}} icon={"material-symbols:delete-outline-sharp"}/>
                        }
                    </IconButton>
                </Tooltip>
            </Stack>
        </div>
    );
};
