import {EquipmentRequestStatusEnum} from "../../../../graphql/graphQlApiHooks";
import {getLabelStatusRequest} from "./getLabelStatusRequest";

export const statusOptions = [
    EquipmentRequestStatusEnum.Submitted,
    EquipmentRequestStatusEnum.Pending,
    EquipmentRequestStatusEnum.DocumentPackageFormation,
    EquipmentRequestStatusEnum.ContractingServiceAndPlanningAndEconomicDepartmentApproval,
    EquipmentRequestStatusEnum.LotFormation,
    EquipmentRequestStatusEnum.Auction,
    EquipmentRequestStatusEnum.Acceptance,
    EquipmentRequestStatusEnum.EquipmentAccepted,
    EquipmentRequestStatusEnum.Rejected
].map((value) => ({
    label: getLabelStatusRequest(value),
    value: value
}));
