import { makeVar, ReactiveVar } from "@apollo/client";
import {useEquipmentRepairRequestsFiltersVar} from "./filters";


export type RepairRequestsListPagination = {
  page: number;
  pageSize: number;
};

export const RepairRequestsListPaginationInitial: RepairRequestsListPagination = {
  page: 0,
  pageSize: 50
};

export const repairRequestsListPaginationVars = makeVar<RepairRequestsListPagination>(
    RepairRequestsListPaginationInitial
);

export const useRepairRequestsListPaginationVars = () => createRepairRequestsListPaginationVars(repairRequestsListPaginationVars);

const createRepairRequestsListPaginationVars = (repairRequestsListPaginationVars: ReactiveVar<RepairRequestsListPagination>) => {
  const setPagination = (pagination: RepairRequestsListPagination) => {
    repairRequestsListPaginationVars(pagination);
  };

  return {
    setPagination
  };
};
