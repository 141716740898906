import React, { FC } from "react";
import { SportEquipmentEntity } from "../../../../../graphql/graphQlApiHooks";
import SODialog from "../../../../../components/Dialogs/Dialog";
import EquipmentInfo from "../../../../../components/Dialogs/Titles/EquipmentInfo";
import { Button, TextField } from "@mui/material";

interface Props {
  open: boolean,
  onClose: () => void,
  originEquipment: SportEquipmentEntity,
  comment: string,
  onChangeComment: (comment: string) => void

}

const AddCommentDialog: FC<Props> =(props: Props) => {
  const { open, onClose, originEquipment, comment, onChangeComment } = props;

  return (<>
    <SODialog
      open={open}
      onClose={onClose}
      title={"Комментарий к состоянию оборудования"}
      titles={[
        <EquipmentInfo equipment={originEquipment} />
      ]}
      actions={<>
        <Button
          variant={"contained"}
          onClick={onClose}
        >
          ок
        </Button>
      </>}

    >
      <>

        <TextField
          label='Комментарий'
          multiline
          maxRows={6}
          minRows={4}
          fullWidth
          value={comment}
          onChange={({ target: { value } }) => onChangeComment(value)}
          variant='filled'
          autoFocus
        />
      </>
    </SODialog>
  </>);
};

export default AddCommentDialog;