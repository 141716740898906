import React from 'react';
import {Box, Skeleton, Stack, Typography} from "@mui/material";

interface Props {
    title: string | React.ReactNode,
    children?: React.ReactNode,
    count?: number,
    totalCount?: number,
    loading?: boolean,

    [key: string]: any,
}

function PageTitle({title, children, count, loading, totalCount, ...props}: Props) {

    return (
        <Typography
            variant="h2"
            component={'div'}
            style={{
                overflow: 'initial',
                textOverflow: 'initial',
                flexShrink: 1,
                left: 0,
                whiteSpace: 'nowrap',
                margin: '0 !important'
            }}
            {...props}
        >
            {title}
            {typeof count === 'number'
                ?
                loading ?
                    <Skeleton width={40} sx={{
                        display: 'inline-block',
                        ml: 2,
                    }}/>
                    :
                    <Box
                        component={'small'}
                        sx={{
                            ml: 2,
                            fontSize: '.7em',
                            color: 'text.secondary',
                            opacity: .5,
                            bgcolor: 'grey.300',
                            borderRadius: 1,
                            px: 1,
                            py: .5,
                        }}
                    >{count} {totalCount ? ` /  ${totalCount}` : ''}</Box>
                :
                null
            }
            {children}
        </Typography>
    );
}

export default PageTitle;
