import React, {useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import {
    Box,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {useStores} from "store";
import {
    AcceptByMaintananceDepartmentInput,
    EquipmentRelocationRequestEntity,
    GetRelocationRequestsDocument,
    SendRequestToTransportDepartmentInput,
    useAcceptByMaintenanceDepartmentMutation,
} from "graphql/graphQlApiHooks";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {LoadingButton} from "@mui/lab";

//@ts-ignore
const tr = new CyrillicToTranslit()

interface Errors {
    notifiableUsers: boolean;
    responsibleUser: boolean;
}

const RELOCATION_TYPES = {
    permanent: {value: 'PERMANENT', label: 'Постоянное'},
    temporary: {value: 'TEMPORARY', label: 'Временное'},
    external: {value: 'EXTERNAL', label: 'Для Мосгорспорта'},
}

const VEHICLE_TYPES = {
    lightVehicle: {value: 'LIGHT_VEHICLE', label: 'Легковой'},
    van: {value: 'VAN', label: 'Фургон'},
    boxVan: {value: 'BoxVan', label: 'Фургон-будка'},
}

interface Input extends Omit<SendRequestToTransportDepartmentInput, "equipmentIds"> {
    externalCollectionPointAddress: string
    externalTargetPointAddress: string
}

const transportDepartmentInputInitial = {
    relocationRequestId: '',
    relocationType: RELOCATION_TYPES.permanent.value,
    collectionPointAddress: '',
    targetPointAddress: '',
    externalCollectionPointAddress: '',
    externalTargetPointAddress: '',
    startEventDate: '',
    startVehicleDeliveryDate: '',
    endEventDate: '',
    returnDate: '',
    giverName: '',
    giverPhone: '',
    receivingName: '',
    receivingPhone: '',
    needForHandling: false,
    needForMountingDismounting: false,
    vehicleType: VEHICLE_TYPES.lightVehicle.value,
    comments: '',
}

interface Props {
    data?: EquipmentRelocationRequestEntity | null
    open: boolean
    onClose: () => void
    rejectButton?: any
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const AcceptTransportRequest = observer((props: Props) => {
    const {
        data,
        open,
        onClose,
        rejectButton
    } = props;

    const {equipmentRelocationRequestStore: {input: inputGetRelocationRequests}} = useStores();
    const [acceptByMaintenanceDepartment] = useAcceptByMaintenanceDepartmentMutation()

    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [comments, setComments] = useState('');

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleClose = () => {
        setComments('')
        setLoading(false)
        setChecked(false)
        onClose();
    };

    useEffect(() => {
        data && formik.setValues((prevState) => ({
            ...prevState,
            collectionPointAddress: data?.sourceSportObject?.address || '',
            targetPointAddress: data?.targetSportObject?.address || '',
        }))
        return () => {
            formik.setValues(transportDepartmentInputInitial)
            resetForm()
        };
    }, [data]);

    const phoneRegExp = /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993|\7)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/

    const [relocationType, setRelocationType] = useState(RELOCATION_TYPES.permanent.value);

    const validationSchema = useMemo(() => {
        if (!checked) return Yup.object().shape({})
        return Yup.object().shape({
            //Тип перемещения.
            relocationType: Yup.string().required('Вы не выбрали тип перемещения'),

            ...(relocationType === RELOCATION_TYPES.permanent.value && {
                //Адрес выдачи оборудования.
                collectionPointAddress: Yup.string().required('Вы не указали адрес выдачи оборудования'),
                //Адрес для подачи автомобиля с оборудованием.
                targetPointAddress: Yup.string().required('Вы не указали адрес для подачи автомобиля с оборудованием'),
            }),

            ...(relocationType === RELOCATION_TYPES.external.value && {
                externalCollectionPointAddress: Yup.string().required('Вы не указали адрес выдачи оборудования'),
                externalTargetPointAddress: Yup.string().required('Вы не указали адрес для подачи автомобиля с оборудованием'),
            }),

            ...(relocationType !== RELOCATION_TYPES.permanent.value && {
                //Дата и время начала мероприятия.
                startEventDate: Yup.date().required('Вы не указали дату и время начала мероприятия'),
                //Дата и примерное время окончания мероприятия.
                endEventDate: Yup.date().required('Вы не указали дату и время окончания мероприятия'),
                //Дата и время возврата оборудования.
                returnDate: Yup.date().required('Вы не указали дату и время окончания мероприятия'),
                //Имя отдающего.
            }),

            // Дата и время подачи автомобиля на объект спорта.
            startVehicleDeliveryDate: Yup.string().required('Вы не указали дату и время подачи автомобиля на объект спорта'),

            giverName: Yup.string().required('Вы не указали имя отдающего'),
            //Номер телефона отдающего.
            giverPhone: Yup.string().matches(phoneRegExp, 'Не верно указан телефон').required('Вы не указали телефон отдающего'),
            //Имя принимающего.
            receivingName: Yup.string().required('Вы не указали имя принимающего'),
            //Номер телефона принимающего.
            receivingPhone: Yup.string().matches(phoneRegExp, 'Не верно указан телефон').required('Вы не указали телефон принимающего'),
            //Тип автомобиля.
            vehicleType: Yup.string().required('Вы не указали тип автомобиля'),
            //Комментарии к заявке.
            comments: Yup.string(),
            //Необходимость погрузо-разгрузочных работ.
            needForHandling: Yup.boolean(),
            //Необходимость монтажа/демонтажа.
            needForMountingDismounting: Yup.boolean(),
        })
    }, [relocationType, checked]);

    const formik = useFormik({
        initialValues: transportDepartmentInputInitial as Input,
        validationSchema: validationSchema,
        onSubmit: (
            values: Input,
            {setSubmitting}: FormikHelpers<Input>,
        ) => {
            const {
                relocationType,
                collectionPointAddress,
                targetPointAddress,
                externalCollectionPointAddress,
                externalTargetPointAddress,
                startEventDate,
                startVehicleDeliveryDate,
                endEventDate,
                returnDate,
                giverName,
                giverPhone,
                receivingName,
                receivingPhone,
                needForHandling,
                needForMountingDismounting,
                vehicleType,
                comments,
            } = values

            const getTimeStamp = (date: string) => date !== '' ? dayjs(date).format('YYYY-MM-DD hh:mm:ss') : dayjs().format('MM-DD-YYYY hh:mm:ss');
            const getCollectionPointAddress = () => (relocationType !== RELOCATION_TYPES.external.value) ?
                collectionPointAddress :
                externalCollectionPointAddress;
            const getTargetPointAddress = () => (relocationType !== RELOCATION_TYPES.external.value) ?
                targetPointAddress :
                externalTargetPointAddress;


            const input: AcceptByMaintananceDepartmentInput = {
                equipmentRelocationRequestId: data.id!,
                comments: comments,
                hasTransportRequest: checked,
                ...(checked && {
                    transportRequest: {
                        relocationRequestId: data.id,
                        relocationType,

                        collectionPointAddress: getCollectionPointAddress(),
                        targetPointAddress: getTargetPointAddress(),

                        startVehicleDeliveryDate: getTimeStamp(startVehicleDeliveryDate),

                        ...(relocationType !== RELOCATION_TYPES.permanent.value && {
                            startEventDate: getTimeStamp(startEventDate),
                            endEventDate: getTimeStamp(endEventDate),
                            returnDate: getTimeStamp(returnDate),
                        }),

                        giverName,
                        giverPhone,

                        receivingName,
                        receivingPhone,

                        equipmentIds: [data.equipment.id],

                        needForHandling,
                        needForMountingDismounting,

                        vehicleType,

                        ...(comments !== '' && {
                            comments
                        }),
                    }
                })

            }


            acceptByMaintenanceDepartment({
                    variables: {input},
                    refetchQueries: [{
                        query: GetRelocationRequestsDocument,
                        variables: {
                            input: inputGetRelocationRequests,
                        },
                    }],
                }
            )
                .then(() => {
                    handleClose()
                    // setSnakeMessage('Отправление оборудования подтверждено')
                })
                .finally(() => setSubmitting(false))
        },
    });


    const {errors, touched, values, isSubmitting, submitForm, getFieldProps, resetForm, handleSubmit} = formik;


    useEffect(() => {
        setRelocationType(values.relocationType)
    }, [values.relocationType])

    useMemo(() => {
        formik.setValues((prevState) => ({...prevState, comments: comments}))
    }, [comments]);

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            title={'Подтверждение на одобрение перемещения оборудования'}
            titles={[
                <EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt}/>,
                <StepperRelocation
                    sourceSportObject={data?.sourceSportObject}
                    targetSportObject={data?.targetSportObject}
                    targetSportZone={data?.targetSportZone}
                    targetSportSubZone={data?.targetSportSubZone}
                />

            ]}
            actions={<>
                {rejectButton}
                <LoadingButton
                    variant={'contained'}
                    onClick={submitForm}
                    loading={loading}
                >Одобрить перемещение</LoadingButton>
            </>}
        >

            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Stack direction={'column'} mb={2}>
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange}/>} sx={{mb: 2}}
                                          label={
                                              <Typography>Заполнить заявку в транспортную службу</Typography>
                                          }/>
                        <Collapse in={checked}>
                            <Stack width={'100%'} spacing={2} direction={'column'} sx={{mb: 2}}>

                                <FormControl fullWidth size={'small'} margin={'dense'}>
                                    <InputLabel id={`AcceptTransportRequest-select-label-${data?.id}`}>Тип
                                        перемещения</InputLabel>
                                    <Select
                                        labelId="AcceptTransportRequest-select-label"
                                        id="AcceptTransportRequest-select"
                                        value={values.relocationType}
                                        label="Тип перемещения"
                                        onChange={({target: {value}}) => formik.setValues((prevState) => ({
                                            ...prevState,
                                            relocationType: value
                                        }))}
                                    >
                                        {Object.values(RELOCATION_TYPES).map(item => (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/*Адрес объекта из Softra для внутреннего перемещения, адрес склада для Мосгорспорта.*/}
                                <TextField
                                    label="Адрес выдачи оборудования"
                                    size="small"
                                    margin="dense"
                                    id="AcceptTransportRequest-collectionPointAddress"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    placeholder={'Адрес выдачи оборудования'}
                                    InputLabelProps={{
                                        shrink: (values.relocationType !== RELOCATION_TYPES.external.value && values.collectionPointAddress !== '') || undefined
                                    }}
                                    value={
                                        values.relocationType !== RELOCATION_TYPES.external.value ?
                                            values.collectionPointAddress :
                                            values.externalCollectionPointAddress
                                    }
                                    onChange={({target: {value}}) => {
                                        values.relocationType !== RELOCATION_TYPES.external.value ?
                                            formik.setValues((prevState) => ({
                                                ...prevState,
                                                collectionPointAddress: value
                                            })) :
                                            formik.setValues((prevState) => ({
                                                ...prevState,
                                                externalCollectionPointAddress: value
                                            }))
                                    }}

                                    {...(values.relocationType !== RELOCATION_TYPES.external.value) && {
                                        error: Boolean(touched.collectionPointAddress && errors.collectionPointAddress),
                                        helperText: touched.collectionPointAddress && errors.collectionPointAddress
                                    }}

                                    {...(values.relocationType === RELOCATION_TYPES.external.value) && {
                                        error: Boolean(touched.externalCollectionPointAddress && errors.externalCollectionPointAddress),
                                        helperText: touched.externalCollectionPointAddress && errors.externalCollectionPointAddress
                                    }}

                                />

                                <Stack direction={'row'} spacing={2} alignItems={'baseline'}>
                                    {/*Для Мосгорспорта, временного перемещения.*/}
                                    {values.relocationType !== RELOCATION_TYPES.permanent.value ?
                                        <TextField
                                            label="Начало мероприятия"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-startEventDate"
                                            type="datetime-local"
                                            fullWidth
                                            placeholder={'Начало мероприятия'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('startEventDate')}
                                            error={Boolean(touched.startEventDate && errors.startEventDate)}
                                            helperText={touched.startEventDate && errors.startEventDate}
                                        /> : null}

                                    <TextField
                                        label="Подача автомобиля на объект спорта"
                                        size="small"
                                        margin="dense"
                                        id="AcceptTransportRequest-startVehicleDeliveryDate"
                                        type="datetime-local"
                                        fullWidth
                                        placeholder={'Подача автомобиля на объект спорта'}
                                        InputLabelProps={{
                                            shrink: true
                                        }}

                                        {...getFieldProps('startVehicleDeliveryDate')}
                                        error={Boolean(touched.startVehicleDeliveryDate && errors.startVehicleDeliveryDate)}
                                        helperText={touched.startVehicleDeliveryDate && errors.startVehicleDeliveryDate}
                                    />
                                </Stack>


                                {/*Для внутреннего/временного перемещения – адрес из софтры, для Мосгорспорта – свободное поле.*/}
                                <TextField
                                    label="Адрес для подачи автомобиля с оборудованием"
                                    size="small"
                                    margin="dense"
                                    id="AcceptTransportRequest-targetPointAddress"
                                    type="text"
                                    fullWidth
                                    placeholder={'Адрес для подачи автомобиля с оборудованием'}
                                    multiline={true}
                                    rows={2}

                                    InputLabelProps={{
                                        shrink: (values.relocationType !== RELOCATION_TYPES.external.value && values.targetPointAddress !== '') || undefined
                                    }}

                                    value={
                                        values.relocationType !== RELOCATION_TYPES.external.value ?
                                            values.targetPointAddress :
                                            values.externalTargetPointAddress
                                    }
                                    onChange={({target: {value}}) => {
                                        values.relocationType !== RELOCATION_TYPES.external.value ?
                                            formik.setValues((prevState) => ({
                                                ...prevState,
                                                targetPointAddress: value
                                            })) :
                                            formik.setValues((prevState) => ({
                                                ...prevState,
                                                externalTargetPointAddress: value
                                            }))
                                    }}


                                    {...(values.relocationType !== RELOCATION_TYPES.external.value) && {
                                        error: Boolean(touched.targetPointAddress && errors.targetPointAddress),
                                        helperText: touched.targetPointAddress && errors.targetPointAddress
                                    }}

                                    {...(values.relocationType === RELOCATION_TYPES.external.value) && {
                                        error: Boolean(touched.externalTargetPointAddress && errors.externalTargetPointAddress),
                                        helperText: touched.externalTargetPointAddress && errors.externalTargetPointAddress
                                    }}

                                />
                                <Stack direction={'row'} spacing={2} alignItems={'baseline'}>
                                    {/*Для Мосгорспорта и временного перемещения.*/}
                                    {values.relocationType !== RELOCATION_TYPES.permanent.value ?
                                        <TextField
                                            label="Окончание мероприятия"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-endEventDate"
                                            type="datetime-local"
                                            fullWidth
                                            placeholder={'Окончание мероприятия'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('endEventDate')}
                                            error={Boolean(touched.endEventDate && errors.endEventDate)}
                                            helperText={touched.endEventDate && errors.endEventDate}
                                        /> : null}

                                    {/*Для Мосгорспорта, временного перемещения. Если мероприятие заканчивается в выходной.*/}
                                    {values.relocationType !== RELOCATION_TYPES.permanent.value ?
                                        <TextField
                                            label="Возврат оборудования"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-returnDate"
                                            type="datetime-local"
                                            fullWidth
                                            placeholder={'Возврат оборудования'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('returnDate')}
                                            error={Boolean(touched.returnDate && errors.returnDate)}
                                            helperText={touched.returnDate && errors.returnDate}
                                        /> : null}

                                </Stack>

                                <FormControl
                                    sx={{m: 3}}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormLabel
                                        component={Typography}
                                        variant={'h3'}
                                    >Контактные данные отдающего. – МОЛ</FormLabel>
                                    <Stack direction={'row'} spacing={2} alignItems={'baseline'} mt={1}>

                                        <TextField
                                            label="Имя для связи"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-car-giverName"
                                            type="text"
                                            fullWidth
                                            placeholder={'Мария'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('giverName')}
                                            error={Boolean(touched.giverName && errors.giverName)}
                                            helperText={touched.giverName && errors.giverName}
                                        />
                                        <TextField
                                            label="Номер телефона"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-giverPhone"
                                            type="tel"
                                            fullWidth
                                            placeholder={'+7 800 840-38-38'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('giverPhone')}
                                            error={Boolean(touched.giverPhone && errors.giverPhone)}
                                            helperText={touched.giverPhone && errors.giverPhone}
                                        />
                                    </Stack>

                                </FormControl>

                                <FormControl
                                    sx={{m: 3}}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormLabel
                                        component={Typography}
                                        variant={'h3'}
                                    >Контактные данные принимающего. – МОЛ</FormLabel>
                                    <Stack direction={'row'} spacing={2} alignItems={'baseline'} mt={1}>

                                        <TextField
                                            label="Имя для связи"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-receivingName"
                                            type="text"
                                            fullWidth
                                            placeholder={'Мария'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('receivingName')}
                                            error={Boolean(touched.receivingName && errors.receivingName)}
                                            helperText={touched.receivingName && errors.receivingName}
                                        />
                                        <TextField
                                            label="Номер телефона"
                                            size="small"
                                            margin="dense"
                                            id="AcceptTransportRequest-receivingPhone"
                                            type="tel"
                                            fullWidth
                                            placeholder={'+7 800 840-38-38'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            {...getFieldProps('receivingPhone')}
                                            error={Boolean(touched.receivingPhone && errors.receivingPhone)}
                                            helperText={touched.receivingPhone && errors.receivingPhone}
                                        />
                                    </Stack>
                                </FormControl>

                                {/*Для Постоянного/временного перемещения.*/}
                                {values.relocationType !== RELOCATION_TYPES.external.value ?
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.needForHandling}
                                                onChange={() => {
                                                    formik.setValues((prevState) => ({
                                                        ...prevState,
                                                        needForHandling: !prevState.needForHandling
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Необходимость погрузо-разгрузочных работ"
                                    /> : null
                                }

                                {/*Для Постоянного/временного перемещения.*/}
                                {values.relocationType !== RELOCATION_TYPES.external.value ?
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.needForMountingDismounting}
                                                onChange={() => {
                                                    formik.setValues((prevState) => ({
                                                        ...prevState,
                                                        needForMountingDismounting: !prevState.needForMountingDismounting
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Необходимость монтажа/демонтажа"
                                    /> : null
                                }

                                <FormControl fullWidth size={'small'} margin={'dense'}>
                                    <InputLabel id="AcceptTransportRequest-select-label">Тип автомобиля</InputLabel>
                                    <Select
                                        labelId="AcceptTransportRequest-vehicleType-select-label"
                                        id="AcceptTransportRequest-vehicleType"
                                        value={values.vehicleType}
                                        label="Тип перемещения"
                                        onChange={({target: {value}}) => formik.setValues((prevState) => ({
                                            ...prevState,
                                            vehicleType: value
                                        }))}
                                    >
                                        {Object.values(VEHICLE_TYPES).map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Collapse>
                    </Stack>
                    <Box>
                        <TextField
                            size="small"
                            margin="dense"
                            id="AcceptRelocationComment"
                            type="text"
                            fullWidth
                            multiline
                            rows={3}
                            placeholder={'Комментарий к перемещению'}
                            value={comments}
                            onChange={({target: {value}}) => setComments(value)}
                        />
                    </Box>
                </Form>
            </FormikProvider>

        </Dialog>
    );
})

export default AcceptTransportRequest;
