import React from "react";
import { ButtonGroup } from "@mui/material";
import { ImportButton } from "./ImportButton";
import { ExportButton } from "./ExportButton";


interface Props {
  importLoading: boolean;
  exportLoading: boolean;

  actions: {
    importSportEquipmentListFromExcel: (event: any) => void
    exportSportEquipmentListInExcel: () => void
  };
}

export default (props: Props) => {
  const {
    importLoading,
    exportLoading,
    actions: { importSportEquipmentListFromExcel, exportSportEquipmentListInExcel }
  } = props;

  return (
    <>
      <ButtonGroup
        disableElevation
        variant="outlined"
        color={"inherit"}
        sx={{ color: "divider", "& .MuiButtonGroup-grouped": { p: 3, bgcolor: "background.paper" } }}
      >
        <ImportButton
          loading={importLoading}
          actions={{
            importSportEquipmentListFromExcel
          }}
        />

        <ExportButton
          loading={exportLoading}
          actions={{
            exportSportEquipmentListInExcel
          }}
        />

      </ButtonGroup>


    </>
  );
};