import { makeVar, ReactiveVar } from "@apollo/client";
import {EquipmentRepairRequestFilterInput} from "../../../graphql/graphQlApiHooks";

export const EquipmentRepairRequestsFiltersInitial: EquipmentRepairRequestFilterInput = {
    equipmentRepairRequestIds: null,
    skip: null,
    sportEquipmentIds: null,
    sportObjectIds: null,
    sportSubZoneIds: null,
    sportZoneIds: null,
    statuses: null,
    take: null,
};

export const equipmentRepairRequestsFiltersVar = makeVar<EquipmentRepairRequestFilterInput>(
    EquipmentRepairRequestsFiltersInitial
);

export const useEquipmentRepairRequestsFiltersVar = () =>
    createEquipmentRepairRequestsFiltersVar(equipmentRepairRequestsFiltersVar);

const createEquipmentRepairRequestsFiltersVar = (
    equipmentRepairRequestsFiltersVar: ReactiveVar<EquipmentRepairRequestFilterInput>
) => {
    const setFilters = (newFilters: EquipmentRepairRequestFilterInput) => {
        equipmentRepairRequestsFiltersVar({ ...newFilters });
    };

    return {
        setFilters,
    };
};
