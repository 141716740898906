import React, {forwardRef} from "react";
import {
    Box,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps
} from "@mui/material";
import {statusOptions} from "../../lib/statusOptions";
import {getStatusIcon} from "../../lib/getStatusIcon";
import {getLabelStatusRequest} from "../../lib/getLabelStatusRequest";
import Tooltiper from "../../../../../components/Tooltiper";
import {getStatusColor} from "../../lib/getStatusColor";
import {EquipmentRequestStatusEnum} from "../../../../../graphql/graphQlApiHooks";

type StatusSelectProps = SelectProps<EquipmentRequestStatusEnum> & {
    value: EquipmentRequestStatusEnum;
    onChange: (event: SelectChangeEvent<EquipmentRequestStatusEnum>, child: React.ReactNode) => void;
    loading?: boolean
    statuses?: {
        label: string
        value: EquipmentRequestStatusEnum
    }[]
};

const StatusSelect = forwardRef<HTMLDivElement, StatusSelectProps>((
    {
        value,
        onChange,
        loading,
        statuses = statusOptions,
        ...rest
    }, ref) => (
    <Select
        ref={ref}
        value={value}
        onChange={onChange}
        disableUnderline
        variant="filled"
        MenuProps={{
            PaperProps: {
                style: {maxWidth: 300},
            },
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },

        }}
        renderValue={(selected: EquipmentRequestStatusEnum) => {
            const selectedOption = statusOptions.find(option => option.value === selected);
            const icon = getStatusIcon(selected, "1.5rem");
            const label = selectedOption ? selectedOption.label : getLabelStatusRequest(selected);

            return (
                <Box sx={{display: "flex", alignItems: "center", gap: 2, width: '100%'}}>
                    <Box sx={{display: "flex", alignItems: "center", justifyItems: "center"}}>{icon}</Box>
                    <Tooltiper text={label}/>
                </Box>
            );
        }}
        sx={{
            border: 'none',
            mr: 2,
            width: '100%',
            "&.Mui-disabled": {
                color: (theme) => getStatusColor(value, theme).color,
                backgroundColor: 'transparent',
                '& .MuiSelect-icon': {
                    visibility: 'hidden'
                },
                '& *': {
                    WebkitTextFillColor: (theme) => getStatusColor(value, theme).color
                }
            },
            "& .MuiSelect-select": {
                width: '100%',
                backgroundColor: (theme) => getStatusColor(value, theme).backgroundColor,
                color: (theme) => getStatusColor(value, theme).color,
                p: 2,
                pr: "32px !important",
                borderRadius: 1,
            },
            "& .MuiSvgIcon-root": {
                color: (theme) => getStatusColor(value, theme).color,
                right: 8,
            },
        }}
        {...rest}
    >
        {statuses.map(({value: optionValue, label}) =>
            <MenuItem
                key={optionValue}
                value={optionValue}
                sx={{
                    alignItems: "start",
                }}
            >

                <ListItemIcon
                    sx={{
                        color: (theme) => getStatusColor(optionValue, theme).color,
                        mt: '2px'
                    }}
                >
                    {loading ? <CircularProgress size={10}/> : getStatusIcon(optionValue, "1.2rem")}
                </ListItemIcon>
                <ListItemText
                    sx={{
                        whiteSpace: "normal",
                        '& .MuiTypography-root': {
                            color: (theme) => getStatusColor(optionValue, theme).color,
                            fontSize: "14px",
                        }
                    }}
                >
                    {label}
                </ListItemText>

            </MenuItem>
        )}
    </Select>
));

StatusSelect.displayName = "StatusSelect";

export default StatusSelect;
