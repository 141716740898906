import {fromResource} from "mobx-utils";
import {autorun, observable} from "mobx";
import {rootStore} from "../store";

export function sortByCreateAt(prev: any, next: any): number {
    const prevDate: number = Number(prev.createdAt),
        nextDate: number = Number(next.createdAt);
    return prevDate - nextDate;
}
export function queryToMobxObservable(queryObservable:any) {
    let subscription: any
    return fromResource(
        (sink) => {
            subscription = queryObservable.subscribe({
                next: (graphqlRes: any) => {
                    if (graphqlRes.data) {
                        sink(observable(graphqlRes.data));
                    }
                },
                error: (error: any) => {
                    if (error.message === 'Could not authenticate with token') {
                        console.error('Could not authenticate with token', error);
                        autorun(()=>{
                            rootStore.userStore.setLoginError(true)
                            rootStore.userStore.setLoginErrorMessage('Не удалось пройти аутентификацию с помощью токена')
                            rootStore.userStore.clearUser()
                        })
                    } else {
                        console.error('there was an error sending the query', error);
                    }

                }
            });
        },
        () => subscription.unsubscribe()
    )
}

export function isEqual(obj1:any, obj2:any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}
