import {styled} from "@mui/styles";
import {Tooltip as MiTooltip, tooltipClasses, TooltipProps} from "@mui/material";
import React from "react";

export const Tooltip = styled(({className, ...props}: TooltipProps) => (
    <MiTooltip {...props} classes={{popper: className}} children={props.children}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1, 2),
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 260,
        maxHeight: 220,
        position: 'relative',
        overflow: 'hidden',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));