import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { SportCategoryDictionaryEntity, useGetAllDictionariesQuery } from "graphql/graphQlApiHooks";
import { Autocomplete, TextField } from "@mui/material";
import { ImportedSportEquipmentRecord } from "../types";

interface Props {

}

export const SportKindEdit = (props: GridRenderCellParams<ImportedSportEquipmentRecord>) => {
  const { id, api, field, row } = props;
  // const {dictionariesStore} = useStores();
  // let {sportKindDictionary: options, sportCategoryDictionary: sportCategories} = dictionariesStore;

  const { data: allDictionaries } = useGetAllDictionariesQuery();
  const options = allDictionaries?.getAllSportKindDictionary?.sportKinds;
  const sportCategories = allDictionaries?.getAllSportKindDictionary?.sportCategories;


  const sportCategory = sportCategories?.find((sportCategory: SportCategoryDictionaryEntity) => sportCategory?.id === row?.sportCategory?.id);
  // options = (sportCategory?.sportKinds) ? sportCategory?.sportKinds : options

  const value = row?.sportKind;
  const handleChange = (event: any, newValue: any) => {
    const sportCategoryValue = sportCategories.find((category: SportCategoryDictionaryEntity) => newValue?.sportCategory?.id === category?.id || newValue?.sportCategoryId === category?.id);

    api.setEditCellValue({ id, field, value: newValue }, event);
    if (row?.equipmentType?.sportKind?.id !== newValue?.id) {
      api.setEditCellValue({ id, field: "equipmentType", value: null }, event);
    }
    if (row?.sportCategory?.sportKind?.id !== newValue?.id) {
      api.setEditCellValue({ id, field: "sportCategory", value: sportCategoryValue }, event);
    }
    api.commitRowChange(row.id);
  };

  return (
    <Autocomplete
      value={value || null}
      id="sportKinds"
      options={options || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
      onChange={handleChange}
      sx={{ width: "100%", height: "100%", ".MuiTextField-root": { height: "100%" } }}
      openOnFocus
      groupBy={(option: any) => (option?.sportCategory) ? option?.sportCategory.name : undefined}
      renderInput={(params) => <TextField
        error={!value}
        {...params}
        variant="standard"
        size={"small"}
        placeholder="Выберите вид спорта"
        InputLabelProps={{
          shrink: true
        }}
      />}
    />
  );
};
