import { makeVar, ReactiveVar } from "@apollo/client";
import {SortDirectionEnum} from "../spor-equipmnts/sort-direction.enum";
import {SortRepairRequestColumnsEnum} from "./sort-repair-request-columns.enum";

export type RepairRequestsListSortOrder = {
  [key in SortRepairRequestColumnsEnum as string]: SortDirectionEnum | null | undefined;
}

export const RepairRequestsListSortOrderInitial: RepairRequestsListSortOrder = {
  [SortRepairRequestColumnsEnum.INVENTORY_NUMBER]: SortDirectionEnum.DESC
};

export const repairRequestsListSortOrderVar = makeVar<RepairRequestsListSortOrder>(
  RepairRequestsListSortOrderInitial
);

export const useRepairRequestsListSortOrderVar = () => createRepairRequestsListSortOrderVar(repairRequestsListSortOrderVar);

const createRepairRequestsListSortOrderVar = (repairRequestsListSortOrderVar: ReactiveVar<RepairRequestsListSortOrder>) => {

  const setOrder = (orderModel: RepairRequestsListSortOrder) => {
    repairRequestsListSortOrderVar(orderModel);
  };

  return {
    setOrder
  };
};
