import React, {ReactNode} from "react";
import {Box, CardMedia, Popover} from "@mui/material";

interface Props {
  children: ReactNode,
  urlAvatar?: string
}

function EquipmentAvatarMouseOver(props: Props) {
  const {children, urlAvatar} = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Box>
      {urlAvatar && <Popover
        id="mouse-over-avatar-equipment"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <CardMedia
          component="img"
          height="300"
          image={urlAvatar}
          alt="green iguana"
        />
      </Popover>}
    </div>
  );
}

export default EquipmentAvatarMouseOver;
