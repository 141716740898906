import React, {useMemo, useState} from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import axios from "axios";
import {Box, LinearProgress, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Settings from "../store/settings";
import {observer} from "mobx-react-lite";
import {useStores} from "../store";

const uploadArea = {
    gap: 10,
    speed: 4,
    stroke: 2,
    colorCard: '#FFFFFF'
}

const useStyles = makeStyles((theme: Theme) => ({
    "@keyframes backgroundPosition": {
        "from": {
            backgroundPosition: `
                0% 0px, 0px 100%,
                100% 0px, 0px 0%
            `,
        },
        "to": {
            backgroundPosition: `
                0%  ${4 * uploadArea.gap}px, ${4 * uploadArea.gap}px 100%, 
                100%  ${-4 * uploadArea.gap}px, ${-4 * uploadArea.gap}px 0%
            `,
        },
    },
    progressBar: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
    },
    root: {
        pointerEvents: 'none'
    },
    uploadArea: {
        position: 'relative',
        overflow: 'hidden',
        marginTop: '1.25rem',
        border: 'none',
        // backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        backgroundColor: 'rgb(243, 246, 250)',
        padding: '.6rem .6rem',
        borderRadius: '4px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        transition: 'all .3s',
        justifyContent: 'space-between',
        fontSize: 13.3333,
        fontWeight: 400,
        color: theme.palette.divider,
        '&.dragOver': {
            padding: '1.5rem 1rem',
            backgroundColor: 'transparent',
            backgroundImage:
                `linear-gradient(to top, currentColor ${uploadArea.gap}px, ${uploadArea.colorCard}  ${uploadArea.gap}px),`
                + `linear-gradient(to right, currentColor ${uploadArea.gap}px, ${uploadArea.colorCard}  ${uploadArea.gap}px),`
                + `linear-gradient(to bottom, currentColor ${uploadArea.gap}px, ${uploadArea.colorCard}  ${uploadArea.gap}px),`
                + `linear-gradient(to left, currentColor ${uploadArea.gap}px, ${uploadArea.colorCard}  ${uploadArea.gap}px)`,
            backgroundSize:
                `${uploadArea.stroke}px ${uploadArea.gap * 2}px,`
                + `${uploadArea.gap * 2}px ${uploadArea.stroke}px,`
                + `${uploadArea.stroke}px ${uploadArea.gap * 2}px,`
                + `${uploadArea.gap * 2}px ${uploadArea.stroke}px`,
            backgroundRepeat: "repeat-y, repeat-x, repeat-y, repeat-x",
            willChange: 'background-position',
            animation: `$backgroundPosition ${uploadArea.speed}s linear infinite`,
            animationPlayState: 'paused',
        },

        '&:not(.dragOver).isUploaded': {
            backgroundImage: 'none',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: 'rgb(243, 246, 250)',
            marginTop: '0.7em',
            '&:hover, &:focus': {
                backgroundImage: 'none',
                backgroundColor: '#f0f4f9',

            },
        },

        '&:hover, &:focus, &.hover': {
            // cursor: 'pointer',
            animationPlayState: 'running',
            color: theme.palette.primary.main,
            backgroundColor: '#f0f4f9',
            '& .uploadIcon': {
                fill: theme.palette.primary.main
            },
        },
        '& .upload-area-icon': {
            display: 'block',
            width: '1.75rem',
            height: '1.75rem',
            marginRight: '.5rem',
            '& svg': {
                maxHeight: '100%',
                maxWidth: '100%',
            }
        },
        '& .upload-area-file': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
            maxWidth: '330px',
            '&.upload-file': {
                '&:hover .MuiOutlinedInput-input': {
                    color: theme.palette.primary.main
                }
            }
        },
        '& .upload-file-name': {
            color: theme.palette.text.secondary,
            textOverflow: 'ellipsis',
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '&:hover': {
                color: theme.palette.primary.main
            }
        },
        '& .uploadIcon': {
            transition: 'all .3s',
            fill: theme.palette.text.secondary,
            transformOrigin: '50%',
            '&.uploaded': {
                transform: 'rotate(90deg)',
            }
        },
        '& .upload-area-titles': {
            display: 'flex',
            flexGrow: 0,
            minWidth: '80px',
            justifyContent: 'flex-end',
            "& .MuiSvgIcon-root": {
                fontSize: '1.3em !important'
            },
            "& .MuiButton-root": {
                minWidth: 'auto',
                whiteSpace: 'nowrap',
                padding: '2px 10px',
                [theme.breakpoints.down('sm')]: {
                    textIndent: '-999999em',
                    "& .MuiButton-startIcon": {
                        margin: 0
                    }
                },
                "&:not(:last-child)": {
                    marginRight: '.5em',
                }
            }
        },
        '& .upload-area-title': {
            display: 'block',
            fontWeight: '700',
            color: theme.palette.text.primary,
            textAlign: 'left'
        },
        '& .upload-file-description': {
            display: 'block',
            fontWeight: '500',
            color: theme.palette.text.primary,
            fontSize: '14px',
            width: '100%',
            padding: 0,
            '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: 'transparent',
                border: 0,
                width: '100%',
                '&:hover':{
                    boxShadow: 'none !important'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none'
                },
                '& .MuiInputBase-input.Mui-disabled': {
                    padding: 0,
                    WebkitTextFillColor: theme.palette.text.primary,
                    textOverflow: 'ellipsis',
                }
            },
        },

    },
}));

const ButtonUpload = observer(({...rest}:any) => {
    const classes = useStyles();
    const {userStore} = useStores();
    const {changeableName, dragOver, document, description} = rest

    const [inputUpload, setInputUpload] = useState('');

    const [fileName, setFileName] = useState();
    const [fileDescription, setFileDescription] = useState(description);
    const [file, setFile] = useState(document);

    const [uploaded, setUploaded] = useState(false);
    const [hover, setHover] = useState(false);

    const [uploadPercentage, setUploadPercentage] = useState<number>(0);

    useMemo(() => {
        if (document) {
            setFile(document)
        } else {
            setFile(undefined)
        }
    }, [document])

    useMemo(() => {
        if (file) {
            setFileName(file.name)
            setFileDescription(file.description)
        } else {
            setFileName(undefined)
            setFileDescription(description)
        }
    }, [file, description])

    const onUploadChange = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            uploadFile(event.target.files)
        }
        setInputUpload('')
    };


    const uploadFile = (files: any) => {
        setUploadPercentage(0)
        setHover(false)
        let data = new FormData();
        data.append('file', files[0])
        data.append('description', fileDescription)


        const options = {
            onUploadProgress: (progressEvent: any) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            },
            headers: {
                "authorization": `Bearer ${userStore.token}`,
            },
        }
        axios.post(Settings.server + "/files/upload", data, options).then(res => {
            const fileData = res.data
            rest.response(fileData)
            setFileName(res.data.name)
            setFile(fileData)

            setUploadPercentage(100)
            setUploaded(true)
        })
    }


    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        if ('preventDefault' in e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setHover((e.type === 'dragover'))
    }

    function handleFileSelect(e: React.DragEvent<HTMLDivElement>) {
        handleDragOver(e);
        const files = e.dataTransfer.files;
        uploadFile(files)
    }

    return (
        <>
            <div
                className={"document"}
                onDragOver={handleDragOver}
                onDragLeave={handleDragOver}
                onDrop={handleFileSelect}
            >
                <div
                    // htmlFor={`btn-upload-${rest.id}}`

                    className={clsx(
                        classes.uploadArea,
                        (uploaded && !hover) && 'isUploaded',
                        (file) && 'isUploaded',
                        hover && 'hover',
                        dragOver && 'dragOver'
                    )}
                >

                    {(uploadPercentage > 0 && uploadPercentage < 100) &&
                    <LinearProgress
                        classes={{root: classes.progressBar}}
                        variant="determinate"
                        value={uploadPercentage}
                    />}
                    <input
                        id={`btn-upload-${rest.id}`}
                        name={`btn-upload-${rest.id}`}
                        style={{display: 'none'}}
                        type="file"
                        accept="documents/*"
                        onInput={onUploadChange}
                        value={inputUpload}

                    />

                    <Box
                        component={(file) ? 'a' : 'div'}
                        href={file && Settings.server + file.url}
                        target={'_blank'}
                        title={file && 'Скачать файл, для просмотра'}
                        className={clsx("upload-area-file", file && "upload-file")}
                    >
                        <span className="upload-area-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="340.531"
                                height="419.116"
                                viewBox="0 0 340.531 419.116"
                            >
                                <g
                                    id="files-new"
                                    clipPath="url(#clip-files-new)"
                                >
                                    <path
                                        className="uploadIcon"
                                        d="M336.7,82.4L258.2,3.8c-2.5-2.5-5.8-3.8-9.3-3.8H39.3C17.6,0,0,17.6,0,39.3v340.5c0,21.7,17.6,39.3,39.3,39.3h262
                                        c21.7,0,39.3-17.6,39.3-39.3V91.7C340.5,88.2,339.2,84.9,336.7,82.4z M314.3,379.8c0,7.2-5.9,13.1-13.1,13.1h-262
                                        c-7.2,0-13.1-5.9-13.1-13.1V39.3c0-7.2,5.9-13.1,13.1-13.1h196.5v52.4c0,14.5,11.7,26.2,26.2,26.2h52.4V379.8z"
                                    />
                                    <path
                                        className={clsx(
                                            "arrow",
                                            "uploadIcon",
                                            (file && file.url) ? "uploaded" : ''
                                        )}
                                        d="M250.4,209.6c0,2.1-0.5,4.1-1.4,6c-0.3,0.5-0.6,1-1,1.5c-0.7,1.2-1.7,2.3-2.8,3.3c-0.2,0.2-0.3,0.5-0.6,0.7
                                        l-57,42.8c-2.5,1.8-5.5,2.8-8.6,2.8c-2.2,0-4.4-0.5-6.4-1.5h0c-4.8-2.4-7.9-7.3-7.9-12.8v-19.1H96c-7.9,0-7.9-47.6,0-47.6h68.8
                                        v-18.8c0-5.4,3-10.3,7.9-12.8c4.8-2.4,10.6-1.9,14.9,1.3l57,42.8c0.2,0.2,0.3,0.5,0.6,0.7c1.1,1,2,2.1,2.8,3.3c0.4,0.5,0.7,1,1,1.5
                                        C249.9,205.4,250.4,207.5,250.4,209.6z"
                                    />
                                </g>
                            </svg>

                        </span>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'80%'}
                        >
                            <TextField
                                classes={{root: clsx('upload-file-description', !(changeableName && file) && 'readOnly')}}
                                size={'small'}
                                fullWidth={true}
                                value={fileDescription || description}
                                placeholder={'Укажите название документа'}
                                onChange={({target: {value}}: any) => setFileDescription(value)}
                                disabled={(!(changeableName && !file))}
                                inputProps={{
                                    readOnly: (!(changeableName && !file))
                                }}
                            />
                            {file && <span className={'upload-file-name'}>{fileName}</span>}
                        </Box>
                    </Box>

                    {!rest.readOnly && <div className="upload-area-titles">
                        <Button
                            component={'label'}
                            htmlFor={`btn-upload-${rest.id}`}
                            className="upload-area-button"
                            // size={'small'}
                            variant={'contained'}
                            startIcon={<AttachFileIcon fontSize={'inherit'}/>}
                        >
                            {(file) ? <>Выбрать другой файл</> : <>Выберите файл</>}
                        </Button>


                        {file &&
                        <Button
                            // size={'small'}
                            variant={'contained'}
                            onClick={() => {
                                rest.handleDeleteFile(rest.id);
                                setFile(undefined)
                            }}
                        >
                            <DeleteOutlinedIcon fontSize={'inherit'}/>
                        </Button>
                        }

                    </div>}
                </div>
            </div>
        </>
    )
});


export default ButtonUpload;
