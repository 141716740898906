import React, {useMemo} from "react";
import {SportEquipmentListFilters} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListFilters";
import {Filter, FiltersToolbar} from "components/FiltersToolbar";
import Autocomplete from "components/FiltersToolbar/Autocomplete";
import {
    EquipmentRelocationRequestsFilters,
    equipmentRelocationRequestsFiltersVar,
    useEquipmentRelocationRequestsFilters
} from "store/reactiveVarsStores/equipment-relocate/filtersVar";
import {sportObjectsVars} from "store/reactiveVarsStores/sport-objects/sportObjects";
import {useReactiveVar} from "@apollo/client";
import Search from "components/FiltersToolbar/Search";
import {EquipmentRelocationRequestStatusEnum} from "store/stores/equipment-relocation-request-status.enum";
import {getRelocationRequestStatusLabel} from "../List/TagStatusRelocationRequest";
import FilterDateRange from "components/FiltersToolbar/DateRange";
import dayjs from "dayjs";
import {useGetAllDictionariesQuery} from "graphql/graphQlApiHooks";


export const Filters = () => {
    const {
        sportObjectList,
    } = useReactiveVar(sportObjectsVars);
    const {setFilters} = useEquipmentRelocationRequestsFilters();
    const filterValues = useReactiveVar(equipmentRelocationRequestsFiltersVar);

    const filterChangeHandler = (filters: EquipmentRelocationRequestsFilters) => {
        setFilters({
            ...filterValues,
            ...filters
        });
    };

    const {data: allDictionaries} = useGetAllDictionariesQuery();

    const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds;
    const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes;

    function getAutocompleteLabel(arrayIds: any[], array: any[]) {
        const names = arrayIds ? array.filter((item) => arrayIds?.includes(item.id))
            .map((item) => `${item.name}`) : [];

        const result = names.length > 0 ? names.length > 2 ? names.slice(0, 2).join(", ") + " и ещё " + (names.length - 2) : names.join(", ") : [];

        return (result?.length === 0) ? `` : `${result}`;

    }


    const filters: Filter[] = useMemo(() => {


        const filtersList: Filter[] = [
            {
                name: 'sourceSportObjectIds',
                title: "Спортобъект нахождения",
                value: filterValues?.sourceSportObjectIds,
                filterComponent: (props: any) => <Autocomplete
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sourceSportObjectIds": result.map(val => val.id)});
                        }
                    }}
                    {...props}
                />,
                options: sportObjectList
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name)),
                label: filterValues?.sourceSportObjectIds ? getAutocompleteLabel(filterValues.sourceSportObjectIds as any, sportObjectList) : "",

                type: "autocomplete",
                disableDelete: true,
            },

            {
                name: 'targetSportObjectIds',
                title: "Спортобъект назначения",
                value: filterValues?.targetSportObjectIds,
                filterComponent: (props: any) => <Autocomplete
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"targetSportObjectIds": result.map(val => val.id)});
                        }
                    }}
                    {...props}
                />,
                options: sportObjectList
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name)),
                label: filterValues?.targetSportObjectIds ? getAutocompleteLabel(filterValues.targetSportObjectIds as any, sportObjectList) : "",

                type: "autocomplete",
                disableDelete: true,
            },
            {
                name: "sportKindIds",
                title: "Вид спорта",
                value: filterValues?.sportKindIds,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportCategory?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sportKindIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                options: sportKinds
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportCategory?.name.localeCompare(a.sportCategory?.name)) || [],
                label: filterValues?.sportKindIds && sportKinds ? getAutocompleteLabel(filterValues.sportKindIds as any, sportKinds) : "",
                type: "autocomplete"
            },
            {
                name: "equipmentTypeIds",
                title: "Тип оборудования",
                value: filterValues?.equipmentTypeIds,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportKind?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"equipmentTypeIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                options: equipmentTypes
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportKind?.name.localeCompare(a.sportKind?.name)) || [],
                label: filterValues?.equipmentTypeIds && equipmentTypes ? getAutocompleteLabel(filterValues.equipmentTypeIds as any, equipmentTypes) : "",
                type: "autocomplete"
            },
            {
                name: "name",
                title: "Название оборудования",
                value: filterValues?.name,

                filterComponent: (props: any) => <Search
                    filterResult={(value) => {
                        filterChangeHandler({"name": value} as SportEquipmentListFilters);
                    }}
                    {...props}
                />,
                label: filterValues?.name ? ` содержит: "${filterValues.name}"` : "",
                type: "search"
            },
            {
                name: "inventoryNumber",
                title: "Инвентарный номер",
                value: filterValues?.inventoryNumber,

                filterComponent: (props: any) => <Search
                    filterResult={(value) => {
                        filterChangeHandler({"inventoryNumber": value} as SportEquipmentListFilters);
                    }}
                    {...props}
                />,
                label: filterValues?.inventoryNumber ? ` содержит: "${filterValues.inventoryNumber}"` : "",
                type: "search"
            },

            {
                name: "statuses",
                title: "Статус заявки",

                value: filterValues?.statuses,
                filterComponent: (props: any) => <Autocomplete
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"statuses": result.map(val => val.id) as (keyof typeof EquipmentRelocationRequestStatusEnum)[]});
                        }
                    }}
                    {...props}
                />,
                options: (Object.keys(EquipmentRelocationRequestStatusEnum) as Array<keyof typeof EquipmentRelocationRequestStatusEnum>)
                    .map((key) => ({
                        id: key,
                        name: getRelocationRequestStatusLabel(EquipmentRelocationRequestStatusEnum[key])
                    })),
                label: filterValues?.statuses ? getAutocompleteLabel(filterValues.statuses as any, (Object.keys(EquipmentRelocationRequestStatusEnum) as Array<keyof typeof EquipmentRelocationRequestStatusEnum>)
                    .map((key) => ({
                        id: key,
                        name: getRelocationRequestStatusLabel(EquipmentRelocationRequestStatusEnum[key])
                    }))) : "",

                type: "autocomplete",
                disableDelete: true,
            },

            {
                name: "createdAt",
                title: "Дата создания",
                value: filterValues.createdAt,
                filterComponent: (props: any) => <FilterDateRange
                    filterResult={(value) => {
                        filterChangeHandler({"createdAt": value} as SportEquipmentListFilters);
                    }}
                    {...props}
                />,
                label: (() => (filterValues?.createdAt && filterValues.createdAt.hasOwnProperty("label")) ? `: ${(filterValues.createdAt as any)?.label}` : ` с: ${dayjs(filterValues.createdAt?.startDate).format("DD.MM.YYYY")} по: ${dayjs(filterValues.createdAt?.endDate).format("DD.MM.YYYY")}`)(),
                type: "dateRange"
            }


        ] as Filter[];

        return filtersList;
    }, [
        filterValues,
        sportObjectList,

        sportKinds,
        equipmentTypes,
    ]);

    function deleteHandler(filter: Filter) {
        setFilters({
            ...filterValues,
            [filter.name]: null
        } as SportEquipmentListFilters);
    }

    function clearFiltersHandler() {
        const cleanedFilters = [...filters].reduce((a, filter) => ({...a, [filter.name]: null}), {});

        setFilters({...cleanedFilters} as SportEquipmentListFilters);
    }

    const onChangeFilterValue = (filterName: string, value: any) => {
        filterChangeHandler({[filterName]: value});
    };

    return (
        <>
            <FiltersToolbar
                filters={filters}
                onDelete={deleteHandler}
                clearFilters={clearFiltersHandler}
                onChangeFilterValue={onChangeFilterValue}
                placeholder={"Фильтры"}
            />
        </>
    );
};