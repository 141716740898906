import React, { FC } from 'react';
import {
  Fab,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DictionaryTypesEnum, useGetFullDictionaryQuery } from '../../../../graphql/graphQlApiHooks';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { MeasurementUnitsUpsertDialog, measurementUnitsUpsertDialogActions } from './MeasurementUnitsUpsertDialog';
import { MeasurementUnitsTableRowMenu } from './MeasurementUnitsTableRowMenu';

const useMeasurementUnitsTableStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
}));

/**
 * Таблица настроек единиц измерения
 */
export const MeasurementUnitsTable: FC = () => {
  const classes = useMeasurementUnitsTableStyles();
  const { data: { dictionaries: measurementUnits = [] } = {} } = useGetFullDictionaryQuery({ variables: { dictionaryType: DictionaryTypesEnum.MeasurementUnit } });

  return (
    <>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='start'
        flexGrow={1}
        paddingX={4}
        paddingY={3}
        overflow='hidden'
        spacing={3}
      >
        <Typography variant='h1'>Единицы измерения</Typography>
        <TableContainer component={Paper} sx={{ width: 'auto' }} elevation={3}>
          <Table stickyHeader sx={{ width: 'auto' }} size='small'>
            <TableHead>
              <TableRow>
                <TableCell width={50}>№</TableCell>
                <TableCell sx={{minWidth: 190}}>Единица измерения</TableCell>
                <TableCell align='right'>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {measurementUnits.map((measurementUnit, index) => (
                <TableRow
                  key={measurementUnit.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell width={50}>{index + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {measurementUnit.name}
                  </TableCell>
                  <TableCell align='right'><MeasurementUnitsTableRowMenu {...measurementUnit} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Fab
        color='primary'
        className={classes.fab}
        onClick={measurementUnitsUpsertDialogActions.create}
      ><AddIcon /></Fab>
      <MeasurementUnitsUpsertDialog />
    </>
  );
};
