import React, {useMemo} from 'react';
import {useReactiveVar} from "@apollo/client";
import {sportObjectsVars} from "store/reactiveVarsStores/sport-objects/sportObjects";
import {
    EquipmentRequestsFilters,
    equipmentRequestsFiltersVar,
    useEquipmentRequestsFilters
} from "store/reactiveVarsStores/equipment-request/filtersVar";
import {EquipmentRequestStatusEnum, useGetAllDictionariesQuery} from "graphql/graphQlApiHooks";
import {Filter, FiltersToolbar} from "components/FiltersToolbar";
import Autocomplete from "components/FiltersToolbar/Autocomplete";
import Search from "../../../../../components/FiltersToolbar/Search";
import FilterDateRange from "../../../../../components/FiltersToolbar/DateRange";
import dayjs from "dayjs";
import {getLabelStatusRequest} from "../../lib/getLabelStatusRequest";
import {statusOptions} from "../../lib/statusOptions";

export const Toolbar = () => {
    const {
        filteredSportZoneList,
        filteredSportSubZoneList
    } = useReactiveVar(sportObjectsVars);
    const filterValues = useReactiveVar(equipmentRequestsFiltersVar);
    const {setFilters} = useEquipmentRequestsFilters()

    const {data: allDictionaries} = useGetAllDictionariesQuery();

    const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds;
    const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes;

    const filterChangeHandler = (filters: EquipmentRequestsFilters) => {
        setFilters({
            ...filterValues,
            ...filters
        } as EquipmentRequestsFilters);
    };


    function getAutocompleteLabel(arrayIds: any[], array: any[]) {
        const names = arrayIds ? array.filter((item) => arrayIds?.includes(item.id))
            .map((item) => `${item.name}`) : [];

        const result = names.length > 0 ? names.length > 2 ? names.slice(0, 2).join(", ") + " и ещё " + (names.length - 2) : names.join(", ") : [];

        return (result?.length === 0) ? `` : `${result}`;

    }


    const filters: Filter[] = useMemo(() => {

        const sportSubZonesOptions = filterValues?.sportZoneIds?.length > 0 ? [...filteredSportSubZoneList].filter(item =>
            filterValues?.sportZoneIds?.includes(item?.sportZone?.id)
        ) : filteredSportSubZoneList;


        const filtersList: Filter[] = [
            {
                name: "sportZoneIds",
                title: "Объекты спорта",
                value: filterValues?.sportZoneIds,
                options: filteredSportZoneList
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportObject?.name.localeCompare(a.sportObject?.name)) || [],
                disableDelete: true,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportObject?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sportZoneIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                label: filterValues?.sportZoneIds ? getAutocompleteLabel(filterValues.sportZoneIds as any, filteredSportZoneList) : "",
                type: "autocomplete"
            },
            {
                name: "sportSubZoneIds",
                title: "Спортзона",
                value: filterValues?.sportSubZoneIds,
                options: sportSubZonesOptions
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportZone?.name.localeCompare(a.sportZone?.name)) || [],
                disableDelete: true,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportZone?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sportSubZoneIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                label: filterValues?.sportSubZoneIds ? getAutocompleteLabel(filterValues.sportSubZoneIds as any, sportSubZonesOptions) : "",
                type: "autocomplete"
            },
            {
                name: "requestStatus",
                title: "Статус заявки",
                value: filterValues?.requestStatus,
                filterComponent: (props: any) => <Autocomplete
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"requestStatus": result.map(val => val.id) as (keyof typeof EquipmentRequestStatusEnum)[]});
                        }
                    }}
                    {...props}
                />,
                options: (Object.keys(EquipmentRequestStatusEnum) as Array<keyof typeof EquipmentRequestStatusEnum>)
                    .map((key) => ({
                        id: key,
                        name: getLabelStatusRequest(EquipmentRequestStatusEnum[key])
                    })),
                label: filterValues?.requestStatus ? getAutocompleteLabel(
                    filterValues.requestStatus as any,
                    statusOptions.map(({value, label}) => ({
                        id: value,
                        name: label
                    }))
                ) : "",
                type: "autocomplete"
            },
            {
                name: "name",
                title: "Название оборудования",
                value: filterValues?.name,
                filterComponent: (props: any) => <Search
                    filterResult={(value) => {
                        filterChangeHandler({"name": value} as EquipmentRequestsFilters);
                    }}
                    {...props}
                />,
                label: filterValues?.name ? ` содержит: "${filterValues.name}"` : "",
                type: "search"
            },
            {
                name: "sportKindIds",
                title: "Вид спорта",
                value: filterValues?.sportKindIds,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportCategory?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sportKindIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                options: sportKinds
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportCategory?.name.localeCompare(a.sportCategory?.name)) || [],
                label: filterValues?.sportKindIds && sportKinds ? getAutocompleteLabel(filterValues.sportKindIds as any, sportKinds) : "",
                type: "autocomplete"
            },
            {
                name: "equipmentTypeIds",
                title: "Тип оборудования",
                value: filterValues?.equipmentTypeIds,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportKind?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"equipmentTypeIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                options: equipmentTypes
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportKind?.name.localeCompare(a.sportKind?.name)) || [],
                label: filterValues?.equipmentTypeIds && equipmentTypes ? getAutocompleteLabel(filterValues.equipmentTypeIds as any, equipmentTypes) : "",
                type: "autocomplete"
            },
            {
                name: "applicationDate",
                title: "Дата заявки",
                value: filterValues.applicationDate,
                filterComponent: (props: any) => <FilterDateRange
                    filterResult={(value) => {
                        filterChangeHandler({"applicationDate": value} as EquipmentRequestsFilters);
                    }}
                    {...props}
                />,
                label: (() => (filterValues?.applicationDate && filterValues.applicationDate.hasOwnProperty("label")) ? `: ${(filterValues.applicationDate as any)?.label}` : ` с: ${dayjs(filterValues.applicationDate?.startDate).format("DD.MM.YYYY")} по: ${dayjs(filterValues.applicationDate?.endDate).format("DD.MM.YYYY")}`)(),
                type: "dateRange"
            },
            {
                name: "description",
                title: "Описание оборудования",
                value: filterValues?.description,
                filterComponent: (props: any) => <Search
                    filterResult={(value) => {
                        filterChangeHandler({"description": value} as EquipmentRequestsFilters);
                    }}
                    {...props}
                />,
                label: filterValues?.description ? ` содержит: "${filterValues.description}"` : "",
                type: "search"
            },


        ] as Filter[];

        return filtersList;
    }, [
        filterValues,
        filteredSportZoneList,
        filteredSportSubZoneList,
    ]);

    function deleteHandler(filter: Filter) {
        setFilters({
            ...filterValues,
            [filter.name]: null
        } as EquipmentRequestsFilters);
    }

    function clearFiltersHandler() {
        const cleanedFilters = [...filters].reduce((a, filter) => ({...a, [filter.name]: null}), {});

        setFilters({...cleanedFilters} as EquipmentRequestsFilters);
    }

    const onChangeFilterValue = (filterName: string, value: any) => {
        filterChangeHandler({[filterName]: value});
    };

    return (
        <>
            <FiltersToolbar
                filters={filters}
                onDelete={deleteHandler}
                clearFilters={clearFiltersHandler}
                onChangeFilterValue={onChangeFilterValue}
                placeholder={"Фильтры"}
            />
        </>
    );
};