import { makeVar, ReactiveVar } from "@apollo/client";
import { SortDirectionEnum } from "../spor-equipmnts/sort-direction.enum";
import {SortEquipmentRequestColumnsEnum} from "./sort-equipment-request-columns.enum";

export type EquipmentRequestListSortOrder = {
  [key in SortEquipmentRequestColumnsEnum as string]: SortDirectionEnum | null | undefined;
}

export const EquipmentRequestListSortOrderInitial: EquipmentRequestListSortOrder = {
  [SortEquipmentRequestColumnsEnum.RequestCreatedAt]: SortDirectionEnum.DESC
};

export const equipmentRequestListSortOrderVar = makeVar<EquipmentRequestListSortOrder>(
  EquipmentRequestListSortOrderInitial
);

export const useEquipmentRequestListSortOrderVar = () => createEquipmentRequestListSortOrderVar(equipmentRequestListSortOrderVar);

const createEquipmentRequestListSortOrderVar = (equipmentRequestListSortOrderVar: ReactiveVar<EquipmentRequestListSortOrder>) => {

  const setOrder = (orderModel: EquipmentRequestListSortOrder) => {
    equipmentRequestListSortOrderVar(orderModel);
  };

  return {
    setOrder
  };
};
