import React, {useEffect, useState, MouseEvent, useMemo} from 'react';
import {
	Button,
	Grid,
	InputLabel,
	FormControl,
	OutlinedInput,
	FormHelperText,
	ListItemText,
	Paper,
	ListItemIcon,
	List,
	ListItem,
	Box,
	Checkbox,
	ListSubheader,
	IconButton,
	Autocomplete,
	Divider,
	TextField,
	Popper, AutocompleteValue,
} from '@mui/material';
import Dialog from 'components/Dialogs/Dialog';
import {
	SportCategoryDictionaryEntity,
	SportKindDictionaryEntity,
	UpdateSportCategoryDictionaryInput,
} from 'graphql/graphQlApiHooks';
import {observer} from 'mobx-react-lite';
import {useStores} from 'store';
import AddIcon from '@mui/icons-material/Add';
import AddSportKind from './AddSportKind';
import {intersection, not, RightList} from './AddSportCategoryDialog';
import {useNavigate, useParams} from "react-router-dom";
import {AutocompleteChangeReason, autocompleteClasses} from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {styled} from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoDialog from "../../../../../components/Dialogs/InfoDialog";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
interface PopperComponentProps {
	anchorEl?: any;
	disablePortal?: boolean;
	open: boolean;
}
function PopperComponent(props: PopperComponentProps) {
	const {disablePortal, anchorEl, open, ...other} = props;
	return <StyledAutocompletePopper {...other} />;
}
const StyledAutocompletePopper = styled('div')(({theme}) => ({
	[`& .${autocompleteClasses.paper}`]: {
		boxShadow: 'none',
		margin: 0,
		color: 'inherit',
		fontSize: 13,
	},
	[`& .${autocompleteClasses.groupLabel}`]: {
		padding: theme.spacing(0, 1),
		display:'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		lineHeight: '40px',
		background: '#f8fafc',
		borderBottom: '1px solid #eaecef',
		top:0
	},
	[`& .${autocompleteClasses.listbox}`]: {
		backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
		padding: 0,
		[`& .${autocompleteClasses.option}`]: {
			minHeight: 'auto',
			alignItems: 'flex-start',
			padding: 8,
			borderBottom: `1px solid  ${
					theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
			}`,
			'&[aria-selected="true"]': {
				backgroundColor: 'transparent',
			},
			'&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	},
	[`&.${autocompleteClasses.popperDisablePortal}`]: {
		position: 'relative',
	},
}));
const EditSportCategoryDialog = observer(() => {
	const {sportCategoryId} = useParams();
	const navigate = useNavigate();

	const {
		dictionariesStore: {
			sportCategoryDictionary,
			updateSportCategoryDictionary,
		},
	} = useStores();


	const sportCategory = useMemo(() => {
		return sportCategoryDictionary?.find((item: SportCategoryDictionaryEntity) => item.id === sportCategoryId)
	}, [sportCategoryId, sportCategoryDictionary]);

	const [categoryName, setCategoryName] = useState<string>(
			sportCategory?.name || ''
	);

	const [left, setLeft] = useState<SportKindDictionaryEntity[]>([]);
	const [right, setRight] = useState<SportKindDictionaryEntity[]>([]);

	const [checked, setChecked] = useState<any>([]);
	const [openAddSportKind, setOpenAddSportKind] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const leftChecked = intersection({a: checked, b: left});
	const rightChecked = intersection({a: checked, b: right});

	useEffect(() => {
		if (sportCategory) {
			setCategoryName(sportCategory.name);
			setLeft(sportCategory.sportKinds.map((sportKind: SportKindDictionaryEntity) => ({
				...sportKind,
				groupName: 'Без категории',
			})));
		}
	}, [sportCategory, sportCategoryDictionary]);

	const onClose = () => {
		setCategoryName('');
		setLeft([]);
		setRight([]);
		navigate('/settings/dictionaries/', {replace:true})
		// props.onClose();
	};

	useEffect(() => {
		if (sportCategoryDictionary?.length && sportCategory) {
			const right = [...sportCategoryDictionary]
					.filter(item => item.id !== sportCategory.id)
					.map(sportCategory =>
							sportCategory?.sportKinds.map(
									(sportKind: SportKindDictionaryEntity) => ({
										...sportKind,
										groupName: sportCategory.name,
									})
							)
					)
					.flat()
					.sort((a: any, b: any) => {
						if (a.groupName === 'Без категории') return -1;
						return 1
					});

			setRight(right);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sportCategoryDictionary, sportCategory]);

	const handleToggle = (value: any) => () => {
		const currentIndex = [...checked].map(val => val.id).indexOf(value.id);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		setRight(leftChecked.concat(right));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(rightChecked.concat(left));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setLeft(left.concat(right));
		setRight([]);
	};

	const handleClickAddSportKindPanel = (
			event: MouseEvent<HTMLButtonElement>
	) => {
		//проверка на наличие изменений
		if (attachSportKindIds.length > 0 || detachSportKindIds.length > 0 || categoryName !== sportCategory?.name){
			setConfirmAddSportKindDialog(true)
		} else {
			setOpenAddSportKind(true);
		}
		setAnchorEl(event.currentTarget);
	};
	const handleCloseAddSportKindPanel = () => {
		setAnchorEl(null);
		setOpenAddSportKind(false);
	};

	const customList = ( items: SportKindDictionaryEntity[], emptyText?: string ) => (
			<Paper
					elevation={0}
					sx={{
						height: '100%',
						overflow: 'auto',
						border: '1px solid',
						borderColor: 'divider',
						display: 'flex',

						boxShadow: 'none',
						margin: 0,
						color: 'inherit',
						fontSize: 13,
					}}
			>
				<List
						dense
						component='div'
						role='list'
						sx={{height: '340px', width:'100% !important'}}
						subheader={
							<ListSubheader disableGutters
							               sx={{
											   px:1,
								               display:'flex',
								               alignItems: 'center',
								               justifyContent: 'space-between',
								               lineHeight: '40px',
								               background: '#f8fafc',
								               borderBottom: '1px solid #eaecef',
								               top:0
										   }}
							               component='div' id='nested-list-subheader'>
								Список видов спорта
								<IconButton
										aria-controls='customized-menu'
										aria-haspopup='true'
										size='small'
										onClick={handleClickAddSportKindPanel}
								>
									<AddIcon fontSize='inherit'/>
								</IconButton>
								<AddSportKind
										anchorEl={anchorEl}
										open={openAddSportKind}
										onClose={handleCloseAddSportKindPanel}
										sportCategoryId={sportCategory?.id as string}
								/>
							</ListSubheader>
						}
				>
					{items.length > 0 ? (
							items.map((value: SportKindDictionaryEntity, index: number) => {
								const labelId = `transfer-list-item-${value.id}-label`;

								return (
										<ListItem
												key={value.id}
												role='listitem'
												button
												onClick={handleToggle(value)}
												sx={{alignItems: 'start',p:1, borderBottom: '1px solid #eaecef'}}
												disableGutters
												disableRipple
										>
											<ListItemIcon sx={{minWidth: 'auto', mr: 1, }}>
												<Checkbox
														sx={{p: 0}}
														checked={
																[...checked].map(val => val.id).indexOf(value.id) !== -1
														}
														tabIndex={-1}
														disableRipple
														inputProps={{
															'aria-labelledby': labelId,
														}}
														size='small'
												/>
											</ListItemIcon>
											<ListItemText
													id={labelId}
													primary={value.name}
													disableTypography
													sx={{m:0}}
											/>
										</ListItem>
								);
							})
					) : emptyText ? (
							<Box
									p={2}
									px={4}
									display={'flex'}
									justifyContent={'center'}
									justifyItems={'center'}
									alignItems={'center'}
									alignContent={'center'}
									height={'calc(100% - 48px)'}
							>
								<FormHelperText sx={{textAlign: 'center'}}>
									{emptyText}
								</FormHelperText>
							</Box>
					) : null}
				</List>
			</Paper>
	);

	const attachSportKindIds: string[] = useMemo(() => {
		if (sportCategory) {
			return [...left]
					.map(val => val.id)
					.filter(
							item =>
									![...sportCategory.sportKinds].map(val => val.id).includes(item)
					);
		}
		return [];
	}, [left, sportCategory]);

	const detachSportKindIds: string[] = useMemo(() => {
		if (sportCategory) {
			return [...sportCategory.sportKinds]
					.map(val => val.id)
					.filter(item => ![...left].map(val => val.id).includes(item));
		}
		return [];
	}, [left, sportCategory]);

	const handleConfirm = () => {
		const input: UpdateSportCategoryDictionaryInput = {
			id: sportCategory?.id as string,
			name: categoryName,
			attachSportKindIds: attachSportKindIds,
			detachSportKindIds: detachSportKindIds,
		};

		updateSportCategoryDictionary(input).then(onClose);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: AutocompleteValue<{}, undefined, undefined, undefined>, reason: AutocompleteChangeReason) => {
		setChecked(newValue)
	};

	const [confirmAddSportKindDialog, setConfirmAddSportKindDialog] = useState(false);

	const handleConfirmAddSportKind = (confirm:boolean) =>{
		if (confirm) {
			const input: UpdateSportCategoryDictionaryInput = {
				id: sportCategory?.id as string,
				name: categoryName,
				attachSportKindIds: attachSportKindIds,
				detachSportKindIds: detachSportKindIds,
			};

			updateSportCategoryDictionary(input).then(()=>{
				setOpenAddSportKind(true);
			});
		}
		setConfirmAddSportKindDialog(false)

	}
	return ( <>
			<Dialog
					open={true}
					title={'Редактирование категории спорта'}
					actions={
						<Button
								variant={'contained'}
								onClick={handleConfirm}
								disabled={categoryName === '' || (categoryName === sportCategory?.name && attachSportKindIds.length === 0 && detachSportKindIds.length === 0)}
						>
							Обновить спортивную категорию
						</Button>
					}
					onClose={onClose}
					props={{
						maxWidth: 'md',
					}}
			>
				<Grid container spacing={2} width='100%'>
					<Grid
							item
							width='50%'
							alignItems='stretch'
							sx={{flexDirection: 'column'}}
							display={'flex'}
					>
						<FormControl fullWidth>
							<InputLabel
									htmlFor='categoryName'
									sx={{
										fontWeight: 400,
										marginBottom: 1,
										position: 'relative',
										transform: 'none',
									}}
							>
								Название категории
							</InputLabel>
							<OutlinedInput
									name={'categoryName'}
									id={'categoryName'}
									value={categoryName}
									onChange={({target: {value}}) => setCategoryName(value)}
									size={'small'}
									placeholder={'Единоборства'}
									fullWidth
							/>
							<FormHelperText>Укажите название категории спорта.</FormHelperText>
						</FormControl>

						<Grid container alignItems='stretch' direction='column' display={'flex'} height={'100%'} mt={2}
						>
							<Grid item sx={{flexDirection: 'column'}} display={'flex'} height={'100%'} >
								{customList(
										left,
										'Выберите виды спорта из списка справа, или добавьте новый вид спорта'
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item alignSelf='center'>
						<Grid container direction='column' alignItems='center'>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleAllRight}
									disabled={left.length === 0}
									aria-label='move all right'
							>
								≫
							</Button>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleCheckedRight}
									disabled={leftChecked.length === 0}
									aria-label='move selected right'
							>
								&gt;
							</Button>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleCheckedLeft}
									disabled={rightChecked.length === 0}
									aria-label='move selected left'
							>
								&lt;
							</Button>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleAllLeft}
									disabled={right.length === 0}
									aria-label='move all left'
							>
								≪
							</Button>
						</Grid>
					</Grid>
					<Grid item width='45%'>
						<Paper
								elevation={0}
								sx={{
									border: '1px solid',
									borderColor: 'divider',
								}}
						>
							<Autocomplete
									open
									disablePortal
									disableCloseOnSelect
									popupIcon={null}
									multiple
									renderTags={() => null}
									PopperComponent={PopperComponent}
									value={rightChecked}
									options={right}
									renderInput={params => (
											<Box sx={{m: 1, mb: 2}}>
												<TextField
														{...params}
														ref={params.InputProps.ref}
														inputProps={params.inputProps}
														autoFocus
														size={'small'}
														placeholder='Вид спорта'
												/>
												<Divider/>
											</Box>
									)}
									isOptionEqualToValue={(option, value) => option?.id === value?.id}

									noOptionsText="Вид спорта не найден"
									getOptionLabel={(option: any) => option.name || ''}
									groupBy={(option: any) => option.groupName}
									renderOption={(props, option, {selected, inputValue}) => {
										const matches = match(option.name, inputValue);
										const parts = parse(option.name, matches);
										return (
												<li {...props} id={option.id} style={{alignItems: 'start'}}>
													<Checkbox
															icon={icon}
															checkedIcon={checkedIcon}
															style={{marginRight: 8, padding: '0'}}
															checked={!!checked.find((val:any) => val.id === option.id)}
													/>
													<Box
															sx={{
																flexGrow: 1,
																'& span': {
																	color: '#586069',
																},
															}}
													>
														{parts.map((part: any, index: number) => (
																<span
																		key={index}
																		style={{
																			fontWeight: part.highlight ? 700 : 400,
																		}}
																>
                                                  {part.text}
                                                </span>
														))}
														<br/>
														<span>{option.address}</span>
													</Box>
												</li>
										);
									}}
									onChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Dialog>
		<InfoDialog
				open={confirmAddSportKindDialog}
				close={handleConfirmAddSportKind}
				title={'Данные спортивной категории были изменены'}
				message={'Вы изменили некоторые значения в этой категории, перед добавлением нового вида спорта нужно сохранить изменнения.' +
						' Хотите сохранить и продолжить?'}
		/>
	</>);
});

export default EditSportCategoryDialog;
