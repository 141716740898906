import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { FC, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { measurementUnitsUpsertDialogActions } from './MeasurementUnitsUpsertDialog';
import {
  DictionaryTypesEnum,
  GetFullDictionaryDocument,
  useDeleteDictionaryValueMutation,
} from '../../../../graphql/graphQlApiHooks';
import DeleteDialog from '../../../../components/Dialogs/DeleteDialog';

/**
 * Тип пропсов для компонента
 */
type MeasurementUnitsTableRowMenuProps = Parameters<typeof measurementUnitsUpsertDialogActions.edit>[0];

/**
 * Меню действий для строки таблицы единиц измерения
 */
export const MeasurementUnitsTableRowMenu: FC<MeasurementUnitsTableRowMenuProps> = (row) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [deleteMeasurementUnit] = useDeleteDictionaryValueMutation({
    variables: { deleteDictionaryValueId: row.id },
    refetchQueries: [{
      query: GetFullDictionaryDocument,
      variables: { dictionaryType: DictionaryTypesEnum.MeasurementUnit },
    }],
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title='Действия'>
        <IconButton
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        <MenuItem onClick={() => {
          setAnchorEl(undefined);
          measurementUnitsUpsertDialogActions.edit(row);
        }}>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText>Изменить</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
          <ListItemIcon><DeleteForeverIcon /></ListItemIcon>
          <ListItemText>Удалить</ListItemText>
        </MenuItem>
      </Menu>
      <DeleteDialog
        title={'Удаление единицы измерения'}
        open={isDeleteDialogOpen}
        close={(value) => setIsDeleteDialogOpen(value)}
        confirm={() => deleteMeasurementUnit()}
      />
    </>
  );
};
