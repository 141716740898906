
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import {Theme} from "@mui/material";
import { keyframes } from '@mui/system';

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export default function MuiDataGrid(theme:Theme) {
  return {
    MuiDataGrid:{
      styleOverrides: {
        root:{
          border: 'none',
          backgroundColor: theme.palette.background.paper,
        },
        main:{
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
        },
        toolbarContainer:{
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
          padding: theme.spacing(.5, 3),
        },
        columnHeadersInner:{
          boxShadow: `inset 0px -1px 0 ${theme.palette.divider}`
        },
        virtualScroller:{
          // overflowY: 'scroll',
          // overflowX: 'auto',
          // overflow: 'initial',
        },
        virtualScrollerRenderZone:{
          width: '100%'
        },
        row:{
          width: 'auto',
          opacity: 0,
          animation: `${fade} .2s normal forwards ease-in-out`,
          transition: 'all .3s',
          '& *:not(.iconify, .MuiChip-icon, .MuiSvgIcon-root)':{
            fontSize: 14,
          },
        },
        cell:{
          MsUserSelect: 'none',
          MozUserSelect: 'none',
          KhtmlUserSelect: 'none',
          WebkitUserSelect: 'none',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          overflow: 'hidden',
          borderColor: theme.palette.divider,
          '&:focus, &:focus-within': {
            outline: 'none',
          },
          '&:first-of-type': {
            paddingLeft: '24px',
            minWidth: '60px !important',
            '& .MuiCheckbox-root':{
              marginLeft: '0 !important',
              '& svg' : {
                fontSize: '18px !important'
              }
            }
          },
          '&:last-of-type': {
            paddingRight: '24px'
          },
        },
        iconSeparator:{
          color: theme.palette.divider,
        },
        columnHeader:{
          MsUserSelect: 'none',
          MozUserSelect: 'none',
          KhtmlUserSelect: 'none',
          WebkitUserSelect: 'none',
          '&:focus, &:focus-within': {
            outline: 'none',
          },
          '&:first-of-type': {
            paddingLeft: '24px',
            minWidth: '60px !important',
            '& .MuiCheckbox-root':{
              '& svg' : {
                fontSize: '18px !important'
              }
            }
          },
          '&:last-of-type': {
            paddingRight: '24px',
            '& .MuiDataGrid-columnSeparator': {
              display: 'none'
            }
          },
        }
      }
    },
  }
}
