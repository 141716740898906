import React, {useEffect, useMemo} from 'react';
import {
    Avatar,
    Backdrop,
    Box,
    Button,
    ClickAwayListener,
    ListItemButton,
    ListItemSecondaryAction,
    ListSubheader,
    Slide,
    TextField
} from '@mui/material';
import {lighten, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import DoneIcon from "@mui/icons-material/Done";
import EditSportObject from "../../Dialogs/EditSportObject";
import AddSportObject from "../../Dialogs/AddSportObject";
import {useTheme} from "@mui/styles";
import {useStores} from '../../../../../../store';
import {Observer} from 'mobx-react-lite';
import {SportObjectEntity} from "../../../../../../graphql/graphQlApiHooks";
import {darken} from "@material-ui/core";
import InfoDialog from "components/Dialogs/InfoDialog";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& $editObject, $addObject": {
            padding: '0 14px',
            '& .edit-avatar, .add-avatar': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '1em',
                '& .MuiAvatar-root': {
                    background: '#747bff'
                }
            },
            '& .input-wrapper': {
                '& .MuiFormControl-root': {
                    marginBottom: '.6em',
                },
                "& .MuiFilledInput-root": {
                    borderRadius: theme.shape.borderRadius
                }
            },

        }
    },
    sportObjectsListContent: {
        height: 'calc(100% - 60px)',
        overflow: 'auto',
        position: 'absolute',
        width: '100%',
        left: 0,
        padding: '8px 16px',
        '& .MuiListItemButton-root': {
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0.5, 1),
            '&.Mui-selected': {
                backgroundColor: darken(theme.palette.primary.main, .1)
            },
            '& .MuiListItemIcon-root': {
                marginRight: '.6em',
                color: 'inherit',
                minWidth: 'auto',
                '& .MuiSvgIcon-root': {
                    fontSize: '1em',

                }
            },
        }
    },
    backdrop: {
        zIndex: 1200,
    },
    searchInput: {
        "& .MuiOutlinedInput-root": {
            background: 'white'
        }
    },

    list: {
        maxWidth: 360,
        borderRadius: theme.shape.borderRadius,
        '& .MuiListSubheader-root': {
            letterSpacing: '0.05em',
            color: '#2f2f33',
            fontSize: 14,
            lineHeight: '14px',
            fontWeight: 500,
            padding: '0 0 20px 0',
        },
        '& .MuiListItem-root': {
            padding: theme.spacing(0),
            transition: 'height .3s',
            '& .MuiList-root': {
                padding: 0,
                width: '100%',
                '& .MuiButtonBase-root': {
                    marginTop: '.5em'
                }
            },
            '&:hover': {
                // backgroundColor: lighten('#f0f4f9', 0.25),
            },
            '& .MuiListItemText-primary': {
                fontWeight: 400,
                fontSize: '14px',
            },
            '&.Mui-selected': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.default,
                '&:hover': {
                    backgroundColor: lighten(theme.palette.background.default, 0.65),
                },
                '& $icon': {
                    color: 'inherit'
                }
            },
        }
    },
    icon: {
        color: theme.palette.text.secondary,
        minWidth: 46
    },

    sportObjectsList: {
        flexShrink: 0,
        borderRadius: 8,
        padding: '0',
        position: 'relative',
        height: 100,
        '& .MuiListSubheader-root': {
            padding: '16px 14px !important',
            color: 'inherit',
            userSelect: 'none',
            backgroundColor: 'transparent',
            '& .MuiListItemSecondaryAction-root': {
                right: 8
            }
        },
        '& .MuiListItem-root': {
            userSelect: 'none',
            '& .centered': {
                textAlign: 'center'
            },
            '& .MuiListItemSecondaryAction-root': {
                right: 10
            }
        },
        '& $icon': {
            marginLeft: '14px',
        },
        '& $list': {
            transition: 'all .3s',
            backgroundColor: '#4851f9',
            color: theme.palette.background.paper,
            height: 100,
            overflow: 'hidden',
            padding: 0,
            zIndex: 1,
            '&:not(.isOpen):hover': {
                // cursor: 'pointer'
            },
            '& .MuiAutocomplete-popper': {
                width: '100% !important',
                height: 'calc(100% - 56px)',
                '& .MuiAutocomplete-listbox': {
                    height: '100%',
                }
            },
            '&.isOpen': {
                boxShadow: '-1px 2px 16px rgb(0 0 0 / 20%)',
                zIndex: 100000,
                height: '400px',
                '& $objectsBottomPanel': {height: 'auto', opacity: 1},
                '& $objectsList': {
                    height: 'calc(100% - 40px)'
                },
            },
        }
    },

    objectsListContent: {
        height: 'calc(100% - 50px)', // размер заголовка
    },
    objectsBottomPanel: {
        opacity: 0,
        overflow: 'hidden',
        height: 0,
        padding: '0 8px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        transition: 'opacity .3s',
        '& .MuiButton-root': {
            fontSize: 12,
            background: 'white',
            padding: '4px 8px'
        }
    },
    listWrapper: {
        position: 'relative',
        display: 'flex',
        height: 'calc(100% - 36px)', // Размер подвала
        '& $listItem': {
            minWidth: '100%',
            maxWidth: '100%',
            position: 'absolute',
            height: '100%',
        },
        '& .MuiListItem-root .MuiListItemText-root .MuiTypography-root':{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '88%'
        }

    },
    listItem: {
        '& .MuiTypography-root': {
            color: theme.palette.text.contrast
        }

    },

    objectsList: {
        overflow: 'hidden',
        width: '100%',
        transition: 'all .1s',
        zIndex: 100000,
        height: '0',
    },
    deleteConfirm: {
        display: 'flex',
        alignItems: 'center',
        padding: 14,
        '& .MuiBox-root': {
            padding: '0 14px',
            background: 'white',
            borderRadius: theme.shape.borderRadius,
            border: '1px solid rgba(255, 37, 47, 0.1)',
        },
        '& h4': {
            fontSize: 14,
            borderBottom: '1px solid rgba(255, 37, 47, 0.1)',
            padding: '14px 0',
            color: theme.palette.secondary.main
        },
        '& p': {
            padding: '14px 0',
            fontSize: 14,
            color: theme.palette.text.primary
        },
    },
    objectsSearchPanel: {
        height: '100%',
    },
    editObject: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    addObject: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        // borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `rgba(${theme.palette.primary.main}, .25) 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
        background: 'transparent',
        height: '100%',

    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: lighten(theme.palette.primary.main, .85),
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            }
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
}));


function SportObjectsList() {
    const theme = useTheme();
    const classes = useStyles();
    const {settingsStore} = useStores();

    const [isOpenObjectsList, setIsOpenObjectsList] = React.useState(false);
    const [objectsSearchPanelVisible, setObjectsSearchPanelVisible] = React.useState(true);
    const [objectsDeleteConfirmVisible, setObjectsDeleteConfirmVisible] = React.useState(false);
    const [objectsEditConfirmVisible, setObjectsEditConfirmVisible] = React.useState(false);
    const [objectsEditConfirmDirection, setObjectsEditConfirmDirection] = React.useState<"left" | "right" | "up" | "down" | undefined>("left");
    const [objectsAddConfirmVisible, setObjectsAddConfirmVisible] = React.useState(false);
    const [sportObjects, setSportObjects] = React.useState<SportObjectEntity[]>([])
    const [searchValue, setSearchValue] = React.useState<string>('')
    const [editObjectFormOpen, setEditObjectFormOpen] = React.useState(false);
    const [deleteErrorDialog, setDeleteErrorDialog] = React.useState(false);


    useEffect(() => {
        setSportObjects(([...settingsStore.sportObjects].filter(obj => {
            return obj.name.toLowerCase().includes(searchValue.toLowerCase())
        })))
    }, [settingsStore.sportObjects, settingsStore.sportObject, searchValue])

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        sportObjectId: string,
    ) => {
        settingsStore.setSportObject(sportObjectId)
        setSportObjects(([...settingsStore.sportObjects].filter(obj => {
            return obj.name.toLowerCase().includes(searchValue.toLowerCase())
        })))
    };


    const deleteCurrentObject = () => {
        if (settingsStore.sportObject) {
            if (!settingsStore.hasEquipments()) {
                settingsStore.sportObjectDelete(settingsStore.sportObject.id);
                setSportObjects([...sportObjects].filter(({id}) => settingsStore.sportObject.id !== id));
                setSearchValue('')
            } else {
                setDeleteErrorDialog(true)
            }
            showSearchPanelSlide()
        }
    };

    const openListHandler = () => {
        setIsOpenObjectsList(!isOpenObjectsList)
        setObjectsSearchPanelVisible(true)
        setObjectsDeleteConfirmVisible(false)
        setObjectsEditConfirmVisible(false)
        setObjectsAddConfirmVisible(false)
    };
    const closeListHandler = () => {
        setIsOpenObjectsList(false)
        setObjectsSearchPanelVisible(true)
        setObjectsDeleteConfirmVisible(false)
        setObjectsEditConfirmVisible(false)
        setObjectsAddConfirmVisible(false)
    };

    const showSearchPanelSlide = () => {
        setEditObjectFormOpen(false)
        setObjectsAddConfirmVisible(false)
        setObjectsEditConfirmVisible(false)
        setObjectsDeleteConfirmVisible(false)
        setObjectsSearchPanelVisible(true)
    };
    const showDeleteConfirmSlide = () => {
        setObjectsEditConfirmDirection('right')
        setObjectsDeleteConfirmVisible(objectsEditConfirmVisible)
        setObjectsEditConfirmVisible(!objectsEditConfirmVisible)
    };
    const showEditSportObjectSlide = () => {
        setEditObjectFormOpen(true)
        setObjectsEditConfirmVisible(objectsSearchPanelVisible)
        setObjectsEditConfirmDirection('left')
        setObjectsSearchPanelVisible(!objectsSearchPanelVisible)
    };
    const showCreateSportObjectSlide = () => {
        setObjectsAddConfirmVisible(objectsSearchPanelVisible)
        setObjectsSearchPanelVisible(!objectsSearchPanelVisible)
    };

    const BottomPanel = () => {
        if (objectsDeleteConfirmVisible) {
            return (
                <>
                    <Box>
                        <Button
                            size={'small'}
                            color='primary'
                            onClick={showDeleteConfirmSlide}
                        >Отмена</Button>
                    </Box>
                    <Button
                        size={'small'}
                        color='secondary'
                        onClick={deleteCurrentObject}
                    >Удалить</Button>
                </>
            );
        }
        if (objectsEditConfirmVisible) {
            return (
                <>
                    <Box>
                        <Button
                            size={'small'}
                            color={"error"}
                            onClick={showEditSportObjectSlide}
                        >Отмена</Button>
                    </Box>
                    <Button
                        size={'small'}
                        color='primary'
                        type='submit'
                        form="edit_object"
                    >Сохранить</Button>
                </>
            );
        }
        if (objectsAddConfirmVisible) {
            return (
                <>
                    <Box>
                        <Button
                            size={'small'}
                            onClick={showCreateSportObjectSlide}
                        >Отмена</Button>
                    </Box>
                    <Button
                        size={'small'}
                        color='primary'
                        type='submit'
                        form='add_object'
                    >Добавить</Button>
                </>
            );
        }

        return (
            <>
                <Box>
                    <Button
                        size={'small'}
                        onClick={showEditSportObjectSlide}
                    >Изменить</Button>
                </Box>
                <Button
                    size={'small'}
                    onClick={showCreateSportObjectSlide}
                >Добавить</Button>
            </>
        );
    };


    useMemo(() => {
        if (settingsStore.sportObjects[0]) {
            showSearchPanelSlide()
        } else {
            if (isOpenObjectsList) {
                showCreateSportObjectSlide()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsStore.sportObjects, isOpenObjectsList])

    return <Observer>{() =>
        <>
            <Box className={clsx(classes.sportObjectsList, classes.root)}>

                <ClickAwayListener onClickAway={closeListHandler}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={isOpenObjectsList ? 'isOpen ' + classes.list : classes.list}

                        subheader={
                            <ListSubheader
                                onClick={openListHandler}
                                component="div"
                                id="nested-list-subheader"
                            >
                                Спортобъекты

                                <ListItemSecondaryAction onClick={openListHandler}>
                                    <UnfoldMoreIcon fontSize={'small'}/>
                                </ListItemSecondaryAction>
                            </ListSubheader>
                        }
                    >
                        {settingsStore.sportObjects &&

                        <div className={classes.objectsListContent}>
                            <div className={classes.listWrapper}>
                                <Slide
                                    direction={'right'}
                                    in={objectsSearchPanelVisible}
                                    appear={false}
                                >
                                    <div className={classes.listItem}>
                                        {settingsStore.sportObject ? (
                                            <ListItem onClick={openListHandler}>
                                                <ListItemIcon classes={{root: classes.icon}}>
                                                    <Avatar
                                                        sx={{
                                                            background: '#747bff',
                                                            width: 32,
                                                            height: 32
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={settingsStore.sportObjects.find((obj: SportObjectEntity) => {
                                                        return obj.id === settingsStore.sportObject.id
                                                    }).name}
                                                />
                                            </ListItem>) : (
                                            <Button
                                                onClick={() => {
                                                    openListHandler()
                                                    showCreateSportObjectSlide()
                                                }}
                                                color={'info'}
                                                sx={{
                                                    display: 'flex',
                                                    m: 'auto',
                                                    bgcolor: theme.palette.background.default,
                                                    "&:hover": {
                                                        bgcolor: theme.palette.background.paper,
                                                    }
                                                }}
                                            >
                                                Добавьте спортобъект
                                            </Button>
                                        )
                                        }
                                        <List
                                            disablePadding
                                            className={
                                                clsx(
                                                    classes.objectsList,
                                                    objectsSearchPanelVisible ? 'visible' : null
                                                )
                                            }
                                        >
                                            <div
                                                className={
                                                    clsx(
                                                        classes.objectsSearchPanel,
                                                        objectsSearchPanelVisible ? 'visible' : null
                                                    )}
                                            >

                                                <Box
                                                    sx={{p: 2}}
                                                >
                                                    <TextField
                                                        type={'search'}
                                                        id="search-sport-object"
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        size={'small'}
                                                        value={searchValue}
                                                        className={classes.searchInput}
                                                        onChange={(event: { target: { value: string } }) => {
                                                            setSearchValue(event.target.value)
                                                            setSportObjects([...settingsStore.sportObjects].filter(obj => {
                                                                return obj.name.toLowerCase().includes(event.target.value.toLowerCase())
                                                            }))
                                                        }}
                                                    />

                                                    <List
                                                        component="nav"
                                                        className={classes.sportObjectsListContent}
                                                    >
                                                        {sportObjects.map((sportObject: SportObjectEntity, index: number) => {
                                                            return (
                                                                <ListItemButton
                                                                    key={index}
                                                                    selected={sportObject.id === settingsStore?.sportObject?.id}
                                                                    onClick={(event) => handleListItemClick(event, sportObject.id)}
                                                                >
                                                                    <ListItemIcon>
                                                                        <DoneIcon style={{visibility: (sportObject.id === settingsStore?.sportObject?.id) ? 'visible' : 'hidden'}}/>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={sportObject.name}/>
                                                                </ListItemButton>
                                                            )
                                                        })}


                                                    </List>


                                                </Box>
                                            </div>

                                        </List>
                                    </div>
                                </Slide>
                                {settingsStore.sportObject &&
                                <>
                                    <Slide
                                        direction={'left'}
                                        in={objectsDeleteConfirmVisible}
                                    >
                                        <div
                                            className={
                                                clsx(
                                                    classes.deleteConfirm,
                                                    objectsDeleteConfirmVisible ? 'visible' : null,
                                                    classes.listItem
                                                )}
                                        >
                                            <Box>
                                                <h4>Вы уверены, что хотите удалить {settingsStore.sportObject.name}?</h4>
                                                <p>Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку
                                                   ниже.</p>
                                            </Box>

                                        </div>
                                    </Slide>
                                    <Slide
                                        direction={objectsEditConfirmDirection}
                                        in={objectsEditConfirmVisible}
                                    >
                                        <div
                                            className={
                                                clsx(
                                                    classes.editObject,
                                                    objectsEditConfirmVisible ? 'visible' : null,
                                                    classes.listItem
                                                )}
                                        >
                                            <EditSportObject
                                                open={editObjectFormOpen}
                                                deleteOnClicked={showDeleteConfirmSlide}
                                                onCompleted={showSearchPanelSlide}
                                            />
                                        </div>
                                    </Slide>

                                </>
                                }
                                <Slide
                                    direction={'left'}
                                    in={objectsAddConfirmVisible}
                                >
                                    <div
                                        className={
                                            clsx(
                                                classes.addObject,
                                                objectsAddConfirmVisible ? 'visible' : null,
                                                classes.listItem
                                            )}
                                    >
                                        <AddSportObject
                                            onAdded={() => {
                                                showSearchPanelSlide();
                                            }}
                                        />
                                    </div>
                                </Slide>
                            </div>
                            <div className={classes.objectsBottomPanel}>
                                <BottomPanel/>
                            </div>
                        </div>
                        }
                    </List>
                </ClickAwayListener>

                <Backdrop
                    open={isOpenObjectsList}
                    className={classes.backdrop}
                />
            </Box>

            <InfoDialog
                open={deleteErrorDialog}
                title={'Невозможно удалить спортобъект'}
                message={"Этот спортобъект содержит оборудование. Сначала удалите или переместите оборудование."}
                close={setDeleteErrorDialog}
            />

        </>
    }</Observer>
}

SportObjectsList.protoTypes = {}

export default SportObjectsList;
