import {Box, Chip, Palette} from "@mui/material";
import React from "react";
import Tooltiper from "components/Tooltiper";
import {EquipmentRelocationRequestStatusEnum} from "store/stores/equipment-relocation-request-status.enum";
import CircleIcon from "@mui/icons-material/Circle";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {useTheme} from "@mui/styles";

export const getRelocationRequestStatusLabel = (status: EquipmentRelocationRequestStatusEnum) => {
    switch (status) {
        case EquipmentRelocationRequestStatusEnum.RequestSubmitted:
            return 'Подана';
        case EquipmentRelocationRequestStatusEnum.AcceptedBySportObject:
            return 'На рассмотрении';
        case EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment:
            return 'Ожидает отправления';
        case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
            return 'Отклонена';
        case EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment:
            return 'Отправлена в транспортную службу';
        case EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment:
            return 'Отклонена отделом приемки';
        case EquipmentRelocationRequestStatusEnum.RelocationStarted:
            return 'В пути';
        case EquipmentRelocationRequestStatusEnum.Relocated:
            return 'Исполнена';
        default:
            return 'Неизвестный статус';
    }
};


const getIcon = (status: EquipmentRelocationRequestStatusEnum) => {
    switch (status) {
        case EquipmentRelocationRequestStatusEnum.RelocationStarted:
            return <Box color={'inherit !important'} sx={{display: "flex",}}><LocalShippingOutlinedIcon color={'inherit'} fontSize={"small"}/></Box>;
        case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
            return <Box color={'inherit !important'} sx={{display: "flex",}}><AccessTimeOutlinedIcon fontSize={"small"} color={'inherit'}  /></Box>;
        case EquipmentRelocationRequestStatusEnum.Relocated:
            return <Box color={'inherit !important'} sx={{display: "flex",}}><DoneAllIcon fontSize={"small"} color={'inherit'}/></Box>;
        default:
            return <Box color={'inherit !important'} sx={{display: "flex", width: '20px'}}>
                <CircleIcon fontSize={"small"} sx={{transform: "scale(.4)"}} color={'inherit'} />
            </Box>;
    }
};

function getBackgroundColor(status: EquipmentRelocationRequestStatusEnum, palette: Palette) {
    switch (status) {
        case EquipmentRelocationRequestStatusEnum.RequestSubmitted:
            return '#d2eefe';
        case EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment:
            return '#d2eefe';
        case EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment:
            return '#def1dd';
        case EquipmentRelocationRequestStatusEnum.RelocationStarted:
            return '#def1dd';
        case EquipmentRelocationRequestStatusEnum.AcceptedBySportObject:
            return '#fdf7e7';
        case EquipmentRelocationRequestStatusEnum.Relocated:
            return palette.primary.main;
        case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
            return '#ffd7d7';
        case EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment:
            return '#ffd7d7';
        default:
            return '#e0e0e0';
    }
}

function getColor(status: EquipmentRelocationRequestStatusEnum, palette: Palette) {
    switch (status) {
        case EquipmentRelocationRequestStatusEnum.RequestSubmitted:
            return '#01b1ff';
        case EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment:
            return '#01b1ff';
        case EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment:
            return '#2ca42c';
        case EquipmentRelocationRequestStatusEnum.RelocationStarted:
            return '#4caf50';
        case EquipmentRelocationRequestStatusEnum.AcceptedBySportObject:
            return '#ce6801';
        case EquipmentRelocationRequestStatusEnum.Relocated:
            return 'white';
        case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
            return '#f73333';
        case EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment:
            return '#f73333';
        default:
            return '#595959';
    }

}

export const TagStatusRelocationRequest = ({status}: {
    status: EquipmentRelocationRequestStatusEnum
}) => {
    const {palette} = useTheme();
    return <Chip
        icon={getIcon(status)}
        label={
            <Tooltiper
                text={getRelocationRequestStatusLabel(status)}
            />
        }
        size="small"
        sx={{
            backgroundColor: getBackgroundColor(status, palette),
            color: getColor(status, palette),
        }}
    />;
};