import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button, Popover, TextField} from '@mui/material';
import {darken, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import {useStores} from '../../../../../store';
import {
	EquipmentSpecificationDictionaryEntity,
	SportKindDictionaryEntity,
} from "../../../../../graphql/graphQlApiHooks";


const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	sportZoneCreatePanel: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		width: 240,
		'& .MuiFilledInput-root': {
			background: darken(theme.palette.background.default, 0.05),
			'&:before': {
				borderColor: theme.palette.divider
			},
			'&:hover, &.Mui-focused': {
				background: darken(theme.palette.background.default, 0.01)
			}
		}
	},
	sportZoneCreatePanelInput: {},
	sportZoneCreatePanelBottomPanel: {
		display: 'none',
		'&.showPanel': {
			display: 'flex',
			justifyContent: 'space-between'
		}
	}
}));

function AddSpecification({...rest}) {
	const classes = useStyles();
	const {dictionariesStore} = useStores();
	const inputName = useRef<HTMLInputElement | null>(null)
	const [showPanel, setShowPanel] = useState<boolean>(false)

	const [inputNameValue, setInputNameValue] = useState('')
	const [nameExistsError, setNameExistsError] = useState<string | null>(null);

	const addSpecification = (e:any) => {
		e.preventDefault()
		if (inputNameValue !== '') {
			dictionariesStore.createEquipmentSpecificationDictionary({
				name:inputNameValue,
				equipmentTypeId: rest.equipmentType.id
			}).finally(onClose)
		} else {
			setNameExistsError('Вы не указали название')
		}
	};

	function onClose() {
		setInputNameValue('')
		rest.onClose()
	}

	useMemo(() => {
		setShowPanel(inputNameValue.length > 0)
	}, [inputNameValue])

	useEffect(() => {
		// inputName.current?.focus()
	}, [rest.anchorEl, inputName])

	return (
			<>
				<Popover
						anchorEl={rest.anchorEl}
						onClose={onClose}
						// getContentAnchorEl={null}
						open={rest.open}

						className={classes.sportZoneCreatePanel}

						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}

						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
				>

					<form
							autoComplete="off"
							className={classes.sportZoneCreatePanel}
							onSubmit={(e)=>addSpecification(e)}
							name={'addSpecification'}
							id={'addSpecification'}
					>
						<TextField
								autoFocus
								onChange={(e) => {
									nameExistsError && setNameExistsError(null)
									setInputNameValue(e.target.value)
								}}
								value={inputNameValue}
								inputRef={inputName}
								id="name"
								label="Название"
								variant="filled"
								size={'small'}
								autoComplete={'off'}
								helperText={nameExistsError}
								error={!!nameExistsError}
						/>
					</form>
					<Box
							className={clsx(classes.sportZoneCreatePanelBottomPanel, showPanel ? 'showPanel' : null)}
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								p: 1
							}}
					>
						<Button onClick={onClose}
						        size={'small'}
						        color={'secondary'}
						        sx={{
							        py: .5,
							        px: 2,
						        }}
						>Отмена</Button>
						<Button
								form="addSpecification"
								type={'submit'}
								color="primary"
								variant={'contained'}
								size={'small'}
								sx={{
									py: .5,
									px: 2,
								}}

						>Добавить</Button>
					</Box>

				</Popover>
			</>
	)
}

AddSpecification.protoTypes = {}

export default AddSpecification;
