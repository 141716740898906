import dayjs from "dayjs";

export const declOfNum = (number:number, words:string[]) => {
  return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

export const declDays = (number:number) => {
  return `${number} ${declOfNum(number, ['день', 'дня', 'дней'])}`
}

export const declHours = (number:number) => {
  return `${number} ${declOfNum(number, ['час', 'часа', 'часов'])}`
}
export const getRentRangeText = (startRentDate:string, endRentDate:string) => {
  const rentRange = () => {
    let hours = dayjs(endRentDate).diff(startRentDate, 'hours');
    const days = Math.floor(hours / 24);
    hours = hours - (days * 24);

    return {days: days, hours: hours}
  }

  const isDaysAboveZero = (rentRange()?.days > 0)
  const isHoursAboveZero = (rentRange()?.hours > 0)
  let res = (isDaysAboveZero || isHoursAboveZero) ? ' - ' : ''
  if (isDaysAboveZero) res = res + declDays(rentRange().days)
  if (isDaysAboveZero && isHoursAboveZero) res = res + ', '
  if (isHoursAboveZero) res = res + declHours(rentRange().hours)
  return res
};
