import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Box, TextField} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {DateRange} from '@mui/lab/DateRangePicker';
import {CalendarPicker, StaticDateRangePicker} from "@mui/lab";

const FilterDateRange = observer((props: {
    value: DateRange<Date>,
    filterResult: (result: DateRange<Date>) => any,
    onClose?: () => void
}) => {
    const [value, setValue] = useState<DateRange<any>>([null,null]);
    const {filterResult} = props;
    const form = useRef<any>(null);

    useEffect(() => {
        if (props?.value) {
            setValue(props.value)
        } else {
            setValue([null,null])
        }
    }, [props]);

    return (
        <>
            <Box
                ref={form}
                sx={{
	                backgroundColor: 'background.paper'
                }}
            >
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ruLocale}
                >
                    <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        calendars={1}
                        disableMaskedInput={true}
                        value={value}
                        onChange={filterResult}
                        renderInput={(params:any) => <TextField {...params} size={'small'}/>}
                    />
                </LocalizationProvider>
            </Box>
        </>
    )
})

export default FilterDateRange;
