import {EquipmentRequestStatusEnum} from "graphql/graphQlApiHooks";
import {alpha, Theme} from "@mui/material/styles";

export const getStatusColor = (requestStatus: EquipmentRequestStatusEnum, theme: Theme) => {
    switch (requestStatus) {
        case EquipmentRequestStatusEnum.Submitted: // На рассмотрении
        case EquipmentRequestStatusEnum.Pending: // На согласовании
            return {
                backgroundColor: '#fdf7e7',
                color: '#ce6801'
            };
        case EquipmentRequestStatusEnum.Success: // Ожидает отправления
        case EquipmentRequestStatusEnum.LotFormation: // Формирование лота
        case EquipmentRequestStatusEnum.Acceptance: // Приемка
        case EquipmentRequestStatusEnum.Auction: // Торги
            return {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                color: theme.palette.primary.main
            };
        case EquipmentRequestStatusEnum.PassedToAno: // Передана в АНО
        case EquipmentRequestStatusEnum.PassedToContractualService: // Передана в контрактную службу
        case EquipmentRequestStatusEnum.ContractingServiceAndPlanningAndEconomicDepartmentApproval: // На согласование в Контрактной службе и в Планово-экономическом отделе
        case EquipmentRequestStatusEnum.DocumentPackageFormation: // Формирование пакета документов
            return {
                backgroundColor: alpha(theme.palette.info.main, 0.1),
                color: theme.palette.info.main
            };
        case EquipmentRequestStatusEnum.Supplied: // Оборудование поставлено
        case EquipmentRequestStatusEnum.EquipmentAccepted: // Оборудование принято
            return {
                backgroundColor: alpha(theme.palette.success.main, 0.1),
                color: theme.palette.success.main
            }
        case EquipmentRequestStatusEnum.Rejected: // Заявка отклонена
            return {
                backgroundColor: '#ffd7d7',
                color: '#f73333'
            };
        default:
            return {
                backgroundColor: '#d2eefe',
                color: '#01b1ff'
            };
    }
};
//   Acceptance = 'Acceptance', //Приемка
//   Auction = 'Auction', //Торги
//   ContractingServiceAndPlanningAndEconomicDepartmentApproval = 'ContractingServiceAndPlanningAndEconomicDepartmentApproval', //Согласование в Контрактной службе и в Планово-экономическом отделе
//   DocumentPackageFormation = 'DocumentPackageFormation', //Формирование пакета документов
//   EquipmentAccepted = 'EquipmentAccepted', //Оборудование принято
//   LotFormation = 'LotFormation', //Формирование лота
//   PassedToAno = 'PassedToANO', //Передана в АНО
//   PassedToContractualService = 'PassedToContractualService', //Передана в контрактную службу
//   Pending = 'Pending', // На согласовании
//   Rejected = 'Rejected', // Отклонена
//   Submitted = 'Submitted', // На рассмотрении
//   Success = 'Success',  // Ожидает отправления
//   Supplied = 'Supplied' //Оборудование принято

// 1. На рассмотрении Submitted
// 2. На согласовании Pending
// 3. Формирование пакета документов DocumentPackageFormation
// 4. Согласование в Контрактной службе и в Планово-экономическом отделе  ContractingServiceAndPlanningAndEconomicDepartmentApproval
// 5. Формирование лота LotFormation
// 6. Торги Auction
// 7. Приемка Acceptance
// 8. Оборудование принято EquipmentAccepted
// 8. Отклонена Rejected