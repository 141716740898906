import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import Settings from "store/settings";
import { FileStoreEntity, ImageStoreEntity } from "graphql/graphQlApiHooks";
import {
  ImportedSportEquipmentRecord
} from "../views/Pages/Equipments/components/Main/Header/ExcelTools/ImportFromExel/types";


export default class UploaderService {
  private static token: string | undefined = localStorage.getItem("user_token");
  private static imagesURL: string = Settings.server + "/images/upload";
  private static filesURL: string = Settings.server + "/files/upload";

  /*----------------------------------------------------
  * Загрузка изображений
  * --------------------------------------------------*/
  static uploadImage = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | ImageStoreEntity> => {
    const target = event.target as HTMLInputElement;
    const imagesList = target?.files;

    if (imagesList && imagesList[0]) {
      const image: File = imagesList[0];

      return await this.upload(image, this.imagesURL, axiosConfig).then(res => {
        return res?.data as ImageStoreEntity;
      });
    }
  };



  /*----------------------------------------------------
  * Обновление изображения по id, с параметрами (поворота...)
  * @param imageId - id изображения (UUID)
  * @param params - параметры изображения {
  *  rotate: number - угол поворота изображения
  * }

  * @return ImageStoreEntity
  * --------------------------------------------------*/
  static updateImage = async (
    imageId: string,
    params: {
      rotate?: number,
    } = {rotate: 0}
  ): Promise<undefined | ImageStoreEntity> => {
    const TOKEN = `Bearer ${localStorage.getItem("user_token")}`;
    const updateImageURL = `${Settings.server}/images/update/${imageId}${(params?.rotate !== 0) ? '/' + params?.rotate : ''}`;
    const getImageURL = `${Settings.server}/images/${imageId}`;

    /*----------------------------------------------------
    * утилита для добавления уникального параметра к url
     */
    const getUniqUrl = (url:string) => {
      const now = new Date().toISOString();
      if (url.includes('?')) return url.replace(/\?.*/, `?${now}`);
      return `${url}?${now}`;
    };

    const image = await axios.get(getImageURL, {responseType: 'blob'});
    const file = new File([image.data], imageId);
    let formData = new FormData();
    formData.append('file', file);
    formData.append('description', imageId);

    const response = await axios.post(updateImageURL, formData, {headers: {'authorization': TOKEN}});

    const result:ImageStoreEntity = {
      ...response.data,
      url: getUniqUrl(response.data.url),
      avatarUrl: getUniqUrl(response.data.avatarUrl)
    }

    return result;
  };

  static uploadImages = async (
    event: React.FormEvent<HTMLInputElement> | Event,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | ImageStoreEntity[]> => {
    const target = event.target as HTMLInputElement;
    const imagesList = target?.files;

    if (imagesList && imagesList[0]) {
      const result: ImageStoreEntity[] = [];

      for (const image of Array.from(imagesList)) {
        await this.upload(image as File, this.imagesURL, axiosConfig).then(res => {
          result.push(res?.data as ImageStoreEntity);
        });
      }
      return result;
    }
  };


  /*----------------------------------------------------
  * Загрузка файлов
  * --------------------------------------------------*/
  static uploadFile = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | FileStoreEntity> => {
    const target = event.target as HTMLInputElement;
    const filesList = target?.files;

    if (filesList && filesList[0]) {
      const file: File = filesList[0];

      return await this.upload(file, this.filesURL, axiosConfig).then(res => {
        return res?.data as FileStoreEntity;
      });
    }
  };

  static uploadImportSportEquipmentFile = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | ImportedSportEquipmentRecord[]> => {
    const target = event.target as HTMLInputElement;
    const filesList = target?.files;

    if (filesList && filesList[0]) {
      const file: File = filesList[0];

      return await this.upload(file, `${Settings.server}/import-sport-equipment/upload`, axiosConfig).then(res => {
        return res?.data as ImportedSportEquipmentRecord[];
      });
    }
  };

  static uploadFiles = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | FileStoreEntity[]> => {
    const target = event.target as HTMLInputElement;
    const filesList = target?.files;

    if (filesList && filesList[0]) {
      const result: FileStoreEntity[] = [];

      for (const file of Array.from(filesList)) {
        await this.upload(file as File, this.filesURL, axiosConfig).then(res => {
          result.push(res?.data as FileStoreEntity);
        });
      }
      return result;
    }
  };

  private static upload = async (
    file: File,
    url: string,
    axiosConfig?: AxiosRequestConfig
  ): Promise<any> => {
    const data = new FormData();
    data.append("file", file);
    data.append("type", file.type);
    data.append("filename", file.name);
    data.append("description", file.name);

    const options = {
      headers: {
        "authorization": `Bearer ${this.token}`
      }
    };
    return await axios.post(url, data, { ...options, ...axiosConfig });
  };
}
