import React from "react";
import {useDialog} from "providers/DialogProvider";
import {CombinedEquipmentRequestDialog} from "../../components/Dialogs/CombinedEquipmentRequestDialog";
import {EquipmentRequestEntity} from "../../../../../graphql/graphQlApiHooks";

export const useCreateCombinedEquipmentRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const onCreateCombinedEquipmentRequestAction = ({equipments, onSuccess}: {
        equipments: EquipmentRequestEntity[],
        onSuccess: () => void
    }) => {
        dialog({
            onClose: closeDialog,
            title: "Объединение заявок",
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            maxWidth: "md",
            fullWidth: true,
            children: <>
                <CombinedEquipmentRequestDialog
                    equipments={equipments}
                    close={() => {
                        closeDialog();
                        onSuccess();
                    }}
                />
            </>
        });
    };

    return {
        onCreateCombinedEquipmentRequestAction
    }
};