import React, { FC } from "react";
import {
  refetchGetSportEquipmentsQuery,
  useSportEquipmentWriteOffMutation
} from "../../../../../../graphql/graphQlApiHooks";
import {
  SportEquipmentWriteOffDialogClose, SportEquipmentWriteOffDialogSetWroteOffCertificate,
  useSportEquipmentWriteOffDialogState
} from "./sport-equipment-write-off-dialog-state";
import { Button, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "../../../../../../components/Dialogs/Dialog";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { downloadFile } from "../../../../../../helpers/downloadFile";
import DownloadIcon from "@mui/icons-material/Download";
import UploaderService from "../../../../../../services/UplodaService";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";

export const SportEquipmentWriteOffDialog: FC = () => {
  const state = useSportEquipmentWriteOffDialogState();
  const [writeOffMutation] = useSportEquipmentWriteOffMutation();
  const wroteOffCertificateInputRef = React.useRef<HTMLInputElement>(null);
  const { getInput } = useGetSportEquipmentInputVars();

  const onSubmit = async () => {
    if (!state.wroteOffCertificate) return;
    console.log('input', getInput());
    await writeOffMutation({
      variables: {
        sportEquipmentId: state.sportEquipmentId,
        wroteOffCertificateId: state.wroteOffCertificate.id
      },
      refetchQueries: [
        refetchGetSportEquipmentsQuery({ input: getInput() })
      ]
    });
    SportEquipmentWriteOffDialogClose();
    state.sportEquipmentCardOnClose();
  };

  return (
    <Dialog
      open={state.isOpen}
      onClose={SportEquipmentWriteOffDialogClose}
      props={{
        keepMounted: true,
        maxWidth: "sm"
      }}
      title={<Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
      >
        <Box>Списание спортивного оборудования</Box>
      </Stack>}
      DialogContentProps={{ sx: { bgcolor: "background.default" } }}
      actions={<>
        <Button
          type='submit'
          color='error'
          variant='text'
          form='editEquipmentRequest'
          onClick={onSubmit}
          disabled={!state.wroteOffCertificate}
        >Списать оборудование</Button>
        <Box flexGrow={1} />
        <Button
          onClick={SportEquipmentWriteOffDialogClose}
          variant='contained'
          color='secondary'
        >Отмена</Button>
      </>}
    >
      <TextField
        /* ==================================== */
        /* ========== Акт о списании ========== */
        /* ==================================== */
        fullWidth
        variant='outlined'
        label='Акт о списании'
        placeholder={state.wroteOffCertificate?.name || "Загрузите файл"}
        value={state.wroteOffCertificate?.name || ""}
        size='small'
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Tooltip title='Загрузить'>
                <IconButton onClick={() => wroteOffCertificateInputRef.current?.click()}>
                  <UploadFileIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              {state.wroteOffCertificate && <Tooltip title='Скачать'>
                <IconButton onClick={() => downloadFile(state.wroteOffCertificate)}>
                  <DownloadIcon fontSize='small' />
                </IconButton>
              </Tooltip>}
            </InputAdornment>
          )
        }}
      />
      <input
        ref={wroteOffCertificateInputRef}
        type='file'
        hidden
        onChange={e => UploaderService.uploadFile(e).then(file => SportEquipmentWriteOffDialogSetWroteOffCertificate(file))}
      />
    </Dialog>
  );
};
