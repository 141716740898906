import React, { useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from "@mui/material";

import {
  GetSportEquipmentsDocument,
  SportEquipmentEntity,
  useDeleteSportEquipmentMutation,
  useSetEditableMutation
} from "graphql/graphQlApiHooks";
import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";
import { useDialog } from "providers/DialogProvider";
import Settings from "store/settings";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import { useReactiveVar } from "@apollo/client";
import { userVars } from "../../../../../../store/reactiveVarsStores/auth/user";
import Iconify from "../../../../../../components/Iconify";
import { SportEquipmentsRelocate } from "../Footer/SportEquipmentsRelocate";

interface Props {
  sportEquipment: SportEquipmentEntity;
  onClose: () => void;
}

// TODO:
//    [PROGRESS] добавить пункты в меню для пользователей с расширенными правами (!isForSportObject):
//      [] перемещение
//          [DONE] интерфейс
//          [] реализация
//      [] смена категории
//          [DONE] интерфейс
//          [] реализация
//      [] разрешение/запрет на редактирование
//          [DONE] интерфейс
//          [] реализация
//    [DONE] добавить в основные документы Акт ввода в эксплуатацию
//    [DONE] исправить названия пунктов меню для основных документов
//    [DONE] добавить иконки
//    [DONE] ограничить ширину меню


export const MoreMenuItems = (props: Props) => {
    const { onClose } = props;
    const [sportEquipment, setSportEquipment] = useState(props.sportEquipment);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [dialog, closeDialog] = useDialog();
    const isBasicDocs = Boolean(sportEquipment?.passport?.length > 0 || sportEquipment?.certificate?.length > 0 || sportEquipment?.manual?.length > 0 || sportEquipment?.guarantee?.length > 0);
    const isOtherDocs = Boolean(sportEquipment?.otherDocuments && sportEquipment?.otherDocuments?.length > 0);
    const { getInput } = useGetSportEquipmentInputVars();
    const { isForSportObject } = useReactiveVar(userVars);


    const [deleteSportEquipment] = useDeleteSportEquipmentMutation();


    function editSportEquipmentClickHandler(sportEquipmentId: string) {
      navigate(`edit/${sportEquipmentId}`);
    }

    function viewSportEquipmentClickHandler(sportEquipmentId: string) {
      navigate(`${sportEquipmentId}`);
    }

    function deleteSportEquipmentClickHandler(sportEquipmentId: string) {

      deleteSportEquipment({
        variables: { id: sportEquipmentId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetSportEquipmentsDocument,
            fetchPolicy: "network-only",
            variables: {
              input: getInput()
            }
          }
        ]
      })
        .then(() => {
          enqueueSnackbar(
            <>
              Обрудование: <b>{sportEquipment.name}</b>, успешно удалено.
            </>
          );
        })
        .catch(({ message }) => {
          enqueueSnackbar(
            message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
            {
              variant: "error"
            }
          );
        })
        .finally(() => {
          closeDialog();
          onClose();
        });
    }


    const relocateSportEquipmentClickHandler = (sportEquipmentId: string) => {
      dialog({
        onClose: closeDialog,
        title: "Перемещение оборудования",
        children: <>
          <SportEquipmentsRelocate
            equipments={[sportEquipmentId] as string[]}
            close={closeDialog}
          />
        </>
      });
    };

    const categoryChangeSportEquipmentClickHandler = (sportEquipmentId: string) => {

    };

    const [editableLoading, setEditableLoading] = useState(false);
    const [setEditable] = useSetEditableMutation();
    const toggleEditableSportEquipmentClickHandler = (sportEquipmentId: string) => {
      setEditableLoading(true);
      setEditable({
        variables: {
          input: {
            sportEquipmentId: sportEquipment?.id,
            isEditable: !sportEquipment?.isEditable
          }
        },
        refetchQueries: [
          {
            query: GetSportEquipmentsDocument,
            fetchPolicy: "network-only",
            variables: {
              input: getInput()
            }
          }
        ],
        awaitRefetchQueries: true
      }).then(() => {
        setSportEquipment(prev => ({
          ...prev,
          isEditable: !sportEquipment?.isEditable
        }));
        enqueueSnackbar(
          sportEquipment?.isEditable ? `Карточка оборудования больше не доступна для редактирования.` : "Карточка оборудования вновь доступна для редактирования."
        );
        setEditableLoading(false);
      }).catch(({ message }) => {
        enqueueSnackbar(
          message.replace("Unexpected error value: ", "").replace(/"/g, ""),
          {
            variant: "error"
          }
        );
      });
    };

    const historySportEquipmentClickHandler = (sportEquipmentId: string) => {
      navigate('/equipments/history/'+sportEquipmentId)
    };

    return (
      <>
        <MenuItem
          onClick={() => {
            onClose();
            viewSportEquipmentClickHandler(sportEquipment.id);
          }}

        >
          <ListItemIcon>
            <Iconify icon={"mdi:show-outline"} />
          </ListItemIcon>
          <ListItemText>Просмотр</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            editSportEquipmentClickHandler(sportEquipment.id);
          }}
          disabled={!sportEquipment?.isEditable}
        >

          <ListItemIcon>
            <Iconify icon={"material-symbols:edit-outline"} />
          </ListItemIcon>
          <ListItemText>Изменить</ListItemText>

        </MenuItem>

        <MenuItem
          onClick={() => {
            dialog({
              onClose: closeDialog,
              title: "Повдверждение удаления оборудования.",
              children: <>
                <DialogContent dividers sx={{ px: 2 }}>
                  <Alert severity={"info"}>
                    <Typography variant={"body1"}>
                      Вы уверены что хотите удалить оборудование: <b>{sportEquipment?.name}</b>?
                    </Typography>
                    <Typography variant={"body1"}>
                      Это действие необратимо!
                    </Typography>
                  </Alert>
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"secondary"}
                    onClick={() => deleteSportEquipmentClickHandler(sportEquipment.id)}
                    size={"small"}
                  >
                    Удалить
                  </Button>
                  <Button
                    color={"primary"}
                    autoFocus
                    onClick={closeDialog}
                    variant={"contained"}
                    size={"small"}
                  >
                    Отмена
                  </Button>
                </DialogActions>
              </>
            });

          }}
          disabled={!sportEquipment?.isEditable}
        >

          <ListItemIcon>
            <Iconify icon={"material-symbols:delete-outline-sharp"} />
          </ListItemIcon>
          <ListItemText>Удалить</ListItemText>
        </MenuItem>

        {!isForSportObject && isForSportObject !== null && <Divider />}
        {!isForSportObject && isForSportObject !== null &&
          <>
            <MenuItem
              dense
              onClick={() => {
                // onClose();
                relocateSportEquipmentClickHandler(sportEquipment.id);
              }}
              disabled={!sportEquipment?.isEditable}
            >

              <ListItemIcon>
                <Iconify icon={"tabler:status-change"} />
              </ListItemIcon>
              <ListItemText>Переместить</ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                // onClose();
                categoryChangeSportEquipmentClickHandler(sportEquipment.id);
              }}
              disabled={!sportEquipment?.isEditable}
            >

              <ListItemIcon>
                <Iconify icon={"carbon:category"} />
              </ListItemIcon>
              <ListItemText>Изменить категорию</ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                // onClose();
                toggleEditableSportEquipmentClickHandler(sportEquipment.id);
              }}
            >

              <ListItemIcon>
                {
                  !editableLoading
                    ?
                    <Iconify
                      icon={sportEquipment?.isEditable ? "material-symbols:lock" : "material-symbols:lock-open-outline"}
                    />
                    :
                    <CircularProgress size={10} />
                }

              </ListItemIcon>
              <ListItemText>{sportEquipment?.isEditable ? "Запретить редактирование" : "Разрешить редактирование"}</ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                onClose();
                historySportEquipmentClickHandler(sportEquipment.id);
              }}
            >

              <ListItemIcon>
                <Iconify icon={"material-symbols:history-rounded"} />
              </ListItemIcon>
              <ListItemText>История изменений</ListItemText>
            </MenuItem>
          </>
        }


        {isBasicDocs && <Divider />}

        {isBasicDocs &&
          <Typography variant={"caption"} color={"text.secondary"} px={2}>
            Основные документы
          </Typography>
        }

        {sportEquipment.commissioningCertificates.length > 0 &&
          <MenuItem
            component={"a"}
            title={sportEquipment.commissioningCertificates[0].description}
            href={Settings.server + sportEquipment.commissioningCertificates[0].url}
            target={"_blank"}
            dense
            onClick={() => onClose()}
          >

            <ListItemIcon>
              <Iconify icon={"material-symbols:sim-card-download-outline"} />
            </ListItemIcon>
            <ListItemText>Акт ввода в эксплуатацию</ListItemText>
          </MenuItem>
        }

        {sportEquipment.passport.length > 0 &&
          <MenuItem
            component={"a"}
            title={sportEquipment.passport[0].description}
            href={Settings.server + sportEquipment.passport[0].url}
            target={"_blank"}
            dense
            onClick={() => onClose()}
          >

            <ListItemIcon>
              <Iconify icon={"material-symbols:sim-card-download-outline"} />
            </ListItemIcon>
            <ListItemText>Паспорт</ListItemText>
          </MenuItem>
        }

        {sportEquipment.certificate.length > 0 &&
          <MenuItem
            component={"a"}
            title={sportEquipment.certificate[0].description}
            href={Settings.server + sportEquipment.certificate[0].url}
            target={"_blank"}
            dense
            onClick={() => onClose()}
          >

            <ListItemIcon>
              <Iconify icon={"material-symbols:sim-card-download-outline"} />
            </ListItemIcon>
            <ListItemText>Сертификат</ListItemText>

          </MenuItem>
        }
        {sportEquipment.guarantee.length > 0 &&
          <MenuItem
            component={"a"}
            title={sportEquipment.guarantee[0].description}
            href={Settings.server + sportEquipment.guarantee[0].url}
            target={"_blank"}
            dense
            onClick={() => onClose()}
          >

            <ListItemIcon>
              <Iconify icon={"material-symbols:sim-card-download-outline"} />
            </ListItemIcon>
            <ListItemText>Гарантийный талон</ListItemText>
          </MenuItem>
        }
        {sportEquipment.manual.length > 0 &&
          <MenuItem
            component={"a"}
            title={sportEquipment.manual[0].description}
            href={Settings.server + sportEquipment.manual[0].url}
            target={"_blank"}
            dense
            onClick={() => onClose()}
          >

            <ListItemIcon>
              <Iconify icon={"material-symbols:sim-card-download-outline"} />
            </ListItemIcon>
            <ListItemText>Руководство по эксплуатации</ListItemText>
          </MenuItem>
        }


        {isOtherDocs && <Divider />}
        {isOtherDocs &&
          <Typography variant={"caption"} color={"text.secondary"} px={2}>
            Прочие документы
          </Typography>
        }

        {isOtherDocs &&
          sportEquipment.otherDocuments.map((document: any, i: number) => {
            return (<MenuItem
              key={i}
              component={"a"}
              title={document.description}
              href={Settings.server + document.url}
              target={"_blank"}
              dense
              onClick={() => onClose()}
            >

              <ListItemIcon>
                <Iconify icon={"material-symbols:sim-card-download-outline"} />
              </ListItemIcon>
              <ListItemText>{document.description}</ListItemText>
            </MenuItem>);
          })}

      </>
    );
  }
;
