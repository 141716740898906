import React, {useRef, useState} from 'react';
// import {Theme} from '@mui/material/styles';
// import makeStyles from '@mui/styles/makeStyles';
import {observer} from "mobx-react-lite";
import {
	Button,
	Dialog,
	DialogActions,
	Slide,
	TextField
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {EquipmentTypeDictionaryEntity} from "../../../../../graphql/graphQlApiHooks";
import {useStores} from "../../../../../store";


// const useStyles = makeStyles((theme: Theme) => ({
//     root: {}
// }));

interface AddDialog {
	open: boolean,
	onClose: (state: boolean) => void
}

const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>,
) {
	return <Slide
			direction="up"
			ref={ref} {...props} />;
});
const AddDictionaries = observer((props: AddDialog) => {
	const {open} = props;
	const {dictionariesStore} = useStores();
	// const classes = useStyles();
	const inputName = useRef<HTMLInputElement | null>(null)
	const [inputNameValue, setInputNameValue] = useState('')
	const [nameExistsError, setNameExistsError] = useState<string | null>(null);
	const [methodName, setMethodName] = useState('')
	const [inputLabel, setInputLabel] = useState('')


	const addHandler = () => {
		if (inputNameValue !== '') {
			const nameIsExists = dictionariesStore?.activeSportKind?.equipmentType?.find((equipmentType: EquipmentTypeDictionaryEntity) => equipmentType.name === inputNameValue)

			if (!!nameIsExists) {
				setNameExistsError('Название занято')
				return
			}
			const input = {
				name: inputNameValue,
				sportKindId: dictionariesStore?.activeSportKind?.id
			}
			dictionariesStore?.[methodName](input).finally(onClose)
		} else {
			setNameExistsError('Вы не указали название.')
		}
	};

	function onClose() {
		setNameExistsError(null)
		setInputNameValue('')
		props.onClose(false)
	}

	React.useEffect(() => {
		setMethodName(`createEquipmentTypeDictionary`);
		setInputLabel('Название типа оборудования');
	}, [dictionariesStore?.activeSportKind])


	return (
			<>
				<Dialog
						open={open}
						onClose={onClose}

						TransitionComponent={Transition}
						sx={{'& .MuiPaper-root': {minWidth: '250px'}}}
				>
					<TextField
							autoFocus={true}
							onChange={(e) => {
								nameExistsError && setNameExistsError(null)
								setInputNameValue(e.target.value)
							}}
							value={inputNameValue}
							inputRef={inputName}
							id="name"
							label={inputLabel}
							variant="filled"
							size={'small'}
							autoComplete={'off'}
							fullWidth
							multiline
							minRows={3}
							helperText={nameExistsError}
							error={!!nameExistsError}
					/>
					<DialogActions sx={{
						display: 'flex',
						justifyContent: 'space-between'
					}}>
						<Button onClick={onClose}>Отменить</Button>
						<Button variant={'contained'} onClick={addHandler}>Добавить</Button>
					</DialogActions>

				</Dialog>
			</>
	)
})

export default AddDictionaries;

