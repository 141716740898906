import {makeVar, ReactiveVar} from "@apollo/client";

export const equipmentRepairRequestsTotalCountVar = makeVar<number>(
    0
);

export const useEquipmentRepairRequestsTotalCountVar = () =>
    createEquipmentRepairRequestsTotalCountVar(equipmentRepairRequestsTotalCountVar);

const createEquipmentRepairRequestsTotalCountVar = (
    equipmentRepairRequestsTotalCountVar: ReactiveVar<number>
) => {
    const setTotalCount = (newTotalCount: number) => {
        equipmentRepairRequestsTotalCountVar(newTotalCount);
    };

    return {
        setTotalCount,
    };
};
