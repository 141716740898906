import {makeVar} from "@apollo/client";
import {DateRange} from "components/mui-daterange-picker";
import { DepartmentEnum } from "graphql/graphQlApiHooks";
import {EquipmentRelocationRequestStatusEnum} from "../../stores/equipment-relocation-request-status.enum";


export interface EquipmentRelocationRequestsFilters {
    name?: string;
    inventoryNumber?: string;

    equipmentTypeIds?: string[];
    sportKindIds?: string[];

    createdAt?: DateRange;

    sourceSportObjectIds?: string[];
    targetSportObjectIds?: string[];

    statuses?: (keyof typeof EquipmentRelocationRequestStatusEnum)[]
}

const initialFilters: EquipmentRelocationRequestsFilters = {
    name: null,
    inventoryNumber: null,

    equipmentTypeIds: null,
    sportKindIds: null,

    sourceSportObjectIds: null,
    targetSportObjectIds: null,

    createdAt: null,

    statuses: localStorage.getItem('user_departments')?.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics) ?
        ['RequestSubmitted'] :
        Object.keys(EquipmentRelocationRequestStatusEnum)
            .filter((val) => val !== 'RequestSubmitted') as (keyof typeof EquipmentRelocationRequestStatusEnum)[]
}

export const equipmentRelocationRequestsFiltersVar = makeVar<EquipmentRelocationRequestsFilters>(initialFilters);

const resetFilters = () => {
    equipmentRelocationRequestsFiltersVar(initialFilters);
}

const setFilters = (filters: EquipmentRelocationRequestsFilters) => {
    equipmentRelocationRequestsFiltersVar(filters);
}

const setFilter = (filter: keyof EquipmentRelocationRequestsFilters, value: any) => {
    equipmentRelocationRequestsFiltersVar({
        ...equipmentRelocationRequestsFiltersVar(),
        [filter]: value
    });
}

const getFilters = () => {
    return equipmentRelocationRequestsFiltersVar();
}

const getFilter = (filter: keyof EquipmentRelocationRequestsFilters) => {
    return equipmentRelocationRequestsFiltersVar()[filter];
}

export const useEquipmentRelocationRequestsFilters = () => {
    return {
        setFilters,
        setFilter,
        getFilters,
        getFilter,
        resetFilters
    }
}