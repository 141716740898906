import { FC } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  DictionaryEntity,
  DictionaryTypesEnum,
  GetFullDictionaryDocument,
  useCreateDictionaryValueMutation,
  useUpdateDictionaryValueMutation,
} from '../../../../graphql/graphQlApiHooks';

/**
 * Начальное состояние диалога
 */
const MeasurementUnitsCreateDialogInitialState = {
  isOpen: false,
  measurementUnitId: undefined as string | undefined,
  measurementUnitName: '',
  initialMeasurementUnitName: '',
};

/**
 * Реактивное состояние диалога
 */
export const measurementUnitsCreateDialogStateVar = makeVar(MeasurementUnitsCreateDialogInitialState);

/**
 * Методы для упрощения управлением состояния диалога
 */
export const measurementUnitsUpsertDialogActions = {
  create: () => measurementUnitsCreateDialogStateVar({
    ...MeasurementUnitsCreateDialogInitialState,
    isOpen: true,
  }),
  edit: (data: Pick<DictionaryEntity, 'id' | 'name'>) => {
    measurementUnitsCreateDialogStateVar({
      isOpen: true,
      measurementUnitId: data.id,
      measurementUnitName: data.name,
      initialMeasurementUnitName: data.name,
    });
  },
  updateState: (newMeasurementUnit: string) => measurementUnitsCreateDialogStateVar({
    ...measurementUnitsCreateDialogStateVar(),
    measurementUnitName: newMeasurementUnit,
  }),
  close: () => measurementUnitsCreateDialogStateVar({
    ...measurementUnitsCreateDialogStateVar(),
    isOpen: false,
  }),
};

/**
 * Режим диалога
 */
enum DialogMode {
  Create,
  Edit,
}

/**
 * Диалог добавления/редактирования единицы измерения
 */
export const MeasurementUnitsUpsertDialog: FC = () => {
  const state = useReactiveVar(measurementUnitsCreateDialogStateVar);

  // Режим диалога
  const dialogMode = state.measurementUnitId ? DialogMode.Edit : DialogMode.Create;

  // Мутация создания единицы измерения
  const [createMeasurementUnit, { loading: isCreating }] = useCreateDictionaryValueMutation({
    variables: {
      input: { dictionaryType: DictionaryTypesEnum.MeasurementUnit, name: state.measurementUnitName, tags: [] },
    },
    onCompleted: measurementUnitsUpsertDialogActions.close,
    refetchQueries: [{
      query: GetFullDictionaryDocument,
      variables: { dictionaryType: DictionaryTypesEnum.MeasurementUnit },
    }],
  });
  // Мутация обновления единицы измерения
  const [updateMeasurementUnit, { loading: isUpdating }] = useUpdateDictionaryValueMutation({
    variables: {
      input: { id: state.measurementUnitId, name: state.measurementUnitName, tags: [] },
    },
    onCompleted: measurementUnitsUpsertDialogActions.close,
    refetchQueries: [{
      query: GetFullDictionaryDocument,
      variables: { dictionaryType: DictionaryTypesEnum.MeasurementUnit },
    }],
  });
  // Выполняется ли сохранение
  const isLoading = isCreating || isUpdating;

  return (
    <Dialog
      open={state.isOpen}
      maxWidth='lg'
      onClose={measurementUnitsUpsertDialogActions.close}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{ flexGrow: '1' }}
          variant='subtitle1'
        >{dialogMode === DialogMode.Create ? 'Добавление' : 'Редактирование'} единицы измерения</Typography>
        <IconButton onClick={measurementUnitsUpsertDialogActions.close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          value={state.measurementUnitName}
          disabled={isLoading}
          label='Единица измерения'
          sx={{ marginTop: 2 }}
          onChange={event => measurementUnitsUpsertDialogActions.updateState(event.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          size='small'
          disabled={isLoading}
          onClick={measurementUnitsUpsertDialogActions.close}
        >Отмена</Button>
        <LoadingButton
          size='small'
          loading={isLoading}
          loadingPosition='start'
          variant='contained'
          disabled={isLoading || state.measurementUnitName === state.initialMeasurementUnitName}
          onClick={() => dialogMode === DialogMode.Create ? createMeasurementUnit() : updateMeasurementUnit()}
        >{dialogMode === DialogMode.Create ? 'Добавить' : 'Сохранить'}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};