import React from 'react';
import {Box, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Page from "components/Page";
import {useTheme} from "@mui/styles";
import {lighten} from "@material-ui/core";
import {ExternalOrderEntity, useGetOrdersQuery} from "../../../graphql/graphQlApiHooks";
import {ExternalOrderStatusEnum} from "./external-order-status.enum";
import RentTable from "../RentEquipments/Table";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	page: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		position: 'relative',
	},
	pageContent: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		height: '100%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		}
	},
	pageTitle: {
		height: 90,
		padding: theme.spacing(3, 4),
		display: 'flex',
		alignItems: 'center',
		"& .MuiTypography-root": {
			fontSize: '21px',
			whiteSpace: 'nowrap',
			color: theme.palette.text.secondary,
			transition: 'all .3s',
			transform: 'scale(.8)',
			"&:hover": {
				color: lighten(theme.palette.text.primary, 0.2),
			},
			"&.active": {
				color: theme.palette.text.primary,
				transform: 'scale(1)',
			}
		},
		'& .toolbar': {
			marginLeft: '2rem',
			display: 'flex',
			listStyle: 'none',
			width: '100%',
			'&__item:not(:last-child)': {
				marginRight: '1em',
			}
		}
	},

	mainSection: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	mainSectionContainer: {
		width: 'auto',
		height: '100%',
		position: 'relative',
		overflowX: 'auto',
		padding: theme.spacing(0, 3),
	}
}));

const ReturnedEquipments = () => {
	const classes = useStyles();
	const theme = useTheme();

	const {data, loading} = useGetOrdersQuery({
		variables: {
			input: {
				statuses: Object.keys(ExternalOrderStatusEnum)
							.map(key => ExternalOrderStatusEnum[key] as string)
							.filter(val => val !== ExternalOrderStatusEnum.Created)
			},
		},
		pollInterval: 5000

	})


	return (

			<Page title={'Возврат оборудования'}>

				<header className={classes.pageTitle}>
					<Typography
							variant="h1"
							className={'active'}
					>
						Возврат оборудования
						<Box
								component={'small'}
								sx={{
									ml: 1,
									mr: 2,
									fontSize: '.7em',
									color: theme.palette.text.secondary,
									opacity: .5
								}}
						>{data?.getOrders?.length}</Box>
					</Typography>

					<Box flexGrow={1}/>


				</header>

				<Box className={classes.pageContent}>
					<main className={classes.mainSection}>
						<div className={classes.mainSectionContainer}>
							<RentTable orders={data?.getOrders as ExternalOrderEntity[]} loading={loading} />
						</div>
					</main>
				</Box>
			</Page>
	)
}

export default ReturnedEquipments;
