import React, { FC, useState } from "react";
import { EquipmentServicePeriodEntity } from "../../../../../../graphql/graphQlApiHooks";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  Box, Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Iconify from "../../../../../../components/Iconify";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "@mui/lab/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import { PeriodTypeEnum } from ".";

interface AddServicePeriodButtonProps {
  onAddServicePeriod: (servicePeriod: EquipmentServicePeriodEntity) => void;
}

const AddServicePeriodButton = (props: AddServicePeriodButtonProps) => {
  const { onAddServicePeriod } = props;
  const [addServicePeriodPopoverAnchorEl, setAddServicePeriodPopoverAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAddServicePeriodPopover = Boolean(addServicePeriodPopoverAnchorEl);
  const [addServicePeriodName, setAddServicePeriodName] = useState("");
  const [addServicePeriodPeriod, setAddServicePeriodPeriod] = useState<string>(PeriodTypeEnum.WEEK as string);
  const [addServicePeriodNextDate, setAddServicePeriodNextDate] = useState<Dayjs>(dayjs());

  const handleAddServicePeriodPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddServicePeriodPopoverAnchorEl(event.currentTarget);
  };

  const handleAddServicePeriodPopoverClose = () => {
    setAddServicePeriodPopoverAnchorEl(null);
  };


  function addServicePeriodHandleClick() {
    if (addServicePeriodName && addServicePeriodPeriod && addServicePeriodNextDate) {
      const newServicePeriod: EquipmentServicePeriodEntity = {
        id: uuidv4(),
        name: addServicePeriodName,
        periodType: addServicePeriodPeriod,
        nextDate: addServicePeriodNextDate
      } as unknown as EquipmentServicePeriodEntity;
      onAddServicePeriod(newServicePeriod);
      setAddServicePeriodName("");
      setAddServicePeriodPeriod("");
      setAddServicePeriodNextDate(null);
      handleAddServicePeriodPopoverClose();
    }
  }

  return (
    <>
      <Popover
        open={openAddServicePeriodPopover}
        anchorEl={addServicePeriodPopoverAnchorEl}
        onClose={handleAddServicePeriodPopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Stack
          direction={"column"}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={2}
            py={.5}
            sx={{
              bgcolor: "background.dark",
              color: "text.contrast"
            }}
          >
            <Typography
              variant={"caption"}
              color={"inherit"}
            >
              Добавление вида работ
            </Typography>
            <IconButton
              color={"inherit"}
              aria-label='close'
              size='small'
              onClick={() => {
                setAddServicePeriodName("");
                setAddServicePeriodPeriod("");
                setAddServicePeriodNextDate(null);
                handleAddServicePeriodPopoverClose();
              }}
            >
              <Iconify
                icon='eva:close-fill'
                fontSize='small'
              />
            </IconButton>

          </Stack>

          <Stack
            direction={"column"}
            p={2}
          >

            <FormControl
              sx={{ m: 1, minWidth: 120 }}
            >

              <InputLabel
                shrink={true}
                htmlFor={"servicePeriodName"}
                sx={{
                  color: "text.secondary",
                  transform: "translate(0, 0) scale(1)",
                  position: "relative"
                }}
                required={true}
              >
                Название вида работ
              </InputLabel>
              <TextField
                required={true}
                size={"small"}
                value={addServicePeriodName}
                name={"servicePeriodName"}
                onChange={(e) => setAddServicePeriodName(e.target.value)}
                placeholder={"Тех. обслуживание"}
                sx={{
                  width: "100%",
                  maxWidth: 300
                }}
                InputLabelProps={{
                  shrink: true
                }}
                autoFocus={true}
              />
            </FormControl>

            <FormControl
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel
                shrink={true}
                htmlFor={"servicePeriodPeriod"}
                sx={{
                  color: "text.secondary",
                  transform: "translate(0, 0) scale(1)",
                  position: "relative"
                }}
                required={true}
              >
                Период обслуживания
              </InputLabel>
              <Select
                value={addServicePeriodPeriod}
                onChange={(e) => setAddServicePeriodPeriod(e.target.value)}
                disableUnderline={true}
                size={"small"}
                name={"servicePeriodPeriod"}
              >
                <MenuItem value={PeriodTypeEnum.WEEK}>1 раз в неделю</MenuItem>
                <MenuItem value={PeriodTypeEnum.MONTH}>1 раз в месяц</MenuItem>
                <MenuItem value={PeriodTypeEnum.QUARTER}>1 раз в квартал</MenuItem>
                <MenuItem value={PeriodTypeEnum.YEAR}>1 раз в год</MenuItem>
              </Select>
            </FormControl>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"ru"}
            >
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  shrink={true}
                  htmlFor={"servicePeriodNextDate"}
                  sx={{
                    color: "text.secondary",
                    transform: "translate(0, 0) scale(1)",
                    position: "relative"
                  }}
                >
                  Дата следующего обслуживания
                </InputLabel>
                <DatePicker
                  views={["day", "month", "year"]}

                  value={addServicePeriodNextDate}
                  onChange={setAddServicePeriodNextDate}

                  renderInput={params => <TextField
                    autoComplete='off'
                    name={"servicePeriodNextDate"}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "дд.мм.гггг"
                    }}
                    InputProps={{
                      ...params.InputProps
                    }}
                    size={"small"}
                  />}
                />
              </FormControl>
            </LocalizationProvider>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2
              }}
            >
              <Button
                variant='contained'
                color={"primary"}
                size={"small"}
                startIcon={<AddIcon fontSize={"small"} />}
                sx={{ ml: "auto", display: "flex" }}
                disabled={addServicePeriodName.length === 0}
                onClick={addServicePeriodHandleClick}
              >
                Добавить
              </Button>
            </Box>

          </Stack>
        </Stack>


      </Popover>
      {/*Кнопка добавления вида работ*/}
      <Button
        variant='text'
        color={"inherit"}
        size={"small"}
        startIcon={<AddIcon fontSize={"small"} />}
        sx={{ ml: "auto", display: "flex" }}
        // onClick={addServicePeriodHandleClick}
        onClick={handleAddServicePeriodPopoverClick}
      >
        Добавить вид работ
      </Button>
    </>
  );
};

export default AddServicePeriodButton;