import React from "react";
import {CombinedEquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {ViewCombineRequestDialog} from "../../components/Dialogs/ViewCombineRequestDialog";

export const useViewCombineRequest = () => {
    const [dialog, closeDialog] = useDialog();

    const handleViewCombineRequest = (equipment: CombinedEquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            // title: "Просмотр заявки на оборудование",
            maxWidth: "sm",
            fullWidth: true,
            children: <>
                <ViewCombineRequestDialog
                    equipment={equipment}
                    onClose={() => closeDialog()}
                />
            </>
        });
    };

    return {
        handleViewCombineRequest
    }
};