import {TypographyOptions} from "@mui/material/styles/createTypography";
import {PaletteOptions} from "@mui/material";

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({sm, md, lg}: any) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

function getLineHeight(fontSize: number) {
  return fontSize * 2 / (fontSize * 2 - 16)
}


const typography = (palette: PaletteOptions) => {
  return {
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      lineHeight: getLineHeight(28),
      fontSize: pxToRem(28),
      ...responsiveFontSizes({sm: 24, md: 26, lg: 28}),
      color: palette.text?.primary,
    },
    h2: {
      fontWeight: 700,
      lineHeight: getLineHeight(26),
      fontSize: pxToRem(26),
      ...responsiveFontSizes({sm: 22, md: 24, lg: 26}),
      color: palette.text?.primary,
    },
    h3: {
      fontWeight: 700,
      lineHeight: getLineHeight(18),
      fontSize: pxToRem(18),
      ...responsiveFontSizes({sm: 18, md: 18, lg: 18}),
      color: palette.text?.primary,
    },
    h4: {
      fontWeight: 700,
      lineHeight: getLineHeight(18),
      fontSize: pxToRem(18),
      ...responsiveFontSizes({sm: 18, md: 18, lg: 18}),
      color: palette.text?.primary,
    },
    h5: {
      fontWeight: 700,
      lineHeight: getLineHeight(16),
      fontSize: pxToRem(16),
      ...responsiveFontSizes({sm: 16, md: 16, lg: 16}),
      color: palette.text?.primary,
    },
    h6: {
      fontWeight: 700,
      lineHeight: getLineHeight(14),
      fontSize: pxToRem(14),
      ...responsiveFontSizes({sm: 14, md: 14, lg: 14}),
      color: palette.text?.primary,
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
      color: palette.text?.primary,
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
      color: palette.text?.primary,
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
      color: palette.text?.primary,
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
      color: palette.text?.primary,
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      color: palette.text?.primary,
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      letterSpacing: 1.1,
      textTransform: 'uppercase',
      color: palette.text?.primary,
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: 'capitalize',
      color: palette.text?.primary,
    },
  } as TypographyOptions
};

export default typography;
