import React, {FC} from 'react';
import {Alert, Button, DialogActions, DialogContent, Typography} from "@mui/material";
import {CombinedEquipmentRequestEntity, EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface Props {
    singleRequest: EquipmentRequestEntity;
    combinedRequest: CombinedEquipmentRequestEntity;
    message?: string;
    onClose: () => void;
    onDelete: () => void
}

export const DeleteSingleRequestFromCombineRequestDialog: FC<Props> = ({
                                                                           message,
                                                                           onClose,
                                                                           onDelete,
                                                                           singleRequest,
                                                                           combinedRequest
                                                                       }) => {
    return (
        <>
            <DialogContent>
                <Alert variant={'standard'} severity="error" icon={<DeleteForeverIcon fontSize="inherit"/>}>
                    <Typography color={'inherit'} gutterBottom>
                        {message ? message : (
                            <>
                                Вы собираетесь удалить заявку
                                <strong> {singleRequest.name} </strong>
                                из объединённой заявки
                                <strong> {combinedRequest.name} </strong>. <br/> <br/>
                                Обратите внимание, что сама заявка останется в списке заявок, она удалится только из
                                объединённой заявки. <br/> <br/>
                                Чтобы подтвердить удаление, нажмите кнопку ниже.
                            </>
                        )}
                    </Typography>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    autoFocus
                    onClick={onDelete}
                    color="error"
                >
                    Удалить
                </Button>
            </DialogActions>
        </>
    )
};

