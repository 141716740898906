import React from 'react';
import {Box, Stack} from '@mui/material';
import {RequestTable} from "./components/EquipmentRequestTable";
import Page from "components/Page";
import PageTitle from "components/PageTitle";
import PageContent from "components/PageContext";
import {EquipmentRequestsExport} from "./components/EquipmentRequestsExport";
import {EquipmentRequestCreate} from "./components/EquipmentRequestCreate";
import {Toolbar} from './components/Toolbar';
import {useEquipmentRequestTable} from "./hooks/useEquipmentRequestTable";


const EquipmentRequest = () => {
    const equipmentRequest = useEquipmentRequestTable();


    const pageTitle = "Заявки на оборудование";
    return (
        <Page title={pageTitle}>
            <Box width={'100%'} justifyContent={"space-between"} display={"flex"} px={5} pt={4} pb={3}
                 component={"header"} className="page__header">
                <PageTitle title={pageTitle} count={equipmentRequest.count} loading={equipmentRequest.loading}
                           totalCount={equipmentRequest.allCount}/>

                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <EquipmentRequestsExport/>
                    <EquipmentRequestCreate/>
                </Stack>
            </Box>

            <Toolbar/>

            <PageContent>
                <RequestTable {...equipmentRequest}/>
            </PageContent>
        </Page>
    )
};

export default EquipmentRequest;