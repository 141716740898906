import {PaletteMode} from '@mui/material'

// ** Types

export type ContentWidth = 'full' | 'boxed'

export type ThemeColor = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'

type ThemeConfig = {
	mode: PaletteMode
	templateName: string
	routingLoader: boolean
	disableRipple: boolean
	navigationSize: number
	menuTextTruncate: boolean
	contentWidth: ContentWidth
	responsiveFontSizes: boolean
}

export const themeConfig: ThemeConfig = {
	// ** Layout Configs
	templateName: 'Московский спорт' /* App Name */,
	mode: 'light' /* light | dark */,
	contentWidth: 'boxed' /* full | boxed */,

	// ** Routing Configs
	routingLoader: true /* true | false */,

	// ** Navigation (Menu) Configs
	menuTextTruncate: true /* true | false */,
	navigationSize: 260 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,

	// ** Other Configs
	responsiveFontSizes: true /* true | false */,
	disableRipple: true /* true | false */
}



export default class Settings {
	static server = process.env.REACT_APP_SERVER_URL || `${window.location.protocol}//${window.location.host}/api`
	static pollIntervals = {
		allSportObjects: 0, // Спортобъекты
		sportSubZonesByZoneId: 0, // Спорт подзоны
		sportEquipments: 120000, // Оборудование
		equipmentRequests: 0, // Заявки на оборудование
		equipmentRelocationRequests: 0, // Заявки на перемещение оборудования
		allEquipmentRelocationRequests: 0, // Заявки на перемещение оборудования (Избыточный запрос)
		serviceJobs: 0, // План обслуживания
		dictionaries: 0, // Виды спорта и тиы оборудования
		users: 0, // Список пользователей
	}
	static testUsers = [ "test_obj", "test_user", "testgd", "testfe", "Testgd", "test_polyarnaya", "test_potapovskiy", "test_akvamarin", ]
}
