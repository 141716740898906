import {EquipmentRequestStatusEnum} from "graphql/graphQlApiHooks";
import React from "react";
import Iconify from "components/Iconify";

export const getStatusIcon = (status: EquipmentRequestStatusEnum, size: string = '1.5rem') => {
    switch (status) {
        case EquipmentRequestStatusEnum.Submitted: // На рассмотрении
            return <Iconify icon="material-symbols:av-timer" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.Pending: // На согласовании
            return <Iconify icon="material-symbols:pending-actions" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.Success: // Ожидает отправления
            return <Iconify icon="material-symbols:check-circle-outline" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.PassedToContractualService: // Передана в контрактную службу
        case EquipmentRequestStatusEnum.PassedToAno: // Передана в АНО
            return <Iconify icon="material-symbols:forward" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.Rejected: // Заявка отклонена
            return <Iconify icon="material-symbols:cancel-outline" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.Acceptance: // Приемка
            return <Iconify icon="material-symbols:assignment-turned-in-outline" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.Auction: // Торги
            return <Iconify icon="ri:auction-line" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.ContractingServiceAndPlanningAndEconomicDepartmentApproval: // На согласование
            return <Iconify icon="material-symbols:approval-outline" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.DocumentPackageFormation: // Формирование пакета документов
            return <Iconify icon="material-symbols:folder-outline" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.Supplied: // Оборудование принято
        case EquipmentRequestStatusEnum.EquipmentAccepted: // Оборудование принято
            return <Iconify icon="material-symbols:check-box-outline" sx={{fontSize: size}}/>;
        case EquipmentRequestStatusEnum.LotFormation: // Формирование лота
            return <Iconify icon="material-symbols:layers-outline" sx={{fontSize: size}}/>;
        default:
            return <Iconify icon="material-symbols:dot-outline" sx={{fontSize: size}}/>;
    }
};