import {RequestEntity} from "../../../../types/RequestEntity";
import {Box, Checkbox, Fade, IconButton, Stack, Typography} from "@mui/material";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltiper from "components/Tooltiper";
import {Tooltip} from "../../../ui/Tooltip";
import Iconify from "components/Iconify";
import {RenderDescriptionCell} from "./RenderDescriptionCell";
import {EquipmentRequestEntity, EquipmentRequestStatusEnum} from "graphql/graphQlApiHooks";
import React, {FC} from "react";
import {GridRowId} from "@mui/x-data-grid";

// Компонент рендеринга ячейки для названия заявки

type Props = {
    toggleGroup: (groupId: string) => void
    selected: GridRowId[]
    handleCheckboxChange: (id: GridRowId) => (event: React.ChangeEvent<HTMLInputElement>) => void
    equipmentRequestEntity: RequestEntity
}

export const RequestNameCellRenderer: FC<Props> = (props) => {
    const {
        toggleGroup,
        selected,
        handleCheckboxChange,
        equipmentRequestEntity,
    } = props

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} gap={2}>
            <Box
                sx={{
                    ...((isSingleRequest(equipmentRequestEntity) && equipmentRequestEntity?.isInCombinedRequest) ? {ml: 8} : {}),
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    width: '100%',
                    minWidth: 0
                }}
            >
                {isCombinedRequest(equipmentRequestEntity) && equipmentRequestEntity?.childRequests?.length && equipmentRequestEntity?.childRequests?.length > 0 && (
                    <>
                        <Box>
                            <IconButton onClick={() => toggleGroup(equipmentRequestEntity.id)} size="small">
                                <ChevronRightIcon
                                    sx={{transform: equipmentRequestEntity?.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}}/>
                            </IconButton>
                        </Box>
                        {(equipmentRequestEntity?.urgentRequirementComment) &&
                            <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 300, exit: false}}
                                disableFocusListener
                                title={
                                    <Stack direction={'row'} spacing={2}>
                                        <Box display={'flex'}>
                                            <Iconify
                                                icon="line-md:alert-twotone"
                                                sx={{
                                                    fontSize: 20,
                                                    color: 'error.main',
                                                }}
                                            />
                                        </Box>
                                        <Stack spacing={1}>
                                            <Typography
                                                sx={{whiteSpace: 'nowrap'}}
                                                variant={'caption'}
                                                color={'error.main'}
                                            >
                                                Срочная заявка!
                                            </Typography>
                                            <Box
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {equipmentRequestEntity?.urgentRequirementComment}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                }
                            >
                                <Box display={'flex'}>
                                    <Iconify
                                        icon="cuida:alert-outline"
                                        sx={{
                                            fontSize: 21,
                                            color: 'error.main',
                                            mr: -1
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        }
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'start'}
                            width={'100%'}
                            gap={0}
                            minWidth={0}
                        >
                            <Tooltiper
                                text={equipmentRequestEntity?.name}
                                props={{
                                    variant: 'subtitle1',
                                    sx: {
                                        mb: 0
                                    }
                                }}
                            />
                            <Tooltiper
                                text={`количество заявок: ${equipmentRequestEntity?.childRequests?.length}`}
                                props={{
                                    sx: {
                                        fontSize: '7px',
                                        color: 'text.secondary',
                                        mt: -1
                                    }
                                }}
                            />

                        </Box>
                    </>
                )}
                {isSingleRequest(equipmentRequestEntity) && (
                    <>
                        {(!equipmentRequestEntity?.isInCombinedRequest) && <>
                            <Checkbox
                                size="small"
                                color={'primary'}
                                sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}
                                checked={selected.includes(equipmentRequestEntity.id)}
                                onChange={handleCheckboxChange(equipmentRequestEntity.id)}
                                disabled={equipmentRequestEntity?.requestStatus == EquipmentRequestStatusEnum.Rejected}
                            />
                        </>}
                        {equipmentRequestEntity?.isInCombinedRequest && <>
                            <Typography color={'text.disabled'}
                                        variant={'body2'}>#{equipmentRequestEntity?.indexNumber}</Typography>
                        </>}
                        {(equipmentRequestEntity?.urgentRequirementComment) &&
                            <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 300, exit: false}}
                                disableFocusListener
                                title={
                                    <Stack direction={'row'} spacing={2}>
                                        <Box display={'flex'}>
                                            <Iconify
                                                icon="line-md:alert-twotone"
                                                sx={{
                                                    fontSize: 20,
                                                    color: 'error.main',
                                                }}
                                            />
                                        </Box>
                                        <Stack spacing={1}>
                                            <Typography
                                                sx={{whiteSpace: 'nowrap'}}
                                                variant={'caption'}
                                                color={'error.main'}
                                            >
                                                Срочная заявка!
                                            </Typography>
                                            <Box
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {equipmentRequestEntity?.urgentRequirementComment}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                }
                            >
                                <Box display={'flex'}>
                                    <Iconify
                                        icon="cuida:alert-outline"
                                        sx={{
                                            fontSize: 21,
                                            color: 'error.main',
                                            mr: -1
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        }

                        <Tooltiper
                            text={equipmentRequestEntity?.name}
                            props={{
                                variant: equipmentRequestEntity?.isInCombinedRequest ? 'body2' : 'subtitle1',
                            }}
                        />
                    </>
                )}
            </Box>
            {(isSingleRequest(equipmentRequestEntity) && equipmentRequestEntity?.description) &&
                <RenderDescriptionCell equipmentRequest={equipmentRequestEntity as unknown as EquipmentRequestEntity}/>
            }
        </Box>
    );
};