import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {
	Button,
	Dialog,
	DialogActions,
	Slide,
	TextField
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {
	EquipmentSpecificationDictionaryEntity,
	EquipmentTypeDictionaryEntity
} from "../../../../../graphql/graphQlApiHooks";
import {useStores} from "../../../../../store";

interface EditDialog {
	open: boolean,
	onClose: (state: boolean) => void,
	value: any
}

const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>,
) {
	return <Slide
			direction="up"
			ref={ref} {...props} />;
});
const EditDictionaries = observer((props: EditDialog) => {
	const {open, value} = props;
	const {dictionariesStore} = useStores();
	const [inputNameValue, setInputNameValue] = useState<string>('')
	const [nameExistsError, setNameExistsError] = useState<string | null>(null);
	const [methodName, setMethodName] = useState('')
	const [inputLabel, setInputLabel] = useState('')


	const addEquipmentTypeDictionary = () => {
		if (inputNameValue !== '') {
			if ((!value.__typename.includes('Specification'))){
				const nameIsExists = dictionariesStore?.activeSportKind?.equipmentTypes?.find((equipmentType: EquipmentTypeDictionaryEntity) => equipmentType.name === inputNameValue)

				if (!!nameIsExists) {
					setNameExistsError('Название занято')
					return
				}

			}

			dictionariesStore?.[methodName]({id: value.id, name: inputNameValue}).finally(onClose)
		} else {
			setNameExistsError('Вы не указали название')
		}
	};

	function onClose() {
		setNameExistsError(null)
		setInputNameValue('')
		props.onClose(false)
	}


	React.useEffect(() => {
		setMethodName(`update${value?.__typename.replace(/Entity/g,'')}`);
		setInputLabel((value?.__typename.includes('Specification')) ? 'Название характеристики оборудования' : 'Название типа оборудования');
	}, [value])

	React.useEffect(() => {
		setInputNameValue(value?.name);
		return () => {
			setInputNameValue('')
		};
	}, [value, open])


	return (
			<>
				<Dialog
						open={open}
						onClose={onClose}

						TransitionComponent={Transition}
						sx={{'& .MuiPaper-root': {minWidth: '250px'}}}
				>
					<TextField
							autoFocus={true}
							onChange={(e) => {
								nameExistsError && setNameExistsError(null)
								setInputNameValue(e.target.value)
							}}
							value={inputNameValue}
							id="nameEditDictionary"
							label={inputLabel}
							variant="filled"
							size={'small'}
							autoComplete={'off'}
							fullWidth
							multiline
							minRows={3}
							helperText={nameExistsError}
							error={!!nameExistsError}
					/>
					<DialogActions sx={{
						display: 'flex',
						justifyContent: 'space-between'
					}}>
						<Button onClick={onClose}>Отменить</Button>
						<Button
								onClick={addEquipmentTypeDictionary}
								variant={'contained'}
								disabled={(inputNameValue && (inputNameValue.replace(/\s+|\n+/g, '') === value?.name)) || inputNameValue === ''}
						>Сохранить</Button>
					</DialogActions>

				</Dialog>
			</>
	)
})

export default EditDictionaries;

