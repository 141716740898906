import React, {useEffect, useState} from "react";
import {List} from "./List";
import {SportEquipmentEntity, useGetSportEquipmentsQuery} from "graphql/graphQlApiHooks";
import {
    SportEquipmentListPagination,
    sportEquipmentListPaginationVars,
    useSportEquipmentListPaginationVars
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListPagination";
import {useReactiveVar} from "@apollo/client";
import {sportEquipmentListFiltersVars} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListFilters";
import {
    SportEquipmentTab,
    sportEquipmentTabActiveVars
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentTabActive";
import {
    SportEquipmentListSortOrder,
    sportEquipmentListSortOrderVar,
    useSportEquipmentListSortOrderVar
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListSortOrder";
import {
    useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import {sportObjectsVars} from "../../../../../../store/reactiveVarsStores/sport-objects/sportObjects";
import Settings from "../../../../../../store/settings";
import {Alert, Card} from "@mui/material";

const STATE_INITIAL = {
    isSportEquipmentsLoading: true,
    sportEquipments: [],
    allCount: 0
};

export default () => {
    const [state, setState] = useState(STATE_INITIAL);

    const {setPagination} = useSportEquipmentListPaginationVars();
    const {setOrder} = useSportEquipmentListSortOrderVar();

    const tabActive = useReactiveVar(sportEquipmentTabActiveVars);
    const filters = useReactiveVar(sportEquipmentListFiltersVars);
    const pagination = useReactiveVar(sportEquipmentListPaginationVars);
    const sortOrderModel = useReactiveVar(sportEquipmentListSortOrderVar);
    const sportObjects = useReactiveVar(sportObjectsVars);
    const {getInput} = useGetSportEquipmentInputVars()


    const {
        data: sportEquipmentsConnection,
        error: sportEquipmentsError,
        refetch: refetchSportEquipments
    } = useGetSportEquipmentsQuery({
        variables: {
            input: {...getInput()}
        },
        skip: !pagination || !tabActive || !filters || !sortOrderModel || !sportObjects,
        pollInterval: Settings.pollIntervals.sportEquipments
    });

    useEffect(() => {
        refetchSportEquipments({input: getInput()})
    }, [tabActive]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            isSportEquipmentsLoading: !sportEquipmentsConnection
        }));

        if (sportEquipmentsConnection?.getSportEquipments?.sportEquipments) {
            setState((prev) => ({
                ...prev,
                sportEquipments: sportEquipmentsConnection.getSportEquipments.sportEquipments
            }));
        }

        if (sportEquipmentsConnection?.getSportEquipments?.count) {
            setState((prev) => ({
                ...prev,
                allCount: sportEquipmentsConnection.getSportEquipments.count
            }));
        }

    }, [sportEquipmentsConnection]);


    if (sportEquipmentsError) return (
        <Card sx={{m: 2}}>
            <Alert severity="error">
                Возникла ошибка
                <pre style={{whiteSpace: 'pre-wrap'}}>
                    {JSON.stringify(sportEquipmentsError, null, 2)}
                </pre>
            </Alert>
        </Card>
    );


    return (
        <>
            <List
                sportEquipments={state.sportEquipments as SportEquipmentEntity[]}
                tabActive={tabActive as SportEquipmentTab}

                pagination={pagination as SportEquipmentListPagination}
                sortOrderModel={sortOrderModel as SportEquipmentListSortOrder}


                allCount={state.allCount}

                loading={state.isSportEquipmentsLoading}

                actions={{
                    setPagination,
                    setOrder
                }}
            />
        </>
    );
};