// Функция для фильтрации и объединения заявок
import {CombinedEquipmentRequestEntity, EquipmentRequestEntity} from "../../../../graphql/graphQlApiHooks";
import {RequestEntity} from "../types/RequestEntity";


export function getCombinedAndSingleRequests(rows?: EquipmentRequestEntity[], combineEquipments?: CombinedEquipmentRequestEntity[]): RequestEntity[] {
    // Фильтруем заявки, у которых нет поля parentCombinedRequestId
    const singleRequests = rows?.filter(request => !request?.parentCombinedRequestId);

    // Объединяем одиночные заявки с уже существующими объединёнными заявками
    return [
        ...[...combineEquipments || []], // Добавляем уже объединённые заявки
        ...[...singleRequests || []], // Добавляем одиночные заявки
    ];
}