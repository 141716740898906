import React, { FC } from "react";
import { EquipmentServicePeriodEntity } from "../../../../../../graphql/graphQlApiHooks";
import { PageDialogActions } from "../../../../../../components/Dialogs/PageDialogActions";
import { Button } from "@mui/material";

interface ActionsProps {
  cancelDataHandleClick: () => void;
  initialData: EquipmentServicePeriodEntity[];
  servicePeriods: EquipmentServicePeriodEntity[];
  saveDataHandleClick: () => void;
}

const Actions = (props: ActionsProps) => {
  const { cancelDataHandleClick, initialData, servicePeriods, saveDataHandleClick } = props;
  return (
    <>
      <PageDialogActions>
        <Button
          disabled={JSON.stringify(initialData) === JSON.stringify(servicePeriods)}
          color={"primary"}
          variant='outlined'
          onClick={cancelDataHandleClick}
          size={"small"}
        >
          Отмена
        </Button>
        <Button
          disabled={JSON.stringify(initialData) === JSON.stringify(servicePeriods)}
          color={"primary"}
          variant='contained'
          onClick={saveDataHandleClick}
          size={"small"}
        >
          Сохранить измениения
        </Button>
      </PageDialogActions>
    </>
  );
}

export default Actions;