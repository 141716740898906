import React, {useState} from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {DataGrid, GridColumns, GridSearchIcon} from "@mui/x-data-grid";
import MuiDataGridStyles from "components/MuiDataGridStyles";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AddSportSubZone from "../Dialogs/AddSportSubZone";
import {Box, IconButton, Menu, MenuItem, TextField, Typography} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteSportSubZone from "../Dialogs/DeleteSportSubZone";
import EditSportSubZone from "../Dialogs/EditSportSubZone";
import {useParams} from 'react-router-dom'
import {useStores} from '../../../../../store';
import {observer} from 'mobx-react-lite';
import clsx from "clsx";
import ClearIcon from '@mui/icons-material/Clear';
import {useTheme} from "@mui/styles";


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		'& .MuiDataGrid-row': {
			'&:hover, &.Mui-selected': {
				'& $moreButton': {
					opacity: 1
				}
			},
		}
	},
	toolbar: {
		padding: theme.spacing(0.5, 0.5, 0),
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		height: 90
	},
	moreButton: {
		opacity: 0,
		transition: 'all .3s'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(6),
		right: theme.spacing(6),
	},
	textField: {
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		margin: theme.spacing(1, 0.5, 1.5),
		'& .MuiSvgIcon-root': {
			marginRight: theme.spacing(0.5),
		},
		'& .MuiInput-underline:before': {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
	},
}));

export const escapeRegExp = (value: any) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
			<div className={classes.toolbar}>
				<Box
						display={'flex'}
						alignItems={'center'}
				>
					<Typography variant="h1">
						{props.title}

						<Box
								component={'small'}
								sx={{
									ml: 1,
									fontSize: '.7em',
									color: theme.palette.text.secondary,
									opacity: .5
								}}
						>
							{/*{settingsStore?.sportSubZonesCount}*/}
						</Box>
					</Typography>
					<Box ml={3}>
						{/*<GridToolbarFilterButton/>*/}
						{/*<GridToolbarDensitySelector/>*/}
					</Box>
				</Box>
				<TextField
						variant="standard"
						value={props.value}
						onChange={props.onChange}
						placeholder="Поиск…"
						className={classes.textField}
						InputProps={{
							startAdornment: <GridSearchIcon fontSize="small"/>,
							endAdornment: (
									<IconButton
											title="Clear"
											aria-label="Clear"
											size="small"
											style={{visibility: props.value ? 'visible' : 'hidden'}}
											onClick={props.clearSearch}
									>
										<ClearIcon fontSize="small"/>
									</IconButton>
							),
						}}
				/>
			</div>
	);
};

interface QuickSearchToolbarProps {
	clearSearch: () => void;
	onChange: () => void;
	value: string;
	title: string;
}

const SportSubZones = observer(() => {
	const classes = useStyles();
	const tableClasses = MuiDataGridStyles();
	const {sportZoneId} = useParams();

	const {settingsStore} = useStores();

	const [subZone, setSubZone] = useState();
	const [sportSubZoneDeleteDialog, setSportSubZoneDeleteDialog] = useState(false);
	const [sportSubZoneEditDialog, setSportSubZoneEditDialog] = useState(false);
	const [sportSubZoneAddDialog, setSportSubZoneAddDialog] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleDeleteSportSubZone = (value: any) => {
		setSubZone(value)
		setSportSubZoneDeleteDialog(true)
		handleCloseMoreAreas()
	};

	const handleClickMoreAreas = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMoreAreas = () => {
		setAnchorEl(null);
	};

	const columns: GridColumns = [
		{
			field: 'name',
			headerName: 'Название спортзоны',
			renderCell: (params) => {
				return (
						<h4>{params.row.name}</h4>
				);
			},
			disableColumnMenu: true,
			flex: 1,
			editable: false,
			sortable: true
		},
		{
			field: 'area', headerName: 'Площадь',
			flex: .5,
			editable: false,
			disableColumnMenu: true,
			sortable: true
		},
		{
			field: 'eps', headerName: 'Е.П.С',
			flex: .5,
			editable: false,
			disableColumnMenu: true,
			sortable: true,

			renderCell: (params) => {
				return (
						<Box
								width={'100%'}
								display='flex'
								flexDirection='row'
								justifyContent='space-between'
						>
							<div>{params.row.eps}</div>
							<div>
								<IconButton
										onClick={handleClickMoreAreas}
										size="small"
										className={classes.moreButton}
								>
									<MoreHorizIcon fontSize="inherit"/>
								</IconButton>
								<Menu
										id="more-menu"
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={handleCloseMoreAreas}
										elevation={2}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
								>
									<MenuItem
											onClick={() => openSportSubZoneEditDialogHandler(params.row)}>Изменить</MenuItem>
									<MenuItem onClick={() => handleDeleteSportSubZone(params.row)}>Удалить</MenuItem>


								</Menu>
							</div>
						</Box>
				);
			},
		},

	];

	const openSportSubZoneEditDialogHandler = (value: any) => {
		setSubZone(value)
		setSportSubZoneEditDialog(true)
		handleCloseMoreAreas()
	};

	const closeAddHandler = (value: boolean) => {
		setSportSubZoneAddDialog(value)
	};

	const closeDeleteHandler = (value: boolean) => {
		setSportSubZoneDeleteDialog(value)
		handleCloseMoreAreas()
	};

	const closeEditHandler = (value: boolean) => {
		setSportSubZoneEditDialog(value)
	};


	const [searchText, setSearchText] = React.useState('');
	const [rows, setRows] = React.useState([]);

	const requestSearch = (searchValue: any) => {
		setSearchText(searchValue);
		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
		const filteredRows = settingsStore.sportSubZones.filter((row: any) => {

			return Object.keys(row).some((field) => {
				return row[field] && searchRegex.test(row[field].toString());
			});
		});
		setRows(filteredRows);
	};

	React.useEffect(() => {
		setRows([...settingsStore?.sportSubZones] as never[]);
	}, [settingsStore.sportSubZones]);

	return (
			<>
				<DataGrid
						components={{Footer: () => <div/>, Toolbar: QuickSearchToolbar}}
						componentsProps={{
							toolbar: {
								title: 'Спортзоны',
								value: searchText,
								onChange: (event: any) => requestSearch(event.target.value),
								clearSearch: () => requestSearch(''),
							},
						}}
						rows={rows}
						columns={columns}
						className={clsx(tableClasses.root, classes.root)}
						rowHeight={60}
						onCellDoubleClick={({row}) => openSportSubZoneEditDialogHandler(row)}
						// loading={sportSubZonesStore.loading}
				/>

				<Fab
						color="primary"
						className={classes.fab}
						onClick={() => setSportSubZoneAddDialog(true)}
						disabled={!Boolean(settingsStore.sportZone)}
				>
					<AddIcon/>
				</Fab>


				<AddSportSubZone
						zoneId={sportZoneId}
						objectId={settingsStore?.sportObject?.id as string}
						open={sportSubZoneAddDialog}
						onClose={(value: boolean) => closeAddHandler(value)}
				/>
				<DeleteSportSubZone
						sportSubZone={subZone}
						open={sportSubZoneDeleteDialog}
						onClose={(value: boolean) => closeDeleteHandler(value)}
				/>
				<EditSportSubZone
						zoneId={sportZoneId}
						sportSubZone={subZone}
						open={sportSubZoneEditDialog}
						onClose={(value: boolean) => closeEditHandler(value)}
				/>
			</>
	);
})


// Main.propTypes = {
//     clearSearch: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
// };
export default SportSubZones;
