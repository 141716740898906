import React from 'react';
import {Button} from "@mui/material";
import {useReactiveVar} from "@apollo/client";
import {userVars} from "store/reactiveVarsStores/auth/user";
import {AddCircleOutlineOutlined} from "@mui/icons-material";
import {useCreateRequestAction} from "../../hooks/actions/useCreateRequestAction";

export const EquipmentRequestCreate = () => {
    const user = useReactiveVar(userVars);
    const {onCreateRequestDialogHandler} = useCreateRequestAction()


    return (
        <Button
            variant="contained"
            color="primary"
            size={'small'}
            startIcon={<AddCircleOutlineOutlined/>}
            onClick={onCreateRequestDialogHandler}
        >
            Подать заявку
        </Button>
    )
};
