import React, {forwardRef} from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        pageLayout: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'visible',
            position: 'relative',
            flexGrow: 1,
            minWidth: 0,

        },
        page: {
            position: 'relative',
            flexShrink: 0,
            borderRadius: theme.shape.borderRadius,
            minHeight: 'calc(100% - 82px)',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minWidth: 0,
            height: '100%',
        }
    }))
;

const Page = forwardRef<any, any>(({children, title = '', ...rest}, ref) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.pageLayout}>
                <div
                    ref={ref} {...rest}
                    className={classes.page}
                >
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                    {children}
                </div>
            </div>
        </div>
    );
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default Page;
