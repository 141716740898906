import {RequestEntity} from "../../../../types/RequestEntity";
import {Box} from "@mui/material";
import DateViewer from "components/DateViewer";
import React from "react";

// Компонент рендеринга ячейки для даты
export const DateCellRenderer = ({equipmentRequestEntity}: { equipmentRequestEntity: RequestEntity }) => {
    return (
        <Box color={'grey.600'}>
            <DateViewer date={equipmentRequestEntity?.createdAt}/>
        </Box>
    );
};