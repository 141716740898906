import { makeVar, ReactiveVar } from "@apollo/client";


export type Pagination = {
  page: number;
  pageSize: number;
};

export const EquipmentRelocationRequestListPaginationVarInitial: Pagination = {
  page: 0,
  pageSize: 50
};

export const equipmentRelocationRequestListPaginationVar = makeVar<Pagination>(
  EquipmentRelocationRequestListPaginationVarInitial
);
export const useEquipmentRelocationRequestListPaginationVar = () => createEquipmentRelocationRequestListPaginationVar(equipmentRelocationRequestListPaginationVar);

const createEquipmentRelocationRequestListPaginationVar = (equipmentRelocationRequestListPaginationVar: ReactiveVar<Pagination>) => {

  const setPagination = (pagination: Pagination) => {
    equipmentRelocationRequestListPaginationVar(pagination);
  };

  return {
    setPagination
  };
};
