import {Box, TextField} from "@mui/material";
import {AcceptRequestInput, EquipmentRelocationRequestEntity} from "graphql/graphQlApiHooks";
import {observer} from "mobx-react";
import {useStores} from "store";
import {useState} from "react";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";
import {NotifiableUsersSelect} from "./ui/NotifiableUsersSelec";
import Dialog from "components/Dialogs/Dialog";
import {LoadingButton} from "@mui/lab";

interface Props {
    data?: EquipmentRelocationRequestEntity | null
    open: boolean
    onClose: () => void
    rejectButton?: any
}

const AcceptRelocation = observer((props: Props) => {
    const {
        data,
        open,
        onClose,
        rejectButton
    } = props;

    const {equipmentRelocationRequestStore} = useStores();

    const [notifiableUsers, setNotifiableUsers] = useState<any>([]);
    const [comment, setComment] = useState<string>('');
    const [errorsHandler, setErrorsHandler] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState(false);


    const onChangeNotifiableUsers = (value: any) => {
        setErrorsHandler(false)
        setNotifiableUsers(value)
    }

    const onChangeComment = (value: string) => {
        setComment(value)
    }
    const handleConfirm = () => {
        setSubmitting(true)
        const input: AcceptRequestInput = {
            equipmentRelocationRequestId: data?.id as string,
            targetSportObjectId: data?.targetSportObject?.id as string,
            notifiableUserIds: notifiableUsers.map((user: any) => user.id),
            comments: (comment !== '') ? comment : null,
        }

        equipmentRelocationRequestStore.accept(input).then(() => {
            handleClose()
        }).catch((error: any) => {
            console.error(error.message)
        }).finally(() => {
            setSubmitting(false)
        })
    }

    const handleClose = () => {
        setNotifiableUsers([]);
        setComment('')
        setErrorsHandler(false)
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={'Одобрение перемещения оборудования'}
            titles={[
                <EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt}/>,
                <StepperRelocation
                    targetSportObject={data?.targetSportObject}
                    sourceSportObject={data?.sourceSportObject}
                />
            ]}
            actions={<>
                {rejectButton}
                <LoadingButton
                    loading={submitting}
                    variant={'contained'}
                    onClick={handleConfirm}
                >
                    Одобрить перемещение
                </LoadingButton>
            </>}
        >
            <Box sx={{mb: 2}}>
                <NotifiableUsersSelect
                    targetSportObjectId={data?.targetSportObject?.id as string}
                    sourceSportObjectId={data?.sourceSportObject?.id as string}

                    errorsHandler={errorsHandler}
                    onChangeNotifiableUsers={onChangeNotifiableUsers}
                />
            </Box>

            <TextField
                size="small"
                margin="dense"
                id="AcceptRelocationComment"
                type="text"
                fullWidth
                multiline={true}
                rows={5}
                placeholder={'Комментарий к перемещению'}
                value={comment}
                onChange={({target: {value}}) => onChangeComment(value)}
            />

        </Dialog>
    );
})

export default AcceptRelocation;