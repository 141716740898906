import React, { useEffect, useState } from "react";
import { GetSportEquipmentsDocument, SportEquipmentEntity, useChangeCategoriesMutation } from "graphql/graphQlApiHooks";
import { Alert, Box, Button, Card, DialogActions, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { declOfNum } from "helpers/declination";
import { SportEquipmentCategory } from "../../Dialogs/views/SportEquipmentCategory";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";

interface ChangeCategoriesValues {
  sportEquipmentIds: string[];
  equipmentTypeId: string;
  sportCategoryId: string;
  sportKindId: string;
}

interface Props {
  close: () => void;
  equipments: string[];
}

export const SportEquipmentsChangeСategory = (props: Props) => {
  const { close, equipments } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { getInput } = useGetSportEquipmentInputVars();

  const [changeCategories] = useChangeCategoriesMutation();

  const [sportEquipment, setSportEquipment] = useState<SportEquipmentEntity | null>(null);

  const ChangeCategoriesSchema = Yup.object().shape({
    sportCategoryId: Yup.string().required("Выберите категорию спорта").nullable(),
    sportKindId: Yup.string().required("Выберите вид спорта").nullable(),
    equipmentTypeId: Yup.string().required("Выберите тип оборудования").nullable()
  });

  const formik = useFormik({
    initialValues: {
      sportEquipmentIds: equipments,
      equipmentTypeId: "",
      sportCategoryId: "",
      sportKindId: ""
    } as ChangeCategoriesValues,
    validationSchema: ChangeCategoriesSchema,
    onSubmit: (
      values: ChangeCategoriesValues,
      { setSubmitting }: FormikHelpers<ChangeCategoriesValues>
    ) => {
      changeCategories({
        variables: {
          input: {
            sportEquipmentIds: equipments,
            toEquipmentTypeId: values.equipmentTypeId,
            toSportCategoryId: values.sportCategoryId,
            toSportKindId: values.sportKindId
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetSportEquipmentsDocument,
            fetchPolicy: "network-only",
            variables: {
              input: getInput()
            }
          }
        ]
      })
        .then((res) => {
          close();
          enqueueSnackbar(
            `Категория спорта успешно изменена.`
          );
        })
        .catch(({ message }) => {
          enqueueSnackbar(
            message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
            {
              variant: "error"
            }
          );

        })
        .finally(() => setSubmitting(false));
    }
  });

  const { isSubmitting, handleSubmit, touched, errors, setValues } = formik;

  useEffect(() => {
    if (sportEquipment) {
      setValues(prevState => ({
        ...prevState,
        ...(sportEquipment?.equipmentTypeObject && { equipmentTypeId: sportEquipment?.equipmentTypeObject.id }),
        ...(sportEquipment?.sportCategoryObject && { sportCategoryId: sportEquipment?.sportCategoryObject.id }),
        ...(sportEquipment?.sportKindObject && { sportKindId: sportEquipment?.sportKindObject.id })
      } as ChangeCategoriesValues));
    }
  }, [sportEquipment]);


  return (
    <>

      <Box sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        borderTop: "1px solid",
        borderColor: "divider"
      }}>
        <Alert severity="info">
          <>
            {declOfNum(equipments.length, ["Выбрана", "Выбрано", "Выбрано"])}
            <b>{equipments.length}</b> {declOfNum(equipments.length, ["единица", "единицы", "единиц"])} оборудования,
            для изменнения категории. <br />
            Укажите <b>новую категорию</b> для выбранного оборудования.
          </>
        </Alert>
      </Box>

      <DialogContent
        dividers
        sx={{
          bgcolor: "background.default"
        }}
      >
        <Card sx={{ p: 3 }}>
          <SportEquipmentCategory
            vertical
            touched={touched}
            errors={errors}
            sportEquipment={sportEquipment}
            setSportEquipment={setSportEquipment}
          />
        </Card>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={close}
          color="secondary"
          size="small"
        >
          Отмена
        </Button>

        <LoadingButton
          loading={isSubmitting}
          size="small"
          onClick={() => handleSubmit()}
          variant="contained"
        >
          Изменить категорию
        </LoadingButton>
      </DialogActions>

    </>
  );
};
