import React, {useState} from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {observer} from "mobx-react-lite";
import {Box, Chip, IconButton, Menu, MenuItem, Stack, styled, TextField, Tooltip, Typography} from "@mui/material";
import MuiDataGridStyles from "components/MuiDataGridStyles";
import {useStores} from "../../../../../store";
import {DataGrid as MuiDataGrid, GridRowModel, GridSearchIcon} from "@mui/x-data-grid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {escapeRegExp} from "../../SportObjects/Main";
import AddDictionaries from "../Dialogs/AddDictionaries";
import EditDictionaries from "../Dialogs/EditDictionaries";
import DeleteDictionaries from "../Dialogs/DeleteDictionaries";
import {
	EquipmentSpecificationDictionaryEntity,
	EquipmentTypeDictionaryEntity
} from "../../../../../graphql/graphQlApiHooks";
import ClearIcon from "@mui/icons-material/Clear";
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddSpecification from "../Dialogs/AddSpecification";
import {useTheme} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		'& .MuiDataGrid-row': {
			'&:hover, &.Mui-selected': {
				'& $moreButton': {
					opacity: 1
				}
			},
		}
	},
	toolbar: {
		padding: theme.spacing(0.5, 0.5, 0),
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		height: 90
	},
	moreButton: {
		opacity: 0,
		transition: 'all .3s'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(6),
		right: theme.spacing(6),
	},
	textField: {
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		margin: theme.spacing(1, 0.5, 1.5),
		'& .MuiSvgIcon-root': {
			marginRight: theme.spacing(0.5),
		},
		'& .MuiInput-underline:before': {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
	},
}));

const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion disableGutters elevation={0} {...props} />
))(({theme}) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary
				{...props}
		/>
))(({theme}) => ({
	backgroundColor: theme.palette.background.paper,
	flexDirection: 'row-reverse',
	height: 60,
	'&:hover': {
		backgroundColor: 'rgb(249, 250, 252)',
	},
	'&.Mui-expanded': {
		backgroundColor: 'rgb(246, 248, 251)',
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between'
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DataGrid = styled(MuiDataGrid)(({theme}) => ({
	"& .MuiDataGrid-columnsContainer": {display: "none"},
	"& .MuiDataGrid-columnHeaders": {display: "none"},
	"& .MuiDataGrid-virtualScroller": {marginTop: "0!important"},
}));

function RenderCellMoreMenu(props: { row: EquipmentTypeDictionaryEntity | EquipmentSpecificationDictionaryEntity, openEditDialogHandler: (value: any) => void, handleDelete: (value: any) => void }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClickMoreAreas = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMoreAreas = () => {
		setAnchorEl(null);
	};

	function openEditDialogHandler(row: GridRowModel<any>) {
		props.openEditDialogHandler(row)
		handleCloseMoreAreas()
	}

	function handleDelete(row: GridRowModel<any>) {
		props.handleDelete(row)
		handleCloseMoreAreas()
	}

	return (

			<div>
				<IconButton
						onClick={handleClickMoreAreas}
						size="small"
						// className={classes.moreButton}
				>
					<MoreHorizIcon fontSize="inherit"/>
				</IconButton>
				<Menu
						id={`more-menu-${props.row.id}`}
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleCloseMoreAreas}
						elevation={2}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
				>
					<MenuItem onClick={() => openEditDialogHandler(props.row)}>Изменить</MenuItem>
					<MenuItem onClick={() => handleDelete(props.row)}>Удалить</MenuItem>


				</Menu>
			</div>
	);
}

const DictionariesContent = observer(() => {
	const classes = useStyles();
	const theme: Theme | any = useTheme();
	const tableClasses = MuiDataGridStyles();

	const {dictionariesStore} = useStores();

	const [editableDictionary, setEditableDictionary] = useState();
	const [editDialog, setEditDialog] = useState<boolean>(false);
	const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
	const [addDictionariesDialog, setAddDictionariesDialog] = useState<boolean>(false);
	const [openAddSpecification, setOpenAddSpecification] = useState<boolean>(false);
	const [currentEquipmentType, setCurrentEquipmentType] = useState<EquipmentTypeDictionaryEntity | null>(null);
	const [anchorSpecification, setAnchorSpecification] = React.useState<null | HTMLElement>(null);


	const handleDelete = (value: any) => {
		setEditableDictionary(value)
		setDeleteDialog(true)
	};

	const openEditDialogHandler = (value: any) => {
		setEditableDictionary(value)
		setEditDialog(true)
	};

	const closeAddHandler = (value: boolean) => {
		setAddDictionariesDialog(value)
	};

	const closeDeleteHandler = (value: boolean) => {
		setDeleteDialog(value)
	};

	const closeEditHandler = (value: boolean) => {
		setEditDialog(value)
	};


	const [searchText, setSearchText] = React.useState('');
	const [rows, setRows] = React.useState([]);

	const requestSearch = (searchValue: any) => {
		setSearchText(searchValue);
		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
		const filteredRows = dictionariesStore.activeSportKind.equipmentTypes.filter((row: any) => {

			return Object.keys(row).some((field) => {
				return row[field] && field !== 'id' && field !== '__typename' && searchRegex.test(row[field].toString());
			});
		});

		setRows(filteredRows);
	};

	React.useEffect(() => {
		if (dictionariesStore?.activeSportKind?.equipmentTypes ) {
			setRows([...dictionariesStore?.activeSportKind?.equipmentTypes] as never[]);
		} else {
			setRows([])
		}
	}, [dictionariesStore?.activeSportKind]);

	const handleChange = (panel: string, id: string) => {
		dictionariesStore.setExpanded((panel !== dictionariesStore.expanded) ? panel : false)
	};

	const handleClickAddSpecificationPanel = (event: React.MouseEvent<HTMLButtonElement>, equipmentType: EquipmentTypeDictionaryEntity) => {
		setAnchorSpecification(event.currentTarget);
		setOpenAddSpecification(true)
		setCurrentEquipmentType(equipmentType)
	};

	const handleCloseAddSpecificationPanel = () => {
		setAnchorSpecification(null);
		setOpenAddSpecification(false)
		setCurrentEquipmentType(null)
	};

	return (
			<>
				<div className={classes.toolbar}>
					<Box
							display={'flex'}
							alignItems={'center'}
					>

						<Typography variant="h1">
							{dictionariesStore?.activeSportKind?.name}
						</Typography>
					</Box>
					<TextField
							variant="standard"
							value={searchText}
							onChange={(event: any) => requestSearch(event.target.value)}
							placeholder="Поиск…"
							className={classes.textField}
							InputProps={{
								startAdornment: <GridSearchIcon fontSize="small"/>,
								endAdornment: (
										<IconButton
												title="Clear"
												aria-label="Clear"
												size="small"
												style={{visibility: searchText ? 'visible' : 'hidden'}}
												onClick={() => requestSearch('')}
										>
											<ClearIcon fontSize="small"/>
										</IconButton>
								),
							}}
					/>
				</div>

				<Typography variant={'caption'} px={2} mb={1} pt={0}  display={'block'}>Тип оборудования</Typography>

				<Box sx={{
					height: 'calc(100% - 110px)',
					flexGrow: 1,
					overflowY: 'auto',
					background: '#fff',
					boxShadow: '0 0 0 1px #e8eff3',
					borderRadius: 1,
				}}>
					{rows.map((row: EquipmentTypeDictionaryEntity, index: number) => (
							<Stack direction={'row'} justifyContent={'space-between'} p={2} >
								<Box
										display={'flex'}
										alignItems={'center'}
										ml={1}
								>

									<Typography variant={'h5'}>{row.name}</Typography>
								</Box>
								<RenderCellMoreMenu
										row={row}
										openEditDialogHandler={openEditDialogHandler}
										handleDelete={handleDelete}
								/>
							</Stack>
					))}

				</Box>

				<Fab
						color="primary"
						className={classes.fab}
						onClick={() => setAddDictionariesDialog(true)}
						disabled={!Boolean(dictionariesStore.dictionaries)}
				>
					<AddIcon/>
				</Fab>

				<EditDictionaries
						value={editableDictionary}
						open={editDialog}
						onClose={(value: boolean) => closeEditHandler(value)}
				/>

				<AddDictionaries
						open={addDictionariesDialog}
						onClose={(value: boolean) => closeAddHandler(value)}
				/>
				<DeleteDictionaries
						dictionary={editableDictionary}
						open={deleteDialog}
						onClose={(value: boolean) => closeDeleteHandler(value)}
				/>

				<AddSpecification
						anchorEl={anchorSpecification}
						open={openAddSpecification}
						onClose={handleCloseAddSpecificationPanel}
						equipmentType={currentEquipmentType}
				/>
			</>
	)
})

export default DictionariesContent;
