import React, {FC} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";

import DashboardLayout from "views/infrastructure/Layouts/Dashboard";
import MainLayout from "views/infrastructure/Layouts/Main";
import NotFoundView from "views/infrastructure/Layouts/NotFound";

import Settings from "views/Pages/Settings";
import UserSettings from "views/Pages/UserSettings";

import Login from "views/Login";

import Equipments from "views/Pages/Equipments";
import Dictionaries from "views/Pages/Settings/Dictionaries";
import EquipmentRequest from "views/Pages/EquipmentRequest";
import RentEquipments from "views/Pages/RentEquipments";
import ReturnedEquipments from "views/Pages/ReturnedEquipments";
import {EquipmentRelocate} from "views/Pages/EquipmentRelocate";
import EquipmentRelocatable from "views/Pages/EquipmentRelocate/Dialogs/EquipmentRelocatable";
import Structure from "views/Pages/Settings/SportObjects";
import EditSportCategoryDialog from "views/Pages/Settings/Dictionaries/Dialogs/EditSportCategoryDialog";
import ResponsibleUsers from "views/Pages/Settings/ResponsibleUsers";
import {EditSportEquipment} from "views/Pages/Equipments/components/Dialogs/Edit";
import AddSportEquipment from "views/Pages/Equipments/components/Dialogs/Add";
import ServicePeriods from "./views/Pages/Equipments/components/Dialogs/ServicePeriods";
import SportEquipment from "./views/Pages/Equipments/components/Dialogs/SportEquipment";
import RentDialog from "./views/Pages/RentEquipments/Dialogs/RentDialog";
import {DepartmentEnum} from "./store/stores/department.enum";
import {HistoryDialog} from "./views/Pages/Equipments/components/Dialogs/History";
import {MeasurementUnitsTable} from './views/Pages/Settings/MeasurementUnits/MeasurementUnitsTable';
import RepairsRequest from "./views/Pages/RepairsRequest";
import Reports from "views/Pages/EquipmentsServiceJob";


interface PrivateRouteProps {
    roles: Array<DepartmentEnum>;
    children?: any;
}

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
    const {roles, children} = props;
    let location = useLocation();

    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : null;
    const userHasRequiredRole = roles.some(role => user?.departments?.includes(role));

    if (!user) {
        return <Navigate
            to='/login'
            state={{from: location}}
        />;
    }

    if (user && !userHasRequiredRole) {
        return <Navigate
            to='/'
            state={{from: location}}
        />;
    }

    return children ? children : <Outlet/>;
};

const routes = [
    {
        path: "/",
        element: <DashboardLayout/>,
        children: [
            {path: "/", element: <Navigate to='/equipments'/>},
            {
                path: "/equipments/*", element: <Equipments/>, children: [
                    {path: ":sportEquipmentId", element: <SportEquipment/>},
                    {path: "add", element: <AddSportEquipment/>},
                    {path: "edit/:sportEquipmentId", element: <EditSportEquipment/>},
                    {path: "service-periods/:sportEquipmentId", element: <ServicePeriods/>},
                    {path: "history/:sportEquipmentId", element: <HistoryDialog/>}
                ]
            },
            {
                path: "relocateEquipments/*", element: <EquipmentRelocate/>, children: [
                    {path: ":id", element: <EquipmentRelocatable/>},
                    {path: "equipments/:sportEquipmentId", element: <SportEquipment/>},
                ]
            },
            {
                path: "settings/*", element: <Settings/>,
                children: [
                    {path: 'structure', title: 'Структура спортивных объектов', element: <Structure/>},
                    {path: 'responsibleUsers', title: 'Управление правами пользователей', element: <ResponsibleUsers/>},
                    {
                        path: 'dictionaries/', title: 'Справочники', element: <Dictionaries/>, children: [
                            {path: 'editSportCategory/:sportCategoryId', element: <EditSportCategoryDialog/>},
                        ],
                    },
                    {path: 'measurementUnits', title: 'Единицы измерения', element: <MeasurementUnitsTable/>},
                ]
            },
            {path: "settings/user", title: "Настройки пользователя", element: <UserSettings/>},

            {path: "/reports", element: <Reports/>},
            {path: "/equipmentRequest", element: <EquipmentRequest/>},
            {path: "/repairs-request", element: <RepairsRequest/>, children: [

                    {path: "equipments/:sportEquipmentId", element: <SportEquipment/>},
                ]},
            {
                path: "/rent",
                element: <PrivateRoute
                    roles={[
                        DepartmentEnum.EquipmentAcceptanceAndDiagnostics,
                        DepartmentEnum.SportObject,
                    ]}
                >
                    <RentEquipments/>
                </PrivateRoute>,
                children: [
                    {
                        path: "order/:orderId", element: <RentDialog/>, children: [
                            {
                                path: "equipment/:sportEquipmentId", element: <SportEquipment
                                    dialogProps={{
                                        open: true,
                                        sx: {
                                            "& .MuiDialog-paper": {
                                                maxWidth: "920px"
                                            }
                                        }
                                    }}
                                />
                            }
                        ]
                    }

                ]
            },
            {
                path: "/rent-returned", element: <PrivateRoute
                    roles={[
                        DepartmentEnum.EquipmentAcceptanceAndDiagnostics,
                        DepartmentEnum.SportObject,
                    ]}
                ><ReturnedEquipments/></PrivateRoute>, children: [
                    {path: ":sportEquipmentId", element: <SportEquipment/>},
                    {
                        path: "order/:orderId", element: <RentDialog/>, children: [
                            {
                                path: "equipment/:sportEquipmentId", element: <SportEquipment
                                    dialogProps={{
                                        open: true,
                                        sx: {
                                            "& .MuiDialog-paper": {
                                                maxWidth: "920px"
                                            }
                                        }
                                    }}
                                />
                            }
                        ]
                    }
                ]
            },
            {path: "*", element: <Navigate to='/404'/>}
        ]
    },
    {
        path: "/",
        element: <MainLayout/>,
        children: [
            {path: "404", element: <NotFoundView/>},
            {path: "/", element: <Navigate to='/report'/>},
            {path: "*", element: <Navigate to='/404'/>}
        ]
    },
    {
        path: "/login",
        element: <Login/>
    }
];

export default routes;
