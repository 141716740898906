import React from "react";
import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {RejectRequestDialog} from "../../components/Dialogs/RejectRequestDialog";

export const useRejectRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const onRejectReasonDialogHandler = (equipment: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Отклонение заявки на новое оборудование",
            maxWidth: "sm",
            fullWidth: true,
            children: <>
                <RejectRequestDialog
                    equipment={equipment}
                    close={() => {
                        closeDialog();
                    }}
                />
            </>
        });
    };

    return {onRejectReasonDialogHandler}
};