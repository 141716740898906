import {RequestEntity} from "../../../../types/RequestEntity";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import Tooltiper from "components/Tooltiper";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import React from "react";

// Компонент рендеринга ячейки для количества
export const CountCellRenderer = ({equipmentRequestEntity}: { equipmentRequestEntity: RequestEntity }) => {
    return (
        <>
            {isSingleRequest(equipmentRequestEntity) &&
                <Tooltiper
                    text={equipmentRequestEntity?.count + (equipmentRequestEntity?.unitOfMeasurement?.name || '')}
                    props={{textAlign: 'center'}}/>
            }
            {isCombinedRequest(equipmentRequestEntity) &&
                <Tooltiper
                    text={`${equipmentRequestEntity?.childRequests?.reduce((acc, curr) => acc + Number(curr.count), 0)}`}
                    props={{
                        variant: 'subtitle1',
                        textAlign: 'center'
                    }}
                />}
        </>
    );
};