import React, {useEffect, useRef, useState} from "react";
import {lighten, styled} from "@mui/material/styles";
import {
    AutocompleteGetTagProps,
    Box,
    ButtonBase,
    Chip as MuiChip,
    chipClasses,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    Paper,
    Popover,
    Popper,
    Stack,
    Tooltip,
    Typography,
    useAutocomplete
} from "@mui/material";
import Iconify from "../Iconify";
import {BootstrapTooltip} from "../BootstrapTooltip";

const Root = styled("div")(({theme}) => `
  font-size: 14px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-items: flex-end;
  cursor: text;
  height: 48px;
  border-bottom: 1px solid;
  border-color: ${theme.palette?.divider}
  
`
);

const InputWrapper = styled("div")(({theme}) => `
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    min-width: 0;     
    row-gap: 6px;
    padding: 7px 0;
    height: max-content;
`
);
const ChipBar = styled("div")(({theme}) => `
    display: flex;
    flex-direction: row;
    padding: 0;
    overflow: hidden;
    min-width: 0;
    flex: 1;
    margin-right: 12px;
    overflow: auto;
    height: 100%;
`
);

const Chip = styled(MuiChip)(({theme}) => {
    return {
        [`&.${chipClasses.root}`]: {
            height: "auto",
            padding: theme.spacing(.5, .7)
        },
        [`& .${chipClasses.label}`]: {
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "12px",
            padding: 0
        },
        [`& .${chipClasses.icon}`]: {
            color: theme.palette.text.primary,
            fontSize: "6px",
            marginRight: "4px",
            marginLeft: "0"
        }
    };
}) as typeof MuiChip;


const StyledPopper = styled(Popover)(({theme}) => ({
    zIndex: theme.zIndex.modal
}));

interface FilterComponentProps {
    onChange: (result: string) => any,
    value: string | null,
    onClose?: (value: any) => void
    onSubmit?: string
    options?: any[]
}

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
    title: string;
    label: string;
    filterComponent: any;
    disableDelete?: boolean;
    value: any;
    onDelete: any;
    onSelectOpen: boolean;
    onChangeFilterValue: (value: string) => void;
    options?: any[];
}

function Tag(props: TagProps) {
    const {
        label,
        title,
        filterComponent,
        onDelete,
        disableDelete,
        value,
        onSelectOpen,
        onChangeFilterValue,
        options,
        ...other
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState(false);
    const chipRef = useRef(null);

    useEffect(() => {
        if (chipRef?.current) {
            setAnchorEl(chipRef?.current);
            onSelectOpen && !disableDelete && (value?.length === 0 || !value) && setOpen(true);
            onSelectOpen && value?.length > 0 && onChangeFilterValue(value);
        }
    }, [chipRef?.current]);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };
    const FilterComponent = (rest: FilterComponentProps) => filterComponent({...rest});
    const id = open ? "filter-menu" : undefined;

    return (
        <>
            <Chip
                label={
                    <BootstrapTooltip title={title + (label ? ": " + label : "")}><Box
                        sx={{
                            maxWidth: 290,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        <Box
                            flexWrap={'nowrap'}
                            display={'flex'}
                            minWidth={0}
                        >
                            <Typography variant={"body2"}>{title}</Typography>
                            {label &&
                                <Typography
                                    ml={1}
                                    variant={"subtitle2"}
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {label}
                                </Typography>}
                        </Box>
                    </Box>
                    </BootstrapTooltip>
                }
                component={ButtonBase}
                clickable
                size={"small"}
                onClick={handleClick}
                onDelete={!disableDelete ? onDelete : undefined}
                ref={chipRef}
                disableRipple={true}

                sx={{
                    "&.MuiButtonBase-root": {
                        px: 2,
                        py: 0,
                        my: 0,
                        transition: "color .03s, background .03s",
                        height: 32,
                        fontSize: "1em",
                        bgcolor: "grey.200",
                        "&:hover": {
                            bgcolor: "grey.300"
                        },
                        "&:focus:hover": {
                            bgcolor: "grey.300"
                        },
                        "&:focus": {
                            bgcolor: "grey.200"
                        }
                    },


                    "& .MuiChip-deleteIcon": {
                        transition: "color .3s, background .3s",
                        ml: 1.2,
                        mr: 0,
                        p: 0.5,
                        bgcolor: "grey.600",
                        color: "grey.100",
                        borderRadius: 5,
                        display: "flex",
                        "&:hover": {
                            bgcolor: "grey.800",
                            color: "grey.0"
                        }
                    }
                }}

                deleteIcon={<BootstrapTooltip title={"Удалить"}>
                    <Box
                        sx={{
                            transition: "color .3s, background .3s",
                            ml: 1.2,
                            mr: 0,
                            p: 0.5,
                            bgcolor: "grey.600",
                            color: "grey.100",
                            borderRadius: 5,
                            display: "flex",
                            "&:hover": {
                                bgcolor: "grey.800",
                                color: "grey.0"
                            }
                        }}
                    >
                        <Iconify
                            color={"inherit"}
                            fontSize={"small"}
                            icon={"eva:close-fill"}
                        />
                    </Box>
                </BootstrapTooltip>}
                {...other}
            />
            <StyledPopper
                id={id}
                open={open}
                onClose={() => {
                    setOpen(false);
                    if (!value || value?.length === 0 && !disableDelete) {
                        if (typeof value !== 'boolean') onDelete();
                    }
                }}
                anchorEl={anchorEl}
                transitionDuration={0}
                PaperProps={{
                    sx: {
                        minWidth: 250
                    }
                }}
            >
                <Stack direction={"column"}>
                    <Stack
                        direction={"row"}
                        width={"100%"}
                        justifyContent={"space-between"}
                        px={2}
                        py={.5}
                        sx={{
                            bgcolor: "background.dark",
                            color: "text.contrast"
                        }}
                    >
                        <Typography variant={"caption"} color={"inherit"}>{title}</Typography>
                        <IconButton color={"inherit"} aria-label="close" size="small"
                                    onClick={() => {
                                        setOpen(false);
                                        if (!value || value?.length === 0 && !disableDelete) onDelete();
                                    }}>
                            <Iconify icon="eva:close-fill" fontSize="small"/>
                        </IconButton>

                    </Stack>
                    <FilterComponent
                        value={value}
                        onClose={(value) => {
                            setOpen(false);
                            if (!value || value?.length === 0 && !disableDelete) onDelete();
                        }}
                        onChange={(value) => onChangeFilterValue(value)}
                        options={options ? options : undefined}
                    />
                </Stack>

            </StyledPopper>
        </>
    );
}

const StyledTag = styled(Tag)<TagProps>(({theme}) => `
  display: flex;
  align-items: center;
  margin: 2px;
  line-height: 22px;
  border-radius: 16px;
  box-sizing: content-box;
  padding: 2px 4px 2px 10px;
  outline: 0;
  overflow: hidden;
  height: '32px';
    background-color: ${theme.palette?.grey?.[200]};

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : theme.palette?.grey?.[300]};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : lighten(theme.palette?.grey?.[300], .9)};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

`
);

export interface Filter {
    name: string;
    title: string;
    filterComponent: (props: any) => JSX.Element;
    disableDelete?: boolean
    value: any,
    label?: string,
    divider?: boolean,
    onSelectOpen?: boolean,
    type: "search" | "autocomplete" | "dateRange" | "menu" | "switch" | "custom",
    options?: any[],
}

interface Props {
    filters?: Filter[],
    placeholder?: string,
    onDelete?: (option: Filter) => void,
    clearFilters?: () => void
    onChangeFilterValue?: (filterName: string, value: any) => void,
}

export const FiltersToolbar = (props: Props) => {
    const {filters, onDelete, clearFilters, onChangeFilterValue, placeholder = "Поиск и фильтры"} = props;

    const [values, setValues] = useState<Filter[]>([]);
    const [groupedOptions, setGroupedOptions] = useState<Filter[]>([]);

    const getInitialValues = () => [...filters.map((filter) => {
        const value = {...filter, onSelectOpen: false};
        if (filter?.disableDelete) return value;
        if (filter?.value?.length > 0) return value;
    })].filter(item => item) as Filter[];

    const getInitialGroupedOptions = () => [...filters.map((filter) => {
        const value = {...filter, onSelectOpen: false};
        if (!filter?.disableDelete) return value;
        if (filter?.value?.length === 0) return value;
    })].filter(item => item) as Filter[];

    useEffect(() => {
        if (values.length === 0) {
            setValues(getInitialValues());
        } else {
            setValues(prevState => {
                return [...prevState].map(item => filters.find(filer => filer.name === item.name) as Filter);
            });
        }

        if (groupedOptions.length === 0) {
            setGroupedOptions(getInitialGroupedOptions());
        } else {
            setGroupedOptions(prevState => {
                return [...prevState].map(item => filters.find(filer => filer.name === item.name) as Filter);
            });

        }
    }, [filters]);


    const {
        getRootProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        focused,
        setAnchorEl,
        anchorEl,
        popupOpen
    } = useAutocomplete(
        {
            value: values,
            multiple: true,
            options: groupedOptions,
            onChange: (event, value, reason, details) => {
                if (reason === "selectOption") {
                    setValues(prevState => [...prevState, {...details?.option as Filter, onSelectOpen: true}]);
                    anchorEl?.blur();
                }
                if (reason === "removeOption" && event?.type !== "keydown") {
                    chipRemovalHandler(details?.option as Filter);
                }

            },
            onInputChange: (event, value, reason) => {

                const filterList = getInitialGroupedOptions();
                const changedGroupedOptions = filterList.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));
                const searchFilters = [...filterList].filter(item => item.type === "search" && value !== "");
                const autoCompleteFilters = [...filterList].filter(item => item.type === "autocomplete" && value !== "");
                const searchableOptions = [...searchFilters].map(item => ({
                    ...item,
                    title: `${item.title}: ${value}`,
                    value: value,
                    divider: false
                }));
                const autoCompletableOptions = [...autoCompleteFilters].map(item => {
                    const optionsIncludes = item?.options?.filter(option => option.name.toLowerCase().includes(value.toLowerCase())) || [];

                    return optionsIncludes.map(option => (option && {
                        ...item,
                        title: `{${item.title}}{${option?.name}}`,
                        value: [option.id],
                        divider: false
                    })).filter(item => item);
                }).flat();
                if (
                    changedGroupedOptions.length > 0 ||
                    autoCompletableOptions.length > 0
                ) {
                    searchableOptions[searchableOptions.length - 1] = {
                        ...searchableOptions[searchableOptions.length - 1],
                        divider: true
                    };
                }
                if (
                    changedGroupedOptions.length > 0 &&
                    searchableOptions.length > 0 &&
                    autoCompletableOptions.length > 0
                ) {
                    autoCompletableOptions[autoCompletableOptions.length - 1] = {
                        ...autoCompletableOptions[autoCompletableOptions.length - 1],
                        divider: true
                    };
                }
                setGroupedOptions([searchableOptions, autoCompletableOptions, changedGroupedOptions].flat());
            },
            // onHighlightChange: (event, option, reason) => {
            //
            // },
            getOptionLabel: (option: any) => option.title,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            openOnFocus: true,
            clearOnBlur: false,
            autoHighlight: true

        }
    );

    const id = "filterInput";


    const chipRemovalHandler = (option: Filter) => {
        if (option?.disableDelete) return;
        onDelete(option);
        setValues(prev => prev.filter(item => item.name !== option.name));
    };

    function getTitleForAutocomplete(option: Filter) {
        const re = /{(.*?)}/g;
        const result = option.title.match(re)?.map(item => item?.replace(/[{}]/g, ""));

        return <>
            {result ? (<>{result?.[0]}: &nbsp; <b>{result?.[1]}</b></>) : option.title}
        </>;
    }

    return (
        <Root>
            <BootstrapTooltip title={"Фильтры"}>
                <IconButton
                    component={"label"}
                    htmlFor={id}
                    aria-label="filter"
                    sx={{
                        flex: 'none',
                        padding: '6px',
                        margin: '4px 8px 4px 24px',
                        cursor: 'pointer',
                    }}
                >
                    <Iconify icon="ic:round-filter-list"/>
                </IconButton>
            </BootstrapTooltip>
            <ChipBar>
                <InputWrapper
                    className={focused ? "focused" : ""}
                >
                    {values.map((option: Filter, index: number) => {
                        return (
                            <StyledTag
                                {...getTagProps({index})}
                                title={option.title}
                                label={filters.find((filter) => filter.name === option.name)?.label || ""}
                                filterComponent={option.filterComponent}
                                disableDelete={option?.disableDelete}
                                value={option.value}
                                onSelectOpen={!!option?.onSelectOpen}
                                onChangeFilterValue={(value) => onChangeFilterValue ? onChangeFilterValue(option.name, value) : undefined}
                                options={option?.options ? option.options : undefined}
                                onDelete={() => chipRemovalHandler(option)}

                            />
                        );
                    })}

                    <Box {...getRootProps()} display={"flex"} flex={1}>
                        <InputBase
                            ref={setAnchorEl}
                            sx={{flex: 1}}
                            placeholder={placeholder}
                            inputProps={{
                                ...getInputProps(),
                                id
                            }}

                        />
                        {filters.filter(filter => filter?.value?.length > 0).length > 0 &&
                            <BootstrapTooltip title={"Очистить фильтры"}>
                                <Box alignItems={"center"} display={"flex"}>
                                    <IconButton
                                        aria-label="clear-filters"
                                        sx={{ml: 1, p: .5}}
                                        size={"small"}
                                        onClick={() => {
                                            setValues((prev: any) => [...prev.filter((value: any) => value.disableDelete)]);
                                            clearFilters();
                                        }}
                                    >
                                        <Iconify icon="eva:close-fill"/>
                                    </IconButton>
                                </Box>
                            </BootstrapTooltip>}
                    </Box>
                </InputWrapper>
            </ChipBar>
            <Popper
                anchorEl={anchorEl}
                open={popupOpen}
                disablePortal
                sx={{zIndex: 1000}}
                placement={"bottom-start"}
            >
                <Paper variant={"outlined"} sx={{maxHeight: 550, overflow: "auto"}}>
                    <List disablePadding {...getListboxProps()} sx={{py: 1}}>
                        {groupedOptions.map((option, index) => {
                            if ([...values].map((val: any) => val.name).includes(option.name)) return null;
                            return (
                                <React.Fragment key={index}>
                                    <ListItem disablePadding  {...getOptionProps({option, index})} >
                                        <ListItemButton>
                                            {option.type === "autocomplete" ? getTitleForAutocomplete(option) : option.title}
                                        </ListItemButton>
                                    </ListItem>
                                    {option?.divider && <Divider sx={{my: .5}}/>}
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Paper>
            </Popper>


        </Root>
    );
};
