import { styled } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";

export const ThumbnailsUploaderButton = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px dashed",
  borderColor: theme.palette.divider,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "stretch",
  padding: "0 8px",
  "&:hover": {
    background: theme.palette.background.default
  },
  "&.disabled": {
    background: theme.palette.background.default,
    color: theme.palette.text.disabled,
  }
})) as unknown as typeof ButtonBase;
