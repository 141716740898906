import React, { useEffect } from "react";
import "./App.css";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import routes from "./routes";

import { StyledEngineProvider, Theme } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { useStores } from "./store";

import { SnackbarProvider, useSnackbar } from "notistack";
import { isEqual } from "./helpers";

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/ru";
import { DepartmentEnum } from "./store/department.enum";
import { SettingsConsumer } from "context/SettingsContext";
import ThemeComponent from "./theme/ThemeComponent";
import 'photoswipe/photoswipe.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

dayjs.locale('ru');
dayjs.extend(LocalizedFormat);

declare module '@mui/styles/defaultTheme' {
	interface DefaultTheme extends Theme {
	}
}


const CheckAuth = observer(() => {
	const routing = useRoutes(routes);
	const {snake, setSnakeMessage, userStore} = useStores();
	const {enqueueSnackbar} = useSnackbar();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (!isEqual(snake.msg, '')) {
			enqueueSnackbar(snake.msg.replace('Unexpected error value: ', '').replace(/\"/g, ''), {variant: snake.type});
			setSnakeMessage('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [snake.msg, enqueueSnackbar, setSnakeMessage, snake.type]);

	useEffect(() => {
		if (location) {
			if (!userStore?.currentUser) { // Пользователь не авторизован!
				if (!location.pathname.endsWith('login')) { // Путь не заканчивается на login
					localStorage.setItem(`startLocation`, location.pathname); // Сохраняем путь с которого пришёл пользователь
					navigate('/login') // Перенаправляем на строницу логина!
					if (JSON.parse(localStorage.getItem('user_logged_out') as string)) { //Пользователь вышел самостоятельно, перезагружаем страницу для сброса данных стора
						window.location.reload();
					}
				}
			} else if (location.pathname.endsWith('login')) { // Пользователь авторизован!
				const startLocation = localStorage.getItem(`startLocation`);

				navigate(startLocation ? startLocation : '/'); // Отправляем на страницу с которой пришёл пользователь или на главную

				localStorage.removeItem('startLocation')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, userStore?.currentUser]);

	return routing
});

const App = observer(() => {

	return (
			<StyledEngineProvider injectFirst>
				<SettingsConsumer>
					{({ settings }) => {
						return <ThemeComponent settings={settings}>
							<SnackbarProvider
								maxSnack={3}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
							>
								<CheckAuth/>
							</SnackbarProvider>
						</ThemeComponent>
					}}
				</SettingsConsumer>

			</StyledEngineProvider>
	);
});

export default App;
