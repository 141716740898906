import React from "react";
import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {SuppliedRequestDialog} from "../../components/Dialogs/SuppliedRequestDialog";

export const useSuppliedRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const onSuppliedDialogHandler = (equipment: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Подтверждение приёма нового оборудования",
            maxWidth: "lg",
            scroll: "paper",
            children: <>
                <SuppliedRequestDialog
                    equipment={equipment}
                    close={() => {
                        closeDialog();
                    }}
                />
            </>
        });
    };


    return {onSuppliedDialogHandler}
};