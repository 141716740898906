import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridEventListener,
  GridEvents,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp
} from "@mui/x-data-grid";
import clsx from "clsx";
import CustomFooterStatus from "./CustomFooterStatus";
import { NameEdit } from "./Name/edit";
import { AccountingNameEdit } from "./AccountingName/edit";
import { InventoryNumberEdit } from "./InventoryNumber/edit";
import { CommissioningDateEdit } from "./CommissioningDate/edit";
import { SportObjectEdit } from "./SportObject/edit";
import { SportSubZoneEdit } from "./SportSubZone/edit";
import { EquipmentSportCategoryEdit } from "./EquipmentSportCategory/edit";
import { SportKindEdit } from "./SportKind/edit";
import { EquipmentTypeEdit } from "./EquipmentType/edit";
import { SportZoneEdit } from "./SportZone/edit";
import { ImportedSportEquipmentRecord } from "./types";
import { rowValidate } from "./utils";
import { useDialog } from "providers/DialogProvider";
import { Alert, Button, DialogActions, DialogContent, Typography } from "@mui/material";
import Tooltiper from "components/Tooltiper";
import { TechnicalCondition } from "../../../../../../../../graphql/graphQlApiHooks";


const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: theme.spacing(0, 1),
  "& .MuiDataGrid-columnHeader:first-of-type": {
    padding: `0 !important`
  },
  "& .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell:first-of-type": {
    padding: `0`,
    "& *": {
      fontSize: "20px"
    }
  },
  "& .MuiDataGrid-cell": {
    backgroundColor: "transparent"
  },
  "& .MuiDataGrid-cell--editing": {
    "& .MuiInputBase-root": {
      height: "100%"
    }
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main
  },
  "& .not-an-exact-match": {
    backgroundColor: "rgba(184,235,255,0.29)",
    color: "#031023"
  },
  "& .not-find": {
    backgroundColor: "rgba(255,205,184,0.29)",
    color: "#031023"
  },
  "& .error": {
    backgroundColor: "rgb(126,10,15, 0.1)",
    color: "#d32f2f"
  },
  "& .MuiDataGrid-main .MuiDataGrid-row.error": {
    backgroundColor: "rgba(253,87,127,0.29)"

  }
}));


const EditableTable = (props: { data: ImportedSportEquipmentRecord[], onDataChange: (data: ImportedSportEquipmentRecord[]) => void }) => {
  const { data, onDataChange } = props;
  // const {sportObjects} = useSportObjects()
  const [dialog, closeDialog] = useDialog();

  const [rows, setRows] = useState<GridRowsProp<ImportedSportEquipmentRecord>>([]);

  useEffect(() => {
    if (data?.length && rows.length === 0) {
      setRows(data);
    }
  }, [data]);

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Название оборудования",
      editable: true,
      flex: 2,
      disableColumnMenu: true,
      renderCell: ({ row: { name } }: { row: ImportedSportEquipmentRecord }) => name && <Tooltiper text={name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) => <NameEdit {...props} />,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        const errors = rowValidate(params.row);
        if (errors?.name) return "error";
      }
    },
    {
      field: "accountingName",
      headerName: "Бухгалтерское название оборудования",
      editable: true,
      flex: 2,
      disableColumnMenu: true,
      renderCell: ({ row: { accountingName } }: { row: ImportedSportEquipmentRecord }) => accountingName &&
        <Tooltiper text={accountingName} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <AccountingNameEdit {...props} />,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        const errors = rowValidate(params.row);
        if (errors?.accountingName) return "error";
      }
    },
    {
      field: "description",
      headerName: "Описание оборудования",
      editable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: ({ row: { description } }: { row: ImportedSportEquipmentRecord }) => description &&
        <Tooltiper text={description} />,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        const errors = rowValidate(params.row as ImportedSportEquipmentRecord);
        if (errors?.description) return "error";
      }

    },
    {
      field: "inventoryNumber",
      headerName: "Инвентарный номер",
      editable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: ({ row: { inventoryNumber } }: { row: ImportedSportEquipmentRecord }) => inventoryNumber &&
        <Tooltiper text={inventoryNumber} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <InventoryNumberEdit {...props} />,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        const errors = rowValidate(params.row as ImportedSportEquipmentRecord);
        if (errors?.inventoryNumber) return "error";
      }

    },
    {
      field: "technicalCondition",
      headerName: "Техническое состояние",
      type: "boolean",
      editable: true,
      flex: .5,
      disableColumnMenu: true,
      valueGetter: ({ row: { technicalCondition } }: { row: ImportedSportEquipmentRecord }) => {
        if (typeof technicalCondition === "string")
          return technicalCondition === TechnicalCondition.Working;
        return technicalCondition;
      },
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        if (row?.isTechnicalConditionEqual === true) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isTechnicalConditionEqual === false,
          "not-find": row?.isTechnicalConditionEqual === false && !row?.technicalCondition
        });
      }

    },
    {
      field: "inUse",
      headerName: "Используется",
      type: "boolean",
      editable: true,
      flex: .5,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row as ImportedSportEquipmentRecord);
        const error = errors?.inUse;
        if (row?.isInUseEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isInUseEqual === false,
          "not-find": row?.isInUseEqual === false && !row?.inUse,
          "error": !!error
        });
      }

    },
    {
      field: "failureReason",
      headerName: "Причина неисправности",
      editable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: ({ row: { failureReason } }: { row: ImportedSportEquipmentRecord }) => failureReason &&
        <Tooltiper text={failureReason} />

    },
    {
      field: "commissioningDate",
      headerName: "Дата ввода",
      type: "date",
      editable: true,
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row: { commissioningDate } }: { row: ImportedSportEquipmentRecord }) => commissioningDate &&
        <Tooltiper text={commissioningDate as string} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <CommissioningDateEdit {...props} />,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        const errors = rowValidate(params.row);
        if (errors?.commissioningDate) return "error";
      }

    },
    {
      field: "sportObject",
      headerName: "Спортобъект*",
      renderCell: ({ row: { sportObject } }: { row: ImportedSportEquipmentRecord }) => sportObject?.name &&
        <Tooltiper text={sportObject.name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <SportObjectEdit
          {...props}
          // sportObjects={sportObjects as SportObjectEntity[]}
        />,
      editable: true,
      flex: 1.5,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row);
        const error = errors?.sportObject;
        if (row?.isSportObjectEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isSportObjectEqual === false && params.row?.sportObject === row?.sportObject,
          "not-find": row?.isSportObjectEqual === false && !params.row?.sportObject,
          "error": !!error
        });
      }

    },
    {
      field: "sportZone",
      headerName: "Объект спорта",
      renderCell: ({ row: { sportZone } }: { row: ImportedSportEquipmentRecord }) => sportZone?.name &&
        <Tooltiper text={sportZone.name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) => <SportZoneEdit {...props} />,
      editable: true,
      flex: 1.5,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row);
        const error = errors?.sportZone;
        if (row?.isSportZoneEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isSportZoneEqual === false && params.row?.sportZone === row?.sportZone,
          "not-find": row?.isSportZoneEqual === false && !params.row?.sportZone,
          "error": !!error
        });
      }

    },
    {
      field: "sportSubZone",
      headerName: "Спортивная зона",
      renderCell: ({ row: { sportSubZone } }: { row: ImportedSportEquipmentRecord }) => sportSubZone?.name &&
        <Tooltiper text={sportSubZone.name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <SportSubZoneEdit {...props} />,
      editable: true,
      flex: 1.5,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row);
        const error = errors?.sportSubZone;
        if (row?.isSportSubZoneEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isSportSubZoneEqual === false && params.row?.sportSubZone === row?.sportSubZone,
          "not-find": row?.isSportSubZoneEqual === false && !params.row?.sportSubZone,
          "error": !!error
        });
      }

    },
    {
      field: "sportCategory",
      headerName: "Категория спорта",
      renderCell: ({ row: { sportCategory } }: { row: ImportedSportEquipmentRecord }) => sportCategory?.name &&
        <Tooltiper text={sportCategory.name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <EquipmentSportCategoryEdit {...props} />,
      editable: true,
      flex: 2,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row);
        if (errors?.commissioningDate) return "error";
        const error = errors?.sportCategory;
        if (row?.isSportCategoryEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isSportCategoryEqual === false && params.row?.sportCategory === row?.sportCategory,
          "not-find": row?.isSportCategoryEqual === false && !params.row?.sportCategory,
          "error": !!error
        });
      }

    },
    {
      field: "sportKind",
      headerName: "Вид спорта*",
      renderCell: ({ row: { sportKind } }: { row: ImportedSportEquipmentRecord }) => sportKind?.name &&
        <Tooltiper text={sportKind.name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <SportKindEdit {...props} />,
      editable: true,
      flex: 2,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row);
        const error = errors?.sportKind;
        if (row?.isSportKindEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isSportKindEqual === false && params.row?.sportKind === row?.sportKind,
          "not-find": row?.isSportKindEqual === false && !params.row?.sportKind,
          "error": !!error
        });
      }

    },
    {
      field: "equipmentType",
      headerName: "Тип оборудования*",
      renderCell: ({ row: { equipmentType } }: { row: ImportedSportEquipmentRecord }) => equipmentType?.name &&
        <Tooltiper text={equipmentType.name} />,
      renderEditCell: (props: GridRenderCellParams<ImportedSportEquipmentRecord>) =>
        <EquipmentTypeEdit {...props} />,
      editable: true,
      flex: 2,
      cellClassName: (params: GridCellParams<ImportedSportEquipmentRecord>) => {
        if (!rows) return "";
        const row = rows.find(item => item?.id === params.row?.id);
        const errors = rowValidate(params.row);
        const error = errors?.equipmentType;

        if (row?.isEquipmentTypeEqual === true && !error) {
          return "";
        }

        return clsx({
          "not-an-exact-match": row?.isEquipmentTypeEqual === false && params.row?.equipmentType === row?.equipmentType,
          "not-find": row?.isEquipmentTypeEqual === false && !params.row?.equipmentType,
          "error": !!error
        });
      }

    }

  ];

  const [selected, setSelected] = React.useState<GridRowId[]>([]);

  const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = (
    params, event
  ) => {
    event.defaultMuiPrevented = true;
    const update = [...props.data];
    const index = update.findIndex(item => item.id === params.id);
    update[index] = { ...params.row };
    onDataChange(update);

    event.defaultMuiPrevented = false;
  };


  return (
    <StyledBox>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={35}

        checkboxSelection
        disableSelectionOnClick

        editMode="row"
        onRowEditStop={handleRowEditStop}

        onSelectionModelChange={(newSelection: any) => {
          setSelected(newSelection);
        }}
        selectionModel={selected}

        components={{
          Footer: CustomFooterStatus
        }}
        componentsProps={{
          footer: {
            selected, onDeleteHandler: () => {
              dialog({
                title: "Удаление оборудования.",
                onClose: () => closeDialog(),
                children: <>
                  <DialogContent>
                    <Alert severity="info">
                      <Typography gutterBottom>Вы уверены, что хотите удалить выбранное оборудование? </Typography>
                      <Typography gutterBottom>Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку
                        ниже.</Typography>
                    </Alert>

                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={closeDialog}
                      color="primary"
                      size={"small"}
                    >
                      Отмена
                    </Button>
                    <Button
                      autoFocus
                      onClick={() => {
                        setRows(prevState => {
                          const update = [...prevState].filter(row => !selected.includes(row?.id));
                          onDataChange(update);
                          return update;
                        });
                        setSelected([]);
                        closeDialog();
                      }}
                      color="secondary"
                      size={"small"}
                    >
                      Удалить
                    </Button>
                  </DialogActions>
                </>
              });
            }
          }
        }}
      />

    </StyledBox>


  );
};

export default EditableTable;
