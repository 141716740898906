import React, {useState} from 'react';
import {Box, Hidden, IconButton, Stack, TextField, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Page from "components/Page";
import ServiceJobsTable from "./Table";
import {useStores} from '../../../store';
import {observer} from "mobx-react-lite";
import {useTheme} from "@mui/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import {lighten} from "@material-ui/core";
import {GridSearchIcon} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	page: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		position: 'relative',
	},
	pageContent: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		height: '100%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		}
	},
	pageTitle: {
		height: 90,
		padding: theme.spacing(3, 4),
		display: 'flex',
		alignItems: 'center',
		"& .MuiTypography-root": {
			fontSize: '21px',
			whiteSpace: 'nowrap',
			color: theme.palette.text.secondary,
			transition: 'all .3s',
			transform: 'scale(.8)',
			cursor: 'pointer',
			"&:hover": {
				color: lighten(theme.palette.text.primary, 0.2),
			},
			"&.active": {
				color: theme.palette.text.primary,
				transform: 'scale(1)',
			}
		},
		'& .toolbar': {
			marginLeft: '2rem',
			display: 'flex',
			listStyle: 'none',
			width: '100%',
			'&__item:not(:last-child)': {
				marginRight: '1em',
			}
		}
	},

	mainSection: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	mainSectionContainer: {
		width: 'auto',
		height: '100%',
		position: 'relative',
		overflowX: 'auto',
		padding: theme.spacing(0, 3),
	}
}));

const Reports = observer(() => {
	const classes = useStyles();
	const theme = useTheme();
	const {serviceJobsStore} = useStores();

	const [isArchived, setIsArchived] = useState<boolean>(false);
	const [searchByEquipmentNameValue, setSearchByEquipmentNameValue] =
			useState('');

	return (

			<Page title={'План обслуживания'}>

				<header className={classes.pageTitle}>
					<Typography
							variant="h1"
							onClick={() => setIsArchived(false)}
							className={!isArchived ? 'active' : ''}
					>
						План обслуживания
						<Box
								component={'small'}
								sx={{
									ml: 1,
									mr: 2,
									fontSize: '.7em',
									color: theme.palette.text.secondary,
									opacity: .5
								}}
						>{serviceJobsStore?.count}</Box>
					</Typography>
					<Typography
							variant="h5"
							onClick={() => setIsArchived(true)}
							className={isArchived ? 'active' : ''}
					>
						Архив
						<Box
								component={'small'}
								sx={{
									ml: 1,
									fontSize: '.7em',
									color: theme.palette.text.secondary,
									opacity: .5
								}}
						>{serviceJobsStore?.archivedCount}</Box>
					</Typography>


					<Box flexGrow={1}/>
					<Stack direction={'row'} spacing={3} alignItems={'center'}>
						<TextField
								variant='standard'
								value={searchByEquipmentNameValue}
								onChange={({target: {value}}) =>
										setSearchByEquipmentNameValue(value)
								}
								placeholder='Название оборудования'
								InputProps={{
									startAdornment: <GridSearchIcon fontSize='small'/>,
									endAdornment: (
											<IconButton
													title='Clear'
													aria-label='Clear'
													size='small'
													style={{
														visibility: searchByEquipmentNameValue
																? 'visible'
																: 'hidden',
													}}
													onClick={() => setSearchByEquipmentNameValue('')}
											>
												<ClearIcon fontSize='small'/>
											</IconButton>
									),
								}}
						/>
					</Stack>

				</header>

				<Box className={classes.pageContent}>
					<main className={classes.mainSection}>
						<div className={classes.mainSectionContainer}>
							<ServiceJobsTable
									isArchived={isArchived}
									searchByEquipmentNameValue={searchByEquipmentNameValue}
							/>
						</div>
					</main>
				</Box>
			</Page>
	)
})

export default Reports;
