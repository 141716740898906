import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import {useStores} from "../../../../../store";


const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DeleteSportSubZone({...rest}) {
    const classes = useStyles();
    const {sportSubZone, open, onClose} = rest
    const {settingsStore} = useStores();


    function handleClose() {
        onClose(false)
    }

    function handleDelete() {
        if (sportSubZone) {
            settingsStore.sportSubZoneDelete(sportSubZone?.id).finally(handleClose)
        }
        onClose(false)
    }

    return <>
        <Dialog
            onClose={handleClose}
            aria-labelledby="delete-sportSubZone-dialog-title"
            open={open}
        >
            <DialogTitle id="delete-sportSubZone-dialog-title">
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                >
                    <DeleteOutlineIcon
                        color={'secondary'}
                        style={{marginRight: '.3em'}}
                    />
                    <span>Удаление спортзоны.</span>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку
                                         ниже.</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    autoFocus
                    onClick={handleDelete}
                    color="secondary"
                >
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

DeleteSportSubZone.protoTypes = {}

export default DeleteSportSubZone;
