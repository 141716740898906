import { ImportedSportEquipmentRecord } from "./types";

export function rowValidate(data: ImportedSportEquipmentRecord) {
  let validateErrors: any = {
    id: data?.id
  };

  if (!data.name || data.name === "") {
    validateErrors = {
      ...validateErrors, name: {
        error: true,
        message: "Введите название оборудования."
      }
    };
  }

  if (!data.inventoryNumber || data.inventoryNumber === "") {
    validateErrors = {
      ...validateErrors, inventoryNumber: {
        error: true,
        message: "Укажите инв. номер."
      }
    };
  }
  if (!data?.sportObject) {
    validateErrors = {
      ...validateErrors, sportObject: {
        error: true,
        message: "Выберите спортобъект."
      }
    };
  }
  if (!data?.sportZone) {
    validateErrors = {
      ...validateErrors, sportZone: {
        error: true,
        message: "Выберите объект спорта."
      }
    };
  }
  if (!data?.sportSubZone) {
    validateErrors = {
      ...validateErrors, sportSubZone: {
        error: true,
        message: "Выберите спортзону."
      }
    };
  }
  if (!data?.commissioningDate) {
    validateErrors = {
      ...validateErrors, commissioningDate: {
        error: true,
        message: "Добавьте дату ввода в эксплуатацию"
      }
    };
  }
  if (!data?.sportCategory) {
    validateErrors = {
      ...validateErrors, sportCategory: {
        error: true,
        message: "Выберите категорию оборудования"
      }
    };
  }
  if (!data?.sportKind) {
    validateErrors = {
      ...validateErrors, sportKind: {
        error: true,
        message: "Выберите вид спорта"
      }
    };
  }
  if (!data?.equipmentType) {
    validateErrors = {
      ...validateErrors, equipmentType: {
        error: true,
        message: "Выберите тип оборудования"
      }
    };
  }


  return validateErrors;

}
