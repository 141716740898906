import React from "react";
import { PhotoThumbnail, ThumbnailsUploaderButton, TooltipIcon } from "../styled";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, CircularProgress, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Iconify from "components/Iconify";
import Tooltiper from "components/Tooltiper";
import { FileStoreEntity } from "graphql/graphQlApiHooks";
import clsx from "clsx";
import Settings from "store/settings";
import { MoreButton } from "../../SportEquipmentDocuments/MoreButton";
import { BootstrapTooltip } from "../../../../../../../../components/BootstrapTooltip";

interface Props {
  buttonId: string;
  onClearInput: () => void;
  onDelete?: () => void;
  loading: boolean;
  progress: number;
  helpText?: string;
  icon?: string;
  primaryText?: string;
  secondaryText?: string;
  value?: FileStoreEntity | null;
  disabled?: boolean;
  isEditable?: boolean;
}

export const UploadPhotoButton = (props: Props) => {
  const {
    buttonId,
    onClearInput,
    loading,
    progress,
    helpText,
    icon = "ic:outline-add-photo-alternate",
    primaryText = "Загрузить фото",
    value,
    onDelete,
    disabled,
    isEditable
  } = props;

  return (
    <Grid
      item
      lg={2}
      xs={3}
    >
      <PhotoThumbnail className={"PhotoThumbnail-upload-button"}>
        <ThumbnailsUploaderButton
          className={clsx([
            "upload-button",
            value && "file isValue",
            disabled && "disabled"
          ])}
          disabled={disabled}
          disableRipple={!!value}
          component={!value ? "label" : undefined}
          htmlFor={!value ? `button-upload-${buttonId}` : undefined}

          onClick={() => !value && onClearInput()}
          sx={{
            width: "100%"
          }}
        >
          {helpText && !disabled &&
            <BootstrapTooltip
              title={helpText}
            >
              <TooltipIcon className={"tooltip-icon"}>
                <HelpOutlineIcon fontSize={"small"} />
              </TooltipIcon>
            </BootstrapTooltip>
          }

          {!loading ?
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >

              {!value ?
                <Box>
                  <Iconify
                    fontWeight={"bold"}
                    fontSize={"20px"}
                    icon={icon}
                  />
                </Box>
                :
                <Box>
                  <Iconify
                    fontWeight={"bold"}
                    fontSize={"20px"}
                    icon={"icon-park-outline:doc-success"}
                    color={"primary.main"}
                  />
                </Box>
              }

              <Tooltiper
                text={primaryText}
                props={{
                  variant: "subtitle1",
                  ...(value && { color: "primary.main" }),
                  sx: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center"
                  }
                }}
              />

              {value &&
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    mt: 1,
                    width: "100%",
                    '& .MuiButton-root': {
                      p: 1,
                      minWidth: "initial",
                      width: "auto",
                      margin: 0,
                      fontSize: 16
                    }
                }}
                >


                  <BootstrapTooltip title={`Загрузить другой файл`}>
                    <Button
                      onClick={onClearInput}
                      size={"small"}
                      fullWidth
                      color={"inherit"}
                      component={"label"}
                      htmlFor={`button-upload-${buttonId}`}
                      disabled={isEditable === false}
                    >
                      <Iconify
                        icon={"mdi:file-replace-outline"}
                      />
                    </Button>
                  </BootstrapTooltip>

                  <BootstrapTooltip title={`Удалить`}>
                    <Button
                      component={"div"}
                      disabled={isEditable === false}
                      onClick={onDelete}
                      size={"small"}
                      color={"secondary"}
                    >
                      <Iconify
                        icon={"fluent:delete-12-regular"}
                      />
                    </Button>
                  </BootstrapTooltip>

                  <Box display={'flex'} flex={1} />

                  <BootstrapTooltip title={`Скачать`}>
                    <Button
                      component={"a"}
                      href={`${Settings.server}${value?.[0]?.url ||value?.url }`}
                      size={"small"}
                      fullWidth
                      color={"primary"}
                      target={"_blank"}
                    >
                      <Iconify
                        icon={"icon-park-outline:download"}
                      />
                    </Button>
                  </BootstrapTooltip>

                </Stack>
              }

            </Box>
            :
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant='determinate'
                value={progress}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography
                  variant='caption'
                  component='div'
                  color='text.secondary'
                >{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
          }

        </ThumbnailsUploaderButton>
      </PhotoThumbnail>
    </Grid>
  );
};
