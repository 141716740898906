import Settings from '../store/settings';
import { FileStoreEntity } from '../graphql/graphQlApiHooks';

/**
 * Get file url for download.
 * @param file - FileStoreEntity to download.
 */
export const getFileDownloadUrl = (file: Pick<FileStoreEntity, 'url'>): string | undefined => {
  return file ? `${Settings.server}${file.url}` : undefined;
}

/**
 * Download anything by url.
 * @param url - File url.
 * @param name - File name.
 */
export const downloadUrl = (url: string, name: string): void => {
  // Variant 1: not work in chrome
  // window.location.replace(`${Settings.server}${url}`);
  // Variant 2
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Download file in browser.
 * @param file - FileStoreEntity to download.
 */
export const downloadFile = (file: Pick<FileStoreEntity, 'url' | 'name'>): void => {
  file && downloadUrl(getFileDownloadUrl(file), file.name);
};
