import React from 'react';
import {Box, Container, Grid, Link, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Theme} from "@mui/material/styles";
import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundImage: 'url(/static/images/Login.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        '&:before': {
            content: '""',
            background: 'rgba(53, 61, 67, .8)',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 0,
            backdropFilter: 'blur(5px)'
        }
    },
    main: {
        position: 'relative',
    },
    section: {},
    logo: {
        display: 'inline-flex',
        flexDirection: 'column',
        color: theme.palette.background.paper,
        '& > .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            '& p':{
                fontWeight: 100,
            }
        },

    },
    copyright: {
        color: 'white',
        position: 'relative',
        padding: theme.spacing(3)
    },
}));

function Copyright() {
    const classes = useStyles();
    return (
        <>
            <Typography
                variant="body2"
                color="inherit"
                className={classes.copyright}
            >
                {'Copyright © '}
                <Link
                    color="inherit"
                    href="https://evgeny-ekim.com/"
                >
                    ООО "Софтра"
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </>
    );
}

const Login = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box flexGrow={1}/>
            <Container
                maxWidth="lg"
                component={'main'}
                className={classes.main}
            >
                <Grid
                    container
                    component="section"
                    className={classes.section}
                    alignItems={'center'}
                >

                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                    >
                        <Box className={classes.logo}>
                            <Box
                                alignItems={'center'}
                                display={'flex'}
                                mb={2}
                            >
                                <svg viewBox="0 0 200 200" style={{width:'60px', marginRight:'1.5em'}}>
                                    <g transform="translate(-100,120.000000) scale(0.200000,-0.200000)" style={{fill:'white'}}>
                                        <path d="M884,395c0-184-3-223-20-277C827-4,731-117,623-169c-55-26-58-29-58-64c-1-45,6-46,74-14C745-198,850-99,898-3c9,17,18,32,21,32c2,0,16-27,31-60c42-93,48-94,86-12c18,39,35,71,38,72c3,0,16-21,30-47c54-102,168-197,289-243l51-20v38c0,37-2,39-62,68c-78,36-185,139-222,213c-44,86-56,165-56,379v192h-35h-35V417c0-215-16-358-40-358c-18,0-30,137-30,338v212h-40h-40V395z"/>
                                        <path d="M750,254C742,151,704,69,633,5c-28-25-55-46-60-46s-9-18-9-40c0-48,12-50,66-14c127,85,194,205,194,349v73l-34,4l-33,4L750,254z"/>
                                        <path d="M1164,271c0-73,23-164,57-221c43-73,136-155,201-175c20-7,22-4,22,31c0,34-5,41-45,66c-93,59-146,155-153,275l-5,82h-38h-39V271z"/>
                                        <path d="M844-171c-67-68-162-129-237-151c-41-12-43-14-43-51c0-21,5-38,10-38c6,0,46,14,89,30c85,33,193,104,254,167l38,40l-21,36c-12,21-23,36-23,35C910-104,880-134,844-171z"/>
                                        <path d="M1062-142c-22-38-14-52,69-121c65-54,148-100,229-128c80-28,86-27,82,16c-3,34-7,38-43,49c-78,22-186,88-252,152c-36,34-66,63-67,63C1080-111,1071-125,1062-142z"/>
                                    </g>
                                </svg>
                                <Typography
                                    sx={{color:'white', fontSize:'52px'}}
                                >
                                    Московский спорт
                                </Typography>
                            </Box>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                sx={{letterSpacing:'4.5px', opacity:.8}}
                                color={'white'}
                            >
                                Введите логин и пароль для входа в систему.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                    >
                        <LoginForm/>
                    </Grid>
                </Grid>

            </Container>
            <Box flexGrow={1}/>
            <Copyright/>
        </div>

    );
}

export default Login;
