import React, {FC, useState, useRef} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip
} from '@mui/material';
import {
    GetEquipmentRequestsInput,
    useExportEquipmentRequestsMutation,
    useGetEquipmentRequestsLazyQuery
} from 'graphql/graphQlApiHooks';
import {useSnackbar} from 'notistack';
import {downloadFile} from 'helpers/downloadFile';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {LoadingButton} from "@mui/lab";
import {SiMicrosoftexcel} from "react-icons/si";
import {useReactiveVar} from "@apollo/client";
import {sportObjectsVars} from "store/reactiveVarsStores/sport-objects/sportObjects";
import {
    EquipmentRequestsFilters,
    equipmentRequestsFiltersVar
} from "store/reactiveVarsStores/equipment-request/filtersVar";
import {
    equipmentRequestListSortOrderVar
} from "store/reactiveVarsStores/equipment-request/equipmentRequestListSortOrder";
import {darken, lighten, useTheme} from "@mui/material/styles";

/**
 * Кнопка экспорта заявок на оборудование.
 */
export const EquipmentRequestsExport: FC = () => {
    const theme = useTheme();
    const {selectedSportObjectIds} = useReactiveVar(sportObjectsVars);
    const filters = useReactiveVar(equipmentRequestsFiltersVar);
    const sortOrderModel = useReactiveVar(equipmentRequestListSortOrderVar);

    const [open, setOpen] = useState(false);
    const options = ['Экспорт выбранных заявок', 'Экспорт всех заявок'];
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const anchorRef = useRef<HTMLDivElement>(null);

    const [getEquipmentRequests] = useGetEquipmentRequestsLazyQuery();
    const [exportRequests, {loading}] = useExportEquipmentRequestsMutation();
    const {enqueueSnackbar} = useSnackbar();

    const getFilters = (equipmentRequestsFilters: EquipmentRequestsFilters) => {
        const {applicationDate, ...filters} = equipmentRequestsFilters;
        return {
            ...filters,
            ...(applicationDate && {
                dateFrom: applicationDate?.startDate?.toISOString(),
                dateTo: applicationDate?.endDate?.toISOString(),
            })
        };
    };

    const loadSelectedRequests = async () => {
        const variables: GetEquipmentRequestsInput = selectedIndex === 0
            ? {
                ...getFilters(filters),
                sportObjectIds: selectedSportObjectIds,
                skip: 0,
                take: 0,
                order: sortOrderModel,
            } as GetEquipmentRequestsInput
            : {
                sportObjectIds: selectedSportObjectIds,
                equipmentTypeIds: [],
                sportSubZoneIds: [],
                sportZoneIds: [],
                sportKindIds: [],
                skip: 0,
                take: 0,
            } as GetEquipmentRequestsInput;

        const res = await getEquipmentRequests({variables: {input: variables}, fetchPolicy: 'cache-and-network'});
        const selectedIds = res.data?.getEquipmentRequests?.equipmentRequests?.map((request) => request.id) || [];
        setSelectedIds(selectedIds)
    };

    const handleClick = async () => {
        await loadSelectedRequests();
        console.log(selectedIds)
        exportRequests({
            variables: {ids: selectedIds}
        })
            .then((res) => downloadFile(res.data.exportedFile))
            .catch((err) => enqueueSnackbar(`Ошибка экспорта файла: ${err?.message}`, {variant: 'error'}));
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
        loadSelectedRequests();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box>
            <ButtonGroup color="inherit" variant="contained" ref={anchorRef} aria-label="split button">
                <Tooltip
                    title={selectedIndex === 0 ?
                        selectedIds?.length
                            ? `Сгенерировать Excel файл из ${selectedIds?.length} заявок`
                            : 'Не найдены заявки для экспорта, попробуйте сбросить фильтры'
                        : 'Сгенерировать Excel файл из всех заявок'}
                >
                    <LoadingButton
                        onClick={handleClick}
                        startIcon={<SiMicrosoftexcel />}
                        disabled={loading || (!selectedIds?.length && selectedIndex === 0)}
                        loading={loading}
                        variant='outlined'
                        size='small'
                        sx={{
                            bgcolor: theme.palette.grey["200"],
                            borderColor: darken(theme.palette.grey["200"], 0.1),
                            '&:hover': {
                                bgcolor: lighten(theme.palette.grey["200"], 0.1),
                            }
                        }}
                    >{options[selectedIndex]}</LoadingButton>
                </Tooltip>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    variant='outlined'
                    disabled={loading}
                    sx={{
                        padding: '0',
                        bgcolor: theme.palette.grey["200"],
                        borderColor: darken(theme.palette.grey["200"], 0.1),
                        '&:hover': {
                            bgcolor: lighten(theme.palette.grey["200"], 0.1),
                        }
                    }}
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{zIndex: 1}}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};
