import {
	SportSubZoneEntity,
	GetSportZonesByZoneIdDocument,
	UpdateSportSubZoneDocument,
	UpdateSportSubZoneInput,
	CreateSportSubZoneDocument,
	CreateSportSubZoneInput,
	DeleteSportSubZoneDocument,
	GetSportZonesByObjectIdDocument,
} from '../../graphql/graphQlApiHooks';
import {autorun, makeAutoObservable} from "mobx";
import RootStore from "../.";

import {queryToMobxObservable, sortByCreateAt} from '../../helpers';
import Settings from "../settings";
import {client} from "../.";

const sportSubZonesStore = class {
	public rootStore: RootStore;
	public sportSubZones: SportSubZoneEntity[] = [];
	public loading: boolean = true
	public count: number = 0

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)

		autorun(() => {
			if (this.query?.current()) {
				let result: any = this.query.current()
				result = result.getSportZonesByZoneId.slice().sort(sortByCreateAt);
				if (JSON.stringify(result) !== JSON.stringify(this.sportSubZones)) {
					this.sportSubZones = result
				}
				this.count = this.sportSubZones.length
			}
		})
	}

	get query() {
		return this.rootStore?.sportZonesStore?.current?.id && queryToMobxObservable(client.watchQuery({
			query: GetSportZonesByZoneIdDocument,
			variables: {sportZoneId: this.rootStore.sportZonesStore.current.id},
			pollInterval: Settings.pollIntervals.sportSubZonesByZoneId,
		}));
	}

	getSportSubZonesByZoneId(sportZoneId: string) {
		return client.query({
			query: GetSportZonesByZoneIdDocument,
			variables: {sportZoneId: sportZoneId},
		});
	}

	update = (input: UpdateSportSubZoneInput) => client.mutate({
		mutation: UpdateSportSubZoneDocument,
		variables: {input},
		refetchQueries: [
			{
				query: GetSportZonesByZoneIdDocument,
				variables: {sportZoneId: this.rootStore?.sportZonesStore.current.id}
			},
			{
				query: GetSportZonesByObjectIdDocument,
				variables: {sportObjectId: this.rootStore?.sportObjectsStore.current.id}
			},
		]
	})
			.then(() => this.rootStore?.setSnakeMessage('Спортзона успешно обновлена'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	create = (input: CreateSportSubZoneInput) => client.mutate({
		mutation: CreateSportSubZoneDocument,
		variables: {input},
		refetchQueries: [
			{
				query: GetSportZonesByZoneIdDocument,
				variables: {sportZoneId: this.rootStore?.sportZonesStore.current.id}
			},
			{
				query: GetSportZonesByObjectIdDocument,
				variables: {sportObjectId: this.rootStore?.sportObjectsStore.current.id}
			},
		]
	})
			.then(() => this.rootStore?.setSnakeMessage('Спортзона успешно создана'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));


	delete = (id: string) => client.mutate({
		mutation: DeleteSportSubZoneDocument,
		variables: {id},
		refetchQueries: [
			{
				query: GetSportZonesByZoneIdDocument,
				variables: {sportZoneId: this.rootStore?.sportZonesStore.current.id}
			},
			{
				query: GetSportZonesByObjectIdDocument,
				variables: {sportObjectId: this.rootStore?.sportObjectsStore.current.id}
			},
		]
	})
			.then(() => this.rootStore?.setSnakeMessage('Спортзона успешно удалена'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

}

export default sportSubZonesStore;
