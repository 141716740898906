import React from "react";
import {CombinedEquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {EditCombinedEquipmentRequestDialog} from "../../components/Dialogs/EditCombinedEquipmentRequestDialog";

export const useEditCombineRequest = () => {
    const [dialog, closeDialog] = useDialog();

    const handleEditCombineRequest = (combinedEquipment: CombinedEquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Редактирование объединенной заявки",
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            maxWidth: "md",
            fullWidth: true,
            children: <>
                <EditCombinedEquipmentRequestDialog
                    combinedEquipment={combinedEquipment}
                    close={() => {
                        closeDialog();
                    }}
                />
            </>
        });
    };


    return {
        handleEditCombineRequest
    }
};