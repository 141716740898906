import React, {useEffect, useRef, useState} from 'react';
import {
	Autocomplete, Avatar,
	Box,
	Button,
	Card, CardHeader, Checkbox, Chip,
	Container,
	Stack, TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography
} from "@mui/material";
import {escapeRegExp} from "../SportObjects/Main";
import userStore from "../../../../store/stores/user.store";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../store";
import Settings from "../../../../store/settings";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//@ts-ignore
const tr = new CyrillicToTranslit()

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface Props {

}

const ResponsibleUsers = observer((props: Props) => {
	const {userStore} = useStores();
	const {users, getCurrentDepartmentName} = userStore;
	const [responsibleUser, setResponsibleUser] = useState<any>(null);
	const [notifiableUsers, setNotifiableUsers] = useState<any>([]);

	const [accountantValue, setAccountantValue] = useState<string>('');
	const [diagnosticsValue, setDiagnosticsValue] = useState<string>('');
	const [paidServicesDepartmentValue, setPaidServicesDepartmentValue] = useState<string>('');
	const [notificationsUsers, setNotificationsUsers] = useState<string>('');


	const [sportObjectsGroups, setSportObjectsGroups] = useState<any>([]);


	const onChangeResponsibleUser = (value: any) => {
		setResponsibleUser(value)
	}
	const onChangeNotifiableUsers = (value: any) => {
		// setErrorsHandler({...errorsHandler, notifiableUsers: false})
		setNotifiableUsers(value)
	}

	const filterOptions = (options: any, state: any) => {
		const {inputValue} = state;
		if (!inputValue) {
			return options;
		}
		const searchRegex = new RegExp(escapeRegExp(inputValue), 'i');

		return options.filter((row: any) => {
			return Object.keys(row).some((field) => {
				return row[field] && searchRegex.test(JSON.stringify(row[field]).toString());
			});
		})
	}

	useEffect(() => {
		let active = true;

		let groupsOptions: any = []
		let otherUsers: any = []

		if (sportObjectsGroups.length === 0 && users && users?.length > 0 ) {
			users
					.map((user: any) => {
						if (process.env.NODE_ENV !== 'development' ) return undefined
						if (!user?.fio && !Settings.testUsers.includes(user.login)) {
							let surname = tr.reverse(user.login);
							surname = surname.charAt(0).toUpperCase() + surname.slice(1);
							const firstName = surname.slice(-2).charAt(0).toUpperCase(),
									lastName = surname.slice(-1).toUpperCase(),
									fio = `${surname.slice(0, -2)} ${firstName}.${lastName}.`;

							return {...user, fio: fio}
						}
						return {...user}
					}).filter((user: any) => (user))
					.forEach((user: any) => {
						if (!user.isForSportObject) {
							groupsOptions = [...groupsOptions,
								{...user, group: 'Группа администраторов', order: '1'}
							]
						} else {
							otherUsers = [...otherUsers, user]
						}
					})

			const departments = Array.from(new Set(otherUsers.map((user: any) => user.sportObjects).flat().map((department: any) => department?.name).filter((department: any) => (department))))

			let otherUsersGroups = departments.map((department) => {
				return otherUsers.filter((user: any) => user?.sportObjects?.find((sportObject: any) => sportObject.name === department)).map((user: any) => ({
					...user,
					group: department,
					order: department
				}))
			}).flat()

			const withoutSportObjectsUsers = otherUsers.filter((user: any) => (!user?.sportObjects)).map((user: any) => ({
				...user,
				group: 'Без категории',
				order: 'z'
			}))

			if (active) {
				setSportObjectsGroups(groupsOptions.concat(otherUsersGroups).concat(withoutSportObjectsUsers))
			}
		}

		return () => {
			active = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ sportObjectsGroups, users]);


	function getNoOptionsText(value: string) {
		const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
		if (regex.test(value)) {
			return <>
				<Box>
					<Button fullWidth>
						Добавить e-mail отвественного сотрудника
					</Button>
				</Box>
			</>;
		}
		return  <>
			<Box>
				<Typography mb={1} variant={'h5'}>Сотрудник не найден.</Typography>
				<Typography variant={'caption'}>
					Укажите корректный e-mail, чтобы добавить ответсвенного сотрудника не из списка.
				</Typography>
			</Box>
		</>;
	}

	return (
			<>
				<Box p={3} >
					<Typography variant={'h1'} mt={1} >Назначение ответсвенных сотрудников</Typography>
					<Stack
						direction={'column'}
						spacing={2}
						mt={3}
					>
						<Card sx={{
							p: 3,
							width: '100%'
						}}
						      elevation={0}
						      variant={'outlined'}

						>
							<Typography variant={'h5'}>Выберите бухгалтера</Typography>
							<Typography variant={'caption'} >Выберите сотрудника из списка или укажите e-mail в поле ввода</Typography>

							<Stack direction={'row'} spacing={2} alignItems={'baseline'} mt={3}>

								<Autocomplete
										size="small"
										value={responsibleUser}
										options={sportObjectsGroups.sort((a: any, b: any) => -b.order.localeCompare(a.order))}
										groupBy={(option) => option.group}
										getOptionLabel={(option: any) => (option?.fio) ? option.fio : option.login}
										filterOptions={filterOptions}
										noOptionsText={getNoOptionsText(accountantValue)}
										renderOption={(props, option) => (
												<li  {...props} key={`${option.group}_${option.id}`}>
													<CardHeader
															sx={{
																p: 0,
																width: '100%'
															}}
															avatar={
																<Avatar sx={{
																	bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
																	color: 'white !important',
																	fontSize: '8px !important',
																	width: '20px',
																	height: '20px'
																}}>{option.login.substring(0, 2).toUpperCase()}</Avatar>
															}
															title={(option?.fio) ? option.fio : option.login}
															subheader={
																(option.isForSportObject) ? `${(option?.sportObjects) ? option?.sportObjects?.map((item: any) => item?.name).join(', ') : ''}` : (
																		`${(option?.departments) ? option?.departments?.map((item: any) => getCurrentDepartmentName(item)).join(', ') : ''}`
																)
															}
															action={
																<Box
																		sx={{
																			fontSize: '12px',
																			marginLeft: 'auto',
																			opacity: '.8'
																		}}
																>
																	{option.login}
																</Box>

															}
													/>
												</li>
										)}
										style={{width: 500}}
										renderInput={(params) => (
												<TextField
														{...params}
														label="Список сотрудников"
														placeholder="Выберите сотрудника или укажите e-mail"
														value={accountantValue}
														onChange={({target:{value}})=>setAccountantValue(value)}
												/>)
										}
										onChange={(event, user) => onChangeResponsibleUser(user)}
								/>

							</Stack>

						</Card>
						<Card sx={{
							p: 3,
							width: '100%'
						}}
						      elevation={0}
						      variant={'outlined'}

						>
							<Box display={'flex'} justifyContent={'space-between'}>
								<Typography variant={'h4'}>Выберите представителя приёмки и диагностики оборудования</Typography>
							</Box>

							<Stack direction={'row'} spacing={2} alignItems={'baseline'} mt={3}>

								<Autocomplete
										size="small"
										id="checkboxes-tags-demo"
										value={responsibleUser}
										options={sportObjectsGroups.sort((a: any, b: any) => -b.order.localeCompare(a.order))}
										groupBy={(option) => option.group}
										getOptionLabel={(option: any) => (option?.fio) ? option.fio : option.login}
										filterOptions={filterOptions}
										noOptionsText={getNoOptionsText(diagnosticsValue)}
										renderOption={(props, option) => (
												<li  {...props} key={`${option.group}_${option.id}`}>
													<CardHeader
															sx={{
																p: 0,
																width: '100%'
															}}
															avatar={
																<Avatar sx={{
																	bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
																	color: 'white !important',
																	fontSize: '8px !important',
																	width: '20px',
																	height: '20px'
																}}>{option.login.substring(0, 2).toUpperCase()}</Avatar>
															}
															title={(option?.fio) ? option.fio : option.login}
															subheader={
																(option.isForSportObject) ? `${(option?.sportObjects) ? option?.sportObjects?.map((item: any) => item?.name).join(', ') : ''}` : (
																		`${(option?.departments) ? option?.departments?.map((item: any) => getCurrentDepartmentName(item)).join(', ') : ''}`
																)
															}
															action={
																<Box
																		sx={{
																			fontSize: '12px',
																			marginLeft: 'auto',
																			opacity: '.8'
																		}}
																>
																	{option.login}
																</Box>

															}
													/>
												</li>
										)}
										style={{width: 500}}
										renderInput={(params) => (
												<TextField
														{...params}
														label="Список сотрудников"
														placeholder="Список сотрудников"
														value={diagnosticsValue}
														onChange={({target:{value}})=>setDiagnosticsValue(value)}
												/>)
										}
										onChange={(event, user) => onChangeResponsibleUser(user)}
								/>

							</Stack>

						</Card>
						<Card sx={{
							p: 3,
							width: '100%'
						}}
						      elevation={0}
						      variant={'outlined'}
						>
							<Box display={'flex'} justifyContent={'space-between'}>
									<Typography variant={'h4'}>Выберите представителя отдела планирования и организации платных услуг</Typography>
							</Box>

							<Stack direction={'row'} spacing={2} alignItems={'baseline'} mt={3}>

								<Autocomplete
										size="small"
										id="checkboxes-tags-demo"
										noOptionsText={getNoOptionsText(paidServicesDepartmentValue)}
										value={responsibleUser}
										options={sportObjectsGroups.sort((a: any, b: any) => -b.order.localeCompare(a.order))}
										groupBy={(option) => option.group}
										getOptionLabel={(option: any) => (option?.fio) ? option.fio : option.login}
										filterOptions={filterOptions}
										renderOption={(props, option) => (
												<li  {...props} key={`${option.group}_${option.id}`}>
													<CardHeader
															sx={{
																p: 0,
																width: '100%'
															}}
															avatar={
																<Avatar sx={{
																	bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
																	color: 'white !important',
																	fontSize: '8px !important',
																	width: '20px',
																	height: '20px'
																}}>{option.login.substring(0, 2).toUpperCase()}</Avatar>
															}
															title={(option?.fio) ? option.fio : option.login}
															subheader={
																(option.isForSportObject) ? `${(option?.sportObjects) ? option?.sportObjects?.map((item: any) => item?.name).join(', ') : ''}` : (
																		`${(option?.departments) ? option?.departments?.map((item: any) => getCurrentDepartmentName(item)).join(', ') : ''}`
																)
															}
															action={
																<Box
																		sx={{
																			fontSize: '12px',
																			marginLeft: 'auto',
																			opacity: '.8'
																		}}
																>
																	{option.login}
																</Box>

															}
													/>
												</li>
										)}
										style={{width: 500}}
										renderInput={(params) => (
												<TextField
														{...params}
														label="Список сотрудников"
														placeholder="Список сотрудников"
														value={paidServicesDepartmentValue}
														onChange={({target:{value}})=>setPaidServicesDepartmentValue(value)}
														// error={errorsHandler.responsibleUser}
														// helperText={(errorsHandler.responsibleUser) ? 'Выберите ответсвенного за перемещение' : undefined}
												/>)
										}
										onChange={(event, user) => onChangeResponsibleUser(user)}
								/>

							</Stack>

						</Card>
						<Card sx={{
							p: 3,
							width: '100%'
						}}
						      elevation={0}
						      variant={'outlined'}

						>
							<Box display={'flex'} justifyContent={'space-between'}>
									<Typography variant={'h4'}>Выберите сотрудников которые будут получать уведомления о перемещении оборудования</Typography>
							</Box>

							<Stack direction={'row'} spacing={2} alignItems={'baseline'} mt={3}>

								<Autocomplete
										multiple
										size="small"
										noOptionsText={getNoOptionsText(notificationsUsers)}
										limitTags={2}
										id="notifiableUsersSelect"
										value={notifiableUsers}
										options={sportObjectsGroups.sort((a: any, b: any) => -b.order.localeCompare(a.order))}
										groupBy={(option) => option.group}
										disableCloseOnSelect
										getOptionLabel={(option: any) => (option?.fio) ? option.fio : option.login}
										isOptionEqualToValue={(option, value) => option?.id === value?.id}
										filterOptions={filterOptions}
										renderOption={(props, option, {selected}) => (
												<li  {...props} key={`${option.group}_${option.id}`}>
													<Checkbox
															icon={icon}
															checkedIcon={checkedIcon}
															style={{marginRight: 8}}
															checked={selected}
													/>
													<CardHeader
															sx={{
																p: 0,
																width: '100%'
															}}
															avatar={
																<Avatar sx={{
																	bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
																	color: 'white !important',
																	fontSize: '8px !important',
																	width: '20px',
																	height: '20px'
																}}>{option.login.substring(0, 2).toUpperCase()}</Avatar>
															}
															title={(option?.fio) ? option.fio : option.login}
															subheader={
																(option.isForSportObject) ? `${(option?.sportObjects) ? option?.sportObjects?.map((item: any) => item?.name).join(', ') : ''}` : (
																		`${(option?.departments) ? option?.departments?.map((item: any) => getCurrentDepartmentName(item)).join(', ') : ''}`
																)
															}
															action={
																<Box
																		sx={{
																			fontSize: '12px',
																			marginLeft: 'auto',
																			opacity: '.8'
																		}}
																>
																	{option.login}
																</Box>

															}/>
												</li>
										)}
										style={{width: 500}}
										renderInput={(params) => (
												<TextField
														{...params}
														label="Список сотрудников"
														placeholder="Список сотрудников"
														value={notificationsUsers}
														onChange={({target:{value}})=>setNotificationsUsers(value)}
														// error={errorsHandler.notifiableUsers}
														// helperText={(errorsHandler.notifiableUsers) ? 'Выберите сотрудников' : undefined}
												/>
										)}
										renderTags={(users, getTagProps) => {
											return users.map((option, index) => (
													<Chip {...getTagProps({index})} key={`${option?.group}_${option?.id}_${index}`} label={(option?.fio) ? option.fio : option.login}
													      size={'small'}
													      avatar={<Avatar sx={{
														      bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
														      color: 'white !important',
														      fontSize: '8px !important',
													      }}>{option.login.substring(0, 2).toUpperCase()}</Avatar>}
													/>
											));
										}}
										onChange={(event, value) => {
											onChangeNotifiableUsers(value)
										}}
								/>
							</Stack>

						</Card>
					</Stack>
				</Box>
			</>
	);
})

export default ResponsibleUsers;