import React from "react";
import {Link} from "react-router-dom";
import {EquipmentRelocationRequestEntity, TechnicalCondition} from "graphql/graphQlApiHooks";
import EquipmentRowName from "components/EquipmentRowName";
import Tooltiper from "components/Tooltiper";
import {GridCellParams, GridColumns} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import HistoryButton from "./HistoryButton";
import DateViewer from "components/DateViewer";
import {TagStatusRelocationRequest} from "./TagStatusRelocationRequest";
import {
    EquipmentRelocationRequestStatusEnum
} from "store/stores/equipment-relocation-request-status.enum";
import {
    SortEquipmentRelocateColumnsEnum
} from "../../../../../store/reactiveVarsStores/equipment-relocate/sort-equipment-relocate-columns.enum";
import ActionButtons from "../ActionButtons";


export const columns: GridColumns<EquipmentRelocationRequestEntity> = [
    {
        field: SortEquipmentRelocateColumnsEnum.EquipmentName, headerName: "Оборудование",
        renderCell: (
            {
                row: {
                    equipment: {
                        name,
                        inventoryNumber,
                        technicalCondition,
                        photos,
                        mainPhoto,
                        inUse,
                        failureReason,
                        isEditable,
                        id
                    }
                }
            }: { row: EquipmentRelocationRequestEntity }
        ) => {
            return (
                <Link to={`/relocateEquipments/equipments/${id}`}>
                    <EquipmentRowName
                        name={name}
                        inventoryNumber={inventoryNumber || "б/н"}
                        inUse={inUse}
                        technicalCondition={(technicalCondition === TechnicalCondition.Working)}
                        avatarUrl={(mainPhoto) ? mainPhoto.avatarUrl : (photos && photos?.length > 0) && photos?.[0].avatarUrl}
                        avatarUrlOrigin={(mainPhoto) ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].url}
                        failureReason={failureReason}
                        isEditable={isEditable}
                    />
                </Link>
            );
        },
        minWidth: 300,
        flex: 2.5,
        disableColumnMenu: true,
        editable: false
    },
    {
        field: SortEquipmentRelocateColumnsEnum.SourceSportObjectName, headerName: "Cпортобъект нахождения",
        renderCell: ({row: {sourceSportObject}}: { row: EquipmentRelocationRequestEntity }) => {
            return (
                <Tooltiper
                    text={sourceSportObject?.name ?
                        <b>{sourceSportObject?.name}</b> : "Cпортобъект не известен"
                    }
                    props={{
                        variant: !sourceSportObject?.name ? "body2" : "subtitle1",
                        sx: {
                            textAlign: "center"
                        }
                    }}
                />
            );
        },
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        flex: 1,
    },
    {
        field:  SortEquipmentRelocateColumnsEnum.TargetSportObjectName, headerName: "Cпортобъект назначения",
        renderCell: ({row: {targetSportObject}}: { row: EquipmentRelocationRequestEntity }) => {
            return (
                <Tooltiper
                    text={targetSportObject?.name ?
                        <b>{targetSportObject?.name}</b> : "Ещё не указан"
                    }
                    props={{
                        variant: !targetSportObject?.name ? "body2" : "subtitle1",
                        sx: {
                            textAlign: "center"
                        }
                    }}
                />
            );
        },
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        flex: 1,
    },
    {
        field:  SortEquipmentRelocateColumnsEnum.Status, headerName: "Статус заявки",
        renderCell: ({row: {status}}: { row: EquipmentRelocationRequestEntity }) => {
            return (
                <TagStatusRelocationRequest
                    status={status as EquipmentRelocationRequestStatusEnum}
                />
            );
        },
        disableColumnMenu: true,
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 1,
    },
    {
        field:  SortEquipmentRelocateColumnsEnum.RejectReason, headerName: "История",
        renderCell: (params: GridCellParams) => {
            return (
                <HistoryButton
                    params={params}
                />
            );
        },
        disableColumnMenu: true,
        sortable: false,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        flex: .5,
    },
    {
        field:  SortEquipmentRelocateColumnsEnum.CreatedAt, headerName: "Дата заявки",
        renderCell: ({row: {createdAt}}: {
            row: EquipmentRelocationRequestEntity
        }) => {
            return (
                <Box>
                    <DateViewer date={createdAt}/>
                </Box>
            );
        },
        flex: 1,
        editable: false,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true
    },
    {
        field: "actions", headerName: "Действия",
        renderCell: ({row}: { row: EquipmentRelocationRequestEntity }) => {
            return (
                <ActionButtons
                    relocateEquipment={row as EquipmentRelocationRequestEntity}
                />
            );
        },
        disableColumnMenu: true,
        sortable: false,
        editable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 2.5,
    }
];