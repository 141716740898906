import {FormEvent, useState} from "react";
import {Box, Card, Fade, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {useTheme} from "@mui/styles";
import {useStores} from "../../store";
import {observer} from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/static/images/Login.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginForm = observer(() => {
    const classes = useStyles();
    const theme = useTheme();
    const {userStore} = useStores();

    const [loginString, setLogin] = useState('');
    const [passwordString, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (loginString && passwordString) {
            userStore.login({login: loginString, password: passwordString})
        }
    };

    return (
        <>
            <Card className={classes.paper}>
                <Typography
                    component="h2"
                    variant="h2"
                >
                    Войдите в систему
                </Typography>
                <Fade in={userStore.loginError}>
                    <Box sx={{mt: 1, color: theme.palette.error.main}}>
                        {userStore.loginErrorMessage}
                    </Box>
                </Fade>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <TextField
                        error={userStore.loginError}
                        onChange={(e) => {
                            userStore.clearLoginErrors()
                            setLogin(e.target.value)
                        }}
                        value={loginString}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Логин"
                        name="email"
                        autoComplete="Логин"
                        size={"small"}
                        autoFocus
                    />
                    <TextField
                        error={userStore.loginError}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        size={"small"}
                        name="password"
                        label="Пароль"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                            userStore.clearLoginErrors()
                            setPassword(e.target.value)
                        }}
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        size="large"
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />


                    <LoadingButton
                        type="submit"
                        className={classes.submit}
                        loading={userStore.loginProgress}
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        disabled={userStore.loginProgress}
                        onClick={handleSubmit}
                    >
                        Войти
                    </LoadingButton>


                </form>
            </Card>
        </>

    );
})

export default LoginForm;
