import React, { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDialog } from "providers/DialogProvider";

import { useTheme } from "@mui/styles";
import { Badge, Box, IconButton, LinearProgress, Menu, Typography } from "@mui/material";
import { DataGrid, GridColumns, GridRowData, GridRowId, GridSortModel } from "@mui/x-data-grid";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";

import EquipmentRowName from "components/EquipmentRowName";
import { EquipmentRowTechnicalCondition } from "components/EquipmentRowTechnicalCondition";
import Tooltiper from "components/Tooltiper";
import DateViewer from "components/DateViewer";
import { MoreMenuItems } from "./MoreMenuItems";
import { ActionButtons } from "./ActionButtons";
import {
  SortSportEquipmentsColumnsEnum
} from "store/reactiveVarsStores/spor-equipmnts/sort-sport-equipments-columns.enum";
import { SportEquipmentListSortOrder } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListSortOrder";
import { SportEquipmentListPagination } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListPagination";
import { SportEquipmentTab } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentTabActive";
import { SportEquipmentEntity, TechnicalCondition } from "graphql/graphQlApiHooks";
import { Footer } from "../Footer";
import { SportEquipmentsChangeСategory } from "../Footer/SportEquipmentsChangeСategory";
import { SportEquipmentsRelocate } from "../Footer/SportEquipmentsRelocate";
import { SortDirectionEnum } from "../../../../../../store/reactiveVarsStores/spor-equipmnts/sort-direction.enum";
import GroupDeleteSportEquipments from "../Footer/GroupDeleteSportEquipments";
import { BootstrapTooltip } from "../../../../../../components/BootstrapTooltip";
import { isValidNumber } from "helpers/isValidNumber";


interface Props {
  allCount: number;
  loading: boolean;
  sportEquipments: SportEquipmentEntity[];
  pagination: SportEquipmentListPagination;
  tabActive: SportEquipmentTab;
  sortOrderModel: SportEquipmentListSortOrder;

  actions: {
    setPagination: (pagination: SportEquipmentListPagination) => void;
    setOrder: (orderModel: SportEquipmentListSortOrder) => void;
  };
}


// TODO:
//    добавить фильтрацию по полю при наведении на ячейку

export const List = (props: Props) => {
  const { sportEquipments, pagination, allCount, loading, tabActive, actions: { setPagination, setOrder } } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const [dialog, closeDialog] = useDialog();

  const [selected, setSelected] = useState<GridRowId[]>([]);

  const sportEquipmentsColumns: GridColumns<SportEquipmentEntity> = [
    {
      field: SortSportEquipmentsColumnsEnum.NAME,
      headerName: "Название",
      renderCell: (
        {
          row: {
            name,
            inventoryNumber,
            technicalCondition,
            photos,
            mainPhoto,
            inUse,
            failureReason,
            isEditable
          }
        }: { row: SportEquipmentEntity }
      ) => {
        return (
          <EquipmentRowName
            name={name}
            inventoryNumber={inventoryNumber || "б/н"}
            inUse={inUse}
            technicalCondition={(technicalCondition === TechnicalCondition.Working)}
            avatarUrl={(mainPhoto) ? mainPhoto.avatarUrl : (photos && photos?.length > 0) && photos?.[0].avatarUrl}
            avatarUrlOrigin={(mainPhoto) ? mainPhoto.url : (photos && photos?.length > 0) && photos?.[0].url}
            failureReason={failureReason}
            isEditable={isEditable}
          />
        );
      },
      minWidth: 300,
      flex: 2.5,
      disableColumnMenu: true,
      editable: false
    },
    {
      field: SortSportEquipmentsColumnsEnum.TECHNICAL_CONDITION,
      headerName: "Техническое состояние",
      flex: .7,
      editable: false,
      renderCell: ({ row: { technicalCondition, inUse, failureReason } }: { row: SportEquipmentEntity }) => {
        return (
          <EquipmentRowTechnicalCondition
            technicalCondition={technicalCondition === TechnicalCondition.Working}
          />
        );
      },
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true
    },
    {
      field: SortSportEquipmentsColumnsEnum.SPORT_ZONE,
      headerName: "Объект спорта",
      renderCell: ({ row: { sportZone, sportObject, inUse } }: { row: SportEquipmentEntity }) => {
        return (
          <Tooltiper
            text={sportZone?.name ?
              <b>{sportZone?.name}</b> : (tabActive?.id === "is_archived") ?
                <><Typography
                  sx={{ opacity: .5 }}
                  mr={1}
                  variant={"body2"}
                  component={"span"}
                >На складе
                  в</Typography><b>{sportObject?.name}</b></> :
                "Объект спорта не известен"
            }
            props={{
              variant: !sportZone?.name ? "body2" : "subtitle1",
              style: {
                ...(!inUse && { color: theme.palette.grey["500"] })
              }
            }}
          />
        );
      },
      flex: 1.5,
      editable: false,
      minWidth: 220,
      disableColumnMenu: true
    },
    {
      field: SortSportEquipmentsColumnsEnum.SPORT_SUBZONE,
      headerName: "Спортзона",
      renderCell: ({ row: { sportSubZone, sportObject, inUse } }: { row: SportEquipmentEntity }) => {
        const sportSubZoneName = sportSubZone?.name;
        return (
          <Tooltiper
            text={sportSubZoneName ?
              <b>{sportSubZoneName}</b> : (tabActive?.id === "is_archived") ?
                <><Typography
                  sx={{ opacity: .5 }}
                  mr={1}
                  variant={"body2"}
                  component={"span"}
                >На складе
                  в</Typography><b>{sportObject?.name}</b></> :
                "Спортзона не известена"
            }
            props={{
              variant: sportSubZoneName ? "subtitle1" : "body2",
              style: {
                ...(!inUse && { color: theme.palette.grey["500"] })
              }
            }}
          />
        );
      },
      flex: 1.5,
      editable: false,
      minWidth: 220,
      disableColumnMenu: true
    },
    {
      field: SortSportEquipmentsColumnsEnum.COMMISSIONING_DATE,
      headerName: "Дата ввода",
      renderCell: ({ row: { commissioningDate, inUse } }: { row: SportEquipmentEntity }) => {
        return (
          <Box color={inUse ? "text.secondary" : "grey.500"}>
            <DateViewer date={commissioningDate} />
          </Box>
        );
      },
      flex: .5,
      minWidth: 120,
      editable: false,
      disableColumnMenu: true
    },
    {
      field: SortSportEquipmentsColumnsEnum.CREATED_AT,
      headerName: "Дата добавления",
      renderCell: ({ row: { createdAt, inUse } }: { row: SportEquipmentEntity }) => {
        return (
          <Box color={inUse ? "text.secondary" : "grey.500"}>
            <DateViewer date={createdAt} />
          </Box>
        );
      },
      flex: .5,
      editable: false,
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "servicePeriods", headerName: "График тех. обслуживания",
      renderCell: ({ row }) => {
        const disabled = !row.sportSubZone?.id;
        return (
          <>
            <IconButton
              size={"small"}
              onClick={() => {
                navigate("service-periods/" + row.id);
              }}
              disabled={disabled}
            >
              <Badge
                badgeContent={row.equipmentServicePeriods?.length}
                color={disabled ? "default" : "primary"}
                variant='dot'
              >
                <BootstrapTooltip title={"График технического обслуживания"}>
                  <EventNoteOutlinedIcon
                    fontSize='small'
                    sx={{ fontSize: "18px !important" }}
                  />
                </BootstrapTooltip>
              </Badge>
            </IconButton>
          </>
        );
      },
      flex: .5,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: "center"
    },
    {
      field: "actions", headerName: "Действия",
      renderCell: ({ row: sportEquipment }: { row: SportEquipmentEntity }) => {
        return (
          <ActionButtons
            sportEquipment={sportEquipment}
          />
        );
      },
      disableColumnMenu: true,
      flex: 0,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      align: "center",
      headerAlign: "center"
    }
  ];

  const [contextRow, setContextRow] = useState<SportEquipmentEntity | null>(null);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const contextMenuHandler = (event: MouseEvent) => {
    event.preventDefault();
    const row = event.currentTarget;
    const sportEquipmentId = row.getAttribute("data-id");
    setContextRow(
      sportEquipments.find((item) =>
        item.id === sportEquipmentId
      )
    );
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6
        }
        :
        null
    );
  };

  const contextMenuCloseHandler = () => {
    setContextMenu(null);
  };


  const onSportEquipmentsRelocateHandler = () => {
    dialog({
      onClose: closeDialog,
      title: "Перемещение оборудования",
      children: <>
        <SportEquipmentsRelocate
          equipments={selected as string[]}
          close={() => {
            setSelected([]);
            closeDialog();
          }}
        />
      </>
    });
  };

  const onCategoryChangeHandler = () => {
    dialog({
      onClose: closeDialog,
      title: "Изменение категории",
      children: <>
        <SportEquipmentsChangeСategory
          equipments={selected as string[]}
          close={() => {
            setSelected([]);
            closeDialog();
          }}
        />
      </>
    });

  };


  const onGroupDeleteHandler = () => {
    dialog({
      onClose: closeDialog,
      title: "Удаление оборудования",
      children: <>
        <GroupDeleteSportEquipments
          close={closeDialog}
          onSubmitted={() => {
            setSelected([])
            closeDialog();
          }}
          equipments={selected as string[]}
        />
      </>
    });

  };

  const doubleClickHandler = (id: string) => {
    //TODO вынести в action
    navigate(id);
  };

  function sortModelChangeHandler(model: GridSortModel) {
    const sort = model.map((item) => {
      return {
        [item.field]: item.sort.toUpperCase() as SortDirectionEnum
      } as SportEquipmentListSortOrder;
    });
    setOrder(sort[0]);
  }

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={[...sportEquipments].sort((a, b) => {
          const isValidA = isValidNumber(a.inventoryNumber);
          const isValidB = isValidNumber(b.inventoryNumber);

          // Если один из номеров некорректен, он должен идти первым
          if (!isValidA && isValidB) {
            return -1;
          } else if (isValidA && !isValidB) {
            return 1;
          }

        })}
        columns={sportEquipmentsColumns}
        getRowClassName={({ id }) => (id === contextRow?.id && contextMenu !== null) ? "isOpenContextMenu" : ""}
        rowHeight={50}
        loading={loading}

        sx={{
          "& .isOpenContextMenu": {
            bgcolor: "action.hover"
          }
        }}

        componentsProps={{
          footer: {
            selected,
            onSportEquipmentsRelocateHandler: onSportEquipmentsRelocateHandler,
            onCategoryChangeHandler: onCategoryChangeHandler,
            onGroupDeleteHandler: onGroupDeleteHandler
          },
          row: {
            onContextMenu: contextMenuHandler,
            style: { cursor: "context-menu" }
          }
        }}
        components={{
          Footer: Footer,
          LoadingOverlay: LinearProgress
        }}

        onRowDoubleClick={(params: GridRowData) => doubleClickHandler(params.row.id)}

        sortingMode='server'
        onSortModelChange={sortModelChangeHandler}

        checkboxSelection
        disableSelectionOnClick={true}

        selectionModel={selected}
        onSelectionModelChange={(newSelection: any) => {
          setSelected(newSelection);
        }}

        columnBuffer={sportEquipmentsColumns.length}
        columnThreshold={sportEquipmentsColumns.length}
        rowThreshold={pagination?.pageSize / 2}
        rowBuffer={pagination?.pageSize / 2}


        pagination
        paginationMode='server'
        rowCount={Math.round(allCount)}
        page={pagination?.page}
        pageSize={pagination?.pageSize}
        onPageChange={(page) => setPagination({ ...pagination, page: page })}
        onPageSizeChange={(pageSize) =>
          setPagination({ ...pagination, pageSize: pageSize })
        }
        rowsPerPageOptions={[5, 10, 50, 100]}
      />

      <Menu
        open={contextMenu !== null}
        onClose={contextMenuCloseHandler}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        elevation={22}
        sx={{
          maxWidth: 300,
          "& .MuiListItemText-root .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100%",
            display: "flow-root"
          }
        }}
      >
        {contextRow && <MoreMenuItems
          sportEquipment={contextRow}
          onClose={contextMenuCloseHandler}
        />}
      </Menu>
    </Box>
  );
};