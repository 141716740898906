import React from "react";
import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {ViewRequestDialog} from "../../components/Dialogs/ViewRequestDialog";

export const useViewRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const handleView = (equipment: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            // title: "Просмотр заявки на оборудование",
            maxWidth: "sm",
            fullWidth: true,
            children: <>
                <ViewRequestDialog
                    equipment={equipment}
                    onClose={() => closeDialog()}
                />
            </>
        });
    };

    return {
        handleView
    }
};