import { makeVar, ReactiveVar } from "@apollo/client";


export type SportEquipmentListPagination = {
  page: number;
  pageSize: number;
};

export const SportEquipmentPaginationInitial: SportEquipmentListPagination = {
  page: 0,
  pageSize: 50
};

export const sportEquipmentListPaginationVars = makeVar<SportEquipmentListPagination>(
  SportEquipmentPaginationInitial
);

export const useSportEquipmentListPaginationVars = () => createSportEquipmentListPaginationVars(sportEquipmentListPaginationVars);

const createSportEquipmentListPaginationVars = (sportEquipmentListPaginationVars: ReactiveVar<SportEquipmentListPagination>) => {

  const setPagination = (pagination: SportEquipmentListPagination) => {
    sportEquipmentListPaginationVars(pagination);
  };

  return {
    setPagination
  };
};
