import React, {useMemo, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import {UpdateSportSubZoneInput} from "../../../../../graphql/graphQlApiHooks";
import {useStores} from "../../../../../store";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(3)
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    form: {
        '& .input-wrapper': {
            flexWrap: 'nowrap',
            display: 'flex',
            '& .MuiFormControl-root': {
                flexGrow: 1,
                '&:first-child': {
                    width: '100%',
                    flexGrow: 2,
                },
                '&:not(:last-child)': {
                    marginRight: '.5em'
                }
            }
        }
    }
}));

function EditSportSubZone({...rest}) {
    const classes = useStyles();
    const {sportSubZone, open, onClose} = rest
    const [name, setName] = useState('');
    const [area, setArea] = useState('');
    const [eps, setEps] = useState('');
    const {settingsStore} = useStores()

    useMemo(() => {
        if (sportSubZone) {
            setName(sportSubZone.name || '')
            setArea(sportSubZone.area || '')
            setEps(sportSubZone.eps || '')
        }
    }, [sportSubZone])


    function handleClose() {
        onClose(false)
    }

    function handleUpdate() {
        if (name !== '') {
            settingsStore.sportSubZoneUpdate(
                {
                    sportObjectId: settingsStore?.sportObject?.id,
                    sportZoneId: settingsStore?.sportZone?.id,
                    id: sportSubZone.id,
                    name,
                    area: parseFloat(area),
                    eps: parseFloat(eps),
                } as UpdateSportSubZoneInput
            ).finally(() => {
                setName('')
                setArea('')
                setEps('')
                handleClose()
            })

        }
    }

    return <>
        {sportSubZone &&

        <Dialog
            onClose={handleClose}
            aria-labelledby="add-sportSubZone-dialog-title"
            open={open}
            className={classes.root}
        >
            <DialogTitle id="add-sportSubZone-dialog-title">
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                >
                    <span>Редактирование спортзоны.</span>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form
                    className={classes.form}
                    id='add-sportSubZone-form'
                    autoComplete="off"
                >
                    <div className="input-wrapper">
                        <TextField
                            name='sportSubZoneName'
                            id="sportSubZoneName"
                            label="Название"
                            placeholder="Добавьте название"
                            variant="outlined"
                            size={'small'}
                            autoComplete='off'
                            onChange={({target: {value}}: any) => setName(value)}
                            value={name}
                            fullWidth={true}
                        />
                        <TextField
                            name='area'
                            id="area"
                            label="Площадь"
                            placeholder="Добавьте адрес"
                            variant="outlined"
                            size={'small'}
                            autoComplete='off'
                            onChange={({target: {value}}: any) => setArea(value)}
                            value={area}
                            type={'number'}
                        />
                        <TextField
                            name='eps'
                            id="eps"
                            label="Е.П.С."
                            placeholder="Добавьте адрес"
                            variant="outlined"
                            size={'small'}
                            autoComplete='off'
                            onChange={({target: {value}}: any) => setEps(value)}
                            value={eps}
                            type={'number'}
                        />
                    </div>
                </form>

            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    color="primary"
                    onClick={handleUpdate}
                >
                    Изменить
                </Button>
            </DialogActions>
        </Dialog>
        }
    </>;
}

EditSportSubZone.protoTypes = {}

export default EditSportSubZone;
