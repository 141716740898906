import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, TextField,} from "@mui/material";
import {EquipmentRelocationRequestEntity, RejectRequestInput,} from "graphql/graphQlApiHooks";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";
import {useStores} from "store";

interface Props {
    data?: EquipmentRelocationRequestEntity | null
    open: boolean
    onClose: () => void
    acceptButton?: any
}

const RejectRelocation = observer((props: Props) => {
    const {data, open, onClose, acceptButton} = props;
    const {equipmentRelocationRequestStore} = useStores();

    const [comment, setComment] = useState<string>('');


    const handleConfirm = () => {
        const input: RejectRequestInput = {
            equipmentRelocationRequestId: data?.id as string,
            reason: comment,
        }

        equipmentRelocationRequestStore.reject(input).then(() => {
            handleClose()
        }).catch((error: any) => {
            console.error(error.message)
        })
    };

    const handleClose = () => {
        setComment('')
        onClose()
    };

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            title={'Отклонение заявки на перемещение оборудования'}
            titles={[
                <EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt}/>,
                <StepperRelocation targetSportObject={data?.targetSportObject}
                                   sourceSportObject={data?.sourceSportObject}/>
            ]}
            actions={<>
                {acceptButton}
                <Button
                    variant={'contained'}
                    onClick={handleConfirm}
                    color={'secondary'}
                >Отклонить заявку</Button>
            </>}
        >
            <TextField
                autoFocus
                margin="dense"
                id="RejectedComment"
                type="text"
                fullWidth
                multiline={true}
                rows={5}
                placeholder={'Причина отклонения'}
                value={comment}
                onChange={({target: {value}}) => setComment(value)}
                size={'small'}
            />

        </Dialog>
    );
})

export default RejectRelocation;
