// ----------------------------------------------------------------------

import {Theme} from "@mui/material";


export default function MuiButton(theme:Theme | any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          position: 'relative',
          maxWidth: '100%',
          boxSizing: 'border-box',
          display: 'inline-flex',
          verticalAlign: 'top',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          borderRadius: 5,
          margin: 0,
          outline: 'none',
          textDecoration: 'none',
          cursor: 'pointer',
          fontWeight: 400,
          transition: 'all .15s ease-in',
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          userSelect: 'none',
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none !important',
          },
        },
        sizeMedium:{
          padding: theme.spacing(1.5, 3),
        },
        sizeSmall:{
          fontSize: 13,
          padding: theme.spacing(1, 3),
        }
      }
    },
  };
}
