import React, {useCallback, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useTheme} from "@mui/styles";
import {Avatar, Box, CardHeader, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import Settings from "../../../store/settings";
import {SportEquipmentEntity} from "../../../graphql/graphQlApiHooks";
import dayjs, {Dayjs} from "dayjs";
import {useStores} from "../../../store";
import EditIcon from '@mui/icons-material/Edit';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {LocalizationProvider, MobileDatePicker} from "@mui/lab";
import { BootstrapTooltip } from "../../BootstrapTooltip";


interface Props {
	equipment?: SportEquipmentEntity | null
	relocationCreatedAt?: string
	endAdornment?: {
		title: string
		date: string
		editableDate?: boolean
	}
	onDateChange?: (date:Dayjs | null) => void;
	onDateAccept?: (date:Dayjs | null) => void;
}

const EquipmentInfo = observer((props: Props) => {
	const theme = useTheme();
	const {equipment, relocationCreatedAt, onDateChange, onDateAccept} = props;
	const [endAdornment, setEndAdornment] = useState<any>();
	const [tooltipDisabled, setTooltipDisabled] = useState(true);
	const [tooltipOpen, setTooltipOpen,] = useState(false);
	const [pickerOpen, setPickerOpen] = useState(false);
	const {userStore} = useStores()

	const equipmentName = useCallback((node: HTMLElement) => (
			(node !== null) && setTooltipDisabled(!(node.offsetWidth < node.scrollWidth))
	), []);

	useEffect(() => (() => {
		tooltipDisabled && setTooltipDisabled(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}), []);

	useEffect(() => {
		if (relocationCreatedAt && !props?.endAdornment) {
			setEndAdornment({
				title: 'Дата заявки',
				date: relocationCreatedAt
			})
		} else if (props?.endAdornment) {
			setEndAdornment({
				title: props?.endAdornment?.title,
				date: props?.endAdornment?.date
			})
		} else {
			setEndAdornment(undefined)
		}
	}, [relocationCreatedAt, props?.endAdornment]);


	function handleChange(date: Dayjs | null) {
		if (onDateChange) {
			onDateChange(date)
		}
	}
	function handleAccept(date: Dayjs | null) {
		if (onDateAccept) {
			onDateAccept(date)
		}
	}

	return (
			<CardHeader
					sx={{width: "100%", '& .MuiCardHeader-content': {overflow: 'hidden'}}}
					avatar={
						<Avatar
								sx={{
									width: 30,
									height: 30,
									bgcolor: theme.palette.background.default
								}}
								src={`${Settings.server}${equipment?.photos?.[0]?.avatarUrl}`}
						>
							<img
									src={`${process.env.PUBLIC_URL}/static/mossport_logo.svg`}
									alt="mossport_logo"
							/>
						</Avatar>
					}
					title={
						<BootstrapTooltip
								open={!tooltipDisabled && tooltipOpen}
								onClose={() => !tooltipDisabled && setTooltipOpen(false)}
								onOpen={() => !tooltipDisabled && setTooltipOpen(true)}
								title={equipment?.name || ''}
						>
							<Typography
									variant={'h5'}
									sx={{
										width: '100%',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden'
									}}
									ref={equipmentName}
							>
								{equipment?.name}
							</Typography>
						</BootstrapTooltip>
					}
					subheader={`Инв. № ${equipment?.inventoryNumber}`}
					action={
						(endAdornment) ?
								<Box sx={{ml: 2}}>
									<Typography
											variant={'caption'}
									>
										{endAdornment?.title}
									</Typography>
									<Typography
											variant={'body2'}
											component={'div'}
											sx={{
												width: '100%',
												display: 'flex',
												alignItems: 'center',
												'&:hover .MuiButtonBase-root': {
													opacity: 1,
												},
												'& > .MuiTextField-root':{
													display: 'none'
												}

											}}
									>
										<LocalizationProvider
												dateAdapter={AdapterDateFns}
												locale={ruLocale}
										>
											<MobileDatePicker
													value={dayjs(Number(endAdornment?.date))}
													onChange={(date) => handleChange(date)}
													onAccept={(date) => handleAccept(date)}
													renderInput={(params) => <TextField {...params} size={'small'}/>}
													open={pickerOpen}
													onClose={()=>setPickerOpen(false)}
													toolbarTitle={'Выберите дату'}
													okText={'Сохранить'}
													cancelText={'Отмена'}
											/>
										</LocalizationProvider>
										{dayjs(Number(endAdornment?.date)).format("DD MMM YYYY")}
										{(props?.endAdornment?.editableDate && !userStore.currentUser.user.isForSportObject) ?
												<BootstrapTooltip title={'Изменить дату крайнего срока выполнения работ'}>
													<IconButton
															size={'small'}
															sx={{
																opacity: 0,
																transition: 'opacity .3s',
																ml: 'auto'
															}}
															onClick={()=>setPickerOpen(true)}
													>
														<EditIcon fontSize={'small'}/>
													</IconButton>
												</BootstrapTooltip> :
												null
										}
									</Typography>
								</Box> : undefined
					}
			/>
	);
})

export default EquipmentInfo;
