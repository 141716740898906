import {Avatar, Box, CardHeader, Checkbox} from "@mui/material";
import {DepartmentEnum, UserEntity} from "graphql/graphQlApiHooks";
import {FC} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {getCurrentDepartmentName} from "../lib/getCurrentDepartmentName";
import {DepartmentEnumWithNoDepartment, NoDepartmentEnum} from "../types";

interface SelectOptionProps {
    option: UserEntity;
    selected: boolean;
    sportObjectId: string;
}

export const SelectOption: FC<SelectOptionProps> = ({option, selected, sportObjectId}) => {

    const isForSportObject = option.departments?.includes(DepartmentEnum.SportObject);


    return (
        <>
            <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                style={{marginRight: 8}}
                checked={selected}
            />
            <CardHeader
                sx={{
                    p: 0,
                    width: '100%',
                }}
                avatar={
                    <Avatar
                        sx={{
                            bgcolor: !isForSportObject ? 'secondary.main' : 'primary.main',
                            color: 'white !important',
                            fontSize: '8px !important',
                            width: '20px',
                            height: '20px',
                        }}
                    >
                        {option.login.substring(0, 2).toUpperCase()}
                    </Avatar>
                }
                title={option.fio || option.login}
                subheader={
                    isForSportObject
                        ? option.sportObjects?.map((item) => item.name).join(', ')
                        : option.departments?.map((item) => getCurrentDepartmentName(item as DepartmentEnumWithNoDepartment)).join(', ')
                }
                action={
                    <Box
                        sx={{
                            fontSize: '12px',
                            marginLeft: 'auto',
                            opacity: '.8',
                        }}
                    >
                        {option.login}
                    </Box>
                }
            />
        </>
    );
};