import React from 'react';

const Logo = (props: any) => {
    return (
        <img
            alt="Logo"
            src="/static/mossport_logo_2.svg"
            {...props}
        />
    );
};

export default Logo;