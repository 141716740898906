import {autorun, makeAutoObservable, runInAction, toJS} from 'mobx';
import {
	AcceptRequestBySportObjectDocument,
	AcceptRequestBySportObjectInput,
	AcceptRequestDocument,
	AcceptRequestInput,
	EquipmentRelocationRequestEntity,
	GetEquipmentRelocationRequestByIdsDocument,
	GetRelocationRequestsDocument,
	RejectRequestByAcceptanceDepartmentDocument,
	RejectRequestByAcceptanceDepartmentInput,
	RejectRequestDocument,
	RejectRequestInput,
	RelocateEquipmentDocument,
	RelocateEquipmentInput,
	SportObjectEntity,
	StartEquipmentRelocationDocument,
	StartEquipmentRelocationInput,
} from '../../graphql/graphQlApiHooks';
import RootStore, {client} from '../.';
import {isEqual, queryToMobxObservable} from '../../helpers';
import Settings from '../settings';
import dayjs from 'dayjs';
import {EquipmentRelocationRequestStatusEnum} from "./equipment-relocation-request-status.enum";

interface Filters {
	sourceSportObjectIds: null | string[];
	targetSportObjectIds: null | string[];
	name: null | string;
	inventoryNumber: null | string;
	applicationDate: [null | Date, null | Date] | null;
	statuses: null | string[];
	equipmentTypeIds: string[] | null;
	sportKindIds: string[] | null;
}

interface SortOrder {
	[key: string]: 'DESC' | 'ASC';
}

const EquipmentRelocationRequest = class {
	public rootStore?: RootStore;
	public allRequests: EquipmentRelocationRequestEntity[] = [];
	public requestsAll: EquipmentRelocationRequestEntity[] = [];
	public currentRelocateEquipment: EquipmentRelocationRequestEntity | null =
			null;
	public loading: boolean = true;
	public error?: any;
	public count: number = 0;
	public currentRelocateEquipmentId?: string;
	public page: number = 0;
	public pageSize: number = 50;
	public sortOrder: SortOrder | null = null;
	private firstLoading: boolean = true;

	get statuses() {
		return [
			{
				id: EquipmentRelocationRequestStatusEnum.RequestSubmitted,
				name: 'Подана'
			},
			{
				id: EquipmentRelocationRequestStatusEnum.AcceptedBySportObject,
				name: 'На рассмотрении'
			},
			{
				id: EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment,
				name: 'Отклонена'
			},
			{
				id: EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment,
				name: 'Ожидает отправления'
			},
			{
				id: EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment,
				name: "Отправлена в транспортную службу"
			},
			{
				id: EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment,
				name: "Отклонена отделом приемки"
			},
			{
				id: EquipmentRelocationRequestStatusEnum.RelocationStarted,
				name: 'В пути'
			},
			{
				id: EquipmentRelocationRequestStatusEnum.Relocated,
				name: 'Исполнена'
			}
		];
	}

	public filters: Filters = {
		sourceSportObjectIds: null,
		targetSportObjectIds: null,
		name: null,
		inventoryNumber: null,
		applicationDate: [null, null],
		statuses: null,
		equipmentTypeIds: null,
		sportKindIds: null,
	};

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
		autorun(() => {
			if (this.firstLoading === true) {
				if (!this.rootStore?.userStore?.currentUser?.user?.isForSportObject) {
					this.setFilters({
						statuses: [
							EquipmentRelocationRequestStatusEnum.AcceptedBySportObject,
							EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment,
							EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment,
							EquipmentRelocationRequestStatusEnum.RelocationStarted,
							EquipmentRelocationRequestStatusEnum.Relocated,
							EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment,
							EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment,
						],
					});
				}
				this.firstLoading = false;
			}

			if (this.query?.current()) {
				let result: any = this.query.current();
				result = toJS(result.getRelocationRequests);
				let count = result.count;
				let relocationRequests = toJS(result.relocationRequests);

				if (!isEqual(relocationRequests, toJS(this.allRequests))) {
					this.allRequests = relocationRequests;
					this.count = count;
				}
				this.setLoading(false);
			}

			if (this.queryCurrentRelocateEquipment?.current()) {
				let result: any = this.queryCurrentRelocateEquipment.current();
				result = toJS(result.getEquipmentRelocationRequestByIds);
				let currentRelocationRequest = toJS(result?.[0]);

				if (
						!isEqual(
								currentRelocationRequest,
								toJS(this.currentRelocateEquipment)
						)
				) {
					this.currentRelocateEquipment = currentRelocationRequest;
				}
			}

			// if (!toJS(this.filters.sourceSportObjectIds) && this.sourceSportObjects.length > 0) {
			// 	this.setFilters({sourceSportObjectIds: toJS(this.sourceSportObjects.map(val => val.id))})
			// }
		});
	}

	get input(): any {
		return {
			sourceSportObjectIds: this.filters.sourceSportObjectIds,
			targetSportObjectIds: this.filters.targetSportObjectIds,
			name: this.filters?.name,
			inventoryNumber: this.filters?.inventoryNumber,
			dateFrom: this.filters?.applicationDate?.[0]
					? dayjs(toJS(this.filters?.applicationDate?.[0])).format(
							'YYYY-MM-DD'
					)
					: null,
			dateTo: this.filters?.applicationDate?.[1]
					? dayjs(toJS(this.filters?.applicationDate?.[1])).format(
							'YYYY-MM-DD'
					)
					: null,
			statuses: this.filters?.statuses,
			skip: this.page * this.pageSize,
			take: this.pageSize,
			equipmentTypeIds: this.filters?.equipmentTypeIds,
			sportKindIds: this.filters?.sportKindIds,
			order: this.sortOrder,
		};
	}

	get query() {
		if (this.rootStore?.userStore?.currentUser)
			return queryToMobxObservable(
					client.watchQuery({
						query: GetRelocationRequestsDocument,
						variables: {
							input:this.input,
						},
						pollInterval: Settings.pollIntervals.equipmentRelocationRequests,
					})
			);
		return undefined;
	}

	get queryCurrentRelocateEquipment() {
		if (
				this.rootStore?.userStore?.currentUser &&
				this?.currentRelocateEquipmentId
		)
			return queryToMobxObservable(
					client.watchQuery({
						query: GetEquipmentRelocationRequestByIdsDocument,
						variables: {
							ids: [this.currentRelocateEquipmentId],
						},
						// pollInterval: Settings.pollIntervals.allEquipmentRelocationRequests,
					})
			);
		return undefined;
	}

	get sourceSportObjects(): SportObjectEntity[] {
		return this.rootStore?.sportObjectsStore.allSportObjects;
	}

	get targetSportObjects(): SportObjectEntity[] {
		return this.rootStore?.sportObjectsStore.sportObjects;
	}

	setFilters(filter: any) {
		const checkFilterIsEmptyString = (filter: any) => {
			if (Object.values(filter)[0] === '' || !Object.values(filter)[0]) {
				const filterName = Object.keys(filter)[0];
				filter =
						filterName !== 'applicationDate'
								? {[filterName]: null}
								: {[filterName]: [null, null]};
			}
			return filter;
		};

		runInAction(() => {
			this.filters = Object.assign(
					this.filters,
					checkFilterIsEmptyString(filter)
			);
		});
	}

	setSortOrder(sortOrder: SortOrder | null) {
		this.sortOrder = sortOrder;
	}

	setLoading(loading: boolean) {
		this.loading = loading;
	}

	setPage(page: number) {
		this.page = page;
	}

	setPageSize(pageSize: number) {
		this.pageSize = pageSize;
	}

	// get currentRelocateEquipment() {
	// 	return this.requestsAll.slice().find((item) => item.id === this.currentRelocateEquipmentId)
	// }

	setCurrentRelocateEquipmentId(id: string) {
		this.currentRelocateEquipmentId = id;
	}

	acceptRequestBySportObject = (input: AcceptRequestBySportObjectInput) =>
			client
					.mutate({
						mutation: AcceptRequestBySportObjectDocument,
						variables: {input},
						refetchQueries: [{
							query: GetRelocationRequestsDocument,
							variables: {
								input:this.input,
							}, }],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage(
								'Заявка на оборудование, принята на объекте.'
						);
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	accept = (input: AcceptRequestInput) =>
			client
					.mutate({
						mutation: AcceptRequestDocument,
						variables: {input},
						refetchQueries: [{
							query: GetRelocationRequestsDocument,
							variables: {
								input:this.input,
							}, }],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Заявка на перемещение подтверждена.');
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	reject = (input: RejectRequestInput) =>
			client
					.mutate({
						mutation: RejectRequestDocument,
						variables: {input},
						refetchQueries: [{
							query: GetRelocationRequestsDocument,
							variables: {
								input:this.input,
							}, }],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Заявка на перемещение отклонена');
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	// acceptByMaintenanceDepartment = (input: SendRequestToTransportDepartmentInput) =>
	// 		client
	// 				.mutate({
	// 					mutation: SendTransportdepartmentRequestDocument,
	// 					variables: {input},
	// 					refetchQueries: [{
	// 						query: GetRelocationRequestsDocument,
	// 						variables: {
	// 							input:this.input,
	// 						}, }],
	// 				})
	// 				.then(() => {
	// 					this.rootStore?.setSnakeMessage('Заявка на перемещение подтверждена.');
	// 				})
	// 				.catch((error: any) =>
	// 						this.rootStore?.setSnakeMessage(error.message, 'error')
	// 				);

	rejectRequestByAcceptanceDepartment = (input: RejectRequestByAcceptanceDepartmentInput) =>
			client
					.mutate({
						mutation: RejectRequestByAcceptanceDepartmentDocument,
						variables: {input},
						refetchQueries: [{
							query: GetRelocationRequestsDocument,
							variables: {
								input:this.input,
							}, }],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Заявка на перемещение отклонена');
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	startEquipmentRelocation = (input: StartEquipmentRelocationInput) =>
			client
					.mutate({
						mutation: StartEquipmentRelocationDocument,
						variables: {input},
						refetchQueries: [{
							query: GetRelocationRequestsDocument,
							variables: {
								input:this.input,
							}, }],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage(
								'Отправление оборудования подтверждено'
						);
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	relocate = (input: RelocateEquipmentInput) =>
			client
					.mutate({
						mutation: RelocateEquipmentDocument,
						variables: {input},
						refetchQueries: [{
							query: GetRelocationRequestsDocument,
							variables: {
								input:this.input,
							}, }],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Оборудование успешно перемещено');
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	getStatusName(status: string) {
		return this.statuses?.find((stat: any) => stat.id === status)?.name;
	}
};

export default EquipmentRelocationRequest;
