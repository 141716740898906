import {RequestEntity} from "../../../../types/RequestEntity";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import Tooltiper from "components/Tooltiper";
import {formatPrice} from "helpers/formatPrice";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import React from "react";

// Компонент рендеринга ячейки для стоимости
export const PriceCellRenderer = ({equipmentRequestEntity}: { equipmentRequestEntity: RequestEntity }) => {
    return (
        <>
            {isSingleRequest(equipmentRequestEntity) &&
                <Tooltiper text={formatPrice(equipmentRequestEntity?.price || 0)} props={{textAlign: 'center'}}/>
            }
            {isCombinedRequest(equipmentRequestEntity) &&
                <Tooltiper
                    text={formatPrice(equipmentRequestEntity?.price || 0)}
                    props={{
                        variant: 'subtitle1',
                        textAlign: 'center'
                    }}
                />}
        </>
    );
};
