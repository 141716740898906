import {Box, Card, DialogContent, Stack, Typography} from '@mui/material';
import React, {FC} from 'react';
import {CombinedEquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {Helmet} from "react-helmet";
import Iconify from "components/Iconify";
import {alpha, lighten} from "@mui/material/styles";
import {useTheme} from "@mui/styles";
import Settings from "store/settings";
import Button from "@mui/material/Button";
import DateViewer from "components/DateViewer";
import {formatPrice} from "helpers/formatPrice";
import {getLabelStatusRequest} from '../../lib/getLabelStatusRequest';
import {getStatusIcon} from "../../lib/getStatusIcon";
import {CombinedRequestChildTable} from "../EquipmentRequestTable/components/CombinedRequestChildTable";
import {StatusChip} from "../ui/StatusChip";
import {FieldDescription} from "../ui/FieldDescription";

interface Props {
    equipment: CombinedEquipmentRequestEntity;
    onClose: () => void;
}

export const ViewCombineRequestDialog: FC<Props> = (props) => {
    const {equipment, onClose} = props;
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>
                    {equipment?.name ? `Просмотр группы заявок на спортивное оборудование | ${equipment?.name}` : "Просмотр группы заявок на спортивное оборудование"}
                </title>
            </Helmet>

            <DialogContent>
                <Box sx={{height: "100%", overflow: "auto", position: "relative"}}>
                    <Box sx={{display: "flex", flexDirection: "column", height: "100%"}} width={'100%'}>

                        <Box width={"100%"} mt={2} sx={{bgcolor: "background.paper"}}>
                            <Stack direction={'row'} spacing={2}>
                                <Box display={'flex'} flexGrow={1} flexShrink={1} minWidth={0}>
                                    <StatusChip
                                        status={equipment?.requestStatus}
                                        size={"small"}
                                        icon={getStatusIcon(equipment?.requestStatus)}
                                        label={getLabelStatusRequest(equipment?.requestStatus)}
                                    />
                                </Box>

                                {equipment?.urgentRequirementComment &&
                                    <Box display={'flex'} flexGrow={0} flexShrink={0}>
                                        <StatusChip
                                            size={"small"}
                                            icon={<Iconify icon="line-md:alert-twotone"/>}
                                            label={'Срочная заявка!'}
                                            customColor={theme.palette.error.main}
                                        />
                                    </Box>
                                }
                            </Stack>

                            <Box position={"sticky"} top={0} sx={{
                                bgcolor: alpha(theme.palette.background.paper, 0.5),
                                zIndex: 1,
                                py: 2,
                                backdropFilter: "blur(10px)",
                                width: "100%",
                                mb: 4
                            }}>
                                <Typography variant={"h2"} color={"text.main"} sx={{mb: -2}}>
                                    {equipment?.name}
                                </Typography>
                                <Typography variant={"caption"} color={"grey.500"}>
                                    Количество заявок: {equipment?.childRequests?.length}
                                </Typography>

                            </Box>

                            <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <Stack gap={4} width={'100%'}>
                                    {equipment?.urgentRequirementComment &&
                                        <Card elevation={0}>
                                            <Box sx={{
                                                px: 2,
                                                py: 1,
                                                bgcolor: lighten(theme.palette.error.main, 0.95),
                                                color: theme.palette.error.main
                                            }}>
                                                <Typography color='inherit' gutterBottom variant="h6">Причина срочной
                                                    потребности</Typography>
                                                <Typography color='inherit'
                                                            variant="body2">{equipment.urgentRequirementComment}</Typography>
                                            </Box>
                                        </Card>
                                    }

                                    <Stack gap={2}>
                                        <FieldDescription label={"Общая стоимость оборудования"}
                                                          value={formatPrice((equipment?.price || 0))}/>
                                        <FieldDescription label={"Дата подачи заявки"}
                                                          value={<DateViewer date={equipment.createdAt}
                                                                             showIcon={false}/>}/>
                                    </Stack>

                                    {equipment?.description &&
                                        <Card elevation={0}>
                                            <Box
                                                sx={{px: 2, py: 1, bgcolor: lighten(theme.palette.primary.main, 0.95)}}>
                                                <Typography gutterBottom variant="h6">Дополнительная информация по
                                                    заявке</Typography>
                                                <Typography variant="body2">{equipment.description}</Typography>
                                            </Box>
                                        </Card>
                                    }

                                    {equipment?.childRequests && equipment.childRequests.length > 0 &&
                                        <Card elevation={0}>
                                            <CombinedRequestChildTable equipments={equipment?.childRequests}
                                                                       disableEdit={true}/>
                                        </Card>
                                    }

                                    {equipment?.serviceNoteScanFile &&
                                        <FieldDescription
                                            label={'Скан служебной записки'}
                                            value={
                                                <Button
                                                    size={"small"}
                                                    component={"a"}
                                                    title={equipment?.serviceNoteScanFile?.description}
                                                    href={Settings.server + equipment?.serviceNoteScanFile?.url}
                                                    target={"_blank"}
                                                    color={'inherit'}
                                                    startIcon={<Iconify
                                                        icon={"material-symbols:sim-card-download-outline"}/>}
                                                >
                                                    Скачать
                                                </Button>
                                            }
                                        />
                                    }
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display={"flex"} pt={4} sx={{
                    position: "sticky",
                    bottom: 0,
                    left: 0,
                    zIndex: 1,
                    width: "100%",
                    bgcolor: "background.paper"
                }}>
                    {/*<Button variant='text' color='inherit' size={"small"} onClick={onClose}>Изменить</Button>*/}
                    <Box flexGrow={1}/>
                    <Button variant='contained' color='secondary' size={"small"} onClick={onClose}>Закрыть</Button>
                </Box>
            </DialogContent>
        </>
    );
};