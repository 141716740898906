import React from "react";
import {CombinedEquipmentRequestEntity, useDeleteCombinedEquipmentRequestMutation} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {DeleteCombineRequestDialog} from "../../components/Dialogs/DeleteCombineRequestDialog";

export const useDeleteCombineRequest = () => {
    const [dialog, closeDialog] = useDialog();
    const [deleteEquipmentRequest] = useDeleteCombinedEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests'],
    })

    const handleDeleteCombineRequest = (equipment: CombinedEquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Удаление объединённой заявки на оборудование",
            maxWidth: "xs",
            fullWidth: true,
            children: <>
                <DeleteCombineRequestDialog
                    equipment={equipment}
                    onClose={() => closeDialog()}
                    onDelete={(id: string) => {
                        closeDialog()
                        deleteEquipmentRequest({variables: {id}})
                    }}
                />
            </>
        });
    };

    return {
        handleDeleteCombineRequest
    }
};