import React, { FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import { LoadingButton, StaticDateRangePicker } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useExportRentedEquipmentExcelMutation } from "../../../../../graphql/graphQlApiHooks";
import { isDate } from "date-fns";
import { useSnackbar } from "notistack";
import Settings from "../../../../../store/settings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

interface RentalReportProps {
  isOpen: boolean;
  onClose: () => void;
}

const RentalReportDialog: FC<RentalReportProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<[Date, Date]>([null, null]);
  const [exportExcel, { loading }] = useExportRentedEquipmentExcelMutation({
    variables: {
      input: {
        startDate: value[0],
        endDate: value[1]
      }
    }
  });

  const handleExport = () => {
    exportExcel()
      .then(res => {
        if (res.data.exportRentedEquipmentExcel.isEquipmentsFound) {
          const url = res.data.exportRentedEquipmentExcel.file.url;
          window.location.replace(`${Settings.server}${url}`);
        } else {
          enqueueSnackbar("В указанный период не найдено ни одной аренды", { variant: "warning" });
        }
      })
      .catch(err => {
        enqueueSnackbar(`Ошибка при экспорте: ${err?.message}`, { variant: "error" });
      });
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth='lg'
      onClose={onClose}
      title='Отчёт о количестве аренд оборудования'
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ flexGrow: "1" }}
          variant='subtitle1'
        >Отчёт о количестве аренд оборудования</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={ruLocale}
        >
          <StaticDateRangePicker
            displayStaticWrapperAs='desktop'
            calendars={2}
            disabled={loading}
            value={value}
            onChange={setValue}
            renderInput={() => <></>}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          size='small'
          onClick={onClose}
        >Отмена</Button>
        <LoadingButton
          size='small'
          loading={loading}
          loadingPosition='start'
          variant='contained'
          startIcon={<CloudDownloadIcon />}
          disabled={loading || value.filter(isDate).length !== 2}
          onClick={handleExport}
        >Сформировать</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RentalReportDialog;
