import React, {useEffect, useState} from 'react';
import {Button, OutlinedInputProps, TextField} from '@mui/material';
import DeleteIconOutline from "@mui/icons-material/DeleteOutline";
import {UpdateSportObjectInput} from "../../../../../graphql/graphQlApiHooks";
import {useStores} from "../../../../../store";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material/styles";
import {observer} from "mobx-react-lite";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxHeight: 297,
        overflow: 'auto'
    },
    inputWrapper: {
        '& .MuiFilledInput-root': {
            marginBottom: '.6em',
            borderRadius: theme.shape.borderRadius
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.text.contrast
        },
        '& .MuiFilledInput-input': {
            color: theme.palette.text.contrast
        },
    }
}))

const EditSportObject = observer(({
    open,
    onCompleted,
    deleteOnClicked
}: {
    open: boolean,
    onCompleted: () => void,
    deleteOnClicked: () => void
}) => {
    const {settingsStore} = useStores();
    const classes = useStyles();

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [activeDirectoryGroup, setActiveDirectoryGroup] = useState('');

    useEffect(() => {

        setName(settingsStore.sportObject.name)
        setAddress(settingsStore.sportObject.address)
        setActiveDirectoryGroup(settingsStore.sportObject.activeDirectoryGroup)

    }, [settingsStore.sportObject])

    const onInputName = ({target: {value}}: any) => setName(value);
    const onInputAddress = ({target: {value}}: any) => setAddress(value);
    const onInputActiveDirectoryGroup = ({target: {value}}: any) => setActiveDirectoryGroup(value);

    const onSubmitEdit = (e: any) => {
        e.preventDefault()
        if (name !== '') {
            settingsStore.sportObjectUpdate({
                id: settingsStore.sportObject.id,
                name,
                address,
                activeDirectoryGroup
            } as UpdateSportObjectInput).then(() => {
                onCompleted()
            })
        }
    };

    return <>
        <form
            id="edit_object"
            className={classes.root}
            onSubmit={onSubmitEdit}
        >

            <div className={classes.inputWrapper}>
                <TextField
                    name='name'
                    id="edit_name"
                    label="Название"
                    placeholder="Добавьте название"
                    variant="filled"
                    size={'small'}
                    autoComplete={'off'}
                    value={name}
                    onChange={onInputName}
                    fullWidth={true}
                    multiline={true}
                    maxRows={3}
                    InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}

                />
                <TextField
                    name='address'
                    id="edit_address"
                    label="Адрес"
                    placeholder="Добавьте адрес"
                    variant="filled"
                    size={'small'}
                    autoComplete={'off'}
                    value={address}
                    onChange={onInputAddress}
                    fullWidth={true}
                    multiline={true}
                    maxRows={2}
                    InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
                />
                <TextField
                    name='activeDirectoryGroup'
                    id="activeDirectoryGroup"
                    label="Группа в Active Directory"
                    placeholder="Укажите группу в Active Directory"
                    variant="filled"
                    size={'small'}
                    autoComplete={'off'}
                    value={activeDirectoryGroup}
                    onChange={onInputActiveDirectoryGroup}
                    fullWidth={true}
                    InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
                />

            </div>
            <Button
                size={'large'}
                color="inherit"
                variant="text"
                startIcon={<DeleteIconOutline/>}
                onClick={deleteOnClicked}
                fullWidth={true}
            >
                Удалить
            </Button>
        </form>
    </>;
})

// EditSportObject.protoTypes = {}

export default EditSportObject;
