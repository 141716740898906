import React from "react";
import {useDialog} from "providers/DialogProvider";
import {CreateRequestDialog} from "../../components/Dialogs/CreateRequestDialog";

export const useCreateRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const onCreateRequestDialogHandler = () => {
        dialog({
            onClose: closeDialog,
            title: "Заявка на новое спортивное оборудование",
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            maxWidth: "md",
            fullWidth: true,
            children: <>
                <CreateRequestDialog close={closeDialog}/>
            </>
        });
    };

    return {
        onCreateRequestDialogHandler
    }
};