import React, {FC, useEffect, useRef, useState} from "react";
import {
    CombinedEquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    FileStoreEntity,
    UpdateCombinedEquipmentRequestInput,
    useUpdateCombinedEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import {
    Box,
    Button,
    Card,
    Collapse,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    OtherDocumentsGrid
} from "views/Pages/Equipments/components/Dialogs/views/SportEquipmentDocuments/OtherDocumentsGrid";
import {getLabelStatusRequest} from "../../lib/getLabelStatusRequest";
import {Helmet} from "react-helmet";
import {statusOptions} from "../../lib/statusOptions";
import {getStatusColor} from "../../lib/getStatusColor";
import {getStatusIcon} from "../../lib/getStatusIcon";
import Tooltiper from "components/Tooltiper";
import {CombinedRequestChildTable} from "../EquipmentRequestTable/components/CombinedRequestChildTable";
import {useSnackbar} from "notistack";

// Валидация с использованием Yup
const validationSchema = Yup.object({
    name: Yup.string().required("Название группы обязательно"),
    price: Yup.number(),
    urgentRequirementComment: Yup.string().when("isCriticallyNeed", {
        is: true,
        then: Yup.string().required("Укажите причину срочности"),
        otherwise: Yup.string().nullable(),
    }),
    serviceNoteScanFileId: Yup.string().nullable(),
    description: Yup.string().nullable(),
    requestStatus: Yup.mixed().oneOf(Object.values(EquipmentRequestStatusEnum)).required("Выберите статус заявки"),
});


interface Props {
    close: () => void;
    combinedEquipment: CombinedEquipmentRequestEntity;
}

export const EditCombinedEquipmentRequestDialog: FC<Props> = (props) => {
    const {enqueueSnackbar} = useSnackbar()
    const {close, combinedEquipment} = props;
    // ссылка на поле описания срочной потребности
    const criticallyNeedRef = useRef<HTMLInputElement>(null);

    const [serviceNoteScan, setServiceNoteScan] = useState<FileStoreEntity | null>(null);


    const [updateCombinedEquipmentRequest, {loading}] = useUpdateCombinedEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests'],
        awaitRefetchQueries: true,
        onCompleted: () => {
            enqueueSnackbar('Заявка обновлена', {variant: 'success'})
            close()
        },
        onError: () => {
            enqueueSnackbar('Произошла ошибка', {variant: 'error'})
        }
    })

    useEffect(() => {
        if (combinedEquipment) {
            setServiceNoteScan(combinedEquipment.serviceNoteScanFile || null);
        }
    }, [combinedEquipment]);

    // Инициализация начального состояния
    const initialValues = {
        id: combinedEquipment.id,
        name: combinedEquipment.name,
        description: combinedEquipment.description,
        price: combinedEquipment?.price || 0,
        serviceNoteScanFileId: combinedEquipment.serviceNoteScanFileId,
        urgentRequirementComment: combinedEquipment?.urgentRequirementComment || '',
        isCriticallyNeed: Boolean(combinedEquipment?.urgentRequirementComment?.length && combinedEquipment?.urgentRequirementComment?.length > 0),
        requestStatus: combinedEquipment.requestStatus,
        childRequestId: combinedEquipment.childRequests.map(equipment => equipment.id),
    };

    const onSubmit = (values: typeof initialValues) => {

        const updateCombinedEquipmentRequestInput = {
            id: combinedEquipment.id,
            name: values?.name || null,
            childRequestId: combinedEquipment.childRequests.map(equipment => equipment.id),
            requestStatus: values?.requestStatus || null,
            price: (values?.price || 0) > 0 ? values.price : null,
            description: values?.description || null,
            urgentRequirementComment: values?.urgentRequirementComment || null,
            serviceNoteScanFileId: values?.serviceNoteScanFileId || null
        } as UpdateCombinedEquipmentRequestInput;

        updateCombinedEquipmentRequest({variables: {input: updateCombinedEquipmentRequestInput}})
    };
    const {
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnBlur: true,

    });

    return (
        <>
            <Helmet>
                <title>
                    {
                        values?.name ? `Объединение заявок спортивное оборудование | ${values?.name}` : "Объединение заявок на спортивное оборудование"
                    }
                </title>
            </Helmet>

            <DialogContent
                dividers
                sx={{
                    bgcolor: "background.default",
                    py: 2,
                    px: 3,
                }}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <Card sx={{py: 2, px: 3}} elevation={2}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"}>
                                    Общая информация
                                </Typography>
                                {/* Название Группы */}
                                <TextField
                                    fullWidth
                                    required
                                    id='name'
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    label='Название группы заявок'
                                    sx={{flexGrow: 1}}
                                    placeholder='Укажите название'
                                    error={touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                    variant={"filled"}
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: {
                                            fontWeight: 600,
                                        }
                                    }}
                                    InputLabelProps={{shrink: true}}
                                />
                                <Stack>
                                    <FormLabel component="legend" sx={{
                                        fontSize: "1rem",
                                        transform: " scale(0.75)",
                                        transformOrigin: "top left"
                                    }}>
                                        Количество заявок: {combinedEquipment.childRequests.length}
                                    </FormLabel>
                                    {/* Таблица заявок */}
                                    <CombinedRequestChildTable equipments={combinedEquipment.childRequests}/>
                                </Stack>
                            </Stack>
                        </Card>

                        <Card sx={{py: 2, px: 3}} elevation={2}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"}>
                                    Детали заявки
                                </Typography>

                                <Stack direction={'row'} spacing={3} width={'100%'}>

                                    {/* Выбор статуса */}
                                    <FormControl
                                        variant="standard"
                                        size="small"
                                        error={touched.requestStatus && !!errors.requestStatus}
                                        fullWidth
                                    >
                                        <Select
                                            name="requestStatus"
                                            value={values.requestStatus}
                                            onChange={handleChange}
                                            disableUnderline={true}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxWidth: 300,
                                                    },
                                                },
                                            }}
                                            renderValue={(selected) => {
                                                const selectedOption = statusOptions.find(option => option.value === selected);
                                                const icon = getStatusIcon(selected, '1.5rem');
                                                const label = selectedOption ? selectedOption.label : getLabelStatusRequest(selected);

                                                return (
                                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyItems: 'center'
                                                        }}>{icon}</Box>
                                                        <Tooltiper text={label}/>
                                                    </Box>
                                                );
                                            }}
                                            sx={{
                                                mr: 2,
                                                '& .MuiSelect-select': {
                                                    backgroundColor: (theme) => {
                                                        const status = values.requestStatus;
                                                        return getStatusColor(status, theme).backgroundColor;
                                                    },
                                                    color: (theme) => {
                                                        const status = values.requestStatus;
                                                        return getStatusColor(status, theme).color;
                                                    },
                                                    p: 2,
                                                    pr: '32px !important',
                                                    borderRadius: 1,
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: (theme) => {
                                                        const status = values.requestStatus;
                                                        return getStatusColor(status, theme).color;
                                                    },
                                                    right: 8
                                                }
                                            }}

                                        >
                                            {statusOptions.map(({value, label}) => {
                                                if (value === EquipmentRequestStatusEnum.Rejected) {
                                                    return null;
                                                }
                                                if (combinedEquipment.requestStatus === EquipmentRequestStatusEnum.Acceptance) {
                                                    if (
                                                        value !== EquipmentRequestStatusEnum.EquipmentAccepted
                                                        && value !== EquipmentRequestStatusEnum.Acceptance
                                                    ) {
                                                        return null;
                                                    }
                                                }
                                                return (
                                                    <MenuItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'start', gap: 2,
                                                            color: (theme) => {
                                                                return getStatusColor(value, theme).color;
                                                            },
                                                        }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyItems: 'center'
                                                            }}>{getStatusIcon(value, '1.5rem')}</Box>
                                                            <Box
                                                                sx={{
                                                                    whiteSpace: 'normal'
                                                                }}
                                                            >{label}</Box>
                                                        </Box>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        {touched.requestStatus && errors.requestStatus && (
                                            <FormHelperText>{errors.requestStatus}</FormHelperText>
                                        )}
                                    </FormControl>


                                    {/*  Срочная потребность */}
                                    <FormControl
                                        size={"small"}
                                        variant={"filled"}
                                        component="fieldset"
                                        fullWidth
                                    >
                                        <FormLabel component="legend" sx={{
                                            fontSize: "1rem",
                                            transform: " scale(0.75)",
                                            transformOrigin: "top left"
                                        }}>
                                            Срочная потребность
                                        </FormLabel>
                                        <FormControlLabel
                                            id={"isCriticallyNeed"}
                                            label={values.isCriticallyNeed ? "Да" : "Нет"}
                                            sx={{ml: 0}}
                                            control={
                                                <Switch
                                                    name="isCriticallyNeed"
                                                    color="primary"
                                                    size={"small"}
                                                    onChange={({target: {checked}}) => {
                                                        setFieldValue("isCriticallyNeed", checked);
                                                        if (!checked) {
                                                            setFieldValue("urgentRequirementComment", "");
                                                            setFieldTouched('urgentRequirementComment', false);
                                                        } else {
                                                            setTimeout(() => {
                                                                criticallyNeedRef?.current.focus();
                                                            }, 0)
                                                        }
                                                    }}
                                                    checked={Boolean(values.isCriticallyNeed)}
                                                />
                                            }
                                        />
                                    </FormControl>


                                    {/*Стоимость оборудования*/}
                                    <TextField
                                        value={values.price}
                                        onChange={handleChange}
                                        name="price"
                                        fullWidth
                                        id='price'
                                        label='Общая стоимость оборудования'
                                        size={'small'}
                                        placeholder='Введите стоимость'
                                        type='number'
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">₽</InputAdornment>
                                        }}
                                        InputLabelProps={{shrink: true}}
                                        error={touched.price && !!errors.price}
                                        helperText={touched.price && errors.price}
                                    />
                                </Stack>

                                {/* Причина срочности */}
                                <Collapse in={values.isCriticallyNeed} sx={{width: "100%"}}>
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        variant={"filled"}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        inputRef={criticallyNeedRef}
                                        required
                                        name="urgentRequirementComment"
                                        id="urgentRequirementComment"
                                        label="Причина срочности"
                                        value={values.urgentRequirementComment}
                                        multiline
                                        onChange={({target: {value}}) => {
                                            setFieldValue("urgentRequirementComment", value);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched('urgentRequirementComment', true);
                                        }}
                                        minRows={3}
                                        maxRows={3}
                                        placeholder="Почему оборудование необходимо срочно?"
                                        size={"small"}
                                        error={touched.urgentRequirementComment && !!errors.urgentRequirementComment}
                                        helperText={touched.urgentRequirementComment && errors.urgentRequirementComment}
                                    />
                                </Collapse>

                                {/* Прикрепить скан служебной записки */}
                                <FormControl
                                    size={"small"}
                                    fullWidth
                                    variant={"filled"}
                                    component="fieldset"
                                    sx={{px: '6px'}}
                                >
                                    <FormLabel component="legend" sx={{
                                        fontSize: "1rem",
                                        transform: "scale(0.75)",
                                        transformOrigin: "top left",
                                        mb: -2
                                    }}>
                                        Прикрепите скан служебной записки с потребностью
                                    </FormLabel>
                                    <OtherDocumentsGrid
                                        documents={serviceNoteScan ? [serviceNoteScan] : null}
                                        setDocuments={(value) => {
                                            const document = value[0] || null;
                                            setFieldValue("serviceNoteScanFileId", document?.id || null);
                                            setServiceNoteScan(document)
                                        }}
                                        multiple={false}
                                        id={'serviceNoteScanFileId'}
                                        isEditable={true}
                                    />
                                </FormControl>
                            </Stack>
                        </Card>


                        <Card sx={{py: 2, px: 3}} elevation={2}>
                            <Stack spacing={2}>
                                <Typography variant={"h5"}>
                                    Дополнительная информация
                                </Typography>

                                <TextField
                                    value={values.description}
                                    onChange={handleChange}
                                    fullWidth
                                    name="description"
                                    id='description'
                                    label='Дополнительная информация по заявке'
                                    placeholder='Укажите дополнительную информацию к заявке'
                                    multiline
                                    rows={4}
                                    variant={"filled"}
                                    InputProps={{disableUnderline: true}}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Stack>
                        </Card>
                    </Stack>


                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={close}
                    color='secondary'
                    size='small'
                >
                    Отмена
                </Button>

                <LoadingButton
                    size='small'
                    type="submit"
                    variant='contained'
                    loading={loading || isSubmitting}
                    onClick={() => handleSubmit()}
                >
                    Сохранить изменения
                </LoadingButton>
            </DialogActions>
        </>
    );
};