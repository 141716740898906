import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ButtonUpload from "components/ButtonUpload";
import {v4 as uuidv4} from 'uuid';
import {Typography} from "@mui/material";


export interface UploadButton {
    description: string;
    id: string;
    document?: Document;
}


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        height: 'calc(100% - 49px)',
        width: '100%',
        overflowY: 'auto',
        padding: '1rem 2rem',
        background: 'white',
        '& .document:not(:first-child)': {
            marginTop: '1rem'
        }
    },
}));


function OtherDocuments({...rest}) {
    const classes = useStyles();
    const {documents, onDocumentsChange} = rest;
    const [dragOver, setDragOver] = useState(false);
    const dragZone = useRef<any>();

    useMemo(() => {
        if (documents.length === 0 && !rest.readOnly) {
            documents.push({
                id: `other_document_${uuidv4()}`,
                description: 'Документ без названия'
            })
            onDocumentsChange(documents)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents])


    useEffect(() => {
        if (dragZone && dragZone.current) {
            dragZone.current.addEventListener('dragover', (e: any) => {
                e.preventDefault();
                setDragOver(true)
            }, false)


            dragZone.current.addEventListener('drop', (e: any) => {
                e.preventDefault();
                setDragOver(false)
            }, false)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragZone])

    function setResponseData(index: number, data: any) {
        documents[index].document = data
        documents[index].description = data.description
        onDocumentsChange(documents)

        if (documents.length === documents.filter((doc: UploadButton) => doc.hasOwnProperty('document')).length) {

            onDocumentsChange([
                ...documents, {
                    id: `other_document_${uuidv4()}`,
                    description: 'Документ без названия'
                }]
            )

        }
    }

    function handleDeleteFile(id: string) {
        const documentIndex = documents.findIndex((document: UploadButton) => document.id === id)
        documents.splice(documentIndex, 1)
        onDocumentsChange(documents)
    }

    return (
        <>
            {/*<div onDragLeave={()=>setDragOver(false)}>*/}
            <div
                className={classes.root}
                ref={dragZone}
            >
                <Typography>
                    Прочие документы
                </Typography>
                {documents.map((document: any, index: number) => {

                    return (
                        <ButtonUpload
                            key={index}
                            id={document.id}
                            description={document.description}
                            changeableName={true}
                            response={(data: any) => setResponseData(index, data)}
                            document={document?.document}
                            dragOver={dragOver}
                            handleDeleteFile={(id: string) => handleDeleteFile(id)}
                            readOnly={rest.readOnly}
                        />
                    )
                })}
            </div>
            {/*</div>*/}
        </>
    )
}

OtherDocuments.protoTypes = {}

export default OtherDocuments;
