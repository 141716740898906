import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useGetAllDictionariesQuery } from "graphql/graphQlApiHooks";
import { Autocomplete, TextField } from "@mui/material";
import { ImportedSportEquipmentRecord } from "../types";

interface Props {

}

export const EquipmentSportCategoryEdit = (props: GridRenderCellParams<ImportedSportEquipmentRecord>) => {
  const { id, api, field, row } = props;
  const { data: allDictionaries } = useGetAllDictionariesQuery();
  const sportCategories = allDictionaries?.getAllSportKindDictionary?.sportCategories;
  // const {dictionariesStore} = useStores();
  // const {sportCategoryDictionary: options} = dictionariesStore;


  const value = row?.sportCategory;

  const handleChange = (event: any, newValue: any) => {
    api.setEditCellValue({ id, field, value: newValue }, event);
    if (row?.sportKind?.sportCategory?.id !== newValue?.id) {
      api.setEditCellValue({ id, field: "sportKind", value: null }, event);
      api.setEditCellValue({ id, field: "equipmentType", value: null }, event);
    }
    api.commitRowChange(row.id);
  };

  return (
    <Autocomplete
      value={value || null}
      id="sportCategory"
      options={sportCategories || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
      onChange={handleChange}
      sx={{ width: "100%", height: "100%", ".MuiTextField-root": { height: "100%" } }}
      openOnFocus
      renderInput={(params) => <TextField
        error={!value}
        {...params}
        variant="standard"
        size={"small"}
        placeholder="Выберите категорию спорта"
        InputLabelProps={{
          shrink: true
        }}
      />}
    />
  );
};
