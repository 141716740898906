import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { SportEquipmentTab, SportEquipmentTabs } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentTabActive";
import {
  RentSportEquipmentTab, RentSportEquipmentTabIdsEnum,
  RentSportEquipmentTabs
} from "../../../../store/rent-sport-equpments/rentSportEquipmentTabActive";
import { values } from "lodash";


interface Props {
  tabActive: RentSportEquipmentTab;

  actions: {
    setTabActive: (tab: RentSportEquipmentTab) => void
  };
}

export default (props: Props) => {
  const { actions: { setTabActive }, tabActive } = props;

  const TABS = values(RentSportEquipmentTabs);

  const handleChange = (event: React.SyntheticEvent, newValue: RentSportEquipmentTabIdsEnum) => {
    setTabActive(TABS.find(tab => tab.id === newValue));
  };

  return (
    <>
      <Tabs
        value={tabActive.id}
        sx={{ px: 3, borderBottom: "1px solid", borderColor: "divider" }}
        onChange={handleChange}
      >
        {TABS.map((tab, index) => (
          <Tab
            key={tab.id}
            disableRipple
            label={
              <Box component={"span"} sx={{ fontSize: 15, fontWeight: "bold" }}>{tab.displayName}</Box>
            }
            value={tab.id}
            onClick={(value) => setTabActive(tab)}
          />
        ))}
      </Tabs>
    </>
  );
};