import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from 'components/Page';
import NotFoundImg from 'assets/empty.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        display: 'inline-block',
        maxWidth: '100%',
        width: 450,
        minHeight: 320,
    }
}));

const NotFoundView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="404"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="md">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            gap: '1rem'

                        }}
                    >
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="h1"
                            style={{fontSize: '8rem', lineHeight: '8.2rem'}}
                        >
                            404
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="h2"
                            style={{fontSize: '3rem', lineHeight: '3.2rem'}}
                        >
                            Страница не существует
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle1"
                        >
                            Вы либо ввели несуществующий адрес, либо попали сюда очень странным способом.
                            Попробуйте воспользоваться навигацией.
                        </Typography>
                        <img
                            alt="Under development"
                            className={classes.image}
                            src={NotFoundImg}

                        />
                    </div>

                </Container>
            </Box>
        </Page>
    );
};

export default NotFoundView;
