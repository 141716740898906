import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe, { UIElementData } from "photoswipe";
import { ImageStoreEntity } from "../../../../../../../../graphql/graphQlApiHooks";
import Settings from "../../../../../../../../store/settings";
import UploaderService from "../../../../../../../../services/UplodaService";
import { UploadPhotoButton } from "./UploadPhotoButton";
import { Grid, Tooltip } from "@mui/material";
import { Image, PhotoThumbnail, TooltipIcon } from "../styled";
import { Box } from "@mui/system";
import { MoreButton } from "./MoreButton";
import Iconify from "../../../../../../../../components/Iconify";
import { styled } from "@mui/material/styles";
import { BootstrapTooltip } from "../../../../../../../../components/BootstrapTooltip";


const convertToDataSource = (photos: ImageStoreEntity[]) => {
  return photos.map((item) => {
    return {
      ...item,
      src: item.url.includes("http") ? item.url : Settings.server + item.url,
      w: item.width,
      h: item.height,
      title: item.description
    };
  });
};


interface PhotoGridProps {
  id?: string;
  photos: ImageStoreEntity[];
  setPhotos: (value: ImageStoreEntity[]) => void;
  setMainPhoto?: (value: ImageStoreEntity) => void;
  mainPhoto?: ImageStoreEntity;
  isEditable?: boolean;
  isSelectable?: boolean;
  selectedPhotos?: string[];
  setSelectedPhotos?: (value: (((prevState: string[]) => string[]) | string[])) => void;
}

export const PhotoGrid: React.FC<PhotoGridProps> = (props) => {
  const {
    id,
    photos,
    setPhotos,
    setMainPhoto,
    mainPhoto,
    isEditable,
    isSelectable,
    selectedPhotos,
    setSelectedPhotos
  } = props;
  const pswpRef = useRef<PhotoSwipeLightbox | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const uploadPhotosInput = useRef<HTMLInputElement | null>(null);


  const onUploadChange: React.FormEventHandler<HTMLInputElement> = (event) => {
    setLoading(true);
    setUploadPercentage(0);
    UploaderService.uploadImages(event, {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      }
    })
      .then((res) => {
          if (res) {
            setPhotos([...photos, [...res]].flat() as ImageStoreEntity[]);
          }
        }
      )
      .finally(() => {
        setUploadPercentage(100);
        setLoading(false);
      });
  };

  const clearInputHandler = () => {
    if (uploadPhotosInput?.current) uploadPhotosInput.current.value = "";
  };


  useEffect(() => {
    if (pswpRef?.current) {
      const { pswp } = pswpRef.current;
      if (!pswp) return;

      if (photos.length === 0) {
        pswp.close();
        return;
      }

      pswp.options.dataSource = convertToDataSource(photos);
      photos.forEach((_, index) => {
        pswp.refreshSlideContent(index);
      });
      pswp.goTo(photos.length - 1);
    }

  }, [pswpRef?.current, photos]);


  useEffect(() => {
    let updateItems = [...photos];
    let rotatedPhotos: {
      id: string;
      rotationAngle: number;
    }[] = [];

    const gallery = new PhotoSwipeLightbox({
      bgOpacity: 0.85,
      showHideOpacity: true,
      pswpModule: () => import("photoswipe")
    });

    pswpRef.current = gallery;
    gallery.init();


    gallery.on("uiRegister", function() {
      const { pswp } = gallery;
      const uiProps: UIElementData = { order: 8, isButton: true, tagName: "button", appendTo: "bar" };

      const elements: UIElementData[] = [
        {
          ...uiProps, name: "add", html: addIcon, title: "Добавить фото", ariaLabel: "add photo",
          onClick: onAddItem
        },
        {
          ...uiProps, name: "delete", html: deleteIcon, title: "Удалить фото", ariaLabel: "delete photo",

          onClick: (e, el, pswp) => {
            onDeleteItem(updateItems[pswp.currIndex].id);
          }
        },
        {
          ...uiProps,
          name: "rotate-left",
          html: rotateLeftIcon,
          title: "Повернуть фото влево",
          ariaLabel: "rotate photo left",

          onClick: (e, el, pswp) => {
            onUpdateItem(updateItems[pswp.currIndex].id, 90);
          }
        },
        {
          ...uiProps,
          name: "rotate-right",
          html: rotateRightIcon,
          title: "Повернуть фото вправо",
          ariaLabel: "rotate photo right",

          onClick: (e, el, pswp) => {
            onUpdateItem(updateItems[pswp.currIndex].id, -90);
          }
        }
      ];

      elements.forEach(element => pswp.ui.registerElement(element));

    });

    const onRotationPhotosUpdate = () => {
      if (rotatedPhotos.length) {

        rotatedPhotos.forEach((rotationPhoto) => {
          const { id, rotationAngle } = rotationPhoto;
          UploaderService.updateImage(id, { rotate: rotationAngle }).then((res) => {
            const index = updateItems.findIndex(item => item.id === id);
            if (index !== -1) {
              updateItems[index] = res;
              setPhotos(updateItems);
            }
          });
        });

        rotatedPhotos = [];
      }
    };

    gallery.on("close", function() {
      onRotationPhotosUpdate();
    });

    gallery.on("slideDeactivate", function() {
      onRotationPhotosUpdate();
    });

    gallery.on("beforeOpen", () => {
      updateItems = [...gallery.pswp.options.dataSource as ImageStoreEntity[]];
    });

    const onAddItem = () => {
      uploadPhotosInput.current?.click();
      uploadPhotosInput.current.onclick = () => {
        clearInputHandler();
      };

      uploadPhotosInput.current.onchange = (e) => {
        UploaderService.uploadImages(e).then((res) => {
          if (res) {
            updateItems = [...updateItems, ...res];
            setPhotos(updateItems);
          }
        });
      };
    };

    const onDeleteItem = (id: string) => {
      if (mainPhoto?.id && id === mainPhoto?.id) {
        setMainPhoto(null);
      }

      updateItems = [...updateItems].filter(item => item.id !== id);
      setPhotos(updateItems);
    };

    const onUpdateItem = (id: string, rotationAngle: number) => {
      photoRotationHandler({
        pswp: pswpRef.current?.pswp,
        angleOfRotation: rotationAngle,
        cb: (rotationValue) => {
          // проверяем есть ли уже такой id в массиве повернутых фото
          const index = rotatedPhotos.findIndex(item => item.id === id);
          if (index !== -1) {
            rotatedPhotos[index].rotationAngle = rotationValue;
          } else {
            rotatedPhotos.push({
              id,
              rotationAngle: rotationValue
            });
          }
        }
      });
    };

    const photoRotationHandler = (options: { pswp: PhotoSwipe, angleOfRotation: number, cb: (rotationValue: number) => void }) => {
      const { pswp, angleOfRotation, cb } = options;

      let rotationValue = 0;
      const container = pswpRef.current.pswp.currSlide.container;
      const img = container.querySelector(".pswp__img");
      const rotation = img.getAttribute("style").match(/rotate\(([-0-9]+)deg\)/);

      if (!img.hasAttribute("style")) {
        img.setAttribute("style", "");
      }
      if (!img.getAttribute("style").includes("transform-origin:")) {
        img.setAttribute("style", img.getAttribute("style") + "transform-origin: center center;");
      }
      if (!img.getAttribute("style").includes("transition:")) {
        img.setAttribute("style", img.getAttribute("style") + "transition: transform 0.3s;");
      }

      if (rotation) {
        rotationValue = parseInt(rotation[1]);
      }
      rotationValue = rotationValue - angleOfRotation;

      if (img.getAttribute("style").includes("transform:")) {
        img.setAttribute("style", img.getAttribute("style").replace(/rotate\(([-0-9]+)deg\)/, `rotate(${rotationValue}deg)`));
      } else {
        img.setAttribute("style", img.getAttribute("style") + `transform: rotate(${rotationValue}deg);`);
      }

      if (rotationValue % 360 !== 0) {
        cb(rotationValue);
      }
    };

    return () => {
      pswpRef.current.destroy();
      pswpRef.current = null;
    };
  }, []);


  const selectToggleHandler = (id: string) => {
    setSelectedPhotos(prevState => {
      if (prevState.includes(id)) {
        return [...prevState].filter(item => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };


  const onDeleteHandler = (id: string) => {
    if (id === mainPhoto.id) {
      setMainPhoto(null);
    }
    setPhotos([...photos].filter(item => item.id !== id) as ImageStoreEntity[]);
  };


  return (
    <>
      <Root
        container
        spacing={2}
        className={"photo-grid pswp-gallery"}
        marginTop={"0 !important"}
        marginLeft={"-16px !important"}
      >


        <UploadPhotoButton
          buttonId={id}
          onClearInput={clearInputHandler}
          loading={loading}
          progress={uploadPercentage}
          helpText={"Загрузите фотографии оборудования с разных сторон"}
          disabled={!isEditable}
        />

        {photos.map((item, index) => (
          <Grid
            item
            lg={2}
            xs={3}
            key={item.id}
          >
            <PhotoThumbnail>
              <Box
                className={`photo ${isSelectable ? "isSelectable" : ""} ${selectedPhotos.includes(item.id) ? "selected" : ""} ${item.id === mainPhoto?.id ? "main" : ""}`}
                onClick={() => {
                  isSelectable && selectToggleHandler(item?.id);
                }}
                sx={{
                  boxShadow: 4,
                  width: "100%",
                  height: "100%",
                  borderRadius: 1,
                  overflow: "hidden"
                }}
              >
                {!isSelectable ? (
                  <MoreButton
                    data={item}
                    onFullScreen={() => {
                      pswpRef.current?.loadAndOpen(index, convertToDataSource(photos));
                    }}
                    onDelete={onDeleteHandler}
                    onSetMainPhoto={setMainPhoto}
                    isMainPhoto={item.id === mainPhoto?.id}
                    isEditable={isEditable}
                  />
                ) : (
                  <TooltipIcon
                    sx={{
                      bgcolor: "background.paper",
                      display: "flex",
                      borderRadius: 50
                    }}
                  >
                    <Iconify
                      icon={selectedPhotos.includes(item?.id) ? "ri:checkbox-circle-fill" : "ri:checkbox-blank-circle-line"}
                      color={selectedPhotos.includes(item?.id) ? "primary.main" : "inherit"}
                      fontSize={"18px"}
                    />
                  </TooltipIcon>
                )}

                {item.id === mainPhoto?.id &&
                  <BootstrapTooltip title={"Основное фото"}>
                    <Box
                      sx={{
                        position: "absolute",
                        left: 8,
                        top: 8,
                        color: "primary.light"
                      }}
                    >
                      <Iconify
                        fontSize={"22px"}
                        icon={"healthicons:ui-user-profile"}
                      />
                    </Box>
                  </BootstrapTooltip>
                }

                <Image
                  src={item.url.includes("http") ? item.url : Settings.server + item.avatarUrl}
                  onClick={() => {
                    if (pswpRef?.current) {
                      pswpRef.current?.loadAndOpen(index, convertToDataSource(photos));
                    }
                  }}
                />


              </Box>
            </PhotoThumbnail>

          </Grid>
        ))}

      </Root>

      <input
        id={`button-upload-${id}`}
        name={`button-upload-${id}`}
        style={{ display: "none" }}
        type='file'
        accept='image/*, .heic'
        onInput={onUploadChange}
        ref={uploadPhotosInput}
        multiple
      />
    </>
  );
};


export const Root = styled(Grid)(({ theme }) => ({})) as typeof Grid;

const rotateLeftIcon = "<svg stroke=\"#4f4f4f\" style=\"font-size: 20px; color: white\" xmlns=\"http://www.w3.org/2000/svg\" width=\"1em\" height=\"1em\" viewBox=\"0 0 24 24\"><path fill=\"currentColor\" d=\"M4 2h3a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m16 13a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-9v-7h9M14 4a8 8 0 0 1 8 8l-.06 1h-2.02l.08-1a6 6 0 0 0-6-6v3l-4-4l4-4v3Z\"/></svg>";

const rotateRightIcon = "<svg stroke=\"#4f4f4f\" style=\"font-size: 20px; color: white\" xmlns=\"http://www.w3.org/2000/svg\"  width=\"1em\" height=\"1em\" viewBox=\"0 0 24 24\"><path fill=\"currentColor\" d=\"M10 4V1l4 4l-4 4V6a6 6 0 0 0-6 6l.08 1H2.06L2 12a8 8 0 0 1 8-8m7-2h3a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2M4 15h9v7H4a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2Z\"/></svg>";

const addIcon = "<svg stroke=\"#4f4f4f\" style=\"font-size: 20px; color: white\" xmlns=\"http://www.w3.org/2000/svg\" width=\"1em\" height=\"1em\" viewBox=\"0 0 24 24\"><path fill=\"currentColor\" d=\"m13.432 14.64l.093.078l6.928 6.8A3.235 3.235 0 0 1 18.75 22H7.25a3.235 3.235 0 0 1-1.703-.481l6.928-6.801l.083-.071a.75.75 0 0 1 .874-.007ZM18.75 4A3.25 3.25 0 0 1 22 7.25v11.5c0 .627-.178 1.213-.485 1.71l-6.939-6.813l-.128-.116a2.25 2.25 0 0 0-2.889-.006l-.135.123l-6.939 6.811A3.235 3.235 0 0 1 4 18.75v-6.248A6.5 6.5 0 0 0 12.502 4h6.248ZM6.5 1a5.5 5.5 0 1 1 0 11a5.5 5.5 0 0 1 0-11Zm10.252 6a2.252 2.252 0 1 0 0 4.504a2.252 2.252 0 0 0 0-4.504Zm0 1.5a.752.752 0 1 1 0 1.504a.752.752 0 0 1 0-1.504ZM6.5 3l-.09.007a.5.5 0 0 0-.402.402L6 3.5V6H3.498l-.09.008a.5.5 0 0 0-.402.402l-.008.09l.008.09a.5.5 0 0 0 .402.402l.09.008H6v2.503l.008.09a.5.5 0 0 0 .402.402l.09.009l.09-.009a.5.5 0 0 0 .402-.402L7 9.503V7h2.505l.09-.008a.5.5 0 0 0 .402-.402l.008-.09l-.008-.09a.5.5 0 0 0-.403-.402L9.504 6H7V3.5l-.008-.09a.5.5 0 0 0-.402-.403L6.5 3Z\"/></svg>";

const deleteIcon = "<svg stroke=\"#4f4f4f\" style=\"font-size: 20px; color: white\" xmlns=\"http://www.w3.org/2000/svg\" width=\"1em\" height=\"1em\" viewBox=\"0 0 24 24\"><path fill=\"currentColor\" d=\"M7 21q-.825 0-1.412-.587Q5 19.825 5 19V6q-.425 0-.713-.287Q4 5.425 4 5t.287-.713Q4.575 4 5 4h4q0-.425.288-.713Q9.575 3 10 3h4q.425 0 .713.287Q15 3.575 15 4h4q.425 0 .712.287Q20 4.575 20 5t-.288.713Q19.425 6 19 6v13q0 .825-.587 1.413Q17.825 21 17 21Zm2-5q0 .425.288.712Q9.575 17 10 17t.713-.288Q11 16.425 11 16V9q0-.425-.287-.713Q10.425 8 10 8t-.712.287Q9 8.575 9 9Zm4 0q0 .425.288.712q.287.288.712.288t.713-.288Q15 16.425 15 16V9q0-.425-.287-.713Q14.425 8 14 8t-.712.287Q13 8.575 13 9Z\"/></svg>";
