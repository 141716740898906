import React from 'react';
import dayjs from "dayjs";
import Iconify from "./Iconify";
import {Box} from "@mui/material";

interface Props {
  date: string
  showTime?: boolean
  showIcon?: boolean
}

function DateViewer(props: Props) {
  const {date, showTime, showIcon=true} = props

  const format = showTime ? 'DD MMM YYYY, hh:mm' : 'DD MMM YYYY'

  const getDate = () => !isNaN(Number(date)) ?
    dayjs(Number(date)).format(format) :
    dayjs(date, "DD.MM.YYYY").format(format)



  return <Box display={'flex'} alignItems={'center'} >
    {showIcon && <Iconify icon={'ic:baseline-date-range'} fontSize={'small'} mr={1}  />}
    <span style={{lineHeight:1}}>{getDate()}</span>
  </Box>
}

export default DateViewer;
