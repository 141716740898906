import React from "react";
import { CardContent, FormControlLabel, Radio, RadioGroup } from "@mui/material";


const Switch = (props: {
  value: boolean,
  labels: { value: boolean | string, label: string }[],
  filterResult: (result: boolean | string) => any,
  onClose?: () => void
}) => {
  const {value, filterResult} = props;

  return (
    <CardContent>
      <RadioGroup
        value={value}
        onChange={(e) => {
          let result = e.target.value
          if (result === 'true' || result === 'false') result = JSON.parse(e.target.value)
          filterResult(result)
        }}
      >
        {props.labels.map((option, index) => {
          return <FormControlLabel key={index} value={option.value} control={<Radio/>} label={option.label}/>
        })}

      </RadioGroup>
    </CardContent>
  )
}

export default Switch;

export {}
