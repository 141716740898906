import { makeVar, ReactiveVar } from "@apollo/client";


export type Pagination = {
  page: number;
  pageSize: number;
};

export const EquipmentRequestListPaginationVarInitial: Pagination = {
  page: 0,
  pageSize: 50
};

export const equipmentRequestListPaginationVar = makeVar<Pagination>(
  EquipmentRequestListPaginationVarInitial
);
export const useEquipmentRequestListPaginationVar = () => createEquipmentRequestListPaginationVar(equipmentRequestListPaginationVar);

const createEquipmentRequestListPaginationVar = (equipmentRequestListPaginationVar: ReactiveVar<Pagination>) => {

  const setPagination = (pagination: Pagination) => {
    equipmentRequestListPaginationVar(pagination);
  };

  return {
    setPagination
  };
};
