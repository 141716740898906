import {makeAutoObservable, toJS} from "mobx";
import RootStore, {client} from "../.";
import {configure} from "mobx"
import {
	DeleteSportEquipmentDocument,
	GetSportEquipmentsDocument,
	ImageStoreEntity, SetMainPhotoDocument,
	SetMainPhotoInput
} from "../../graphql/graphQlApiHooks";
import axios from "axios";
import Settings from "../settings";

configure({
	enforceActions: "never",
})

export interface Image extends ImageStoreEntity {
	url: string;
	rotate?: number;
	subHtml?: string;
	loading?: boolean;
	mainPhoto: boolean
}

const GalleryStore = class {
	rootStore?: RootStore;
	images: Image[] = [];
	changedImages: Image[] = [];

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)
	}

	setImages = (images: Image[]) => {
		this.images = images
	};

	deleteImage = (lgItem:any) => {
		let images = toJS(this.images);
		let image = images.filter((image:Image) => (lgItem.src.indexOf(image.id) !== -1))?.[0];
		if (!image) return
		this.setImages(images.filter(i => i.id !== image.id))
	};

	onChangeImage = (lgItem:any) => {
		// заводим массив измененных картинок
		let images = toJS(this.images);
		// находим картинку в массиве по id
		let image = images.filter((image:Image) => (lgItem.src.indexOf(image.id) !== -1))?.[0];
		// если нет картинки, то выходим
		if (!image) return

		// если есть картинка, то меняем ее, добавляеь rotate
		images[this.getImageIndexById(image.id)] = {...image, rotate: lgItem.rotate};
		// меняем массив картинок
		this.setImages(images)

		// если в массиве измененных картинок нет картинки с таким же id, то добавляем ее
		if (this.changedImages.some(changedImage => changedImage?.id !== image.id)) {
			// добавляем картинку в массив измененных картинок
			this.changedImages.push({...image, rotate: lgItem.rotate})
		} else {
			// если картинка есть в массиве измененных картинок, то меняем ее
			let changedImages = toJS(this.changedImages); changedImages[this.getImageIndexById(image.id)] = {...image, rotate: lgItem.rotate};
			// убираем дубликаты
			this.changedImages = Array.from(new Set(changedImages))
		}
	};

	onSaveChangedImages = () => {
		if (this.changedImages.length === 0) return

		this.changedImages.forEach((image)=>{
			if (image && image.rotate !== 0) {
				let images = toJS(this.images);
				images[this.getImageIndexById(image.id)] = {...this.getImageById(image.id) as Image, loading: true}
				this.setImages(images)
				this.updateFile(image)
			}
		})
		this.changedImages = []
	}

	updateFile = (image: Image) => {
		const URI = `${Settings.server}/images/update/${image?.id}${(image?.rotate !== 0) ? '/' + image?.rotate : ''}`;
		const TOKEN = `Bearer ${this.rootStore?.userStore.token}`

		if (image) {
			axios.get(image.url, {responseType: 'blob'})
					.then(({data}) => new File([data], image.name))
					.then((file) => {
						let formData = new FormData();
						formData.append('file', file);
						formData.append('description', image.description);

						axios.post(URI, formData, {headers: {'authorization': TOKEN}}).then(({data}) => {
							let images = toJS(this.images);
							images[this.getImageIndexById(image.id)] = {
								...data,
								url: Settings.server+this.getUniqUrl(data.url),
								rotate: 0,
								loading: false
							};
							this.setImages(images)
						})
					});
		}
	};
	uploadFile = (image: File, description?: string) => {
		const URI = `${Settings.server}/images/upload`;
		const TOKEN = `Bearer ${this.rootStore?.userStore.token}`
		let formData = new FormData();
		formData.append('file', image);
		formData.append('description', image.name);

		return axios.post(URI, formData, {headers: {'authorization': TOKEN}}).then(({data}) => {
			let images = toJS(this.images);
			images.push({
				...data,
				url: Settings.server + data.url,
				rotate: 0,
				subHtml: `<div class="captions"> <h4> ${(description) ? description : image.name.replace(/(\.[^/.]+)+$/, "")} </h4> </div>`,
			})
			this.setImages(images)
		})
	};

	getImageById = (id: string) => this.images.find((i: Image) => i.id === id);
	getImageIndexById = (id: string) => this.images.findIndex((i: Image) => i.id === id);
	getUniqUrl = (url:string) => {
		const now = new Date().toISOString();
		if (url.includes('?')) return url.replace(/\?.*/, `?${now}`);
		return `${url}?${now}`;
	};

	clear = () => {
		this.images = []
		this.changedImages = []
	};


	setMainPhoto = (input: SetMainPhotoInput) => {
		const changeIndex = this.images.findIndex((image:Image) => input.mainPhotoId === image.id)
		let updateImages: Image[] = [...this.images]
		updateImages = updateImages.map(image => ({...image, mainPhoto: false}) )
		updateImages[changeIndex] = {...updateImages[changeIndex], mainPhoto: true}

		this.setImages(updateImages)
		// this.rootStore?.setSnakeMessage('Основное фото успешно обновлено.')
	}
}

export default GalleryStore;

