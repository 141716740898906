import { darken, lighten, styled } from "@mui/material/styles";
import { Box } from "@mui/material";


export const PhotoThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: 90,
  position: "relative",
  ["&:hover .more-button"]: {
    opacity: 1
  },
  [`& .photo.isSelectable`]: {
    borderWidth: 0,
    borderStyle: "solid",
    cursor: "pointer",
    ["&:before"]: {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      background: theme.palette.primary.main,
      opacity: 0,
      transition: "all .3s"
    },
    ["&:hover:before"]: {
      opacity: 0.05
    }
  },
  [`& .photo.selected`]: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    ["&:before"]: {
      opacity: 0.1
    }
  },
  [`& .photo.main:not(.isSelectable)`]: {
    outline: "4px solid",
    outlineColor: theme.palette.primary.light
  },
  [`& .file`]: {
    backgroundColor: lighten(theme.palette.background.default, .4),
    borderStyle: "solid",
    borderColor: darken(theme.palette.background.default, .08),
    cursor: "default"
  },
  [`& .file.isValue`]: {
    backgroundColor: lighten(theme.palette.primary.main, .95),
    borderColor: lighten(theme.palette.primary.main, .55)
  }

})) as typeof Box;
