import React, {FC} from "react";
import {
    EquipmentRequestAcceptIntoUseInput,
    useEquipmentRequestsAcceptForProcessingMutation
} from "graphql/graphQlApiHooks";
import {Alert, Button, DialogActions, DialogContent} from "@mui/material";
import {declOfNum} from "helpers/declination";
import {LoadingButton} from "@mui/lab";

interface Props {
    close: () => void;
    equipmentIds: string[];
}

export const MultiplySuccessRequestsDialog: FC<Props> = (rest) => {
    const {close, equipmentIds} = rest;
    const [equipmentRequestsAcceptForProcessing] = useEquipmentRequestsAcceptForProcessingMutation()

    const handleSubmit = () => {
        const inputs = equipmentIds.map((id) => ({
            id
        })) as EquipmentRequestAcceptIntoUseInput[];

        equipmentRequestsAcceptForProcessing({variables: {inputs}}).then(() => {
            close();
        })
    };

    return (

        <>

            <DialogContent>
                <Alert severity='info'>
                    <>
                        {declOfNum(equipmentIds.length, ["Выбрана", "Выбрано", "Выбрано"])} {" "}
                        <b>{equipmentIds.length}</b> {declOfNum(equipmentIds.length, ["единица", "единицы", "единиц"])} оборудования,
                        для приема в работу. <br/>
                    </>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={close}
                    color='secondary'
                    size='small'
                >
                    Отмена
                </Button>

                <LoadingButton
                    size='small'
                    onClick={() => handleSubmit()}
                    variant='contained'
                >
                    Принять в работу
                </LoadingButton>
            </DialogActions>
        </>
    );
};