import {EquipmentRepairRequestStateObject, EquipmentRepairRequestStatusEnum} from "../../../../graphql/graphQlApiHooks";
import {Box, Chip} from "@mui/material";
import React from "react";
import {FaCheckSquare} from "react-icons/fa";
import {LiaFileContractSolid} from "react-icons/lia";
import {MdAccessTime, MdOutlineReportGmailerrorred} from "react-icons/md";
import {GiAutoRepair} from "react-icons/gi";
import {GoDotFill} from "react-icons/go";
import Tooltiper from "../../../../components/Tooltiper";

export const getLabelStatusRepairRequest = (status: EquipmentRepairRequestStatusEnum) => {
    switch (status) {
        case EquipmentRepairRequestStatusEnum.FixedByOwnEfforts:
            return 'Устранено своими силами';
        case EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract:
            return 'Включено в контрактный ремонт';
        case EquipmentRepairRequestStatusEnum.Pending:
            return 'Ожидает ремонта';
        case EquipmentRepairRequestStatusEnum.Repaired:
            return 'Отремонтировано';
        case EquipmentRepairRequestStatusEnum.RepairRecognisedImpractical:
            return 'Ремонт признан невозможным';
        default:
            return 'Неизвестный статус';
    }
};

const getIconStatusRepairRequest = (status: EquipmentRepairRequestStatusEnum) => {
    switch (status) {
        case EquipmentRepairRequestStatusEnum.FixedByOwnEfforts:
            return <Box color={'inherit !important'}><GiAutoRepair color={'inherit'}/></Box>;
        case EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract:
            return <Box color={'inherit !important'}><LiaFileContractSolid color={'inherit'}/></Box>;
        case EquipmentRepairRequestStatusEnum.Pending:
            return <Box color={'inherit !important'}><MdAccessTime color={'inherit'}/></Box>;
        case EquipmentRepairRequestStatusEnum.Repaired:
            return <Box color={'inherit !important'}><GiAutoRepair color={'inherit'}/></Box>;
        case EquipmentRepairRequestStatusEnum.RepairRecognisedImpractical:
            return <Box color={'inherit !important'}><MdOutlineReportGmailerrorred color={'inherit'}/></Box>;
        default:
            return <GoDotFill color={'inherit'}/>;
    }
};

function getBackgroundColorStatusRepairRequest(status: EquipmentRepairRequestStatusEnum) {
    switch (status) {
        case EquipmentRepairRequestStatusEnum.FixedByOwnEfforts:
            return '#f1fff2';
        case EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract:
            return '#ffefee';
        case EquipmentRepairRequestStatusEnum.Pending:
            return '#fffbed';
        case EquipmentRepairRequestStatusEnum.Repaired:
            return '#e8ffe9';
        case EquipmentRepairRequestStatusEnum.RepairRecognisedImpractical:
            return '#ffeae9';
        default:
            return '#e0e0e0';
    }
}

function getColorStatusRepairRequest(status: EquipmentRepairRequestStatusEnum) {
    switch (status) {
        case EquipmentRepairRequestStatusEnum.FixedByOwnEfforts:
            return '#4caf50';
        case EquipmentRepairRequestStatusEnum.PendingAndIncludedInContract:
            return '#f44336';
        case EquipmentRepairRequestStatusEnum.Pending:
            return '#d77c06';
        case EquipmentRepairRequestStatusEnum.Repaired:
            return '#4caf50';
        case EquipmentRepairRequestStatusEnum.RepairRecognisedImpractical:
            return '#f44336';
        default:
            return '#595959';
    }

}

export const TagStatusRepairRequest = ({stateHistory}: { stateHistory: Array<EquipmentRepairRequestStateObject> }) => {
    const lastState = stateHistory[stateHistory.length - 1];
    return <Chip
        icon={getIconStatusRepairRequest(lastState.status)}
        label={
            <Tooltiper
                text={getLabelStatusRepairRequest(lastState.status)}
            />
        }
        size="small"
        sx={{
            backgroundColor: getBackgroundColorStatusRepairRequest(lastState.status),
            color: getColorStatusRepairRequest(lastState.status),
        }}
    />;
};