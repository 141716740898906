import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {Box, Button, DialogContentText, Fade, IconButton, Typography} from "@mui/material";
import React from "react";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import {Tooltip} from "../../../ui/Tooltip";
import Dialog from "components/Dialogs/Dialog";

export const RenderDescriptionCell = ({equipmentRequest}: { equipmentRequest: EquipmentRequestEntity; }) => {
    const [openDescriptionDialog, setOpenDescriptionDialog] = React.useState(false);
    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleOpenDialog = () => {
        setOpenDescriptionDialog(true);
        setOpenTooltip(false);
    };

    const handleCloseDialog = () => {
        setOpenDescriptionDialog(false);
    };

    return (
        <>
            <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{timeout: 300, exit: false}}
                disableFocusListener
                open={openTooltip}
                onOpen={() => setOpenTooltip(true)}
                onClose={() => setOpenTooltip(false)}
                title={
                    (equipmentRequest.description) ? <React.Fragment>
                        <Typography
                            sx={{whiteSpace: 'nowrap', mb: 1}}
                            variant={'h6'}
                            color="inherit"
                        >Дополнгительная информация</Typography>
                        <Box
                            sx={{
                                textOverflow: "ellipsis",
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden'
                            }}
                        >
                            {equipmentRequest.description}
                        </Box>

                        <Box display={'flex'}>
                            <Button
                                size={'small'}
                                onClick={handleOpenDialog}
                                sx={{marginLeft: 'auto', py: .5, px: 1, mt: 1}}
                            >Подробнее...</Button>
                        </Box>

                    </React.Fragment> : 'Дополнгительная информация'

                }
            >
                <IconButton
                    color="inherit"
                    component="span"
                    disabled={!equipmentRequest.description}
                    onClick={handleOpenDialog}
                >
                    <MessageOutlinedIcon
                        component={'svg'}
                        sx={{
                            opacity: .5,
                            transition: 'all .6s',
                            fontSize: '18px !important'
                        }}
                    />
                </IconButton>
            </Tooltip>

            <Dialog
                open={openDescriptionDialog}
                onClose={handleCloseDialog}
                props={{
                    "aria-labelledby": "alert-dialog-title",
                    "aria-describedby": "alert-dialog-description"
                }}
                title='Дополнительная информация по заявке'
                actions={
                    <Button
                        onClick={handleCloseDialog}
                        autoFocus
                    >
                        Закрыть
                    </Button>
                }
            >
                <DialogContentText id="alert-dialog-description">
                    {`${equipmentRequest?.description?.charAt(0).toUpperCase()}${equipmentRequest?.description?.slice(1)}`}
                </DialogContentText>
            </Dialog>
        </>
    )
};