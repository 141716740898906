import React, { useEffect, useRef } from "react";
import {
  Card,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { SportEquipmentEntity, TechnicalCondition } from "graphql/graphQlApiHooks";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { TextFieldLabel } from "./TextFieldLabel";


interface Props {
  errors?: FormikErrors<any>;
  sportEquipment: SportEquipmentEntity;
  setSportEquipment: (values: React.SetStateAction<SportEquipmentEntity>) => void;
  isEditable?: boolean;
  touched?: FormikTouched<any>;
  handleBlur?: (e: any) => void;
}

export const SportEquipmentAboutInfo = (props: Props) => {
  const {
    errors,
    sportEquipment,
    isEditable,
    setSportEquipment,
    touched,
    handleBlur
  } = props;


  const failureReasonRef = useRef(null);

  useEffect(() => {
    if (sportEquipment?.technicalCondition === TechnicalCondition.NotWorking && failureReasonRef?.current) {
      failureReasonRef?.current.focus();
    }
  }, [failureReasonRef, sportEquipment?.technicalCondition]);


  return (
    <>
      <Stack spacing={3}>

        <Card sx={{ p: 3 }} elevation={2}>

          <Stack spacing={3}>
            <Typography variant={"h5"}>
              Общая информация
            </Typography>
            <Stack direction={"row"} spacing={2} width={"100%"}>
              <TextField
                variant={"filled"}
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                  readOnly: !isEditable
                }}


                InputLabelProps={{
                  shrink: true
                }}
                required={true}
                label={
                  <TextFieldLabel
                    labelText={"Название"}
                    helperText={"Короткое название для понимания пользователей системы"}
                    required
                  />
                }
                placeholder={"Укажите короткое название оборудования"}
                fullWidth
                size={"small"}

                autoFocus
                name={"name"}
                value={sportEquipment?.name}
                onChange={({ currentTarget: { value } }) => {
                  setSportEquipment(prevState => ({ ...prevState, name: value }));
                }}

                onBlur={handleBlur}
                {
                  ...{
                    ...(touched?.name && errors?.name && { error: true }),
                    ...(touched?.name && errors?.name && { helperText: errors?.name as string })
                  }
                }
              />
              <TextField
                variant={"filled"}
                autoComplete="off"
                required={true}
                label={
                  <TextFieldLabel
                    labelText={"Инвентарный номер"}
                    helperText={"Если инвентарный номер отсутствует, введите в поле \"б/н\""}
                    required
                  />
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  startAdornment: <InputAdornment
                    position="start"
                  >№</InputAdornment>,
                  disableUnderline: true,
                  readOnly: !isEditable
                }}
                placeholder={"Укажите инвентарный номер."}
                fullWidth
                size={"small"}

                name={"inventoryNumber"}
                value={sportEquipment?.inventoryNumber}
                onChange={({ currentTarget: { value } }) => {
                  setSportEquipment(prevState => ({ ...prevState, inventoryNumber: value }));
                }}

                onBlur={handleBlur}
                {
                  ...{
                    ...(touched?.inventoryNumber && errors?.inventoryNumber && { error: true }),
                    ...(touched?.inventoryNumber && errors?.inventoryNumber && { helperText: errors?.inventoryNumber as string })
                  }
                }
              />
            </Stack>
            <TextField
              autoComplete="off"
              variant={"filled"}
              InputProps={{
                disableUnderline: true,
                readOnly: !isEditable
              }}
              required={true}
              label={
                <TextFieldLabel
                  labelText={"Бухгалтерское название"}
                  helperText={"Полное название оборудования, для бухгалтерского учёта"}
                  required
                />
              }
              InputLabelProps={{
                shrink: true
              }}
              placeholder={"Укажите полное название оборудования"}
              size={"small"}

              name={"accountingName"}
              value={sportEquipment?.accountingName}
              onChange={({ currentTarget: { value } }) => {
                setSportEquipment(prevState => ({ ...prevState, accountingName: value }));
              }}

              onBlur={handleBlur}
              {
                ...{
                  ...(touched?.accountingName && errors?.accountingName && { error: true }),
                  ...(touched?.accountingName && errors?.accountingName && { helperText: errors?.accountingName as string })
                }
              }

            />

            <TextField
              label={
                <TextFieldLabel
                  labelText={"Описание оборудования"}
                />
              }
              autoComplete="off"
              variant={"filled"}
              InputProps={{
                disableUnderline: true,
                readOnly: !isEditable
              }}
              InputLabelProps={{
                shrink: true
              }}
              multiline={true}
              minRows={3}
              maxRows={3}

              placeholder={"Введите описание оборудования"}
              size={"small"}

              name={"description"}
              value={sportEquipment?.description}
              onChange={({ currentTarget: { value } }) => {
                setSportEquipment(prevState => ({ ...prevState, description: value }));
              }}

              onBlur={handleBlur}
              {
                ...{
                  ...(touched?.description && errors?.description && { error: true }),
                  ...(touched?.description && errors?.description && { helperText: errors?.description as string })
                }
              }

            />
          </Stack>
        </Card>
        <Card sx={{ p: 3 }} elevation={2}>

          <Stack spacing={3}>
            <Stack direction={"row"} spacing={2}>


              <FormControl
                size={"small"}
                fullWidth
                variant={"filled"}
                component="fieldset"

              >
                <FormLabel component="legend" sx={{
                  fontSize: "1rem",
                  transform: "translate(-12px, -4px) scale(0.75)"
                }}>
                  Используется
                </FormLabel>
                <FormControlLabel
                  id={"inUse"}
                  label={sportEquipment?.inUse ? "Да" : "Нет"}


                  control={
                    <Switch
                      name="inUse"
                      color="primary"
                      size={"small"}

                      // disabled={!isEditable}
                      onChange={({ target: { checked } }) => {
                        setSportEquipment(prevState => ({ ...prevState, inUse: checked }));
                      }}
                      checked={sportEquipment?.inUse}
                    />
                  }
                />
              </FormControl>
              <FormControl
                size={"small"}
                fullWidth
                variant={"filled"}
                component="fieldset"

              >
                <FormLabel component="legend" sx={{
                  fontSize: "1rem",
                  transform: "translate(-12px, -4px) scale(0.75)"
                }}>
                  Доступно для аренды
                </FormLabel>
                <FormControlLabel
                  id={"inUse"}
                  label={sportEquipment?.availableForRent ? "Да" : "Нет"}


                  control={
                    <Switch
                      name="inUse"
                      color="primary"
                      size={"small"}

                      disabled={!isEditable}
                      onChange={({ target: { checked } }) => {
                        setSportEquipment(prevState => ({ ...prevState, availableForRent: checked }));
                      }}
                      checked={sportEquipment?.availableForRent}
                    />
                  }
                />
              </FormControl>

              <FormControl
                size={"small"}
                fullWidth
                variant={"filled"}
                component="fieldset"
              >
                <FormLabel component="legend" sx={{
                  fontSize: "1rem",
                  transform: "translate(-12px, -4px) scale(0.75)"
                }}>
                  Техническое состояние
                </FormLabel>
                <FormControlLabel
                  id={"technicalCondition"}
                  label={sportEquipment?.technicalCondition === TechnicalCondition.Working ? "Исправно" : "Не исправно"}

                  control={
                    <Switch
                      name="technicalCondition"
                      color="primary"
                      size={"small"}
                      // disabled={!isEditable}
                      onChange={({ target: { checked } }) => {
                        setSportEquipment(prevState => ({
                          ...prevState,
                          technicalCondition: checked ? TechnicalCondition.Working : TechnicalCondition.NotWorking
                        }));
                      }}
                      checked={sportEquipment?.technicalCondition === TechnicalCondition.Working}
                    />
                  }
                />
              </FormControl>

            </Stack>

            <Collapse in={sportEquipment?.technicalCondition === TechnicalCondition.NotWorking} sx={{
              width: "100%"
            }}>
              <TextField
                autoComplete="off"
                variant={"filled"}
                InputProps={{
                  disableUnderline: true,
                  readOnly: !isEditable
                }}
                inputRef={failureReasonRef}
                fullWidth
                required
                label={
                  <TextFieldLabel
                    labelText={"Причина неисправности"}
                    required
                  />
                }
                multiline={true}
                minRows={3}
                maxRows={3}
                InputLabelProps={{
                  shrink: true
                }}

                placeholder={"Опишите причину несиправности"}
                size={"small"}

                name={"failureReason"}
                onChange={({ currentTarget: { value } }) => {
                  setSportEquipment(prevState => ({ ...prevState, failureReason: value }));
                }}
                value={sportEquipment?.failureReason}

                {
                  ...{
                    ...(errors?.failureReason && { error: true }),
                    ...(errors?.failureReason && { helperText: errors?.failureReason as string })
                  }
                }

              />
            </Collapse>
          </Stack>
        </Card>


      </Stack>
    </>
  );
};
