import {DepartmentEnum, useGetUsersOfSportObjectQuery, UserEntity, useUsersQuery} from "graphql/graphQlApiHooks";
import {FC, useEffect, useState} from "react";
import {DepartmentEnumWithNoDepartment, Errors, IUser, NoDepartmentEnum} from "../types";
import {getCurrentDepartmentName} from "../lib/getCurrentDepartmentName";
import {SelectOption} from "./SelectOption";
import {Autocomplete, Avatar, Chip, TextField} from "@mui/material";
import {escapeRegExp} from "lodash";

import CyrillicToTranslit from 'cyrillic-to-translit-js';
//@ts-ignore
const tr = new CyrillicToTranslit()

interface NotifiableUsersSelectProps {
    targetSportObjectId: string;
    sourceSportObjectId: string;
    errorsHandler: boolean;
    onChangeNotifiableUsers: (value: any) => void
    label?: string;
    disabled?: boolean;
}

export const NotifiableUsersSelect: FC<NotifiableUsersSelectProps> = (props) => {
    const {
        targetSportObjectId,
        sourceSportObjectId,
        errorsHandler,
        onChangeNotifiableUsers,
        label,
        disabled
    } = props;

    const [notifiableUsers, setNotifiableUsers] = useState<any[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);

    const {data: allUsers} = useUsersQuery();

    const {data} = useGetUsersOfSportObjectQuery({
        variables: {
            sportObjectId: targetSportObjectId
        }
    });

    useEffect(() => {
        if (allUsers?.users) {

            const currentUser = JSON.parse(localStorage.getItem('user') as string);

            let internalUsers = allUsers?.users
                ?.filter((user) => !user.departments?.includes(DepartmentEnum.External))
                ?.filter((user) => user.id !== currentUser.id)
                ?.map((user) => {
                    if (!user?.fio && !user.login?.toLowerCase().includes('test')) {
                        let surname = tr.reverse(user.login);
                        surname = surname.charAt(0).toUpperCase() + surname.slice(1);
                        const firstName = surname.slice(-2).charAt(0).toUpperCase(),
                            lastName = surname.slice(-1).toUpperCase(),
                            fio = `${surname.slice(0, -2)} ${firstName}.${lastName}.`;

                        return {...user, fio: fio}
                    }
                    return {...user}
                });

            if (process.env.NODE_ENV === 'production') {
                internalUsers = internalUsers?.filter((user) => !user.login.includes('test'))
            }

            const isForTargetSportObjectUsers = [...internalUsers]?.filter((user) => user.sportObjects?.map((item) => item.id).includes(targetSportObjectId))
                ?.map((user) => ({
                    ...user,
                    group: `Спортобъект назначения - ${user.sportObjects?.find((item) => item.id === targetSportObjectId)?.name}`
                }))
                ?.sort((a, b) => a.fio?.localeCompare(b?.fio as string));

            const isForSourceSportObjectUsers = [...internalUsers]?.filter((user) => user.sportObjects?.map((item) => item.id).includes(sourceSportObjectId))
                ?.map((user) => ({
                    ...user,
                    group: `Спортобъект нахождения - ${user.sportObjects?.find((item) => item.id === sourceSportObjectId)?.name}`
                }))
                ?.sort((a, b) => a.fio?.localeCompare(b?.fio as string));

            internalUsers = [...internalUsers]?.filter((user) => !user.sportObjects?.map((item) => item.id).includes(targetSportObjectId) && !user.sportObjects?.map((item) => item.id).includes(sourceSportObjectId))


            const groupedUsersByDepartment = internalUsers?.reduce((acc: {
                [key: string]: UserEntity[]
            }, user: UserEntity) => {
                // Если у пользователя нет департамента, добавляем его в специальную группу "NoDepartment"
                const departments = user.departments || [ NoDepartmentEnum.NoDepartment ];
                departments?.forEach((department) => {
                    if (!acc[department]) {
                        acc[department] = [];
                    }
                    // Проверяем, есть ли пользователь уже в текущей группе
                    const userExists = acc[department].some((u: UserEntity) => u.id === user.id);
                    if (!userExists) {
                        acc[department].push(user);
                    }
                });
                return acc;
            }, {} as { [key: string]: UserEntity[] });


            const departmentOrder = [
                DepartmentEnum.EquipmentAcceptanceAndDiagnostics,
                DepartmentEnum.PlanningAndOrganizationPaidServices,
                DepartmentEnum.AccountingSportEquipment,
                DepartmentEnum.Admins,
                DepartmentEnum.SportObject,
                NoDepartmentEnum.NoDepartment
            ];

            const sortedDepartments = Object.entries(groupedUsersByDepartment)
                .sort(([keyA], [keyB]) => {
                    return departmentOrder.indexOf(keyA as DepartmentEnumWithNoDepartment) - departmentOrder.indexOf(keyB as DepartmentEnumWithNoDepartment);
                });


            const sortedGroupedUsersByDepartment: { [key: string]: UserEntity[] } = {};
            sortedDepartments.forEach(([key, value]) => {
                sortedGroupedUsersByDepartment[key] = value;
            });


            const groupedUsers = Object.keys(sortedGroupedUsersByDepartment).map((department: DepartmentEnumWithNoDepartment) => {
                return sortedGroupedUsersByDepartment[department]
                    ?.sort((a, b) => a.fio?.localeCompare(b?.fio as string))
                    ?.map((user) => ({
                            ...user,
                            group: getCurrentDepartmentName(department)
                        }))
            }).flat()


            setUsers([...isForTargetSportObjectUsers, ...isForSourceSportObjectUsers,  ...groupedUsers] as IUser[])
        }
        else if (data?.usersOfSportObject) {

            let usersOfSportObject: any = [...data?.usersOfSportObject]

            usersOfSportObject = usersOfSportObject.map((user) => {
                if (!user.login?.toLowerCase().includes('test')) {
                    let surname = tr.reverse(user.login);
                    surname = surname.charAt(0).toUpperCase() + surname.slice(1);
                    const firstName = surname.slice(-2).charAt(0).toUpperCase(),
                        lastName = surname.slice(-1).toUpperCase(),
                        fio = `${surname.slice(0, -2)} ${firstName}.${lastName}.`;

                    return {...user, fio: fio, department: 'Спортобъект назначения', id: user.userId, email: user.email}
                }
                return {...user, fio: user.login, department: 'Спортобъект назначения', id: user.userId, email: user.email}
            })

            console.log('usersOfSportObject', usersOfSportObject)

            if (process.env.NODE_ENV === 'production') {
                usersOfSportObject = usersOfSportObject?.filter((user: any) => !user.login.includes('test'))
            }

            usersOfSportObject?.sort((a: any, b: any) => a.fio?.localeCompare(b?.fio as string))



            setUsers(usersOfSportObject as unknown as IUser[])

        }
    }, [data, allUsers, targetSportObjectId, sourceSportObjectId]);


    useEffect(() => {
        setNotifiableUsers([])

    }, [data, allUsers ]);


    const filterOptions = (options: IUser[], state: { inputValue: string }) => {
        const {inputValue} = state;
        if (!inputValue) {
            return options;
        }
        const searchRegex = new RegExp(escapeRegExp(inputValue), 'i');

        return options.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] && searchRegex.test(JSON.stringify(row[field]).toString());
            });
        });
    };

    return (
        <Autocomplete
            multiple
            size="small"
            limitTags={2}
            id="notifiableUsersSelect"
            value={notifiableUsers}
            options={users}
            disableCloseOnSelect
            getOptionLabel={(option: IUser) => (option?.fio ? option.fio : option.login)}
            isOptionEqualToValue={(option: IUser, value) => option?.id === value?.id}
            sx={{width: '100%'}}
            disabled={disabled}


            filterOptions={filterOptions}
            groupBy={(option: IUser) => option.group}

            renderOption={(props, option, {selected}) => (
                <li {...props} key={`${option?.group}_${option.id}`}>
                    <SelectOption
                    option={option}
                    selected={selected}
                    sportObjectId={targetSportObjectId}/>
                </li>

            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || "Уведомить сотрудников"}
                    placeholder="Список сотрудников"
                    error={errorsHandler }
                    helperText={errorsHandler ? 'Выберите сотрудников' : undefined}
                />
            )}
            renderTags={(users, getTagProps) => {
                return users.map((option: IUser, index) => (
                    <Chip
                        key={`${option?.id}_${index}`}
                        {...getTagProps({index})}
                        label={option.fio  + option?.userSettings?.email ? ` (${option?.userSettings?.email})` : option?.email}
                        size={'small'}
                        avatar={
                            <Avatar
                                sx={{
                                    bgcolor: !option.departments?.includes(DepartmentEnum.SportObject as DepartmentEnumWithNoDepartment ) ? 'secondary.main' : 'primary.main',
                                    color: 'white !important',
                                    fontSize: '8px !important',
                                }}
                            >
                                {option.login.substring(0, 2).toUpperCase()}
                            </Avatar>
                        }
                    />
                ));
            }}
            onChange={(event, value) => {
                setNotifiableUsers(value);
                onChangeNotifiableUsers(value);
            }}
        />
    );
};
