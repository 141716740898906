import React from "react";
import {Stack, Typography} from "@mui/material";

interface Props {
    title: string;
    description?: string;
}

export const Header = (props: Props) => {

    const {title, description} = props;

    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack>
                <Typography variant={"h5"}>
                    {title}
                </Typography>
                {description && <Typography variant={"caption"}>
                    {description}
                </Typography>}
            </Stack>
        </Stack>
    );
};
