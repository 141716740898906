import { makeVar, ReactiveVar } from "@apollo/client";
import { SortDirectionEnum } from "../spor-equipmnts/sort-direction.enum";
import {SortEquipmentRelocateColumnsEnum} from "./sort-equipment-relocate-columns.enum";

export type EquipmentRelocatedListSortOrder = {
  [key in SortEquipmentRelocateColumnsEnum as string]: SortDirectionEnum | null | undefined;
}

export const EquipmentRelocationRequestListSortOrderInitial: EquipmentRelocatedListSortOrder = {
  [SortEquipmentRelocateColumnsEnum.CreatedAt]: SortDirectionEnum.DESC
};

export const equipmentRelocationRequestListSortOrderVar = makeVar<EquipmentRelocatedListSortOrder>(
  EquipmentRelocationRequestListSortOrderInitial
);

export const useEquipmentRelocationRequestListSortOrderVar = () => createEquipmentRelocationRequestListSortOrderVar(equipmentRelocationRequestListSortOrderVar);

const createEquipmentRelocationRequestListSortOrderVar = (equipmentRelocationRequestListSortOrderVar: ReactiveVar<EquipmentRelocatedListSortOrder>) => {

  const setOrder = (orderModel: EquipmentRelocatedListSortOrder) => {
    equipmentRelocationRequestListSortOrderVar(orderModel);
  };

  return {
    setOrder
  };
};
