import React, { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";

import {
  SportEquipmentListFilters,
  sportEquipmentListFiltersVars,
  useSportEquipmentListFiltersVars
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListFilters";
import { sportObjectsVars } from "store/reactiveVarsStores/sport-objects/sportObjects";

import { Filter, FiltersToolbar } from "components/FiltersToolbar";
import Search from "components/FiltersToolbar/Search";
import Autocomplete from "components/FiltersToolbar/Autocomplete";
import Switch from "components/FiltersToolbar/Switch";

import { TechnicalCondition, useGetAllDictionariesQuery } from "graphql/graphQlApiHooks";
import FilterDateRange from "../../../../../../components/FiltersToolbar/DateRange";
import dayjs from "dayjs";

// TODO:
//    проверить как работает в фильтр по дате ввода
//    добавить фильтр по дате добавления

export const Filters = () => {

  const {
    filteredSportZoneList,
    filteredSportSubZoneList
  } = useReactiveVar(sportObjectsVars);
  const filterValues = useReactiveVar(sportEquipmentListFiltersVars);
  const { setFilters } = useSportEquipmentListFiltersVars();


  const { data: allDictionaries } = useGetAllDictionariesQuery();

  const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds;
  const sportCategories = allDictionaries?.getAllSportKindDictionary?.sportCategories;
  const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes;


  const filterChangeHandler = (filters: SportEquipmentListFilters) => {
    setFilters({
      ...filterValues,
      ...filters
    } as SportEquipmentListFilters);
  };


  function getAutocompleteLabel(arrayIds: any[], array: any[]) {
    const names = arrayIds ? array.filter((item) => arrayIds?.includes(item.id))
      .map((item) => `${item.name}`) : [];

    const result = names.length > 0 ? names.length > 2 ? names.slice(0, 2).join(", ") + " и ещё " + (names.length - 2) : names.join(", ") : [];

    return (result?.length === 0) ? `` : `${result}`;

  }


  const filters: Filter[] = useMemo(() => {

    const sportSubZonesOptions = filterValues?.sportZoneIds?.length > 0 ? [...filteredSportSubZoneList].filter(item =>
      filterValues?.sportZoneIds?.includes(item?.sportZone?.id)
    ) : filteredSportSubZoneList;


    const filtersList: Filter[] = [
      {
        name: "sportZoneIds",
        title: "Объекты спорта",
        value: filterValues?.sportZoneIds,
        options: filteredSportZoneList
          ?.slice()
          ?.sort((a, b) => -b?.name.localeCompare(a?.name))
          ?.sort((a, b) => -b.sportObject?.name.localeCompare(a.sportObject?.name)) || [],
        disableDelete: true,
        filterComponent: (props: any) => <Autocomplete
          groupBy={(option) => option?.sportObject?.name}
          filterResult={(result: any[]) => {
            if (filterChangeHandler) {
              filterChangeHandler({ "sportZoneIds": result.map(val => val.id) as string[] });
            }
          }}
          {...props}
        />,
        label: filterValues?.sportZoneIds ? getAutocompleteLabel(filterValues.sportZoneIds as any, filteredSportZoneList) : "",
        type: "autocomplete"
      },
      {
        name: "sportSubZoneIds",
        title: "Спортзона",
        value: filterValues?.sportSubZoneIds,
        options: sportSubZonesOptions
          ?.slice()
          ?.sort((a, b) => -b?.name.localeCompare(a?.name))
          ?.sort((a, b) => -b.sportZone?.name.localeCompare(a.sportZone?.name)) || [],
        disableDelete: true,
        filterComponent: (props: any) => <Autocomplete
          groupBy={(option) => option?.sportZone?.name}
          filterResult={(result: any[]) => {
            if (filterChangeHandler) {
              filterChangeHandler({ "sportSubZoneIds": result.map(val => val.id) as string[] });
            }
          }}
          {...props}
        />,
        label: filterValues?.sportSubZoneIds ? getAutocompleteLabel(filterValues.sportSubZoneIds as any, sportSubZonesOptions) : "",
        type: "autocomplete"
      },
      {
        name: "sportCategoryIds",
        title: "Категория спорта",
        value: filterValues?.sportCategoryIds,
        options: sportCategories
          ?.slice()
          ?.sort((a, b) => -b?.name.localeCompare(a?.name)) || [],
        filterComponent: (props: any) => <Autocomplete
          filterResult={(result: any[]) => {
            if (filterChangeHandler) {
              filterChangeHandler({ "sportCategoryIds": result.map(val => val.id) as string[] });
            }
          }}
          {...props}
        />,
        label: filterValues?.sportCategoryIds && sportCategories ? getAutocompleteLabel(filterValues.sportCategoryIds as any, sportCategories) : "",
        type: "autocomplete"
      },
      {
        name: "sportKindIds",
        title: "Вид спорта",
        value: filterValues?.sportKindIds,
        filterComponent: (props: any) => <Autocomplete
          groupBy={(option) => option?.sportCategory?.name}
          filterResult={(result: any[]) => {
            if (filterChangeHandler) {
              filterChangeHandler({ "sportKindIds": result.map(val => val.id) as string[] });
            }
          }}
          {...props}
        />,
        options: sportKinds
          ?.slice()
          ?.sort((a, b) => -b?.name.localeCompare(a?.name))
          ?.sort((a, b) => -b.sportCategory?.name.localeCompare(a.sportCategory?.name)) || [],
        label: filterValues?.sportKindIds && sportKinds ? getAutocompleteLabel(filterValues.sportKindIds as any, sportKinds) : "",
        type: "autocomplete"
      },
      {
        name: "equipmentTypeIds",
        title: "Тип оборудования",
        value: filterValues?.equipmentTypeIds,
        filterComponent: (props: any) => <Autocomplete
          groupBy={(option) => option?.sportKind?.name}
          filterResult={(result: any[]) => {
            if (filterChangeHandler) {
              filterChangeHandler({ "equipmentTypeIds": result.map(val => val.id) as string[] });
            }
          }}
          {...props}
        />,
        options: equipmentTypes
          ?.slice()
          ?.sort((a, b) => -b?.name.localeCompare(a?.name))
          ?.sort((a, b) => -b.sportKind?.name.localeCompare(a.sportKind?.name)) || [],
        label: filterValues?.equipmentTypeIds && equipmentTypes ? getAutocompleteLabel(filterValues.equipmentTypeIds as any, equipmentTypes) : "",
        type: "autocomplete"
      },
      {
        name: "inventoryNumber",
        title: "Инвентарный номер",
        value: filterValues?.inventoryNumber,
        filterComponent: (props: any) => <Search
          filterResult={(value) => {
            filterChangeHandler({ "inventoryNumber": value } as SportEquipmentListFilters);
          }}
          {...props}
        />,
        label: filterValues?.inventoryNumber ? ` содержит: "${filterValues.inventoryNumber}"` : "",
        type: "search"
      },
      {
        name: "accountingName",
        title: "Бухгалтерское название",
        value: filterValues?.accountingName,
        filterComponent: (props: any) => <Search
          filterResult={(value) => {
            filterChangeHandler({ "accountingName": value } as SportEquipmentListFilters);
          }}
          {...props}
        />,
        label: filterValues?.accountingName ? ` содержит: "${filterValues.accountingName}"` : "",
        type: "search"
      },
      {
        name: "technicalCondition",
        title: "Техническое состояние",
        value: filterValues?.technicalCondition,
        filterComponent: (props: any) => <Switch
          value={filterValues?.technicalCondition === TechnicalCondition.Working}
          labels={[
            { value: TechnicalCondition.Working, label: "Исправно" },
            { value: TechnicalCondition.NotWorking, label: "Не исправно" }
          ]}
          filterResult={result => {
            filterChangeHandler({ "technicalCondition": result } as SportEquipmentListFilters);
          }
          }
          {...props}
        />,
        label: filterValues?.technicalCondition ? ` "${filterValues.technicalCondition === TechnicalCondition.Working ? "Исправно" : "Не исправно"}"` : "",
        type: "switch"
      },
      {
        name: "inUse",
        title: filterValues?.inUse === null || filterValues?.inUse ? 'Используется' : "Не используется",
        value: filterValues?.inUse ,
        filterComponent: (props: any) => <Switch
          value={filterValues?.inUse}
          labels={[
            { value: true, label: "Используется" },
            { value: false, label: "Не используется" }
          ]}
          filterResult={result => {
            filterChangeHandler({ "inUse": result } as SportEquipmentListFilters);
          }
          }
          {...props}
        />,
        label: '',
        type: "switch"
      },
      {
        name: "commissioningDate",
        title: "Дата ввода в эксплуатацию",
        value: filterValues.commissioningDate,
        filterComponent: (props: any) => <FilterDateRange
          filterResult={(value) => {
            filterChangeHandler({ "commissioningDate": value } as SportEquipmentListFilters);
          }}
          {...props}
        />,
        label: (() => (filterValues?.commissioningDate && filterValues.commissioningDate.hasOwnProperty("label")) ? `: ${(filterValues.commissioningDate as any)?.label}` : ` с: ${dayjs(filterValues.commissioningDate?.startDate).format("DD.MM.YYYY")} по: ${dayjs(filterValues.commissioningDate?.endDate).format("DD.MM.YYYY")}`)(),
        type: "dateRange"
      }
    ] as Filter[];

    return filtersList;
  }, [
    filterValues,
    filteredSportZoneList,
    filteredSportSubZoneList,

    sportKinds,
    sportCategories,
    equipmentTypes
  ]);


  function deleteHandler(filter: Filter) {
    setFilters({
      ...filterValues,
      [filter.name]: null
    } as SportEquipmentListFilters);
  }

  function clearFiltersHandler() {
    const cleanedFilters = [...filters].reduce((a, filter) => ({ ...a, [filter.name]: null }), {});

    setFilters({ ...cleanedFilters } as SportEquipmentListFilters);
  }

  const onChangeFilterValue = (filterName: string, value: any) => {
    filterChangeHandler({ [filterName]: value });
  };

  return (
    <>
      <FiltersToolbar
        filters={filters}
        onDelete={deleteHandler}
        clearFilters={clearFiltersHandler}
        onChangeFilterValue={onChangeFilterValue}
        placeholder={"Фильтры"}
      />
    </>
  );
};