import React from "react";
import {EquipmentRequestEntity, useUpdateCombinedEquipmentRequestMutation} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {
    DeleteSingleRequestFromCombineRequestDialog
} from "../../components/Dialogs/DeleteSingleRequestFromCombineRequestDialog";

export const useDeleteSingleRequestFromCombineRequestAction = () => {
    const [dialog, closeDialog] = useDialog();


    const [updateCombinedEquipmentRequest, {loading}] = useUpdateCombinedEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests']
    });


    const handleDeleteSingleRequestFromCombineRequest = (singleRequest: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "Удаление заявки из объединённой заявки",
            maxWidth: "xs",
            fullWidth: true,
            children: <>
                <DeleteSingleRequestFromCombineRequestDialog
                    singleRequest={singleRequest}
                    combinedRequest={singleRequest.parentCombinedRequest}
                    onClose={() => closeDialog()}
                    onDelete={() => {
                        closeDialog()
                        updateCombinedEquipmentRequest({
                            variables: {
                                input: {
                                    id: singleRequest.parentCombinedRequestId,
                                    childRequestId: singleRequest.parentCombinedRequest.childRequests.map(item => item.id).filter(item => item !== singleRequest.id)
                                }
                            }
                        })
                    }}
                />
            </>
        });


    };

    return {
        handleDeleteSingleRequestFromCombineRequest
    }
};