import {
	SportObjectEntity,
	GetAllSportObjectsDocument,
	CreateSportObjectDocument,
	CreateSportObjectInput,
	DeleteSportObjectDocument,
	UpdateSportObjectInput,
	UpdateSportObjectDocument,
	SportZoneEntity,
	SportSubZoneEntity,
	CreateSportZoneInput,
	CreateSportZoneDocument,
	UpdateSportZoneInput,
	UpdateSportZoneDocument,
	DeleteSportZoneDocument,
	CreateSportSubZoneInput,
	CreateSportSubZoneDocument,
	UpdateSportSubZoneInput,
	UpdateSportSubZoneDocument, DeleteSportSubZoneDocument, SportEquipmentEntity
} from '../../graphql/graphQlApiHooks';
import {makeAutoObservable, runInAction} from "mobx";
import RootStore, {client} from "../.";
import {sortByCreateAt} from '../../helpers';

const settingsStore = class {
	public rootStore?: RootStore;

	public sportObjects: SportObjectEntity[] = [];
	public sportObject?: SportObjectEntity;

	public sportZones: SportZoneEntity[] = [];
	public sportZone?: SportZoneEntity;

	public sportSubZones: SportSubZoneEntity[] = [];
	public sportSubZone?: SportSubZoneEntity;

	public sportObjectsCount: number = 0
	public sportZonesCount: number = 0
	public sportSubZonesCount: number = 0

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)
	}

	public setSportObject = (sportObjectId: string) => {
		const current = this.sportObjects.find((obj: SportObjectEntity) => obj.id === sportObjectId)
		runInAction(() => this.sportObject = current);
		if (current?.sportZones) {
			this.setSportZones(current.sportZones)
		}
	};

	public setSportObjects = (sportObjects: SportObjectEntity[]) => {

		if (JSON.stringify(sportObjects) === JSON.stringify(this.sportObjects)) return
		runInAction(() => {
			this.sportObjects = sportObjects;
			this.sportObjectsCount = sportObjects.length;
		})

		if (!this.sportObject && sportObjects.length > 0) {
			this.setSportObject(sportObjects[0].id)
		}

		if (this.sportObject?.id) {
			this.setSportObject(this.sportObject?.id)
		}
	};

	public setSportZone = (sportZoneId: string) => {
		const sportZone = this.sportZones.find((zone: SportZoneEntity) => {
			return zone.id === sportZoneId
		})
		this.sportZone = sportZone
		if (sportZone && sportZone?.sportSubZones) {
			this.setSportSubZones(sportZone.sportSubZones)
		} else {
			this.sportSubZones = []
			this.sportSubZone = undefined
		}
	};

	public setSportZones = (sportZones: SportZoneEntity[]) => {
		this.sportZones = sportZones.slice().sort(sortByCreateAt);
		this.sportZonesCount = sportZones.length;
		if (sportZones.length > 0) {
			this.setSportZone(sportZones[0].id)
			if (sportZones[0]?.sportSubZones && sportZones[0].sportSubZones.length > 0) {
				this.setSportSubZones(sportZones[0].sportSubZones)
			}
		} else {
			this.sportZones = []
			this.sportZone = undefined
			this.sportSubZones = []
			this.sportSubZone = undefined
		}
	};

	public setSportSubZone = (sportSubZoneId: string) => {
		this.sportSubZone = this.sportSubZones.find((zone: SportSubZoneEntity) => {
			return zone.id === sportSubZoneId
		});
	};

	public setSportSubZones = (sportSubZones: SportSubZoneEntity[]) => {
		this.sportSubZones = sportSubZones.slice().sort(sortByCreateAt);
		this.sportSubZonesCount = sportSubZones.length
	};

	public hasEquipments = () => {
		const equipmentsBySportObjectId = this.rootStore?.equipmentsStore.equipmentsResponse.filter((equipment: SportEquipmentEntity) => {
			return equipment.sportObject.id === this.sportObject?.id
		})

		return Boolean(equipmentsBySportObjectId.length > 0)
	}

	sportObjectUpdate = (input: UpdateSportObjectInput) => client.mutate({
		mutation: UpdateSportObjectDocument,
		variables: {input},
		refetchQueries: [{query: GetAllSportObjectsDocument}]
	})
			.then(() => {
				this.rootStore?.setSnakeMessage('Спортобъект успешно обнавлён.')
			})
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	sportObjectCreate = (input: CreateSportObjectInput) => client.mutate({
		mutation: CreateSportObjectDocument,
		variables: {input},
		refetchQueries: [{query: GetAllSportObjectsDocument}]
	})
			.then((res) => {
				this.rootStore?.setSnakeMessage('Новый спортобъект успешно создан.');
				this.rootStore?.sportObjectsStore?.setUserSportObjects([...this.rootStore?.sportObjectsStore?.userSportObjects, res.data.createSportObject.id]);
			})
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	sportObjectDelete = (id: string) => client.mutate({
		mutation: DeleteSportObjectDocument,
		variables: {id},
		refetchQueries: [{query: GetAllSportObjectsDocument}]
	})
			.then(() => {
				this.rootStore?.setSnakeMessage('Спортобъект успешно удалён.')
				runInAction(() => {
					this.sportObject = undefined
				})
			})
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));


	sportZoneUpdate = (input: UpdateSportZoneInput) => client.mutate({
		mutation: UpdateSportZoneDocument,
		variables: {input},
		refetchQueries: [{query: GetAllSportObjectsDocument}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Объект спорта успешно обнавлён.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	sportZoneCreate = (input: CreateSportZoneInput) => client.mutate({
		mutation: CreateSportZoneDocument,
		variables: {input},
		refetchQueries: [{query: GetAllSportObjectsDocument}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Новый объект спорта успешно создан.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	sportZoneDelete = (id: string) => client.mutate({
		mutation: DeleteSportZoneDocument,
		variables: {id},
		refetchQueries: [{query: GetAllSportObjectsDocument}]
	})
			.then(() => {
				this.rootStore?.setSnakeMessage('Объекта спорта успешно удалён.');
				this.sportZone = undefined
			})
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));


	sportSubZoneUpdate = (input: UpdateSportSubZoneInput) => client.mutate({
		mutation: UpdateSportSubZoneDocument,
		variables: {input},
		refetchQueries: [
			{
				query: GetAllSportObjectsDocument,
			},
		]
	})
			.then(() => this.rootStore?.setSnakeMessage('Спортзона успешно обновлена.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	sportSubZoneCreate = (input: CreateSportSubZoneInput) => client.mutate({
		mutation: CreateSportSubZoneDocument,
		variables: {input},
		refetchQueries: [
			{
				query: GetAllSportObjectsDocument,
			},
		]
	})
			.then(() => this.rootStore?.setSnakeMessage('Новая спортзона успешно создана.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	sportSubZoneDelete = (id: string) => client.mutate({
		mutation: DeleteSportSubZoneDocument,
		variables: {id},
		refetchQueries: [
			{
				query: GetAllSportObjectsDocument,
			},
		]
	})
			.then(() => this.rootStore?.setSnakeMessage('Спортзона успешно удалена.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));
};


export default settingsStore;
