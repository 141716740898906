import {DepartmentEnum, UserEntity} from "../../../../../../graphql/graphQlApiHooks";

export interface Errors {
    notifiableUsers: boolean;
    responsibleUser: boolean;
}


export enum NoDepartmentEnum {
    NoDepartment = 'NoDepartment'
}

export type DepartmentEnumWithNoDepartment = DepartmentEnum & NoDepartmentEnum;

export interface IUser extends UserEntity {
    group?: string;
    departments?: DepartmentEnumWithNoDepartment[];
    email?: string;
}