import React from "react";
import { Outlet } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        flexDirection:'column'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
    }
}));

const MainLayout = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/*<TopBar/>*/}
            <Header/>
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
