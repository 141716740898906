import makeStyles from "@mui/styles/makeStyles";
import {lighten, Theme} from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    sportAreasList: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        '& .MuiListSubheader-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

        }
    },
    list: {
        maxWidth: 360,
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        background: 'white',
        padding: 8,
        '& $list':{
            padding: 0
        },
        '& .MuiListSubheader-root': {
            letterSpacing: '0.05em',
            color: '#2f2f33',
            fontSize: 14,
            lineHeight: '14px',
            fontWeight: 500,
            padding: '0 0 16px 0',
        },
        '& .MuiList-root': {
            height: 'calc(100% - 40px)',
            overflowY: 'auto',
        },
        '& .MuiListItem-root': {
            transition: 'all .3s',
            '&:hover': {
                backgroundColor: lighten(theme.palette.background.default, 0.2),
                '& + $listItemSecondaryAction': {
                    opacity: 1,
                }
            },
            '&.Mui-selected': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.default,
            },
        }
    },
    listItem: {
        padding: theme.spacing(1, 2),
        '& .MuiListItemIcon-root':{
            minWidth: 'auto',
            marginRight: theme.spacing(1),
            '& .MuiChip-root':{
                borderRadius: theme.shape.borderRadius,
                height: 18,
                '& .MuiChip-label':{
                    padding: theme.spacing(0, .5),
                    fontSize: '10px'
                }
            }
        }
    },
    listItemSecondaryAction: {
        right: '8px',
        opacity: 0,
        transition: 'all .3s',
        '&:hover': {
            opacity: 1,
        },
        '& .MuiButtonBase-root': {
            background: 'transparent',
        }
    },
    subHeaderDetails: {},
    sportZoneCreatePanel: {},
    sportZoneCreatePanelInput: {},
    sportZoneCreatePanelBottomPanel: {},
    sportZoneMoreAction: {},

}));
