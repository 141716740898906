import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Drawer, Hidden, List } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NavItem from "./NavItem";
import { items } from "./navItems";
import { Theme } from "@mui/material/styles";
import LogOut from "components/logout";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../store";
import { DepartmentEnum } from "../../../../../store/department.enum";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`
  },
  mobileDrawer: {
    width: 280
  },
  drawer: {
    position: "relative",
    zIndex: 1
  },
  desktopDrawer: {
    position: "relative",
    display: "none",
    overflow: "hidden",
    height: "100%",
    background: "transparent",
    border: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: 70,
      boxSizing: "content-box"
    }
  },
  logo: {
    marginTop: 15,
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "left",
      "& a": {
        display: "flex",
        alignItems: "center",
        "& h3": {
          color: theme.palette.primary.main
        }
      }
    }
  },
  navBarScroll: {
    padding: 0
  },
  listItems: {
    // padding: theme.spacing(1)
    marginTop: "1rem",
    flexGrow: 1,
    flexDirection: "column",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
      marginRight: "1rem",
      flexGrow: 0,
    }

  },
  listItemsScroll: {
    paddingRight: 4
  }
}));

interface INavBarProps {
  onMobileClose: () => void,
  openMobile: boolean,

  [key: string]: any
}


const NavBar = observer(({ onMobileClose, openMobile, ...rest }: INavBarProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { userStore } = useStores();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  const content = (
    <Box
      className={classes.root}
    >
      <div className={classes.listItems}>
        <List disablePadding>
          {items.filter(
            (item) => {
              const user = JSON.parse(localStorage.getItem('user') || '{}');
              const isExternalUser = user?.departments?.some((department: DepartmentEnum) => {
                return department === DepartmentEnum.EquipmentAcceptanceAndDiagnostics ||
                  department === DepartmentEnum.SportObject
              });

              return !(!isExternalUser && item.title === "Аренда оборудования");

            }
          ).map((item) => (
            <NavItem
              className={"NavItem"}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </div>
      <Hidden mdDown>
        <Box flexGrow={1} />
      </Hidden>


      {!userStore.currentUser?.user.isForSportObject && <div
        className={classes.listItems}
        style={{ marginBottom: "1rem", flexGrow: 0 }}
      >
        <NavItem
          className={"NavItem"}
          href={"/settings/"}
          key={"Настройки"}
          title={"Настройки"}
          icon={"settings"}
        />

        <Hidden mdUp>
          <LogOut />
        </Hidden>
      </div>}

    </Box>
  );


  return <>
    <Hidden mdUp>
      <Drawer
        anchor='left'
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant='temporary'
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden mdDown>
      <Drawer
        anchor='left'
        classes={{
          root: classes.drawer,
          paper: classes.desktopDrawer
        }}
        open
        variant='persistent'
        {...rest}
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
});

export default NavBar;
