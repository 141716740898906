import {GridColumns, GridRowId} from "@mui/x-data-grid";
import {RequestEntity} from "../../types/RequestEntity";
import {ActionsCellRenderer} from "./components/RenderCells/ActionsCellRenderer";
import {RequestNameCellRenderer} from "./components/RenderCells/RequestNameCellRenderer";
import {DateCellRenderer} from "./components/RenderCells/DateCellRenderer";
import {CountCellRenderer} from "./components/RenderCells/CountCellRenderer";
import {PriceCellRenderer} from "./components/RenderCells/PriceCellRenderer";
import {SportObjectNameCellRenderer} from "./components/RenderCells/SportObjectNameCellRenderer";
import {SportKindNameCellRenderer} from "./components/RenderCells/SportKindNameCellRenderer";
import {RequestStatusCellRenderer} from "./components/RenderCells/RequestStatusCellRenderer";
import React from "react";


export const columns = (
    {
        toggleGroup,
        selected,
        handleCheckboxChange,
    }: {
        toggleGroup: (groupId: string) => void;
        selected: GridRowId[];
        handleCheckboxChange: (id: GridRowId) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    }): GridColumns<RequestEntity> => [
    {
        field: 'request.name',
        headerName: 'Название необходимого оборудования',
        renderCell: ({row}) =>
            <RequestNameCellRenderer
                equipmentRequestEntity={row}
                toggleGroup={toggleGroup}
                selected={selected}
                handleCheckboxChange={handleCheckboxChange}
            />,
        flex: 3,
        editable: false,
        disableColumnMenu: true,
        minWidth: 300
    },
    {
        field: 'request.createdAt',
        headerName: 'Дата подачи заявки',
        type: 'date',
        flex: 1,
        renderCell: ({row}) => <DateCellRenderer equipmentRequestEntity={row}/>,
        minWidth: 120,
        disableColumnMenu: true,
        align: 'center',
        editable: false,
        headerAlign: 'center'
    },
    {
        field: 'request.count', headerName: 'Количество оборудования',
        flex: .5,
        renderCell: ({row}) => <CountCellRenderer equipmentRequestEntity={row}/>,
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'request.price',
        headerName: 'Стоимость',
        flex: 1,
        renderCell: ({row}) => <PriceCellRenderer equipmentRequestEntity={row}/>,
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'sportObject.name',
        headerName: 'Спортобъект',
        renderCell: ({row}) => <SportObjectNameCellRenderer equipmentRequestEntity={row}/>,
        flex: 1.5,
        editable: false,
        disableColumnMenu: true,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'request.sportKindObject.name', headerName: 'Вид спорта',
        renderCell: ({row}) => <SportKindNameCellRenderer equipmentRequestEntity={row}/>,
        flex: 1.5,
        editable: false,
        disableColumnMenu: true,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'request.requestStatus',
        headerName: 'Статус заявки',
        renderCell: ({row}) => <RequestStatusCellRenderer equipmentRequestEntity={row}/>,
        flex: 1.75,
        editable: false,
        align: 'left',
        headerAlign: 'left',
        disableColumnMenu: true,
        minWidth: 250
    },
    {
        field: 'actions', headerName: 'Действия',
        renderCell: ({row}) => <ActionsCellRenderer equipmentRequestEntity={row}/>,
        disableColumnMenu: true,
        flex: 1.25,
        minWidth: 150,
        editable: false,
        sortable: false,
        hideSortIcons: true,
        align: 'right',
        headerAlign: 'right'
    },
];








