import React from "react";
import {Box, Button, TablePagination, Tooltip} from "@mui/material";
import {GridSelectedRowCount} from "@mui/x-data-grid/components/GridSelectedRowCount";
import {useGridApiContext} from "@mui/x-data-grid";

interface Props {
    selected: any;
    onTakeToWorkHandler: () => void;
    isDisabled: boolean;
    selectedAvailable: number;
    onCreateCombinedEquipmentRequest: () => void
    onAddToCombinedEquipmentRequest: () => void
    combinedRequestsLength: number
}


export const Footer = (props: Props) => {

    return (
        <>
            <Box sx={{px: 3, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Box display={"flex"} gap={2}>

                    {props.selected.length > 0 ? <>
                        <GridSelectedRowCount selectedRowCount={props.selected.length}/>
                        <Tooltip
                            title={`Принять в работу можно только заявки со статусом 'На рассмотрении', для принятия в работу доступно ${props.selectedAvailable} заявок`}
                        >
                            <Box>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    sx={{py: .5, px: 2, mr: 2}}
                                    onClick={props.onTakeToWorkHandler}
                                    disabled={props.isDisabled}
                                >Принять в работу</Button>
                            </Box>
                        </Tooltip>

                        <Box>
                            <Button
                                color={"primary"}
                                variant={"outlined"}
                                size={"small"}
                                sx={{py: .5, px: 2, mr: 2}}
                                onClick={props.onCreateCombinedEquipmentRequest}
                                disabled={props.selected.length === 1}
                            >Объединить заявки</Button>
                        </Box>

                        <Box>
                            <Button
                                color={"primary"}
                                variant={"outlined"}
                                size={"small"}
                                sx={{py: .5, px: 2, mr: 2}}
                                onClick={props.onAddToCombinedEquipmentRequest}
                            >Добавить в объединенную</Button>
                        </Box>

                    </> : null}
                </Box>
                {
                    <CustomPagination

                    />
                }
            </Box>
        </>
    );
};

function CustomPagination() {
    const apiRef = useGridApiContext();

    const handleRowsPerPageChange = (event) => {
        apiRef.current.setPageSize(parseInt(event.target.value, 10));
    };

    return (
        <TablePagination
            component="div"
            count={apiRef.current.state.pagination.pageCount * apiRef.current.state.pagination.pageSize}
            page={apiRef.current.state.pagination.page}
            onPageChange={(event, newPage) => apiRef.current.setPage(newPage)}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={apiRef.current.state.pagination.pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            SelectProps={{
                MenuProps: {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                }
            }}
        />
    );
}