import { makeVar, ReactiveVar } from "@apollo/client";
import { DateRange } from "components/mui-daterange-picker";
import { TechnicalCondition } from "../../../graphql/graphQlApiHooks";

export type SportEquipmentListFilters = {
  name?: string;
  inventoryNumber?: string;
  accountingName?: string;

  archived?: boolean;
  isWroteOff?: boolean;

  commissioningDate?: DateRange;
  commissioningDateFrom?: string;
  commissioningDateTo?: string;

  createdAtFrom?: string;
  createdAtTo?: string;

  inUse?: boolean;
  technicalCondition?: TechnicalCondition;

  equipmentTypeIds?: string[];
  sportCategoryIds?: string[];
  sportKindIds?: string[];

  sportSubZoneIds?: string[];
  sportZoneIds?: string[];
};

export const SportEquipmentListFiltersInitial: SportEquipmentListFilters = {
  name: null,
  inventoryNumber: null,
  accountingName: null,

  archived: null,
  isWroteOff: null,

  sportZoneIds: null,
  sportSubZoneIds: null,

  sportKindIds: null,
  equipmentTypeIds: null,
  sportCategoryIds: null,

  inUse: null,
  technicalCondition: null,

  commissioningDate: null,
  commissioningDateTo: null,
  commissioningDateFrom: null,
  createdAtTo: null,
  createdAtFrom: null,
};

export const sportEquipmentListFiltersVars = makeVar<SportEquipmentListFilters>(
  SportEquipmentListFiltersInitial
);

export const useSportEquipmentListFiltersVars = () => createSportEquipmentListFiltersVars(sportEquipmentListFiltersVars);

const createSportEquipmentListFiltersVars = (sportEquipmentListFiltersVars: ReactiveVar<SportEquipmentListFilters>) => {

  const setFilters = (newFilters: SportEquipmentListFilters) => {
    sportEquipmentListFiltersVars({ ...newFilters });
  };

  return {
    setFilters
  };
};
