import React from "react";
import { Box, Tooltip } from "@mui/material";
import { LoadingButton } from "./LoadingButton.styled";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { BootstrapTooltip } from "../../../../../../../../components/BootstrapTooltip";

interface Props {
  loading: boolean;

  actions: {
    exportSportEquipmentListInExcel: () => void
  };
}

export const ExportButton = (props: Props) => {
  const { loading, actions: { exportSportEquipmentListInExcel } } = props;

  return (

    <LoadingButton
      loading={loading}
      onClick={exportSportEquipmentListInExcel}
    >
      <BootstrapTooltip title={"Сгенерировать Excel файл"}>
        <Box sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          p: .4,
          color: "text.primary",
          fontSize: "20px"
        }}>
          {!loading &&
            <FileDownloadOutlinedIcon fontSize={"inherit"} />}
        </Box>
      </BootstrapTooltip>
    </LoadingButton>

  );
};