import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Settings from "store/settings";

import { Autocomplete, FormControl, Stack, TextField, Typography } from "@mui/material";
import { lighten, styled } from "@mui/material/styles";

import { FormikErrors, FormikTouched } from "formik/dist/types";

import {
  EquipmentTypeDictionaryEntity,
  SportCategoryDictionaryEntity,
  SportEquipmentEntity,
  SportKindDictionaryEntity,
  useGetAllSportKindDictionaryQuery
} from "graphql/graphQlApiHooks";

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: lighten(theme?.palette?.text?.secondary, .1),
  backgroundColor: lighten(theme?.palette?.background?.default, .1),
  fontWeight: "bold"
}));

export const GroupItems = styled("ul")({
  padding: 0
});


interface Props {
  vertical?: boolean;
  touched?: FormikTouched<any>;
  errors?: FormikErrors<any>;
  sportEquipment: SportEquipmentEntity;
  setSportEquipment: (values: React.SetStateAction<SportEquipmentEntity>) => void;
  isEditable?: boolean;
  handleBlur?: (event: React.FocusEvent<any>) => void;
}

export const SportEquipmentCategory = (props: Props) => {
  const {
    vertical,
    errors,
    touched,
    sportEquipment,
    isEditable,
    setSportEquipment,
    handleBlur
  } = props;

  const { data: allDictionaries } = useGetAllSportKindDictionaryQuery({
    pollInterval: Settings.pollIntervals.dictionaries
  });


  const [sportCategory, setSportCategory] = useState<SportCategoryDictionaryEntity | null>(null);
  const [equipmentType, setEquipmentType] = useState<EquipmentTypeDictionaryEntity | null>(null);
  const [sportKind, setSportKind] = useState<SportKindDictionaryEntity | null>(null);

  const sportCategories: SportCategoryDictionaryEntity[] = useMemo(() => {
    const sportCategories = allDictionaries?.getAllSportKindDictionary?.sportCategories as SportCategoryDictionaryEntity[];
    if (sportCategories) {
      return sportCategories;
    }
    return [];

  }, [
    allDictionaries?.getAllSportKindDictionary?.sportCategories
  ]);

  const sportKinds: SportKindDictionaryEntity[] = useMemo(() => {
    const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds as SportKindDictionaryEntity[];

    if (sportCategory && sportKinds) {
      return [...sportKinds].filter(item => item.sportCategoryId === sportCategory.id);
    }

    if (sportKinds) {
      return sportKinds;
    }

    return [];

  }, [
    allDictionaries?.getAllSportKindDictionary?.equipmentTypes,
    sportCategory
  ]);

  const equipmentTypes: EquipmentTypeDictionaryEntity[] = useMemo(() => {
    const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes as EquipmentTypeDictionaryEntity[];

    if (sportCategory && !sportKind && equipmentTypes) {
      const sportKindIds = [...sportKinds].map(item => item.id);
      return [...equipmentTypes].filter(item => sportKindIds.includes(item.sportKindId));
    }

    if (sportKind && equipmentTypes) {
      return [...equipmentTypes].filter(item => item.sportKindId === sportKind.id);
    }

    if (equipmentTypes) {
      return equipmentTypes;
    }

    return [];


  }, [
    allDictionaries?.getAllSportKindDictionary?.equipmentTypes,
    sportKind,
    sportCategory
  ]);


  useEffect(() => {
    if (sportEquipment?.sportCategoryObject?.id && !sportCategory && sportCategories.length > 0) {
      setSportCategory(sportCategories.find(item => item.id === sportEquipment.sportCategoryObject.id));
    }
    if (sportEquipment?.sportKindObject?.id && !sportKind && sportKinds.length > 0) {
      setSportKind(sportKinds.find(item => item.id === sportEquipment.sportKindObject.id));
    }
    if (sportEquipment?.equipmentTypeObject?.id && !equipmentType && equipmentTypes.length > 0) {
      setEquipmentType(equipmentTypes.find(item => item.id === sportEquipment.equipmentTypeObject.id));
    }
  }, [
    sportEquipment?.sportCategoryObject?.id,
    sportEquipment?.sportKindObject?.id,
    sportEquipment?.equipmentTypeObject?.id,
    sportCategory,
    sportKind,
    equipmentType,
    sportCategories,
    sportKinds,
    equipmentTypes
  ]);


  function onChangeSportCategoryHandler(value: SportCategoryDictionaryEntity | null) {
    const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds?.filter(item => item.sportCategoryId === value?.id) as SportKindDictionaryEntity[];
    const sportKind = sportKinds.length === 1 ? sportKinds[0] : null;
    const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes?.filter(item => item.sportKindId === sportKind?.id) as EquipmentTypeDictionaryEntity[];
    const equipmentType = equipmentTypes.length === 1 ? equipmentTypes[0] : null;

    setSportCategory(value);
    setSportKind(sportKind);
    setEquipmentType(equipmentType);

    setSportEquipment(prevState => ({
      ...prevState,
      sportCategoryObject: value ? value : null,
      sportKindObject: sportKind ? sportKind : null,
      equipmentTypeObject: equipmentType ? equipmentType : null
    }));
  }

  function onChangeSportKindHandler(value: SportKindDictionaryEntity | null) {
    const sportCategory = sportCategories.find(item => item?.id === value?.sportCategoryId) || null;
    const equipmentTypes = allDictionaries?.getAllSportKindDictionary?.equipmentTypes?.filter(item => item.sportKindId === value?.id) as EquipmentTypeDictionaryEntity[];
    const equipmentType = equipmentTypes.length === 1 ? equipmentTypes[0] : null;

    setSportCategory(sportCategory);
    setSportKind(value);
    setEquipmentType(equipmentType);


    setSportEquipment(prevState => ({
      ...prevState,
      sportCategoryObject: sportCategory ? sportCategory : null,
      sportKindObject: value ? value : null,
      equipmentTypeObject: equipmentType ? equipmentType : null
    }));

  }

  function onChangeEquipmentTypeHandler(value: EquipmentTypeDictionaryEntity | null) {
    const sportKind = sportKinds.find(item => item?.id === value?.sportKindId) || null;
    const sportCategory = sportCategories.find(item => item?.id === sportKind.sportCategoryId) || null;

    setSportCategory(sportCategory);
    setSportKind(sportKind);
    setEquipmentType(value);


    setSportEquipment(prevState => ({
      ...prevState,
      sportCategoryObject: sportCategory ? sportCategory : null,
      sportKindObject: sportKind ? sportKind : null,
      equipmentTypeObject: value ? value : null
    }));
  }

  return (
    <>
      <Stack spacing={3}>
        <Typography variant={"h5"}>
          Категория спорта
        </Typography>
        <Stack direction={vertical ? "column" : "row"} spacing={vertical ? 2 : 1}>
          <FormControl
            size={"small"}
            fullWidth
          >
            <Autocomplete
              value={sportCategory}
              id="sportCategory"
              disabled={sportCategories?.length === 0}
              readOnly={!isEditable}
              {...{
                ...(!isEditable && {
                  popupIcon: null
                })
              }}
              options={
                sportCategories
                  .slice()
                  .sort((a, b) => -b?.name.localeCompare(a?.name))
              }
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: SportCategoryDictionaryEntity) => onChangeSportCategoryHandler(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Категория спорта"
                  size={"small"}
                  placeholder="Выберите категорию спорта"
                  variant={"filled"}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}

                  name={"sportCategoryId"}

                  onBlur={handleBlur}

                  {
                    ...{
                      ...(touched?.sportCategoryId && errors?.sportCategoryId && { error: true }),
                      ...(touched?.sportCategoryId && errors?.sportCategoryId && { helperText: errors?.sportCategoryId as ReactNode })
                    }
                  }

                />
              )}
            />
          </FormControl>
          <FormControl
            size={"small"}
            fullWidth
            variant="outlined"
          >
            <Autocomplete
              value={sportKind}
              id="sportKind"
              disabled={sportKinds.length === 0}
              readOnly={!isEditable}
              {...{
                ...(!isEditable && {
                  popupIcon: null
                })
              }}
              options={
                sportKinds
                  .slice()
                  .sort((a, b) => -b?.name.localeCompare(a?.name))
                  .sort((a, b) => -b?.sportCategory?.name.localeCompare(a?.sportCategory?.name))
              }
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: SportKindDictionaryEntity) => onChangeSportKindHandler(value)}
              groupBy={(option) => option?.sportCategory?.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Вид спорта"
                  size={"small"}
                  placeholder="Выберите вид спорта"
                  variant={"filled"}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}

                  name={"sportKindId"}

                  onBlur={handleBlur}

                  {
                    ...{
                      ...(touched?.sportKindId && errors?.sportKindId && { error: true }),
                      ...(touched?.sportKindId && errors?.sportKindId && { helperText: errors?.sportKindId as ReactNode })
                    }
                  }

                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </FormControl>
          <FormControl
            size={"small"}
            fullWidth
            variant="outlined"
          >
            <Autocomplete
              value={equipmentType}
              id="equipmentType"
              disabled={equipmentTypes.length === 0}
              readOnly={!isEditable}
              {...{
                ...(!isEditable && {
                  popupIcon: null
                })
              }}
              options={
                equipmentTypes
                  .slice()
                  .sort((a, b) => -b?.name.localeCompare(a?.name))
                  .sort((a, b) => -b?.sportKind?.name.localeCompare(a?.sportKind?.name))
              }
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: EquipmentTypeDictionaryEntity) => onChangeEquipmentTypeHandler(value)}
              groupBy={(option) => option?.sportKind?.name}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Тип оборудования"
                  size={"small"}
                  placeholder="Выберите тип оборудования"
                  variant={"filled"}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}

                  name={"equipmentTypeId"}

                  onBlur={handleBlur}

                  {
                    ...{
                      ...(touched?.equipmentTypeId && errors?.equipmentTypeId && { error: true }),
                      ...(touched?.equipmentTypeId && errors?.equipmentTypeId && { helperText: errors?.equipmentTypeId as ReactNode })
                    }
                  }
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};
