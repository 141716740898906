import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import React, {useState} from "react";
import {DataGrid, GridColDef, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid";
import {formatPrice} from "helpers/formatPrice";
import {Box, Fade, IconButton, Stack, tableClasses, Tooltip, Typography} from "@mui/material";
import Iconify from "components/Iconify";
import {RenderDescriptionCell} from "../RenderCells/RenderDescriptionCell";
import Tooltiper from "components/Tooltiper";
import DateViewer from "components/DateViewer";

import {Tooltip as CustomTooltip} from "../../../ui/Tooltip";
import {useViewRequestAction} from "../../../../hooks/actions/useViewRequestAction";
import {
    useDeleteSingleRequestFromCombineRequestAction
} from "../../../../hooks/actions/useDeleteSingleRequestFromCombineRequestAction";

type Props = {
    equipments: EquipmentRequestEntity[];
    disableEdit?: boolean
};

export const CombinedRequestChildTable: React.FC<Props> = ({equipments, disableEdit = false}) => {
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'name', sort: 'asc'}]);

    const {handleView} = useViewRequestAction();
    const {handleDeleteSingleRequestFromCombineRequest} = useDeleteSingleRequestFromCombineRequestAction();


    const columns: GridColDef<EquipmentRequestEntity>[] = [
        {
            field: 'index',
            headerName: '#',
            flex: 0.1,
            sortable: false,
            editable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => params.api.getRowIndex(params.id) + 1
        },
        {
            field: 'name',
            headerName: 'Оборудование',
            flex: 2,
            editable: false,
            disableColumnMenu: true,
            renderCell: ({row: equipment}: { row: EquipmentRequestEntity }) => (
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} gap={2}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        width: '100%',
                        minWidth: 0
                    }}>
                        {equipment?.urgentRequirementComment && (
                            <Box>
                                <CustomTooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{timeout: 300, exit: false}}
                                    disableFocusListener
                                    title={
                                        <Stack direction={'row'} spacing={2}>
                                            <Box display={'flex'}>
                                                <Iconify
                                                    icon="line-md:alert-twotone"
                                                    sx={{
                                                        fontSize: 20,
                                                        color: 'error.main',
                                                    }}
                                                />
                                            </Box>
                                            <Stack spacing={1}>
                                                <Typography
                                                    sx={{whiteSpace: 'nowrap'}}
                                                    variant={'caption'}
                                                    color={'error.main'}
                                                >
                                                    Срочная заявка!
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        textOverflow: "ellipsis",
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {equipment?.urgentRequirementComment}
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    }
                                >
                                    <Box display={'flex'} width={24} height={24} alignItems={'center'}
                                         justifyContent={'center'}>
                                        <Iconify
                                            icon="cuida:alert-outline"
                                            sx={{
                                                fontSize: 21,
                                                color: 'error.main',
                                                mr: -1
                                            }}
                                        />
                                    </Box>
                                </CustomTooltip>
                            </Box>
                        )}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'start'}
                            width={'100%'}
                            gap={0}
                            minWidth={0}
                        >
                            <Tooltiper
                                text={equipment?.name}
                                props={{
                                    variant: 'subtitle1',
                                    sx: {
                                        mb: 0,
                                        transition: 'color 200ms ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'primary.main'
                                        }
                                    },
                                    onClick: () => {
                                        handleView(equipment)
                                    }

                                }}
                            />
                            <Box sx={{color: 'gray.500', fontSize: '0.85em'}}>
                                <DateViewer date={equipment?.createdAt} showIcon={false}/>
                            </Box>
                        </Box>
                        {equipment?.description && (
                            <RenderDescriptionCell equipmentRequest={equipment}/>
                        )}
                    </Box>
                </Box>
            ),
        },
        {
            field: 'count',
            headerName: 'Количество',
            type: 'number',
            editable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            renderCell: ({row: equipment}: { row: EquipmentRequestEntity }) => (
                <Tooltiper props={{textAlign: 'center'}}
                           text={equipment.count + (equipment?.unitOfMeasurement?.name || '')}/>
            ),
        },
        {
            field: 'price',
            headerName: 'Цена',
            editable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => <Tooltiper props={{sx: {textAlign: 'center'}}}
                                                                     text={formatPrice((params?.value || 0))}/>,
            flex: .5,
            minWidth: 100
        },
        ...(!disableEdit ? [{
            field: 'actions',
            headerName: '',
            editable: false,
            disableColumnMenu: true,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => <>
                <Box>
                    <Tooltip title="Убрать из заявки">
                        <IconButton size="small"
                                    onClick={() => handleDeleteSingleRequestFromCombineRequest(params.row)}
                        >
                            <Box display={'flex'} width={24} height={24} alignItems={'center'}
                                 justifyContent={'center'}>
                                <Iconify sx={{fontSize: '1.2rem'}} icon={"mage:box-minus"}/>
                            </Box>
                        </IconButton>
                    </Tooltip>
                </Box>
            </>,
            width: 68,
            sortable: false
        } as GridColDef<EquipmentRequestEntity, any, any>] : [])
    ];

    const total = equipments.reduce((acc, equipment) => acc + (equipment?.price || 0) * (parseInt(equipment?.count) || 0), 0);

    return (
        <Box sx={{height: 232, width: '100%', border: '1px solid', borderColor: 'divider', borderRadius: '4px'}}>
            <DataGrid
                className={tableClasses.root}
                headerHeight={35}
                rowHeight={50}
                components={{
                    Footer: () => <Box sx={{pr: !disableEdit ? '68px' : 2, py: 1, textAlign: 'right'}}>
                        <Typography variant="h6">Итого: {formatPrice(total)}</Typography>
                    </Box>
                }}
                rows={equipments}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                getRowId={(row) => row.id}
            />

        </Box>
    );
};
